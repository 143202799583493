import React from 'react';
import { AdVariant } from 'types/adEditorTypes';
import { AdChannel, AdCreativeType, AdPlacement } from 'types/adTypes';
import AdEditorFeedIcon from 'components/ad-builder/ad-editor-preview-icons/FeedIcon';
import AdEditorStoryIcon from 'components/ad-builder/ad-editor-preview-icons/StoryIcon';
import SocialIcon from 'components/icons/SocialIcon';
import { uniqueId } from 'lodash';

export const getFormattedChannel = (channel: AdChannel) => {
  switch (channel) {
    case AdChannel.FACEBOOK:
      return 'Facebook';
    case AdChannel.INSTAGRAM:
      return 'Instagram';
    case AdChannel.LINKEDIN:
      return 'LinkedIn';
    case AdChannel.TIKTOK:
      return 'TikTok';
    case AdChannel.INDEED:
      return 'Indeed';
    case AdChannel.GOOGLE:
      return 'Google';
  }
};

export const getChannelIcon = (channel: AdChannel, size?: number) => {
  if (!size) size = 16;
  return (
    <SocialIcon
      company={channel}
      width={`${size}px`}
      height={`${size}px`}
      color={'currentColor'}
      grayScale
    />
  );
};

export const getVariantIcon = (
  placement: AdPlacement,
  creativeType = AdCreativeType.IMAGE
) => {
  switch (placement) {
    case AdPlacement.FEED:
      switch (creativeType) {
        case AdCreativeType.IMAGE:
          return <AdEditorFeedIcon />;
        case AdCreativeType.VIDEO:
          return <AdEditorFeedIcon isVideo />;
      }
    // eslint-disable-next-line no-fallthrough
    case AdPlacement.STORIES:
      switch (creativeType) {
        case AdCreativeType.IMAGE:
          return <AdEditorStoryIcon />;
        case AdCreativeType.VIDEO:
          return <AdEditorStoryIcon isVideo />;
      }
    // eslint-disable-next-line no-fallthrough
    case AdPlacement.REELS:
      return <AdEditorStoryIcon isReel />;
  }
};

export const isVariantSupported = (
  placement: AdPlacement,
  channel: AdChannel,
  creativeType: AdCreativeType
) => {
  switch (placement) {
    case AdPlacement.FEED:
      switch (creativeType) {
        case AdCreativeType.IMAGE:
        case AdCreativeType.VIDEO:
          switch (channel) {
            case AdChannel.FACEBOOK:
            case AdChannel.INSTAGRAM:
            case AdChannel.LINKEDIN:
              return true;
            default:
              return false;
          }
      }
    // eslint-disable-next-line no-fallthrough
    case AdPlacement.STORIES:
      switch (creativeType) {
        case AdCreativeType.IMAGE:
        case AdCreativeType.VIDEO:
          switch (channel) {
            case AdChannel.FACEBOOK:
            case AdChannel.INSTAGRAM:
              return true;
            default:
              return false;
          }
      }
    // eslint-disable-next-line no-fallthrough
    case AdPlacement.REELS:
      switch (creativeType) {
        case AdCreativeType.VIDEO:
          switch (channel) {
            case AdChannel.FACEBOOK:
            case AdChannel.INSTAGRAM:
            case AdChannel.TIKTOK:
              return true;
            default:
              return false;
          }
        default:
          return false;
      }
    default:
      return false;
  }
};

export const DEFAULT_VARIANT: AdVariant = {
  id: undefined,
  path: null,
  function: '',
  company: '',
  headline: '',
  display_link: undefined,
  logo: null,
  description: '',
  text: '',
  isDeleted: false,
  isDirty: true,
  placement: AdPlacement.FEED,
  creative_type: AdCreativeType.IMAGE,
  svg: null,
  uuid: uniqueId(),
};
