import { CardsKeyFields } from '@symplbe/sympl-components';
import { WithPlaceholder } from 'types/careers-page/section';
import { getSectionHeaderDefaultConfig } from '../shared/defaultConfig';
import { getEmoji, getBuzzNoun, getParagraph } from 'data/faker';

export const defaultCardSettings: CardsKeyFields & WithPlaceholder = {
  ...getSectionHeaderDefaultConfig(),
  cards: [...Array(3)].map(() => ({
    emoji: getEmoji(),
    title: getBuzzNoun(),
    content: getParagraph(),
    isPlaceholder: true,
  })),
};
