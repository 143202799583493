import tw, { styled } from 'twin.macro';
import { useMutation } from 'hooks/sympl-mutation';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Button from 'components/button/Button';
import { Modal } from 'components/page/app-page/Modal';
import Input from 'components/form/input/Input';
import ToolTip from 'components/tooltip/ToolTip';
import { VacancySpendStatus } from 'types/vacancyTypes';
import { TOGGLE_CAMPAIGN } from 'graphql/vacancies/mutations';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';
import { fireEvent } from 'utils/eventHelper';
import Checkbox from 'components/checkbox/Checkbox';
import InformationToolTip from 'components/tooltip/InformationToolTip';
import { Routes } from 'types/routeTypes';
import { useNavigate } from 'react-router-dom';

interface ToggleCampaignButtonProps {
  id: number;
  campaignStatus: VacancySpendStatus;
  disabled?: boolean;
  onStop: () => void;
  disabledReason?: string;
}

interface ToggleCampaignPayload {
  id: number;
  input: {
    status: VacancySpendStatus;
    transfer_budget: boolean;
    reason?: string;
  };
}

interface StopCampaignReason {
  value: string;
  text: string;
}

const ToggleCampaignButton: React.FC<ToggleCampaignButtonProps> = ({
  id,
  campaignStatus,
  disabled = false,
  onStop,
  disabledReason,
}) => {
  const navigate = useNavigate();
  const { addToast } = useToastNotifications();
  const [isModalShown, setIsModalShown] = useState(false);
  const [reason, setReason] = useState<string>('');
  const [transferBudget, setTransferBudget] = useState<boolean>(false);

  useEffect(() => {
    if (campaignStatus === VacancySpendStatus.ACTIVE) setTransferBudget(true);
    else setTransferBudget(false);
  }, [campaignStatus]);

  const { control, handleSubmit, register } = useForm();

  const [toggleStatus, { loading }] = useMutation<
    undefined,
    ToggleCampaignPayload
  >(TOGGLE_CAMPAIGN);

  const modalOpenHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsModalShown(true);
  };

  const modalCancelHandler = () => {
    setIsModalShown(false);
  };

  const submitHandler = async (data: {
    reason: string;
    other_reason: string;
  }) => {
    await toggleStatus({
      variables: {
        id,
        input: {
          status:
            campaignStatus === VacancySpendStatus.ACTIVE
              ? VacancySpendStatus.INACTIVE
              : VacancySpendStatus.ACTIVE,
          reason: reason === 'other' ? data.other_reason : data.reason,
          transfer_budget: transferBudget,
        },
      },
    }).then(() => {
      if (campaignStatus !== VacancySpendStatus.ACTIVE)
        navigate(Routes.REPUBLISH_SUCCESS);
      addToast({
        type: ToastTypes.SUCCESS,
        description:
          campaignStatus === VacancySpendStatus.ACTIVE
            ? 'Campaign stopped successfully'
            : 'Campaign started successfully',
      });
      fireEvent(
        campaignStatus === VacancySpendStatus.ACTIVE
          ? 'stop_campaign'
          : 'start_campaign',
        {
          status:
            campaignStatus === VacancySpendStatus.ACTIVE
              ? 'stopped'
              : 'resumed',
          reason: reason === 'other' ? data.other_reason : data.reason,
        }
      );
    });
    setIsModalShown(false);
    onStop();
  };

  const reasons: StopCampaignReason[] = [
    {
      value: 'candidate_signed',
      text: 'We signed a candidate for the job!',
    },
    {
      value: 'enough_replies',
      text: "We've got enough applications for now",
    },
    {
      value: 'few_replies',
      text: "We're getting too few replies",
    },
    {
      value: 'low_reply_quality',
      text: "The replies we're getting are not up to our standards",
    },
    {
      value: 'pause',
      text: "It's just a temporary pause",
    },
    {
      value: 'other',
      text: 'Other...',
    },
  ];

  useEffect(() => {
    return () => {
      setIsModalShown(false);
    };
  }, []);

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Modal show={isModalShown} onClose={modalCancelHandler}>
        <div tw="my-4">
          {campaignStatus === VacancySpendStatus.ACTIVE && (
            <>
              <label tw="text-base font-medium text-gray-900">
                Are you sure you wish to stop this campaign?
              </label>
              <p tw="mb-4 text-sm leading-5 text-gray-500">
                This will cause your ads to go down.
              </p>
              {/* transfer remaining budget */}
              <div tw="mb-4 flex flex-row items-center gap-2">
                <Checkbox
                  label="Transfer the remaining budget to my balance"
                  onChange={(value) => setTransferBudget(value)}
                  color="#4f47e6"
                  size={20}
                  value={transferBudget}
                />
                <InformationToolTip
                  placement="top"
                  name="transfer_remaining_budget"
                  text="Selecting this option will immediately transfer the remaining budget to your account balance, with a temporary €3 margin held to cover pending metrics. This margin will be transferred to your balance after 2 days. If left unchecked, the entire budget, including the margin, will be automatically transferred after 2 days."
                />
              </div>
              <hr />
              <p tw="mt-4 text-base text-gray-900">
                Would you mind telling us why you are stopping this campaign?
              </p>
              <fieldset tw="mt-4">
                <legend tw="sr-only">Reason for stopping campaign</legend>
                <div tw="space-y-4">
                  {reasons.map(({ value, text }) => {
                    return (
                      <div key={value} tw="flex items-center">
                        <input
                          ref={register}
                          id={'reason_' + value}
                          type="radio"
                          name="reason"
                          value={value}
                          onClick={() => setReason(value)}
                          tw="h-4 w-4 border-gray-300"
                        />
                        <label
                          htmlFor={'reason_' + value}
                          tw="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {text}
                        </label>
                      </div>
                    );
                  })}
                </div>
                {reason === 'other' && (
                  <div tw="my-2">
                    <Controller
                      id="other_reason"
                      name="other_reason"
                      control={control}
                      defaultValue=""
                      render={({ onChange }: { onChange: any }) => (
                        <Input
                          id="other_reason"
                          name="other_reason"
                          textarea={true}
                          onChange={(value) => onChange(value)}
                        />
                      )}
                    />
                  </div>
                )}
              </fieldset>
            </>
          )}
          {campaignStatus === VacancySpendStatus.INACTIVE &&
            'Are you sure you want to (re)start this campaign?'}
          <div tw="float-right mt-6 mb-2">
            <Button loading={loading} onClick={handleSubmit(submitHandler)}>
              {campaignStatus === VacancySpendStatus.ACTIVE
                ? 'Stop Campaign'
                : 'Start Campaign'}
            </Button>
          </div>
        </div>
      </Modal>
      <ToolTip
        placement="bottom"
        disabled={!disabled}
        text={disabledReason}
        arrow
      >
        <div
          tw="h-full flex flex-col justify-center mr-auto cursor-pointer"
          style={disabled ? { cursor: 'not-allowed' } : {}}
        >
          <Button
            variant="secondary"
            disabled={disabled}
            onClick={(e) => modalOpenHandler(e)}
          >
            {campaignStatus === VacancySpendStatus.ACTIVE
              ? 'Stop Campaign'
              : 'Start Campaign'}
          </Button>
        </div>
      </ToolTip>
    </Form>
  );
};

const Form = styled.form(tw`w-full max-w-[500px]`);

export default ToggleCampaignButton;
