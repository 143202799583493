import { SealCheck } from '@phosphor-icons/react';
import React from 'react';
import tw, { styled } from 'twin.macro';

import Badge, { colorStyles, BadgeColor } from '../badge/Badge';

interface CalloutProps {
  label?: string;
  color?: BadgeColor;
  useIcon?: boolean;
  icon?: React.ReactNode;
}

const Callout: React.FC<CalloutProps> = ({
  label,
  color,
  icon = <SealCheck weight="fill" size={24} />,
}) => {
  return (
    <Wrapper color={color}>
      {icon ? icon : <Badge label={label} color={color} />}
    </Wrapper>
  );
};

const Wrapper = styled.span<{ color?: BadgeColor }>(() => [
  tw`px-3 inline-flex text-xs leading-5 font-semibold rounded-full justify-center items-center`,

  ({ color }) => color && colorStyles(color),
]);

export default Callout;
