import React, { useEffect, useMemo } from 'react';
import Container from 'components/container/Container';
import ContainerHeader from 'components/container/container-header/ContainerHeader';
import { Subhead, Title2, Title3 } from 'components/typography/Typography';
import tw from 'twin.macro';
import TransactionsTable from './TransactionsTable';
import useNavigationContext from 'hooks/context/nav-context';
import Button from 'components/button/Button';
import BudgetSelector from 'components/payment/BudgetSelector';
import { Controller, useForm } from 'react-hook-form';
import useModalContext from 'hooks/context/modals-context';
import { Routes } from 'types/routeTypes';
import { useMutation } from 'hooks/sympl-mutation';
import { TOPUP_BALANCE } from 'graphql/financial-transactions/mutations';
import { DownloadSimple } from '@phosphor-icons/react';
import { downloadFile } from 'utils/fileHelpers';
import InformationToolTip from 'components/tooltip/InformationToolTip';
import { useNavigate } from 'react-router-dom';
import { fireEvent } from 'utils/eventHelper';
import PermissionWrapper from 'components/permissions/permissionWrapper/PermissionWrapper';
import { PERMISSIONS } from 'context/PermissionContext';
import { PostHogFeature } from 'posthog-js/react';

const MIN_ACCOUNT_BUDGET = 1000;
const MAX_ACCOUNT_BUDGET = 25000;

const TopupBalanceModal: React.FC<{ onSubmit: () => void }> = ({
  onSubmit,
}) => {
  const { control, handleSubmit } = useForm();

  const [topupBalance, { loading }] = useMutation(TOPUP_BALANCE);

  const submitHandler = (data: { 'extra-budget': number }) => {
    if (loading) return; // Extra double-click protection

    topupBalance({
      variables: {
        input: {
          budget: data['extra-budget'],
        },
      },
    }).then(() => {
      fireEvent('initiate_balance_topup', {
        extraBudget: data['extra-budget'],
      });
      onSubmit();
    });
  };

  return (
    <form
      id="topup-balance-form"
      tw="py-3"
      onSubmit={handleSubmit(submitHandler)}
    >
      <div tw=" max-w-lg">
        <Title2>Add budget to your balance</Title2>
        <Subhead>
          An invoice will be generated. When this invoice is paid, the related
          budget will be added to your account balance.
        </Subhead>
      </div>

      <div tw="my-8">
        <Controller
          id="extra-budget"
          name="extra-budget"
          control={control}
          required
          defaultValue={MIN_ACCOUNT_BUDGET}
          rules={{
            min: MIN_ACCOUNT_BUDGET,
            max: MAX_ACCOUNT_BUDGET,
          }}
          render={({ value, onChange }) => (
            <BudgetSelector
              id="extra-budget"
              onChange={onChange}
              currentValue={value || MIN_ACCOUNT_BUDGET}
              allowCustomValue={true}
              options={[1000, 2000, 5000, 10000]}
              minValue={MIN_ACCOUNT_BUDGET}
              maxValue={MAX_ACCOUNT_BUDGET}
            />
          )}
        />
      </div>

      <Button stretch type="submit" disabled={loading} loading={loading}>
        Generate invoice
      </Button>
    </form>
  );
};

const BalanceSettings: React.FC = () => {
  const { customer, refetchCustomer, activeCustomer, subscription } =
    useNavigationContext();
  const { pushModal, removeModal } = useModalContext();
  const navigate = useNavigate();
  const topUpIsDisabled = useMemo(() => {
    return (
      !customer?.invoice_address ||
      !customer?.legal_form ||
      subscription?.is_trial
    );
  }, [customer]);

  const modalKey = 'topup-balance-modal';
  const triggerTopupModal = () => {
    pushModal({
      key: modalKey,
      show: true,
      children: (
        <TopupBalanceModal
          onSubmit={() => {
            removeModal(modalKey);
            refetchCustomer();
            navigate(Routes.INVOICING_SETTINGS);
          }}
        />
      ),
    });
  };

  useEffect(() => {
    refetchCustomer();
  }, []);

  const exportFileHandler = () =>
    downloadFile(
      `${import.meta.env.VITE_BASE_URL_API}/v4/financial-transactions/export`,
      `transactions-${customer?.name}-${new Date().toLocaleDateString(
        'nl-NL'
      )}.csv`,
      [
        {
          name: 'Accept',
          value: 'text/csv',
        },
        {
          name: 'Active-Customer',
          value: `${activeCustomer!}`,
        },
        {
          name: 'Authorization',
          value: `Bearer ${localStorage.getItem('token')}`,
        },
      ]
    );

  return (
    <Container noShadow={true}>
      <ContainerHeader tw="mb-4">
        <Title2 mb={0}>Account Balance</Title2>
      </ContainerHeader>

      <div tw="px-8 py-4 shadow-neutral w-fit rounded-md">
        <Title3>Available Budget</Title3>
        <p tw="text-3xl font-bold text-gray-700">
          € {(customer?.balance ?? 0).toLocaleString()}
        </p>

        {!customer?.is_legacy && (
          <PostHogFeature flag="balance-topup" match={true}>
            <div tw="mt-4 text-center">
              <Button
                stretch
                onClick={triggerTopupModal}
                disabled={topUpIsDisabled}
                customStyle={tw`relative! flex justify-center`}
              >
                Add budget
                {topUpIsDisabled && (
                  <InformationToolTip
                    color="white"
                    size={16}
                    weight="bold"
                    icon="information"
                    name="topup-disabled-tooltip"
                    text="You must first complete your invoicing details in general settings before you can add funds to your balance. You also need an active subscription to do this."
                  />
                )}
              </Button>
            </div>
          </PostHogFeature>
        )}
      </div>

      <div tw="mt-10">
        <div tw="flex justify-between items-center">
          <Title3>Transaction History</Title3>

          <PermissionWrapper permission={PERMISSIONS.MANAGE_FINANCES}>
            <Button
              onClick={exportFileHandler}
              variant="inverted"
              size="small"
              icon={<DownloadSimple weight="bold" />}
            >
              Export
            </Button>
          </PermissionWrapper>
        </div>
        <div tw="mt-4">
          <TransactionsTable />
        </div>
      </div>
    </Container>
  );
};

export default BalanceSettings;
