import 'twin.macro';
import React, { useContext } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import FormQuestionItem from '../form-question-item/FormQuestionItem';
import SkeletonList from 'components/skeleton/skeleton-list/SkeletonList';
import { SurveyContext } from 'context/SurveyContext';

export type Choice = {
  ref: string;
  label: string;
};

export interface FormQuestion {
  label: string;
  ref: string;
  type: FormQuestionType;
  required: boolean;
  custom: boolean;
  properties?: {
    choices?: Choice[];
  } | null;
  editable?: boolean;
  index: number;
  delete?: boolean;
}

export type FormQuestionType =
  | 'short_text'
  | 'long_text'
  | 'yes_no'
  | 'rating'
  | 'file_upload'
  | 'multiple_choice';

export const FormQuestionsList: React.FC = () => {
  const { loadingQuestions, questions, setQuestions } =
    useContext(SurveyContext);

  if (loadingQuestions) return <SkeletonList rows={3} />;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedQuestions = Array.from(questions);
    const [removed] = updatedQuestions.splice(result.source.index, 1);
    updatedQuestions.splice(result.destination.index, 0, removed);

    updatedQuestions.forEach((question, index) => {
      question.index = index;
    });
    setQuestions(updatedQuestions);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="questionList">
        {(provided) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            tw="w-full h-full overflow-y-auto no-scrollbar max-h-full"
            data-testid="application-form-list"
          >
            {questions
              .filter((question) => !question.delete)
              .map((question, index) => (
                <Draggable
                  key={question.ref}
                  draggableId={question.ref}
                  index={index}
                >
                  {(provided) => (
                    <div
                      key={question.ref}
                      tw="my-4"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        background: 'transparent',
                      }}
                    >
                      <FormQuestionItem question={question} />
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default FormQuestionsList;
