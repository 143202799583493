import { Subhead, Title1 } from 'components/typography/Typography';
import React from 'react';
import tw, { styled } from 'twin.macro';
import { RESET_PREFERENCES_TEMPLATE } from 'graphql/vacancies/mutations';

import ConfigInput from './ConfigInput';
import {
  PreferencesFormData,
  PreferencesFormQuestionKey,
} from './PreferencesConfigTypes';
import { useMutation } from 'hooks/sympl-mutation';
import useNavigationContext from 'hooks/context/nav-context';

interface ConfigFormProps {
  formData?: PreferencesFormData[];
  onChange?: (
    sectionIndex: number,
    questionIndex: number,
    key: PreferencesFormQuestionKey,
    value: string | boolean | number
  ) => void;
}

export type ConfigFormQuestionType =
  | 'name'
  | 'lead-recruiter'
  | 'hide-from-careers-page'
  | 'notification-check'
  | 'delete-button'
  | 'vacancy-template-enabled'
  | 'survey-done'
  | 'ask-for-cv'
  | 'got-cv';

const ConfigForm: React.FC<ConfigFormProps> = ({ formData, onChange }) => {
  const { activeVacancy, currentVacancy } = useNavigationContext();
  const [vacanciesTemplates, setVacanciesTemplates] = React.useState<number[]>(
    currentVacancy?.templates ?? []
  );
  const questionHasHelper = (helper?: string): boolean => {
    return helper ? helper !== undefined && helper !== '' : false;
  };

  const [resetTemplate] = useMutation<
    { resetMailTemplate: { id: number } },
    {
      id: number;
      input: {
        type: string;
      };
    }
  >(RESET_PREFERENCES_TEMPLATE);

  const resetQuestion =
    (
      sectionIndex: number,
      questionIndex: number,
      key: PreferencesFormQuestionKey,
      type: string
    ) =>
    async () => {
      await resetTemplate({
        variables: {
          id: activeVacancy as number,
          input: {
            type: type,
          },
        },
      }).then((val) => {
        const templateId = val?.data?.resetMailTemplate?.id;
        onChange?.(sectionIndex, questionIndex, key, templateId ?? 'null');
        if (templateId) {
          setVacanciesTemplates((prev) => [...prev, templateId]);
        }
      });
    };

  const templateQuestions = ['survey-done', 'ask-for-cv', 'got-cv'];

  return (
    <form tw="grid grid-cols-1 gap-4 md:grid-cols-2 h-full overflow-y-auto auto-rows-auto no-scrollbar">
      {formData?.map((formSection, sectionIndex) => (
        <Section key={sectionIndex}>
          <Title1 mb={2}>{formSection.section}</Title1>
          <ul>
            {formSection.questions.map((question, questionIndex) => (
              <ConfigInputItem key={questionIndex}>
                {![
                  'notification-check',
                  'vacancy-template-enabled',
                  'hide-from-careers-page',
                ].includes(question.type) && (
                  <Label hasHelper={questionHasHelper(question.helper)}>
                    {question.label}
                  </Label>
                )}
                {question.helper && (
                  <>
                    <Subhead mb={2} isLight>
                      {question.helper}
                    </Subhead>
                  </>
                )}
                <ConfigInput
                  id={`${question.label}-${questionIndex}`}
                  type={question.type}
                  value={question.value}
                  label={question.label}
                  placeholder={question.placeholder}
                  onChange={(val) =>
                    onChange?.(sectionIndex, questionIndex, question.key, val)
                  }
                  handler={question?.handler}
                />
                {templateQuestions.includes(question.type) &&
                  !vacanciesTemplates.includes(Number(question.value)) &&
                  question.value && (
                    <p
                      tw="text-indigo-700 text-sm mt-2 ml-2 font-medium cursor-pointer"
                      onClick={resetQuestion(
                        sectionIndex,
                        questionIndex,
                        question.key,
                        question.type === 'survey-done'
                          ? 'done-survey'
                          : question.type
                      )}
                    >
                      Reset to default
                    </p>
                  )}
              </ConfigInputItem>
            ))}
          </ul>
          {formSection.content}
        </Section>
      ))}
    </form>
  );
};

const Section = styled.div(
  tw`border shadow-sm p-6 rounded-md bg-white col-[1/3] md:col-auto`
);

const Label = styled.p<{ hasHelper: boolean }>`
  ${tw`text-base text-gray-700`}
  ${({ hasHelper }) => !hasHelper && tw`mb-2`}
`;

const ConfigInputItem = styled.li(tw`mb-6 last-of-type:m-0`);

export default ConfigForm;
