import React from 'react';
import 'twin.macro';
import Container from 'components/container/Container';
import KomboButton from 'components/integrations/KomboButton';
import { useQuery } from 'hooks/sympl-query';
import { GET_INTEGRATIONS } from 'graphql/integrations/queries';
import ContainerHeader from 'components/container/container-header/ContainerHeader';
import { Body, Title2 } from 'components/typography/Typography';
import Button from 'components/button/Button';
import { showIntercom } from 'utils/intercomHelper';
import { fireEvent } from 'utils/eventHelper';
import { PostHogFeature } from 'posthog-js/react';

interface Integration {
  type: string;
  friendly_name: string;
  logo_url: string;
  icon_url: string;
}

const IntegrationSettings = () => {
  const { data, refetch: refetchIntegrations } = useQuery<{
    integrations: Integration[];
  }>(GET_INTEGRATIONS);

  return (
    <Container>
      <PostHogFeature
        flag="integrations"
        match={true}
        fallback={
          <div>
            <ContainerHeader>
              <Title2>ATS Integration</Title2>
            </ContainerHeader>
            <p tw="my-3 max-w-3xl">
              Sympl supports integration with over 70 ATS and HRIS platforms,
              allowing you to seamlessly transfer candidates from your sympl
              campaigns directly to job requisitions in your trusted system.
              Read more on the benefits of integrating your ATS{' '}
              <a
                tw="text-indigo-600 font-bold"
                target="_blank"
                href="https://intercom.help/sympl-a27647b3ab23/en/articles/9388238-can-we-integrate-with-our-own-ats"
              >
                here
              </a>
              .
            </p>
            <Button
              onClick={() => {
                fireEvent('integrations_interested');
                showIntercom();
              }}
            >
              I'm interested 🙋‍♂️
            </Button>
          </div>
        }
      >
        <div>
          <ContainerHeader>
            <Title2>Current Integrations</Title2>
          </ContainerHeader>

          <ul>
            {data?.integrations?.map(({ icon_url, friendly_name }) => (
              <li tw="flex flex-row items-center py-3">
                <img tw="h-10 mr-2" alt="logo" src={icon_url} />
                <Body>{friendly_name}</Body>
              </li>
            ))}
          </ul>
          <div tw="mt-4">
            <KomboButton onIntegrationCreated={refetchIntegrations} />
          </div>
        </div>
      </PostHogFeature>
    </Container>
  );
};

export default IntegrationSettings;
