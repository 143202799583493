import { gql } from '@apollo/client';

export const GET_USER = gql`
  query getUser {
    user @rest(type: "User", path: "/v4/users/me") {
      firstname
      lastname
      job_position
      signature
      language_id
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query permissions {
    permissions @rest(type: "User", path: "/v4/permissions") {
      name
      vacancies
      brands
      is_customer_level
    }
  }
`;

export const GET_ROLES = gql`
  query roles {
    roles @rest(type: "Role", path: "/v4/roles") {
      id
      name
      friendly_name
      available_models
      permissions
    }
  }
`;

export const GET_DEFAULT_ROLE_TREE = gql`
  query defaultRoleTree {
    defaultRoleTree @rest(type: "Role", path: "/v4/default-role-tree") {
      id
      name
      model_type
      roles
      children
    }
  }
`;
