import type { LatLngTuple } from 'leaflet';

export interface CustomerAddress {
  city: string | null;
  country_code: string;
  house_number?: string;
  road: string;
  box_number?: string;
  zip_code: string;
}

export interface TargetingLocation {
  id: number | null;
  city: string;
  country_code: string;
  lat: number;
  lng: number;
  name: string;
  radius?: number | null;
  region: string | null;
  state: string;
  zipcode: string;
  polygon?: PolygonData;
  isCountry?: boolean;
  type: LocationType;
}

export interface PolygonData {
  id?: string;
  boundingbox: number[];
  data: LatLngTuple[];
}

export enum LocationType {
  COUNTRY = 'country',
  REGION = 'region',
  STATE = 'state',
  CITY = 'city',
  SUB_CITY = 'sub_city', // Town or village
}
