import { VacanciesKeyFields } from '@symplbe/sympl-components';
import { WithPlaceholder } from 'types/careers-page/section';
import { getSectionHeaderDefaultConfig } from '../shared/defaultConfig';
import { getJobTitle, getParagraph, getCity } from 'data/faker';

export const defaultVacanciesSettings: VacanciesKeyFields & WithPlaceholder = {
  ...getSectionHeaderDefaultConfig({
    placeholderValue: {
      title: '',
      subTitle: 'Join our team',
      description: '',
      primaryButtonLabel: '',
      linkButtonLabel: '',
    },
  }),
  image: '',
  vacancies: [...Array(3)].map(() => ({
    functionTitle: getJobTitle(),
    description: getParagraph(),
    url: 'https://sympl.be/vacatures',
    location: getCity(),
    isPlaceholder: true,
  })),
};
