import { gql } from '@apollo/client';

export const TRANSITION_CANDIDATE = gql`
  mutation transitionCandidate($procedureId: ID!) {
    candidateTransition(input: $input, procedureId: $procedureId)
      @rest(
        type: "CandidateTransition"
        path: "/v4/procedures/{args.procedureId}/apply-transition"
        method: "POST"
      )
  }
`;

export const UPDATE_CANDIDATE_COLOR = gql`
  mutation updateCandidateColor($proc_id: ID!) {
    updatedCandidateColor(input: $input, proc_id: $proc_id)
      @rest(
        type: "Procedure"
        path: "/v4/procedures/{args.proc_id}"
        method: "PUT"
      ) {
      color
    }
  }
`;

export const SAVE_CANDIDATE_NOTES = gql`
  mutation saveCandidateNotes($proc_id: ID!) {
    saveCandidateNotes(input: $input, proc_id: $proc_id)
      @rest(
        type: "CandidateNotes"
        path: "/v4/procedures/{args.proc_id}"
        method: "PUT"
      ) {
      notes
    }
  }
`;

export const UPDATE_CANDIDATE = gql`
  mutation updateCandidateName($candidate_id: ID!) {
    updatedCandidateName(input: $input, candidate_id: $candidate_id)
      @rest(
        type: "CandidateName"
        path: "/v4/candidates/{args.candidate_id}"
        method: "PUT"
      )
  }
`;
