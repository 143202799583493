import React from 'react';
import 'twin.macro';

import { Routes } from 'types/routeTypes';
import CreateCampaignButton from 'components/navbar/menu/CreateCampaignButton';
import { useNavigate } from 'react-router-dom';

interface ListItem {
  icon: string;
  text: string;
}

interface DemoPopupContentProps {
  title: string;
  listItems: ListItem[];
}

const DemoPopupContent: React.FC<DemoPopupContentProps> = ({
  listItems,
  title,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div tw="flex flex-col text-left p-4">
        <h2 tw="mb-4 text-xl font-semibold text-gray-700">{title}</h2>

        <ul>
          {listItems.map((listItem, index) => (
            <li tw="flex flex-row items-center my-3" key={index}>
              <span tw="text-xl mr-4">{listItem.icon}</span>
              <span tw="ml-3 text-lg text-gray-700">{listItem.text}</span>
            </li>
          ))}
        </ul>
      </div>

      <CreateCampaignButton
        buttonVariant="button"
        formVariant="inline"
        onCampaignCreate={() => navigate(Routes.GETTING_STARTED)}
        onButtonClick={() => navigate(Routes.GETTING_STARTED)}
        buttonText="Create your first campaign"
      />
    </>
  );
};

export default DemoPopupContent;
