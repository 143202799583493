import useNavigationContext from 'hooks/context/nav-context';
import React, { createContext, useEffect, useMemo, useState } from 'react';
export interface FiltersType {
  customers: Array<string>;
  brands: Array<string>;
  vacancies: Array<string>;
  start_date: string | null;
  end_date: string | null;
  channels: Array<string>;
}

export type ReportingData = {
  applications: number;
  impressions: number;
  spend: number;
  visits: number;
  clicks: number;
  ctr: number;
  conversion: number;
  cpm: number;
  cpc: number;
  cpa: number;
};

type ReportingContextType = {
  filters: FiltersType;
  queryString: string;
  reportingScreen: ReportingScreens;
  defaultStartDate: string;
  defaultEndDate: string;
  setFilters: (filters: FiltersType) => void;
  setReportingScreen: (s: ReportingScreens) => void;
};

export const ReportingContext = createContext<ReportingContextType>(undefined!);

export type ReportingScreens = 'general' | 'channels' | 'creatives';

export const ReportingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Constants
  const TODAY = new Date();
  const DEFAULT_START_DATE = new Date(
    TODAY.getFullYear() - 1,
    TODAY.getMonth(),
    TODAY.getDate()
  )
    .toISOString()
    .split('T')[0];

  const DEFAULT_END_DATE = TODAY.toISOString().split('T')[0];

  const DEFAULT_FILTERS: FiltersType = {
    customers: [],
    brands: [],
    vacancies: [],
    start_date: DEFAULT_START_DATE,
    end_date: DEFAULT_END_DATE,
    channels: [],
  };

  const [filters, setFilters] = useState<FiltersType>(DEFAULT_FILTERS);

  const { activeCustomer } = useNavigationContext();

  const queryString = useMemo(() => {
    return Object.keys(filters)
      .filter((k) => !!filters[k as keyof FiltersType]?.length) // Filter out empty arrays and nullish values
      .map((k) => {
        let value = filters[k as keyof FiltersType]!;
        // If Array, make into comma separated string
        if (Array.isArray(value)) {
          value = value.join(',');
        }
        return `filter[${k}]=${value}`;
      })
      .join('&');
  }, [filters]);

  const [reportingScreen, setReportingScreen] =
    useState<ReportingScreens>('general');
  useEffect(() => {
    if (activeCustomer) {
      setFilters(DEFAULT_FILTERS);
    }
  }, [activeCustomer]);

  return (
    <ReportingContext.Provider
      value={{
        filters,
        queryString,
        reportingScreen,
        defaultStartDate: DEFAULT_START_DATE,
        defaultEndDate: DEFAULT_END_DATE,
        setFilters,
        setReportingScreen,
      }}
    >
      {children}
    </ReportingContext.Provider>
  );
};
