import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { CaretLeft } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';

import Title from 'components/title/Title';

interface ToolbarProps {
  title: string | React.ReactNode;
  cta?: React.ReactNode;
  clipboardCopy?: React.ReactNode;
  breadcrumbUrl?: string;
}

const Toolbar: React.FC<ToolbarProps> = ({
  cta,
  title,
  breadcrumbUrl,
  clipboardCopy,
}) => {
  return (
    <>
      <Wrapper>
        <TitleWrapper
          // @ts-ignore
          title={title}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {breadcrumbUrl && (
            <Link to={breadcrumbUrl}>
              <div tw="p-2 hover:(rounded-full bg-gray-100)">
                <CaretLeft weight="bold" tw="text-gray-700" size={20} />
              </div>
            </Link>
          )}
          <Title
            style={{ marginLeft: breadcrumbUrl ? '5px' : 0 }}
            tw="min-w-max"
          >
            {title}
          </Title>
        </TitleWrapper>

        {clipboardCopy && <div tw="me-auto sm:me-0">{clipboardCopy}</div>}

        {cta && (
          <ControlsWrapper>
            <div tw="ml-4">{cta}</div>
          </ControlsWrapper>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div(() => [
  tw`
    border-b border-gray-100 flex justify-between max-w-full gap-x-4 items-center w-full overflow-x-auto no-scrollbar
  `,
  css`
    background-color: #fdfdfd;
    min-width: 320px;
    padding: 6px 12px;

    @media (min-width: 768px) {
      padding: 10px 35px;
      min-height: 61px;
    }

    @media (min-width: 1500px) {
      padding: 12px 65px;
    }
  `,
]);

const ControlsWrapper = styled.div(() => [
  tw`
    flex items-center justify-end
  `,
]);

const TitleWrapper = styled.div<{ title: string }>(() => [
  `${tw`
    flex flex-row items-center space-x-2
  `}`,
  css`
    @media (min-width: 433px) {
      margin-top: 0;
      width: 100%;
    }
  `,
  ({ title }) =>
    title === 'My Candidates' &&
    css`
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    `,
]);

export default Toolbar;
