import tw, { css, styled, theme, TwStyle } from 'twin.macro';
import React from 'react';
import Tippy from '@tippyjs/react/headless';

const arrowThickness = 8;

export type ToolTipPlacementType = 'left' | 'top' | 'right' | 'bottom';

interface ToolTipProps {
  forwardRef?: React.RefObject<HTMLDivElement>;
  placement?: ToolTipPlacementType;
  delay?: number;
  text?: string;
  content?: React.ReactNode;
  img?: string;
  children?: React.ReactElement;
  arrow?: boolean;
  disabled?: boolean;
  maxWidth?: string;
  visible?: boolean;
  wordBreak?: string;
  customStyle?: TwStyle;
}

const ToolTip: React.FC<ToolTipProps> = ({
  forwardRef,
  placement = 'bottom',
  delay,
  text,
  content,
  img,
  children,
  arrow,
  disabled = false,
  maxWidth,
  visible,
  wordBreak,
  customStyle,
}) => {
  if (disabled) return <>{children}</>;

  return (
    <Tippy
      delay={delay}
      placement={placement}
      visible={visible}
      render={(attrs) => (
        <ToolTipBox
          ref={forwardRef}
          style={{ maxWidth }}
          {...attrs}
          customStyle={customStyle}
        >
          {content ?? (
            <ToolTipContent wordBreak={wordBreak}>
              {text && <ToolTipText>{text}</ToolTipText>}
              {img && <img src={img} alt="Tooltip" />}
            </ToolTipContent>
          )}
          {arrow && (
            <ToolTipArrow
              placement={attrs['data-placement'] as ToolTipPlacementType}
              data-popper-arrow=""
            />
          )}
        </ToolTipBox>
      )}
    >
      {children}
    </Tippy>
  );
};

const ToolTipBox = styled.div<{ customStyle?: TwStyle }>`
  ${tw`bg-gray-800 rounded-lg p-2 cursor-pointer`}
  ${({ customStyle }) => customStyle}
`;

const ToolTipContent = styled.div<{ wordBreak?: string }>`
  ${tw`flex flex-col justify-center gap-y-2 px-3 max-w-[18rem]`}

  ${({ wordBreak }) =>
    wordBreak &&
    css`
      word-break: ${wordBreak};
    `}
`;

const ToolTipText = styled.p(tw`text-sm font-light text-white`);

const ToolTipArrow = styled.div<{ placement: ToolTipPlacementType }>`
  ${tw`absolute h-0 w-0`}

  // The arrow must always point to the inverse direction of it's placement.
  ${({ placement }) =>
    placement === 'left' &&
    css`
      border-top: ${arrowThickness}px solid transparent;
      border-bottom: ${arrowThickness}px solid transparent;
      border-left: ${arrowThickness}px solid ${theme`colors.gray.800`};
      right: ${arrowThickness * -1 + 1}px;
    `}
  ${({ placement }) =>
    placement === 'top' &&
    css`
      border-left: ${arrowThickness}px solid transparent;
      border-right: ${arrowThickness}px solid transparent;
      border-top: ${arrowThickness}px solid ${theme`colors.gray.800`};
      bottom: ${arrowThickness * -1 + 1}px;
    `}
  ${({ placement }) =>
    placement === 'right' &&
    css`
      border-top: ${arrowThickness}px solid transparent;
      border-bottom: ${arrowThickness}px solid transparent;
      border-right: ${arrowThickness}px solid ${theme`colors.gray.800`};
      left: ${arrowThickness * -1 + 1}px;
    `}
  ${({ placement }) =>
    placement === 'bottom' &&
    css`
      border-left: ${arrowThickness}px solid transparent;
      border-right: ${arrowThickness}px solid transparent;
      border-bottom: ${arrowThickness}px solid ${theme`colors.gray.800`};
      top: ${arrowThickness * -1 + 1}px;
    `}
`;

export default ToolTip;
