import React, { useEffect, useMemo, useState } from 'react';
import tw, { styled, TwStyle } from 'twin.macro';
import { Check, Warning } from '@phosphor-icons/react';
import { ApolloError } from '@apollo/client';

import SvgSpinner from '../svg/SvgSpinner';
import { wait } from 'utils/baseHelpers';
import { Headline } from 'components/typography/Typography';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'sympl'
  | 'outline'
  | 'inverted'
  | 'alternative';
type ButtonSize = 'small' | 'medium' | 'large';

interface SaveButtonProps {
  type?: 'button' | 'submit';
  /** The form id in case the button is of the submit type */
  form?: string;
  /** Original button label before it is clicked */
  labelSave?: string;
  /** Label shown while saving */
  labelSaving?: string;
  /** Label shown on success */
  labelSaved?: string;
  /** Label shown on failure */
  labelError?: string;
  /** Label shown when onClick is skipped (rejected Promise) */
  labelSkip?: string;
  /** Label shown when there are unsaved changes (when shouldSave flag is set) */
  labelUnsaved?: string;
  loading?: boolean;
  /** Indicates whether the user should interact with the button (because of a changed form state) */
  shouldSave?: boolean;
  error?: ApolloError | boolean;
  disabled?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  stretch?: boolean;
  customStyles?: TwStyle;
  /** onClick can return a boolean to indicate whether the button should skip all remaining logic*/
  onClick?: () => Promise<void | string | boolean>;
  onDone?: () => void;
}

const DELAY = 1.5;

const SaveButton: React.FC<SaveButtonProps> = ({
  form,
  type = 'button',
  // No default label values here because i18n fails to load
  labelSave = 'Save',
  labelSaving = 'Saving ...',
  labelSaved = 'Saved!',
  labelError = 'Something went wrong',
  labelSkip = 'Some fields are incorrect',
  labelUnsaved = 'Unsaved changes',
  shouldSave = false,
  loading: isSaving = false,
  error,
  disabled,
  variant = 'primary',
  size = 'medium',
  stretch = false,
  customStyles,
  onClick,
  onDone,
}) => {
  // Changes label depending on state
  const [label, setLabel] = useState(labelSave);
  // Shows spinner while loading
  const [showSpinner, setShowSpinner] = useState(isSaving);

  const isDoneSaving = useMemo(
    () => !isSaving && showSpinner,
    [isSaving, showSpinner]
  );

  const isSuccessful = useMemo(
    () => isDoneSaving && !error,
    [error, isDoneSaving]
  );

  useEffect(() => {
    let isWaiting = true;

    if (isDoneSaving) {
      if (error) setLabel(labelError);
      else setLabel(labelSaved);

      if (isWaiting) {
        wait(DELAY).then(() => {
          setShowSpinner(false);
          setLabel(labelSave);
          onDone?.();
        });
      }
    }

    return () => {
      isWaiting = false;
    };
  }, [error, isDoneSaving, labelError, labelSave, labelSaved, onDone]);

  /**
   * Handles the custom click event logic for the button if it is provided
   * @returns A boolean indicating whether the click event was handled successfully
   */
  const customClickHandler = async (): Promise<boolean> => {
    try {
      await onClick?.();
      return true;
    } catch (_err) {
      return false;
    }
  };

  const skipHandler = () => {
    setLabel(labelSkip);
    wait(DELAY).then(() => {
      setLabel(labelSave);
    });
  };

  const saveHandler = async () => {
    // Skip all logic if the button is disabled
    if (disabled) return;

    // Handle the custom button logic
    const skip = (await customClickHandler()) === false;
    // Skip the button animation if the skip flag is set
    if (skip) {
      skipHandler();
      return;
    }

    // Update the button state
    setLabel(labelSaving);
    setShowSpinner(true);
  };

  return (
    <div tw="flex items-center gap-4">
      {shouldSave && (
        <Headline mb={0}>
          <div tw="flex items-center w-fit ms-auto">
            <span tw="text-end">{labelUnsaved}</span>
            <span>&nbsp;&rarr;</span>
          </div>
        </Headline>
      )}
      <Container
        form={form}
        type={type}
        disabled={isSaving || disabled}
        variant={variant}
        size={size}
        isStretched={stretch}
        isSaving={isSaving}
        isSuccessful={isSuccessful}
        isFailed={!isSuccessful}
        isDisabled={isSaving || disabled}
        customStyles={customStyles}
        onClick={() => saveHandler()}
        data-testid="save-button"
      >
        {isSuccessful && <Check weight="bold" tw="mr-2" />}

        {!isSuccessful && (label === labelError || label === labelSkip) && (
          <Warning weight="fill" tw="mr-2" />
        )}

        {isSaving && showSpinner && <SvgSpinner tw="mr-2" />}

        <span>{isSaving ? labelSaving : label}</span>
      </Container>
    </div>
  );
};

const Container = styled.button<{
  isSaving: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  isStretched?: boolean;
  isDisabled?: boolean;
  isSuccessful?: boolean;
  isFailed?: boolean;
  customStyles?: TwStyle;
}>`
  ${tw`
    inline-flex px-4 py-2 items-center whitespace-nowrap text-center rounded-[3px] text-sm font-medium shadow-sm transition
    hover:bg-gray-50 focus:(outline outline-blue-500 outline-offset-[3px])
  `}
  ${({ isStretched }) =>
    isStretched && tw`w-full flex justify-center items-center`}
  ${({ isSaving }) => isSaving && tw`cursor-wait`}
  ${({ isSuccessful }) => isSuccessful && tw`bg-green-500`}
  ${({ variant }) =>
    variant === 'primary' &&
    tw`bg-gray-800 text-white ring-2 ring-gray-800 hover:(bg-sympl ring-sympl)`}
  ${({ variant }) =>
    variant === 'alternative' &&
    tw`bg-gray-800 text-white ring-2 ring-gray-800 hover:(bg-indigo-600 ring-indigo-600)`}
  ${({ variant }) =>
    variant === 'secondary' && tw`bg-white text-gray-700 ring-2`}
  ${({ variant }) => variant === 'inverted' && tw`bg-gray-100 text-gray-700`}
  ${({ variant }) =>
    variant === 'outline' &&
    tw`bg-white shadow-none text-gray-700 hover:bg-gray-200`}
  ${({ variant }) =>
    variant === 'sympl' && tw`bg-sympl ring-0 text-white hover:bg-gray-700`}
    ${({ size }) => size === 'small' && tw`text-xs`}
  ${({ isDisabled }) =>
    isDisabled &&
    tw`cursor-not-allowed bg-gray-300 ring-gray-300 hover:(bg-gray-300 ring-gray-300)`}
  ${({ customStyles }) => customStyles}
`;

export default SaveButton;
