import 'twin.macro';
import React from 'react';
import Input from 'components/form/input/Input';
import { CustomerAddress } from 'types/geolocationTypes';
import SubLabel from './SubLabel';
import { COUNTRIES } from 'data/geo/countries';
import Dropdown from 'components/dropdown/Dropdown';
import {
  fetchPlaceDetailsByPlaceId,
  fetchSearchResults,
  getAvailableAddressValues,
} from 'utils/geolocationHelpers';
import useDebouncedCallback from 'hooks/debounceCallback';

const AddressInput = ({
  value,
  onChange,
}: {
  value: CustomerAddress;
  onChange: (value: CustomerAddress) => void;
}) => {
  const [dropdownItems, setDropdownItems] = React.useState<
    { key: string; label: string }[]
  >([]);

  const updateValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange({
      ...value,
      [e.target.name]: e.target.value.trim() ? e.target.value : '',
    });

  const handleRoadChange = useDebouncedCallback(async (road: string) => {
    if (!road.trim()) return;

    const results = await fetchSearchResults(road, 'nl', 'address');

    setDropdownItems(
      results.map(({ place_id, description }) => ({
        key: place_id,
        label: description,
      }))
    );
  }, 500);

  const handleRoadClick = async (placeId: string) => {
    const { address_components } = await fetchPlaceDetailsByPlaceId(
      placeId,
      'nl',
      'geometry,address_components'
    );

    const availableAddressValues =
      getAvailableAddressValues(address_components);

    const newAddress = { ...value };

    if (availableAddressValues.route?.length)
      newAddress.road = availableAddressValues['route'][0];

    if (availableAddressValues.street_number?.length)
      newAddress.house_number = availableAddressValues['street_number'][0];

    if (availableAddressValues.postal_code?.length)
      newAddress.zip_code = availableAddressValues.postal_code[0];

    if (availableAddressValues.locality?.length)
      newAddress.city = availableAddressValues.locality[0];

    if (availableAddressValues.country?.length) {
      // Make sure the country code is valid
      const countryCode = COUNTRIES.find(
        ({ key }) => key === availableAddressValues.country[0]
      )?.key.toLowerCase();

      newAddress.country_code = countryCode ?? 'be';
    }

    onChange(newAddress);
  };

  const addressPicked = !!value?.road?.trim();

  return (
    <div tw="flex flex-col w-full gap-y-4">
      <div tw="flex gap-x-4">
        <section tw="grow">
          <SubLabel htmlFor="road">
            {addressPicked ? 'Street' : 'Address'}
          </SubLabel>
          <Dropdown
            label={value?.road ?? ''}
            value={value?.road ?? ''}
            items={dropdownItems}
            showIcon={false}
            noResultsLabel={
              !value?.road?.trim() ? 'Start typing...' : 'No results found'
            }
            onSearchChange={handleRoadChange}
            onChange={(value) => handleRoadClick(value as string)}
            testId="general-settings-address"
          />
        </section>
        {addressPicked && (
          <>
            <section>
              <SubLabel htmlFor="house_number">No.</SubLabel>
              <Input
                id="house_number"
                name="house_number"
                type="text"
                placeholder="37"
                value={value?.house_number ?? ''}
                onChange={updateValue}
              />
            </section>
            <section>
              <SubLabel htmlFor="box_number">Bus (optional)</SubLabel>
              <Input
                id="box_number"
                name="box_number"
                type="text"
                value={value?.box_number ?? ''}
                onChange={updateValue}
              />
            </section>
          </>
        )}
      </div>

      {addressPicked && (
        <div tw="flex gap-x-4">
          <section tw="grow">
            <SubLabel htmlFor="zip_code">ZIP code</SubLabel>
            <Input
              id="zip_code"
              name="zip_code"
              type="text"
              placeholder="2000"
              value={value?.zip_code ?? ''}
              onChange={updateValue}
            />
          </section>
          <section tw="grow">
            <SubLabel htmlFor="city">City</SubLabel>
            <Input
              id="city"
              name="city"
              type="text"
              placeholder="Antwerp"
              value={value?.city ?? ''}
              onChange={updateValue}
            />
          </section>
          <section tw="grow">
            <SubLabel htmlFor="country">Country</SubLabel>
            <Dropdown
              label={
                value && value.country_code
                  ? COUNTRIES.find(
                      (country) =>
                        country.key.toLowerCase() ===
                        value.country_code.toLowerCase()
                    )?.label
                  : ''
              }
              value={
                value && value.country_code
                  ? value.country_code.toLowerCase()
                  : ''
              }
              items={COUNTRIES}
              onChange={(country_code) =>
                onChange({
                  ...value,
                  country_code: country_code as string,
                })
              }
            />
          </section>
        </div>
      )}
    </div>
  );
};

export default AddressInput;
