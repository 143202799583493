import 'twin.macro';
import React, { useMemo } from 'react';
import FileUpload, {
  ResourceUploadProps,
} from 'components/upload/ResourceUpload';
import { CustomerResource } from 'types/apiTypes';
import { FileTypes } from 'types/fileTypes';

interface SurveyUploadFormProps {
  defaultValue: ResourceUploadProps['defaultValue'];
  mode: 'logo' | 'asset';
  style?: Object;
  onUpload?: () => void;
  setLogo: (value: CustomerResource | null) => void;
}

const fileTypes = [FileTypes.JPG, FileTypes.JPEG, FileTypes.PNG];

const SurveyUploadForm: React.FC<SurveyUploadFormProps> = ({
  defaultValue,
  mode,
  onUpload,
  setLogo,
}) => {
  const isLogo = useMemo(() => mode === 'logo', [mode]);

  const uploadChangeHandler = (resources: CustomerResource[]) => {
    setLogo(resources[0]);
    onUpload?.();
  };

  const uploadDeleteHandler = () => setLogo(null);

  return (
    <div tw="bg-gray-50 p-4">
      <FileUpload
        defaultValue={defaultValue}
        maxFiles={1}
        fileTypes={fileTypes}
        isLogo={isLogo}
        onChange={uploadChangeHandler}
        onDelete={uploadDeleteHandler}
      />
    </div>
  );
};

export default SurveyUploadForm;
