import { VacancyCopy } from 'types/vacancyTypes';
import { WordPressIconItem } from 'types/wordpressTypes';
import { textToHtml } from 'utils/baseHelpers';
import {
  getFormattedText,
  ChatGPTMessage,
  getOpenAiThreadsResponse,
} from 'utils/openAiHelpers';
import {
  generatePromptEssence,
  generatePromptQualities,
  generatePromptTasks,
  generatePromptRequirements,
  generatePromptOffer,
  generatePromptAbout,
  VacancyAIPromptPayload,
} from './prompts';
import retry from 'retry';
import { Dispatch, SetStateAction } from 'react';
import emojiRegex from 'emoji-regex';

const OPEN_AI_RETRIES = 3; // Excluding initial call

const regex = emojiRegex();

const getOperationInstance = () =>
  retry.operation({ retries: OPEN_AI_RETRIES });

export const getInitialPrompt = (
  keyName: string,
  payload: VacancyAIPromptPayload
): ChatGPTMessage[] | undefined => {
  // Specific prompt for each case
  const specificPrompt = (keyName: string, payload: VacancyAIPromptPayload) => {
    switch (keyName) {
      case 'vac_text_essence':
        return generatePromptEssence(payload);
      case 'vac_text_uniqueness':
        return generatePromptQualities(payload);
      case 'vac_text_todo':
        return generatePromptTasks(payload);
      case 'vac_text_expectations':
        return generatePromptRequirements(payload);
      case 'vac_text_offer':
        return generatePromptOffer(payload);
      case 'vac_text_company':
        return generatePromptAbout(payload);
      default:
        return undefined;
    }
  };
  const prompt = specificPrompt(keyName, payload);
  return (
    prompt && [
      {
        role: 'assistant',
        content: `You are a copywriting assistant that writes parts of a job posting.
              Act as a recruiter.
              Company: ${payload.companyName}.
              About the company: ${payload.companyDescription}.
              The company is located in: ${payload.location}.
              The output language should be: ${payload.language}.
              Tone of voice: ${payload.toneOfVoice}.`,
      },
      ...prompt,
    ]
  );
};

export interface VacancyPromptPayload {
  jobFunction: string;
  companyName: string;
  location: string;
  jobCategory: string;
  language: string;
  experienceLevel: string;
  companyDescription?: string;
  toneOfVoice?: string;
  offers?: string[];
  previousOutput?: string;
  feedback?: string | null;
}

export const generateVacancy = async ({
  vacancyId,
  payload,
  setCopyData,
  setGeneratingFields,
}: {
  vacancyId: number;
  payload: VacancyAIPromptPayload;
  setCopyData: React.Dispatch<React.SetStateAction<VacancyCopy | undefined>>;
  setGeneratingFields: React.Dispatch<
    React.SetStateAction<(keyof VacancyCopy)[]>
  >;
}) =>
  await Promise.allSettled(
    [
      'vac_text_essence',
      'vac_text_uniqueness',
      'vac_text_todo',
      'vac_text_expectations',
      'vac_text_offer',
      'vac_text_company',
    ].map((keyName) =>
      generateText(
        vacancyId,
        keyName as keyof VacancyCopy,
        payload,
        setCopyData,
        setGeneratingFields
      )
    )
  );

const handleFinish = async (
  output: string,
  keyName: string,
  vacancyId: number,
  threadId: string,
  setCopyData: Dispatch<SetStateAction<VacancyCopy | undefined>>,
  setGeneratingFields: Dispatch<SetStateAction<(keyof VacancyCopy)[]>>
) => {
  const isUniqueness = keyName === 'vac_text_uniqueness';

  if (!isUniqueness) {
    try {
      const data = await getFormattedText(output, vacancyId, keyName, threadId);

      setCopyData((prev) => ({ ...prev, [keyName]: data } as VacancyCopy));
    } catch (error) {
      setGeneratingFields((prev) => prev.filter((field) => field !== keyName));
      return;
    }
  } else {
    const data = [
      ...output
        .split('\n')
        .filter((item) => !!item)
        .map((item) => item.replace(/^- /, ''))
        .map((item) => {
          const emojis = item.match(regex);

          const data = emojis?.length
            ? {
                icon: emojis[0],
                value: item.trim().replace(emojis[0], ''),
              }
            : {
                icon: 'FaPencilAlt',
                value: item.trim(),
              };

          return data;
        }),
    ];

    setCopyData(
      (prev) =>
        ({
          ...prev,
          [keyName]: data,
        } as VacancyCopy)
    );
  }

  setGeneratingFields((prev) => prev.filter((field) => field !== keyName));
};

// Splits text into paragraphs
const getFormattedValue = (key: keyof VacancyCopy, value: string) => {
  if (
    ['vac_text_todo', 'vac_text_expectations', 'vac_text_offer'].includes(key)
  ) {
    return value
      .split('\n')
      .filter((line) => line.match(regex)?.length)
      .join('\n\n');
  }

  return value;
};

const generateText = async (
  vacancyId: number,
  keyName: keyof VacancyCopy,
  payload: VacancyAIPromptPayload,
  setCopyData: Dispatch<SetStateAction<VacancyCopy | undefined>>,
  setGeneratingFields: Dispatch<SetStateAction<(keyof VacancyCopy)[]>>
) =>
  new Promise((resolve) => {
    setGeneratingFields((prev) => [...prev, keyName]);

    const operation = getOperationInstance();

    operation.attempt(async () => {
      const onChange = (value: string) => {
        if (keyName === 'vac_text_uniqueness') {
          const data = [
            ...value
              .split('\n')
              .filter((item) => !!item)
              .map(
                (item) =>
                  ({
                    icon: 'FaPencilAlt',
                    value: item.trim(),
                  } as WordPressIconItem)
              ),
          ];

          setCopyData(
            (prev) =>
              ({
                ...prev,
                [keyName]: data,
              } as any)
          );
        } else {
          setCopyData(
            (prev) =>
              ({
                ...prev,
                [keyName]: textToHtml(getFormattedValue(keyName, value)),
              } as any)
          );
        }
      };

      try {
        const output = await getOpenAiThreadsResponse({
          onChange,
          identifier: keyName,
          messages: getInitialPrompt(keyName, payload)!,
          vacancy_id: vacancyId,
          stream: true,
        });
        if (output)
          handleFinish(
            getFormattedValue(keyName, output.response),
            keyName,
            vacancyId,
            output.thread_id,
            setCopyData,
            setGeneratingFields
          );
      } catch (error: any) {
        if (operation.retry(new Error(error))) return;
        setGeneratingFields((prev) =>
          prev.filter((field) => field !== keyName)
        );
        return;
      } finally {
        resolve('');
      }
    });
  });
