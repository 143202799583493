import React, { createContext, useEffect } from 'react';
import useNavigationContext from 'hooks/context/nav-context';
import { GET_PERMISSIONS, GET_ROLES } from 'graphql/users/queries';
import { useQuery } from 'hooks/sympl-query';
import { models } from 'types/permissionTypes';

type PermissionContextType = {
  allRoles?: Role[];
  permissionData?: PermissionData[];
  userManagers?: number[];
  setUserManagers: (count: number[]) => void;
  userPermissions?: string[];
  currentVacPermissions?: string[];
  refetchPermissions: () => void;
  userHasPermission: (
    permission: string | string[],
    on?: 'brand' | 'customer' | 'vacancy'
  ) => boolean;
  permissionFetchLoading?: boolean;
};

interface PermissionData {
  name: string;
  vacancies: number[];
  brands: number[];
  is_customer_level: boolean;
}

export interface Role {
  id: number;
  name: string;
  friendly_name: string;
  available_models?: (typeof models)[keyof typeof models][];
  permissions: string[];
}

export const PERMISSIONS = Object.freeze({
  MANAGE_CAMPAIGNS: 'campaigns_manage',
  MANAGE_CANDIDATES: 'candidates_manage',
  MANAGE_USERS: 'users_manage',
  MANAGE_FINANCES: 'finances_manage',
});

export const PermissionContext = createContext<PermissionContextType>(
  undefined!
);

export const PermissionProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { activeCustomer, activeVacancy } = useNavigationContext();
  const [userManagers, setUserManagers] = React.useState<number[]>([]);

  const {
    data,
    loading: permissionFetchLoading,
    refetch,
    refetching,
  } = useQuery<{ permissions: PermissionData[] }>(GET_PERMISSIONS, {
    skip: !activeCustomer,
  });

  const permissionData: PermissionData[] = React.useMemo(
    () => data?.permissions ?? [],
    [data]
  );

  const userPermissions = React.useMemo(() => {
    return data?.permissions?.flatMap((item) => item.name);
  }, [data]);

  const currentVacPermissions = React.useMemo(() => {
    if (!activeVacancy) return [];
    return permissionData
      .filter((item) => item.vacancies.includes(activeVacancy))
      .flatMap((item) => item.name);
  }, [permissionData, activeVacancy]);

  const customerLevelPermissions = React.useMemo(() => {
    return permissionData.filter((item) => item.is_customer_level);
  }, [permissionData]);

  useEffect(() => {
    if (!activeCustomer) return;
    refetch();
  }, [activeCustomer, refetch]);

  const { data: allRolesData } = useQuery<{ roles: Role[] }>(GET_ROLES, {
    skip: !activeCustomer,
  });

  const userHasPermission = (
    allowedPermissions: string | string[],
    on: 'brand' | 'customer' | 'vacancy' = 'vacancy'
  ): boolean => {
    const permissions = [allowedPermissions].flat();

    const hasPermission = (source: { name: string }[]) =>
      permissions.some((p) => source.flatMap((item) => item.name).includes(p));

    switch (on) {
      case 'brand':
        return (
          hasPermission(customerLevelPermissions) ||
          permissions.some((p) =>
            permissionData
              .filter((item) => item.brands.length)
              .flatMap((item) => item.name)
              .includes(p)
          )
        );
      case 'customer':
        return hasPermission(customerLevelPermissions);
      default:
        return (
          hasPermission(customerLevelPermissions) ||
          currentVacPermissions.some((p) => permissions.includes(p))
        );
    }
  };

  return (
    <PermissionContext.Provider
      value={{
        allRoles: allRolesData?.roles,
        userManagers,
        setUserManagers,
        permissionData,
        userPermissions,
        currentVacPermissions,
        refetchPermissions: refetch,
        userHasPermission,
        permissionFetchLoading: refetching || permissionFetchLoading,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};
