export enum Interval {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  TWO_QUARTERLY = 'TWO_QUARTERLY',
  THREE_QUARTERLY = 'THREE_QUARTERLY',
  YEARLY = 'YEARLY',
}

export interface Subscription {
  id: number;
  customer_id?: string;
  plan: Plan;
  scheduled: ScheduledPlan;
  status: 'ACTIVE' | 'INACTIVE';
  is_cancelled: boolean;
  disable_cancel: boolean;
  interval: Interval;
  current_period: {
    start: Date;
    end: Date;
  };
  price: number;
  trial_ends: Date;
  is_trial: boolean;
  payment_method: 'CREDIT_CARD' | 'WIRE_TRANSFER';
  current_live_vacs: number;
  max_live_vacs: number;
}

export interface Plan {
  name: string;
  tier: string;
  prices?: PlanPrice[];
  features?: string[];
}

interface ScheduledPlan {
  interval: Interval;
  amount: number;
  plan: {
    tier: string;
    name: string;
  };
}

interface PlanPrice {
  interval: Interval;
  amount: number;
  id: number;
}
