import { ContactKeyFields } from '@symplbe/sympl-components';
import { WithPlaceholder } from 'types/careers-page/section';
import { getSectionHeaderDefaultConfig } from '../shared/defaultConfig';

export const defaultContactSettings: ContactKeyFields & WithPlaceholder = {
  ...getSectionHeaderDefaultConfig({
    placeholderValue: {
      title: 'Contact',
      subTitle: 'Get in touch',
      description: '',
      primaryButtonLabel: '',
      linkButtonLabel: '',
    },
  }),
  cards: [],
};
