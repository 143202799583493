import React, { useContext } from 'react';
import tw, { styled } from 'twin.macro';
import {
  GlobeHemisphereWest,
  MapPin,
  EnvelopeSimple,
  Phone,
  User,
  PencilSimple,
} from '@phosphor-icons/react';

import { Candidate } from 'types/candidates/types';
import { CandidateContext, CandidateDetailTab } from 'context/CandidateContext';
import { Body, Title3 } from 'components/typography/Typography';
import WhatsAppButton from 'components/button/WhatsAppButton';
import SourceIcon from 'components/icon-card/SourceIcon';
import sanitize from 'sanitize-html';
import { useMutation } from 'hooks/sympl-mutation';
import { UPDATE_CANDIDATE } from 'graphql/candidates/mutations';
import useModalContext from 'hooks/context/modals-context';
import Button from 'components/button/Button';
import Input from 'components/form/input/Input';
import Label from 'components/form/Label';

const ContactList: React.FC<{
  email: string;
  phone: string;
  source: string;
  candidate?: Candidate;
  onChange?: () => void;
}> = ({ candidate, email, phone, source, onChange }) => {
  const { pushModal, removeModal } = useModalContext();
  const [candidateName, setCandidateName] = React.useState<{
    firstname: string;
    lastname: string;
  }>({
    firstname: candidate?.firstname ?? '',
    lastname: candidate?.lastname ?? '',
  });

  const candidateContext = useContext(CandidateContext);

  const setActiveTab = candidateContext
    ? candidateContext.setActiveDetailTab
    : null;

  const setSidebarIsOpen = candidateContext
    ? candidateContext.setSidebarIsOpen
    : null;

  const setMailModalIsOpen = candidateContext
    ? candidateContext.setMailModalIsOpen
    : null;

  const onEmailClicked = () => {
    setActiveTab?.(CandidateDetailTab.COMMUNICATION);
    setMailModalIsOpen?.(true);
    setSidebarIsOpen?.(false);
  };

  const handleCandidateModal = () => {
    const modalKey = `candidate-edit-${candidate?.id}`;
    pushModal({
      key: modalKey,
      show: true,
      onClose: () => removeModal(modalKey),
      children: (
        <CandidateModalContent
          candidateData={candidateName}
          candidate={candidate as Candidate}
          onSuccess={(val: { firstname: string; lastname: string }) => {
            removeModal(modalKey);
            setCandidateName({ ...val });
            onChange?.();
          }}
        />
      ),
    });
  };

  return (
    <>
      <Title3>Contact</Title3>
      <ul>
        <ContactListItem tw="text-left">
          <User tw="text-gray-400 mr-2" size="20" />
          <Body isLight>
            {candidateName.firstname} {candidateName.lastname}
          </Body>
          <PencilSimple
            size="16"
            tw="ml-2 cursor-pointer"
            onClick={() => handleCandidateModal()}
          />
        </ContactListItem>
        <ContactListItem tw="text-left break-all">
          <EnvelopeSimple tw="text-gray-400 mr-2" size="20" />
          <Body isLight>{email}</Body>
        </ContactListItem>
        {phone && (
          <ContactListItem tw="text-left">
            <Phone tw="text-gray-400 mr-2" size="20" />
            <Body isLight>{phone}</Body>
          </ContactListItem>
        )}
        {candidate?.nationality && (
          <ContactListItem tw="text-left">
            <GlobeHemisphereWest tw="text-gray-400 mr-2" size="20" />
            <Body isLight isUppercase>
              {candidate.nationality}
            </Body>
          </ContactListItem>
        )}
        {candidate?.address && (
          <ContactListItem tw="text-left truncate">
            <MapPin tw="text-gray-400 mr-2" size="20" />
            <Body isLight>{candidate.address}</Body>
          </ContactListItem>
        )}
        {source && (
          <ContactListItem tw="text-left truncate gap-2">
            <SourceIcon source={source} />
            <Body isLight>Via {source.toLowerCase()}</Body>
          </ContactListItem>
        )}
        <ContactListItem tw="text-left flex-wrap gap-x-2 gap-y-2">
          {candidate ? (
            <ContactButton onClick={() => onEmailClicked()}>
              Email
            </ContactButton>
          ) : (
            <ContactLink
              href={`mailto:${email}`}
              target="_blank"
              rel="noreferrer"
            >
              Email
            </ContactLink>
          )}
          {phone && (
            <>
              <WhatsAppButton phone={phone} />
              <ContactLink href={`tel:${phone}`}>Call</ContactLink>
            </>
          )}
        </ContactListItem>
      </ul>
    </>
  );
};

const CandidateModalContent: React.FC<{
  candidate: Candidate;
  candidateData?: { firstname: string; lastname: string };
  onSuccess?: ({
    firstname,
    lastname,
  }: {
    firstname: string;
    lastname: string;
  }) => void;
}> = ({ candidateData, candidate, onSuccess }) => {
  const [candidateName, setCandidateName] = React.useState<{
    firstname: string;
    lastname: string;
  }>(candidateData ?? { firstname: '', lastname: '' });

  const [updateCandidateName, { loading }] = useMutation<
    undefined,
    {
      candidate_id: number;
      input: { firstname: string; lastname: string };
    }
  >(UPDATE_CANDIDATE);

  const handleCandidateNameChange = async (candidateName: {
    firstname: string;
    lastname: string;
  }) => {
    try {
      await updateCandidateName({
        variables: {
          candidate_id: candidate?.id as number,
          input: candidateName,
        },
      });
      onSuccess?.(candidateName);
    } catch (error) {
      console.error(error);
      setCandidateName({
        firstname: candidate?.firstname ?? '',
        lastname: candidate?.lastname ?? '',
      });
    }
  };

  return (
    <>
      <div tw="p-8">
        <Title3 mb={4}>Edit candidate name</Title3>
        <div tw="flex gap-4">
          <div>
            <Label htmlFor="first-name">First name</Label>
            <Input
              id="first-name"
              label="First name"
              inputStyles={tw``}
              value={candidateName?.firstname}
              onChange={(e) => {
                const sanitizedValue = sanitize(e.target.value, {
                  allowedTags: [],
                  allowedAttributes: {},
                });

                setCandidateName((prev) => ({
                  ...prev,
                  firstname: sanitizedValue,
                }));
              }}
            />
          </div>
          <div>
            <Label htmlFor="last-name">Last name</Label>
            <Input
              id="last-name"
              label="Last name"
              inputStyles={tw``}
              value={candidateName?.lastname}
              onChange={(e) => {
                const sanitizedValue = sanitize(e.target.value, {
                  allowedTags: [],
                  allowedAttributes: {},
                });
                setCandidateName((prev) => ({
                  ...prev,
                  lastname: sanitizedValue,
                }));
              }}
            />
          </div>
        </div>
      </div>

      <div tw="flex justify-end">
        <Button
          loading={loading}
          variant="indigo"
          onClick={() => {
            handleCandidateNameChange(candidateName);
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

const ContactListItem = styled.li(
  tw`flex items-center text-gray-600 mb-3 last-of-type:(m-0 mt-6)`
);

const ContactLink = styled.a(
  tw`
    rounded-md inline-flex items-center px-4 py-2 border border-gray-100 transition
  bg-white text-gray-700 text-sm font-medium
  hover:bg-gray-50
    focus:(ring-2 ring-offset-2 ring-indigo-400 outline-none)
  `
);

const ContactButton = styled.button(
  tw`
    rounded-md inline-flex items-center px-4 py-2 border border-gray-100 transition
  bg-white text-gray-700 text-sm font-medium
    hover:bg-gray-50
    focus:(ring-2 ring-offset-2 ring-indigo-400 outline-none)
  `
);

export default ContactList;
