import React, { useState } from 'react';
import tw, { css, styled } from 'twin.macro';

interface ColorPickerPaletteProps {
  onColorSelected?: (color: string) => void;
  defaultSelectedColor?: PaletteColor;
}

const COLORS = ['#F75B51', '#F7A233', '#4BCF5F', '#348CF7', '#FFFFFF'] as const;

export type PaletteColor = (typeof COLORS)[number];

const ColorPickerPalette: React.FC<ColorPickerPaletteProps> = ({
  onColorSelected,
  defaultSelectedColor = '#FFFFFF',
}) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(
    COLORS.indexOf(defaultSelectedColor)
  );

  const handleDotClick = (color: string, index: number) => {
    if (onColorSelected) {
      setActiveIndex(index);
      onColorSelected(color);
    }
  };

  return (
    <div tw="flex flex-row gap-1 rounded-lg m-2">
      {COLORS.map((color, index) => (
        <Dot
          key={index}
          color={color}
          onClick={() => handleDotClick(color, index)}
          isSelected={activeIndex === index}
        />
      ))}
    </div>
  );
};

const Dot = styled.div<{ color: string; isSelected: boolean }>`
  ${tw`border-2 border-gray-200 cursor-pointer hover:scale-110`}
  ${({ isSelected }) =>
    isSelected ? tw`border-gray-500` : tw`border-gray-300`}
  ${({ color }) => [
    tw`h-5 w-5 inline-block rounded-[50%]`,
    css`
      background-color: ${color};
    `,
  ]}
`;

export default ColorPickerPalette;
