import useGetStartedContext from 'hooks/context/get-started-context';
import useNavigationContext from 'hooks/context/nav-context';
import React from 'react';
import { CheckCircle } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';

import tw, { styled } from 'twin.macro';

const SVG_CIRCLE = (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M 21 12 C 21 18.928 13.5 23.258 7.5 19.794 C 4.715 18.187 3 15.215 3 12 C 3 5.072 10.5 0.742 16.5 4.206 C 19.285 5.813 21 8.785 21 12 Z"
    />
  </svg>
);

const MenuLink: React.FC<{
  to: {
    pathname: string;
    search: string;
  };
  isActive?: boolean;
  isCampaign: boolean;
  isDisabled?: boolean;
  isCompleted?: boolean;
  icon: React.ReactNode;
  label: string;
}> = ({
  to,
  isActive = false,
  isDisabled = false,
  isCampaign,
  isCompleted,
  icon,
  label,
}) => {
  const { hasVacancies, vacIsPublished, vacancyLoading } =
    useNavigationContext();

  const { checklist, checklistLoading } = useGetStartedContext();

  return (
    <Container
      isActive={isActive}
      isDisabled={isDisabled}
      isCompleted={!vacIsPublished && !!isCompleted}
    >
      <div tw="text-sm flex justify-between place-items-center">
        <Link
          to={to}
          tw="flex w-full justify-between p-2.5"
          css={[isDisabled && tw`pointer-events-none opacity-50`]}
        >
          <div tw="flex items-center pr-3" css={[isCompleted && tw`-ml-px`]}>
            {!vacancyLoading &&
              !vacIsPublished &&
              !!checklist.length &&
              isCompleted === false && <div tw="-ml-px">{SVG_CIRCLE}</div>}

            {isCampaign && !vacIsPublished && isCompleted && (
              <CheckCircle weight="bold" color={'limegreen'} size={20} />
            )}
            {(checklistLoading ||
              vacancyLoading ||
              vacIsPublished ||
              !hasVacancies ||
              (!isCompleted && !isCampaign)) && <>{icon}</>}
            <div tw="flex flex-col">
              <span
                css={[
                  vacancyLoading || vacIsPublished
                    ? tw`ml-[10px]`
                    : tw`ml-[9px]`,
                ]}
              >
                {label}
              </span>
            </div>
          </div>
        </Link>
      </div>
    </Container>
  );
};

const Container = styled.div<{
  isActive: boolean;
  isDisabled?: boolean;
  isCompleted: boolean;
}>`
  ${tw`font-medium w-full cursor-pointer rounded-md hover:(text-gray-800 bg-gray-100 [svg]:text-gray-800)`}
  ${({ isActive }) =>
    isActive
      ? tw`text-gray-800 bg-gray-100 [svg]:text-gray-800`
      : tw`text-gray-500 [svg]:text-gray-400`}
  ${({ isDisabled }) => isDisabled && tw`cursor-not-allowed opacity-70`}
  ${({ isCompleted }) =>
    isCompleted &&
    tw`text-[rgb(50, 205, 50)] bg-[rgba(50, 205, 50, 0.1)] hover:(text-[rgb(50, 205, 50)] bg-[rgba(50, 205, 50, 0.25)])`}
`;

export default MenuLink;
