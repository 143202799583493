import React, { ChangeEvent, RefObject, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { MagnifyingGlass } from '@phosphor-icons/react';

import useDebouncedCallback from 'hooks/debounceCallback';
import { TargetingLocation } from 'types/geolocationTypes';
import {
  fetchPlaceDetailsByPlaceId,
  fetchSearchResults,
  getLocationType,
} from 'utils/geolocationHelpers';

interface LocationSearchProps {
  placeholder: string;
  searchRef: RefObject<HTMLInputElement>;
  locations: TargetingLocation[];
  language: string;
  onClick?: (place: PlaceResult) => void;
}

export interface PlaceResult
  extends Pick<
    google.maps.places.PlaceResult,
    'name' | 'geometry' | 'address_components'
  > {
  types: string[];
}

const LocationSearch: React.FC<LocationSearchProps> = ({
  placeholder,
  searchRef,
  locations,
  language,
  onClick,
}) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [results, setResults] =
    useState<google.maps.places.AutocompletePrediction[]>();
  const debounceSearch = useDebouncedCallback(async (query: string) => {
    try {
      setResults(
        await fetchSearchResults(query, language, '(regions)', locations)
      );
    } catch (error) {
      setResults(undefined);
    }
    setLoading(false);
  }, 500);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const searchedText = event.target.value.trim();

    if (searchedText !== '') {
      setQuery(event.target.value);
      debounceSearch(event.target.value);
    } else {
      resetSearch();
      setLoading(false);
    }
  };

  const resultClickHandler = async (placeId: string) => {
    resetSearch();

    const place = await fetchPlaceDetailsByPlaceId(placeId, language);
    onClick?.(place);
  };

  const resetSearch = () => {
    setResults([]);
    setQuery('');
    if (searchRef?.current?.value) searchRef.current.value = '';
  };

  return (
    <Wrapper>
      <InputWrapper tabIndex={0} hasItems={!!locations.length}>
        <div tw="text-gray-400 pl-2">
          <MagnifyingGlass weight="bold" size={20} />
        </div>
        <input
          tabIndex={-1}
          type="text"
          ref={searchRef}
          title={'Search location'}
          tw="w-full p-3 outline-none rounded-md"
          name="query"
          placeholder={placeholder}
          defaultValue={query}
          autoComplete="off"
          onChange={changeHandler}
          data-testid="location-search"
        />
      </InputWrapper>

      {loading ? (
        <Message>
          <p tw="text-sm text-gray-600 animate-pulse">{'Searching ...'}</p>
        </Message>
      ) : (
        <>
          {(results?.length ?? 0) > 0 && (
            <Results data-testid="location-search-results">
              {results?.map((result) => (
                <ResultItem
                  key={result.place_id}
                  isDisabled={false}
                  onClick={() => resultClickHandler(result.place_id)}
                >
                  <p title={result.description} tw="text-sm truncate">
                    {result.description}
                  </p>
                  <span tw="text-xs text-gray-500 ml-3">
                    {getLocationType(result.types[0])?.replaceAll('_', ' ')}
                  </span>
                </ResultItem>
              ))}
            </Results>
          )}

          {!results?.length && query && (
            <Message>
              <p tw="text-sm text-gray-600 font-medium">No results found ...</p>
            </Message>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${tw`w-full xl:px-4`}
`;

const ResultItem = styled.li<{ isDisabled: boolean }>`
  ${tw`flex items-baseline justify-between cursor-pointer hover:text-gray-600`}
  ${({ isDisabled }) => isDisabled && tw`cursor-not-allowed opacity-25`}
`;

const InputWrapper = styled.div<{
  hasItems: boolean;
}>`
  ${tw`flex items-center border rounded-md lg:(pb-0 pt-0) focus-within:(outline-none outline outline-blue-500)`}
`;

const Results = styled.ul`
  ${tw`p-4 flex flex-col gap-4 border border-gray-100 rounded-md mt-2 bg-gray-50`}
`;

const Message = styled.div(() => [
  tw`
    px-4 py-4 flex items-center rounded border border-gray-100 rounded-b-md mt-2 bg-gray-50
  `,
]);

export default LocationSearch;
