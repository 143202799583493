type RGB = [number, number, number];

type ColorCount = {
  color: RGB;
  count: number;
};

const getLightness = ([r, g, b]: RGB): number => {
  return (Math.max(r, g, b) + Math.min(r, g, b)) / 2 / 255;
};

const getColorDistance = ([r1, g1, b1]: RGB, [r2, g2, b2]: RGB): number => {
  return Math.sqrt((r1 - r2) ** 2 + (g1 - g2) ** 2 + (b1 - b2) ** 2);
};

const toHex = ([r, g, b]: RGB): string => {
  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const isSameColorDifferentLightness = (
  [r1, g1, b1]: RGB,
  [r2, g2, b2]: RGB
): boolean => {
  const lightness1 = getLightness([r1, g1, b1]);
  const lightness2 = getLightness([r2, g2, b2]);
  return (
    getColorDistance([r1, g1, b1], [r2, g2, b2]) < 30 &&
    Math.abs(lightness1 - lightness2) > 0.1
  );
};

const extractColors = (imageSrc: string): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = imageSrc;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return reject('Canvas context not supported');

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
      const colorMap = new Map<string, ColorCount>();

      for (let i = 0; i < imageData.length; i += 4) {
        const rgb: RGB = [imageData[i], imageData[i + 1], imageData[i + 2]];
        if (getLightness(rgb) > 0.8) continue;

        const key = rgb.join(',');
        if (colorMap.has(key)) colorMap.get(key)!.count++;
        else colorMap.set(key, { color: rgb, count: 1 });
      }

      const sortedColors = Array.from(colorMap.values())
        .sort((a, b) => b.count - a.count)
        .map((entry) => entry.color);

      const distinctColors: RGB[] = [];
      sortedColors.forEach((color) => {
        if (
          distinctColors.every(
            (existing) => getColorDistance(existing, color) > 50
          )
        )
          distinctColors.push(color);
      });

      if (distinctColors.length === 1) {
        resolve([toHex(distinctColors[0])]);
        return;
      }

      const filteredColors = distinctColors.filter((color, index, self) =>
        self.every(
          (otherColor, otherIndex) =>
            index === otherIndex ||
            !isSameColorDifferentLightness(color, otherColor)
        )
      );

      const resultColors = filteredColors.slice(0, 5).map(toHex);
      const blackIndex = resultColors.indexOf('#000000');
      if (blackIndex !== -1)
        resultColors.push(resultColors.splice(blackIndex, 1)[0]);

      resolve(resultColors);
    };
    img.onerror = () => reject('Failed to load image');
  });
};

export default extractColors;
