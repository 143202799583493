import Input from 'components/form/input/Input';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { v4 as uuid } from 'uuid';
import { Title3 } from 'components/typography/Typography';
import { Routes } from 'types/routeTypes';
import useModalContext from 'hooks/context/modals-context';
import SourceIcon from 'components/icon-card/SourceIcon';
import { ArrowRight } from '@phosphor-icons/react';
import { useQuery } from 'hooks/sympl-query';
import { SEARCH_CANDIDATES } from 'graphql/candidates/queries';
import PermissionWrapper from '../../permissions/permissionWrapper/PermissionWrapper';
import { PERMISSIONS } from 'context/PermissionContext';

interface CandidateSearchProps {}

interface ShortVacancy {
  id: number;
  name: string;
  language_id: number;
  customer_id: number;
}

interface Procedure {
  id: number;
  vacancy_id: number;
  vacancy_name: string;
  customer_name: string;
  created_at: string;
  source: string;
  vacancy: ShortVacancy;
}
interface Candidate {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  procedures: Procedure[];
}

const CandidateSearch: React.FC<CandidateSearchProps> = () => {
  const [searchValue, setSearchValue] = useState('');
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const { removeModal } = useModalContext();
  let controller: AbortController | undefined = undefined;
  const navigate = useNavigate();

  const handleSearch = (value: string) => {
    if (controller) controller.abort('Abort previous request');
    setSearchValue(value);
  };

  const handleDetailClick = (procedureId: number): void => {
    navigate(Routes.PROCEDURES + '/' + procedureId);
    removeModal('candidate-search');
  };

  useEffect(() => {
    if (searchValue.length >= 3) {
      controller = new AbortController();
      refetch({ search: searchValue });
    }
  }, [searchValue]);

  const { loading, refetch } = useQuery<
    {
      searchCandidates: Candidate[];
    },
    { search: string }
  >(SEARCH_CANDIDATES, {
    fetchPolicy: 'network-only',
    skip: searchValue.length < 3,
    abortController: controller,
    onCompleted: (data) => {
      setCandidates(data.searchCandidates);
    },
  });

  const LoadingSkeleton: React.FC = () => (
    <div tw="flex flex-col">
      {[...Array(3)].map((_value, index) => (
        <div
          key={index}
          tw="flex justify-start py-2 px-2 gap-4 items-center relative hover:bg-slate-100 hover:rounded cursor-pointer transition-all duration-500 ease-in-out"
        >
          <div tw="animate-pulse w-[40px] h-[40px] rounded-full bg-slate-200"></div>
          <div tw="animate-pulse w-[250px] h-4 bg-slate-200"></div>
          <div tw="animate-pulse w-[250px] h-4 bg-slate-200"></div>
        </div>
      ))}
    </div>
  );

  return (
    <div
      tw="w-[95%] h-[calc(100% - 2rem)] mx-auto"
      data-testid="candidate-sidebar"
    >
      <div tw="mb-12">
        <Title3 mt={2} mb={2}>
          Search Candidates
        </Title3>
        <PermissionWrapper permission={PERMISSIONS.MANAGE_CANDIDATES}>
          <Input
            type="text"
            value={searchValue}
            placeholder="e.g. John Doe"
            data-testid="candidate-search-input"
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            inputStyles={tw`border border-gray-300`}
          />
        </PermissionWrapper>
      </div>
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <div>
          <table>
            <tbody>
              {candidates.map((candidate: Candidate) =>
                candidate?.procedures?.map((procedure: Procedure) => (
                  <div
                    key={uuid()}
                    tw="flex justify-start py-2 px-2 gap-4 items-center relative hover:bg-slate-100 hover:rounded cursor-pointer transition-all duration-500 ease-in-out"
                    onClick={() => handleDetailClick(procedure.id)}
                  >
                    <td tw="w-10">
                      <SourceIcon source={procedure.source} fill="#7a78dd" />
                    </td>

                    <UserIcon>
                      {candidate.firstname.charAt(0) ?? ''}
                      {candidate.lastname.charAt(0) ?? ''}
                    </UserIcon>

                    <p tw="w-48 truncate">{`${candidate.firstname} ${candidate.lastname}`}</p>

                    <p tw="text-gray-400 text-sm w-40">
                      {procedure.vacancy_name}
                    </p>

                    <p
                      tw="ml-auto flex items-center gap-1 flex-nowrap"
                      onClick={() => handleDetailClick(procedure.id)}
                    >
                      View <ArrowRight />
                    </p>
                  </div>
                ))
              )}
            </tbody>
          </table>
          {searchValue !== '' && candidates.length === 0 && (
            <div tw="w-full h-20 grid place-items-center">
              <p tw="w-fit" data-testid="candidate-list-first-item">
                No candidates found
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CandidateSearch;

const UserIcon = styled.td`
  ${tw`p-2 rounded-full w-10 h-10 text-center bg-indigo-200 text-gray-600`}
`;
