import tw from 'twin.macro';
import Badge from 'components/badge/Badge';
import Button from 'components/button/Button';
import React from 'react';
import { User } from 'types/userTypes';
import PermissionGrid from 'components/permissions/permission-grid/PermissionGrid';
import { PermissionLevel } from '../../../types/permissionTypes';
import usePermissionContext from 'hooks/context/permission-context';
import { RoleData } from './UsersConfig';
import { PERMISSIONS } from 'context/PermissionContext';
import { Title3 } from 'components/typography/Typography';
import { Info } from '@phosphor-icons/react';
import useModalContext from 'hooks/context/modals-context';
import { PostHogFeature } from 'posthog-js/react';

const ColleaguesListItem = ({
  colleague,
  removable,
  onResend,
  onUnlink,
  loading,
  onUpdate,
}: {
  colleague: User;
  removable: boolean;
  onResend: () => void;
  onUnlink: () => void;
  loading: boolean;
  onUpdate: (userId: number, roles: RoleData[]) => void;
}) => {
  const { allRoles, userHasPermission } = usePermissionContext();
  const { pushModal, removeModal } = useModalContext();

  const flattenRoles = (
    nodes: typeof colleague.roles,
    parentRoles: string[] = []
  ): RoleData[] => {
    return nodes.flatMap((node) => {
      // Filter current roles by checking if they are not already in the parent roles
      const currentRoles = node.roles
        .filter((r) => !parentRoles.includes(r)) // Only include roles that are not in parentRoles
        .map((r) => ({
          role_id: allRoles?.find((role) => role.name === r)?.id ?? 0,
          role_name: r,
          model_id: node.id,
          model_type: node.model_type,
        }));

      // For children, pass only the immediate parent's roles to check for new roles
      const childrenRoles = node.children
        ? flattenRoles(node.children, node.roles) // Only pass down the parent's roles (not the grandparent's)
        : [];

      return [...currentRoles, ...childrenRoles];
    });
  };

  const handleRolesPermissionModal = () => {
    const modalKey = 'roles-permissions';
    pushModal({
      key: modalKey,
      show: true,
      twclass: tw`overflow-x-hidden`,
      onClose: () => removeModal(modalKey),
      children: (
        <div tw="p-4 sm:max-w-xl lg:max-w-screen-2xl w-full lg:w-screen flex flex-col gap-8 overflow-x-hidden">
          <div tw="space-y-6 overflow-x-hidden">
            {/*  Roles and Responsibilities  */}
            <div>
              <h3 tw="text-xl font-semibold text-gray-800">
                Roles and Responsibilities
              </h3>
              <p tw="text-gray-700 mt-2">
                Roles and responsibilities ensure that users have{' '}
                <b>well-defined</b> permissions based on their tasks within the
                platform. <br />
                There are <b>four key roles</b>, each with specific functions:
              </p>
              <ul tw="list-disc list-inside space-y-2 mt-3 text-gray-700">
                <li>
                  <b>Campaign Management:</b> Users can create brands and
                  oversee multiple views related to:
                  <ul tw="list-disc list-inside ml-5 mt-1">
                    <li>Ad campaigns</li>
                    <li>Targeting</li>
                    <li>Reporting</li>
                    <li>Media settings</li>
                  </ul>
                </li>
                <li>
                  <b>Candidate Management:</b> Users can:
                  <ul tw="list-disc list-inside ml-5 mt-1">
                    <li>Handle applications</li>
                    <li>Search for candidates</li>
                    <li>Manage campaign preferences</li>
                  </ul>
                </li>
                <li>
                  <b>User Management:</b> Provides control over:
                  <ul tw="list-disc list-inside ml-5 mt-1">
                    <li>Assigning or revoking roles for other users</li>
                    <li>Inviting new users to the account</li>
                  </ul>
                </li>
                <li>
                  <b>Admin & Finance Management:</b> Grants access to:
                  <ul tw="list-disc list-inside ml-5 mt-1">
                    <li>Account-wide settings</li>
                    <li>Integrations</li>
                    <li>Subscriptions and invoices</li>
                    <li>Financial balances</li>
                  </ul>
                </li>
              </ul>
              <p tw="text-gray-700 mt-3">
                These roles collectively ensure a structured workflow, granting
                users the appropriate level of access for their
                responsibilities.
              </p>
            </div>

            {/*  Responsibility Levels  */}
            <div>
              <h3 tw="text-xl font-semibold text-gray-800">
                Responsibility Levels
              </h3>
              <p tw="text-gray-700 mt-2">
                Roles can be <b>assigned</b> on <b>multiple levels</b>, each
                offering specific permission scopes:
              </p>
              <ul tw="list-disc list-inside space-y-2 mt-3 text-gray-700">
                <li>
                  <b>Customer (Global) Level:</b>
                  <ul tw="list-disc list-inside ml-5 mt-1">
                    <li>
                      Grants access to all <b>current</b> and{' '}
                      <b>future brands and campaigns</b>.
                    </li>
                    <li>
                      Provides the <b>highest level</b> of access for that role.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Brand Level:</b>
                  <ul tw="list-disc list-inside ml-5 mt-1">
                    <li>
                      Allows role execution <b>within a specific brand</b>.
                    </li>
                    <li>
                      Restricts access to <b>other brands</b> and account-wide
                      settings.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Vacancy (Campaign) Level:</b>
                  <ul tw="list-disc list-inside ml-5 mt-1">
                    <li>
                      Grants access to <b>specific campaigns only</b>.
                    </li>
                    <li>
                      Prevents the user from modifying broader brand or account
                      settings.
                    </li>
                  </ul>
                </li>
              </ul>
              <p tw="text-gray-700 mt-3">
                This tiered system ensures <b>precise control</b> over
                permissions, maintaining security while enabling flexibility.
              </p>
            </div>
          </div>
        </div>
      ),
    });
  };

  return (
    <div tw="mt-1 flex w-full flex-row flex-wrap justify-between rounded border-2 border-solid border-gray-200 p-4 relative gap-4">
      <div tw="flex flex-1 flex-col max-w-full">
        <span tw="float-left flex flex-row font-bold">
          {`${colleague?.firstname} ${colleague?.lastname}`}
        </span>
        <span tw="text-sm text-gray-500">{colleague?.email}</span>

        <PostHogFeature flag="roles-and-permissions" match={true}>
          <div tw="flex items-end gap-2">
            <Title3 mt={4}>Permissions</Title3>
            <Info
              weight="bold"
              tw="mb-3 cursor-pointer"
              onClick={handleRolesPermissionModal}
            />
          </div>
          <PermissionGrid
            roles={allRoles ?? []}
            userId={colleague.id}
            nodes={colleague.roles}
            onRoleChange={(nodes: PermissionLevel[]) => {
              const flattenedRoles = flattenRoles(nodes);
              onUpdate(colleague.id, flattenedRoles);
            }}
          />
        </PostHogFeature>
      </div>

      <div tw="my-auto">
        <div tw="flex items-center space-x-4 py-4">
          {!colleague?.verified && (
            <>
              <Badge label={'Pending'} color="gray" />
              <Button variant="link" onClick={onResend} loading={loading}>
                {loading ? 'Resending invite' : 'Resend invite'}
              </Button>
            </>
          )}
          {removable &&
            userHasPermission(PERMISSIONS.MANAGE_USERS, 'customer') && (
              <Button variant="link" onClick={onUnlink}>
                Unlink
              </Button>
            )}
        </div>
      </div>
    </div>
  );
};

export default ColleaguesListItem;
