import React, { useEffect, useState } from 'react';
import Container from 'components/container/Container';
import ContainerHeader from 'components/container/container-header/ContainerHeader';
import { Title2 } from 'components/typography/Typography';
import 'twin.macro';
import { Brand } from 'types/customer/types';
import Button from 'components/button/Button';
import { Plus } from '@phosphor-icons/react';
import {
  BrandModal,
  IModalProps,
  ModalType,
} from 'views/settings/brands/BrandModal';
import { useMutation } from 'hooks/sympl-mutation';
import { CREATE_BRAND, DELETE_BRAND } from 'graphql/brands/mutations';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { BrandAccordionHeader } from './BrandAccordionHeader';
import { BrandEditForm } from './BrandEditForm';
import useNavigationContext from 'hooks/context/nav-context';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';
import { fireEvent } from 'utils/eventHelper';

interface IModalState {
  type: ModalType;
  show: boolean;
  brandItem?: Brand;
}

interface ICustomerBrandProps {
  brandId: number;
  input: {
    name: string;
  };
}

export const BrandsSettings: React.FC = () => {
  const [modal, setModal] = useState<IModalState>({
    type: ModalType.ADD,
    show: false,
    brandItem: undefined,
  });
  const { brands, refetchBrands } = useNavigationContext();
  const { addToast } = useToastNotifications();
  const [activeBrands, setActiveBrands] = useState<number[] | null>([]);

  const [createBrand] = useMutation(CREATE_BRAND, {
    onCompleted: refetchBrands,
  });
  const [deleteBrand] = useMutation<{}, ICustomerBrandProps>(DELETE_BRAND, {
    onCompleted: refetchBrands,
  });

  // set the active brand index to the first one if there is only one brand
  useEffect(() => {
    if (brands.length === 1) setActiveBrands([0]);
  }, [brands]);

  const changeBrandModalHandler = (
    type: ModalType,
    item?: Brand,
    show = true
  ) => {
    setModal({
      type: type,
      show: show,
      brandItem: item,
    });
  };

  const modalOnActionHandler = async (props?: IModalProps) => {
    switch (modal.type) {
      case ModalType.ADD:
        await createBrand({
          variables: {
            input: {
              name: props?.brandName,
            },
          },
        }).then(() => {
          fireEvent('create_new_brand', {
            brand: props?.brandName,
          });
        });
        break;

      case ModalType.DELETE:
        await deleteBrand({
          variables: {
            brandId: props?.id as number,
            input: {
              name: props?.brandName as string,
            },
          },
        });
        break;
    }

    addToast({
      type: ToastTypes.SUCCESS,
      description:
        modal.type === ModalType.ADD
          ? 'Your brand was successfully added'
          : 'Your brand was successfully deleted',
    });

    changeBrandModalHandler(ModalType.ADD, undefined, false);
  };

  return (
    <Container noShadow={true}>
      {modal.show && (
        <BrandModal
          modalType={modal.type}
          showModal={modal.show}
          closeModal={() => setModal({ ...modal, show: false })}
          brandItem={modal.brandItem}
          onClick={async (props) => await modalOnActionHandler(props)}
        />
      )}
      <ContainerHeader>
        <div tw="flex flex-row justify-between">
          <Title2 mb={0}>Brands</Title2>
          <Button
            onClick={() => changeBrandModalHandler(ModalType.ADD)}
            icon={<Plus weight="bold" />}
          >
            Add brand
          </Button>
        </div>
      </ContainerHeader>
      <div tw="flex flex-col gap-4 w-full mt-4">
        <Accordion
          allowMultiple
          transition
          transitionTimeout={250}
          id="brand-settings-accordion"
          tw="flex flex-col gap-4 w-full"
          onStateChange={({ key, current }) => {
            if (current.isResolved) {
              setActiveBrands((prev) => {
                const keyNumber = Number(key);
                if (prev?.indexOf(keyNumber) === -1) {
                  return [...(prev ?? []), keyNumber];
                } else {
                  return prev?.filter((item) => item !== keyNumber) ?? null;
                }
              });
            }
          }}
        >
          {brands.map((item, index) => (
            <div tw="relative" key={index}>
              <AccordionItem
                initialEntered={brands.length === 1}
                key={index}
                header={
                  <BrandAccordionHeader
                    title={item.name}
                    index={index}
                    open={activeBrands?.indexOf(index) ? false : true}
                  />
                }
                itemKey={index}
              >
                <BrandEditForm
                  brand={item}
                  handleDelete={() =>
                    changeBrandModalHandler(ModalType.DELETE, item)
                  }
                />
              </AccordionItem>
            </div>
          ))}
        </Accordion>
      </div>
    </Container>
  );
};
