import { AdChannel, AdDateRange } from 'types/adTypes';

type SymplSession = {
  activeVacancy?: number;
  activeCustomer?: number;
  activeDateFilter?: AdDateRange;
  email?: string;
  activeChannels?: AdChannel[];
  brandColors?: [string, string];
  firstName?: string;
  lastName?: string;
  userId?: string;
  isAdmin?: boolean;
};

/**
 * Gets the current app session from localStorage, if available.
 * @returns SymplSession The current app session
 */
export const getLocalAppSession = (): SymplSession => {
  try {
    const session = localStorage.getItem('sympl-session');
    return session ? JSON.parse(session) : {};
  } catch (_error) {
    return {};
  }
};

/**
 * Sets the current app session in localStorage
 * @param  {SymplSession} session The current app session object
 */
export const setLocalAppSession = (session: SymplSession) => {
  try {
    localStorage.setItem(
      'sympl-session',
      JSON.stringify({ ...getLocalAppSession(), ...session })
    );
    window.dispatchEvent(new Event('storage'));
  } catch (_error) {}
};
