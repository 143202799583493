import { TeamKeyFields } from '@symplbe/sympl-components';
import { WithPlaceholder } from 'types/careers-page/section';
import { getSectionHeaderDefaultConfig } from '../shared/defaultConfig';
import { getAvatar, getFirstName, getJobTitle } from 'data/faker';

export const defaultTeamSettings: TeamKeyFields & WithPlaceholder = {
  ...getSectionHeaderDefaultConfig(),
  team: [...Array(Math.round(3 + Math.random() * 4))].map(() => ({
    imageUrl: getAvatar(),
    name: getFirstName(),
    role: getJobTitle(),
  })),
};
