import {
  getBuzzVerb,
  getBuzzNoun,
  getBuzzPhrase,
  getParagraph,
} from 'data/faker';
import { capitalize } from 'lodash';

interface SectionHeaderDefaultConfig {
  subTitle: string;
  title: string;
  description: string;
  primaryButtonLabel: string;
  linkButtonLabel: string;
  placeholderValue: {
    subTitle: string;
    title: string;
    description: string;
    primaryButtonLabel: string;
    linkButtonLabel: string;
  };
}

export const getSectionHeaderDefaultConfig = (
  values: Partial<SectionHeaderDefaultConfig> = {}
): SectionHeaderDefaultConfig => {
  return {
    subTitle: values.subTitle || '',
    title: values.title || '',
    description: values.description || '',
    primaryButtonLabel: '',
    linkButtonLabel: '',
    placeholderValue: {
      subTitle:
        values.placeholderValue?.subTitle ||
        capitalize(getBuzzVerb() + ' ' + getBuzzNoun()),
      title: values.placeholderValue?.title || capitalize(getBuzzPhrase()),
      description: values.placeholderValue?.description || getParagraph(),
      primaryButtonLabel: 'Check our open positions',
      linkButtonLabel: 'Contact us',
    },
  };
};

export const defaultTextClasses = (
  title?: string,
  description?: string,
  subTitle?: string
) => {
  const textClasses: { [key: string]: string } = {};
  if (!title) textClasses.title = 'opacity-50';
  if (!description) textClasses.description = 'opacity-50';
  if (!subTitle) textClasses.subTitle = 'opacity-50';

  return textClasses;
};
