import React, { useMemo, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

import SvgSpinner from 'components/svg/SvgSpinner';

interface AutoPaginationProps {
  selectedPage?: number;
  itemsLoaded?: number;
  totalItems?: number;
  maxItems?: number;

  totalPages?: number;
  onPageChange?: (currentPage: number) => Promise<void>;
}

const AutoPagination: React.FC<AutoPaginationProps> = ({
  selectedPage,
  itemsLoaded,
  totalItems,
  totalPages = 1,
  onPageChange,
}) => {
  const START_PAGE_INDEX = 1;

  const [isRefetching, setIsRefetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    selectedPage ?? START_PAGE_INDEX
  );

  const isAllowedToGoBack = useMemo(
    () => currentPage - 1 >= START_PAGE_INDEX,
    [currentPage]
  );

  const isAllowedToGoForward = useMemo(
    () => currentPage + 1 <= totalPages,
    [currentPage, totalPages]
  );

  const goToPage = async (direction: 'next' | 'previous') => {
    let curPage = currentPage;

    setIsRefetching(true);

    if (direction === 'next') curPage += 1;
    else if (direction === 'previous') curPage -= 1;

    try {
      await onPageChange?.(curPage);
      setCurrentPage(curPage);
    } catch (_) {
    } finally {
      setIsRefetching(false);
    }
  };

  return (
    <>
      <div tw="py-3 flex items-center justify-between sm:px-6">
        {/*Mobile view*/}
        <div tw="flex-1 flex justify-between sm:hidden">
          <MobileNavButton
            isDisabled={!isAllowedToGoBack}
            isLoading={isRefetching}
            onClick={() => goToPage('previous')}
          >
            Previous
          </MobileNavButton>
          <MobileNavButton
            isDisabled={!isAllowedToGoForward}
            isLoading={isRefetching}
            onClick={() => goToPage('next')}
          >
            Next
          </MobileNavButton>
        </div>
        {/*Regular view*/}
        <div tw="hidden sm:(flex items-center justify-between flex-1)">
          <div>
            <p tw="text-sm text-gray-700 mr-5">
              Showing
              <span tw="font-medium ml-1 mr-1">{itemsLoaded ?? 0}</span>
              results out of
              <span tw="font-medium ml-1 mr-1">{totalItems ?? 0}</span>
              total
            </p>
          </div>
          <div tw="items-center flex">
            {isRefetching && <SvgSpinner />}
            <nav
              tw="relative z-0 inline-flex rounded-md shadow-sm -space-x-px "
              aria-label="Pagination"
            >
              <RegularNavButton
                position="left"
                isDisabled={!isAllowedToGoBack}
                isLoading={isRefetching}
                onClick={() =>
                  isAllowedToGoBack ? goToPage('previous') : null
                }
              >
                <span tw="sr-only">Previous</span>
                <CaretLeft weight="bold" />
              </RegularNavButton>
              <button
                disabled={isRefetching}
                tw="bg-white border-gray-300 text-gray-500 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              >
                page
                <span tw="font-medium ml-1 mr-1">{currentPage}</span>
                of
                <span tw="font-medium ml-1 mr-1">{totalPages}</span>
              </button>
              <RegularNavButton
                position="right"
                isDisabled={!isAllowedToGoForward}
                isLoading={isRefetching}
                onClick={() => (isAllowedToGoForward ? goToPage('next') : null)}
              >
                <span tw="sr-only">Next</span>
                <CaretRight weight="bold" />
              </RegularNavButton>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

interface NavButtonProps {
  isDisabled: boolean;
  isLoading: boolean;
}

const MobileNavButton = styled.button<NavButtonProps>`
  ${tw`
    relative inline-flex items-center px-4 py-2 border
  border-gray-300 text-sm font-medium rounded-md
  text-gray-700 bg-white
  `}

  ${({ isDisabled }) =>
    isDisabled ? tw`cursor-not-allowed` : tw`hover:bg-gray-200 cursor-pointer`}

  ${({ isLoading }) => isLoading && tw`cursor-wait`}
`;

const RegularNavButton = styled.button<
  NavButtonProps & {
    position: string;
  }
>`
  ${tw`
    relative inline-flex items-center px-2 py-2 border
    border-gray-300 bg-white text-sm
    font-medium text-gray-500
  `}

  ${({ position }) =>
    position === 'left' ? tw`rounded-l-md` : tw`rounded-r-md`}

  ${({ isDisabled }) =>
    isDisabled ? tw`cursor-not-allowed` : tw`hover:bg-gray-200 cursor-pointer`}

  ${({ isLoading }) => isLoading && tw`cursor-wait`}
`;

export default AutoPagination;
