import React, { useCallback, useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import { getOverlay } from 'utils/overlayHelpers';
import useNavigationContext from 'hooks/context/nav-context';
import Button from 'components/button/Button';
import { fireEvent } from 'utils/eventHelper';
import { showIntercom } from 'utils/intercomHelper';
import { Title3 } from 'components/typography/Typography';
import { PostHogFeature } from 'posthog-js/react';

const OverlayPreview = ({ overlay }: { overlay: string }) => {
  const { currentVariant, changeOverlay } = useAdEditorContext();
  const { currentVacancy } = useNavigationContext();

  const SVGComponent = useCallback(
    (overlay: string) => {
      return getOverlay(
        overlay,
        currentVariant,
        currentVacancy?.brand,
        currentVacancy?.targeting?.locations?.[0]?.city,
        false
      );
    },
    [
      currentVariant?.uuid,
      currentVariant?.company,
      currentVariant?.function,
      currentVariant?.logo?.path,
    ]
  );
  return (
    <picture
      tw="[svg]:(h-32 w-auto)"
      onClick={() => changeOverlay(overlay)}
      data-testid="overlay-preview"
    >
      <div
        dangerouslySetInnerHTML={{
          __html: SVGComponent(overlay),
        }}
      />
    </picture>
  );
};

export const PlacementMenu = () => {
  const { currentVariant, defaultAdOverlays, customAdOverlays } =
    useAdEditorContext();

  const defOverlays = useMemo(() => {
    return defaultAdOverlays.filter(
      (overlay) => currentVariant?.placement === overlay.placement
    );
  }, [defaultAdOverlays]);

  const cusOverlays = useMemo(() => {
    return customAdOverlays.filter(
      (overlay) => currentVariant?.placement === overlay.placement
    );
  }, [customAdOverlays]);

  return (
    <div tw="flex flex-col gap-12" id="placement-menu">
      {/* CUSTOM OVERLAYS */}
      {currentVariant?.path ? (
        <>
          <div>
            <Title3 tw="text-2xl font-bold">Custom Overlays</Title3>
            <PostHogFeature
              flag="custom-overlays"
              match={true}
              fallback={
                <span tw="text-gray-700">
                  Sounds interesting? <br />
                  Contact us via the chat button.
                </span>
              }
            >
              {cusOverlays.length ? (
                <PlacementMenuOptionContainer>
                  {cusOverlays &&
                    cusOverlays.map((overlay) => (
                      <OverlayPreview key={overlay.id} overlay={overlay.svg} />
                    ))}
                </PlacementMenuOptionContainer>
              ) : (
                <span tw="text-gray-700">
                  You have no custom overlays yet. <br />
                  Get started below.
                </span>
              )}
            </PostHogFeature>
          </div>

          <div>
            <Title3 tw="text-2xl font-bold">Default Overlays</Title3>

            <PlacementMenuOptionContainer>
              {defOverlays.map((overlay) => (
                <OverlayPreview key={overlay.id} overlay={overlay.svg} />
              ))}
            </PlacementMenuOptionContainer>
          </div>
        </>
      ) : (
        <div>
          <span>Select an image before picking an overlay.</span>
        </div>
      )}
      <div tw="flex flex-col items-center mt-8">
        <Button
          onClick={() => {
            fireEvent('custom_overlay_interested');
            showIntercom(
              'Hi, I want to use my own custom overlays. Help me out.'
            );
          }}
        >
          Add your own overlay
        </Button>
      </div>
    </div>
  );
};

const PlacementMenuOptionContainer = styled.div(
  tw`grid grid-cols-1 xl:grid-cols-2 gap-4 overflow-y-auto p-4 place-items-center cursor-pointer`
);
