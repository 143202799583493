import { UPDATE_BUDGET } from 'graphql/vacancies/mutations';
import { useMutation } from 'hooks/sympl-mutation';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'twin.macro';
import { redirectToStripeCheckout } from 'utils/paymentHelpers';
import BudgetSelector from './BudgetSelector';

import {
  Error,
  Subhead,
  Title2,
  Title3,
} from 'components/typography/Typography';
import useNavigationContext from 'hooks/context/nav-context';
import Checkbox from 'components/checkbox/Checkbox';
import { useQuery } from 'hooks/sympl-query';
import { GET_VACANCY_BUDGET } from 'graphql/vacancies/queries';
import Button from 'components/button/Button';
import { VacancyMetric } from 'types/vacancyTypes';
import RadioGroup from 'components/form/RadioGroup';
import Label from 'components/form/Label';
import InformationToolTip from 'components/tooltip/InformationToolTip';

interface UpdateBudgetResponse {
  updatedBudget: {
    session_id?: string;
  };
}

export enum PaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  WIRE_TRANSFER = 'WIRE_TRANSFER',
}

interface UpdateBudgetPayload {
  vacancyId: number;
  input: {
    total_budget: number;
    resume_campaign: boolean | null;
    use_balance?: boolean;
    payment_method?: PaymentMethod;
  };
}

const MIN_AD_BUDGET = 100;
const MAX_AD_BUDGET = 5000;

interface BudgetProps {
  title?: string;
  subtitle?: string | JSX.Element;
  cta: string;
  showResume?: boolean;
  updateBudget?: boolean;
  onSubmit?: (
    budget: number,
    paymentMethod?: PaymentMethod,
    useBalance?: boolean
  ) => void;
  closeModal?: () => void;
}

const Budget: React.FC<BudgetProps> = ({
  subtitle,
  title,
  cta,
  showResume = false,
  updateBudget = true,
  onSubmit,
  closeModal,
}) => {
  const { control, handleSubmit, reset: resetForm, watch, errors } = useForm();

  const [updateBudgetCall, { loading: updatingBudget }] = useMutation<
    UpdateBudgetResponse,
    UpdateBudgetPayload
  >(UPDATE_BUDGET);

  const { activeVacancy, customer, refetchCustomer } = useNavigationContext();

  // TODO: const wireTransferEnabled = useFeatureFlagEnabled('wire-transfer');

  const { data } = useQuery<
    {
      budget: { vacBudget: VacancyMetric['vacBudget'] };
    },
    { vacancyId: number }
  >(GET_VACANCY_BUDGET, {
    skip: !activeVacancy,
    fetchPolicy: 'network-only',
    variables: { vacancyId: activeVacancy ?? 0 },
  });

  const oldTotalBudget = useMemo(
    () => data?.budget?.vacBudget?.total_budget ?? 0,
    [data?.budget?.vacBudget?.total_budget]
  );

  const customerBalance = customer?.balance ?? 0;

  const useBalanceSelected = watch('use-balance');
  const remainingBudgetAfterBalance =
    watch('extra-budget') - (useBalanceSelected ? customerBalance : 0);

  const submitHandler = (data: {
    'extra-budget': number;
    'resume-campaign'?: boolean;
    'use-balance'?: boolean;
    'payment-method'?: PaymentMethod;
  }) => {
    const newTotalBudget = oldTotalBudget + data['extra-budget'];

    if (!updateBudget) {
      onSubmit?.(newTotalBudget, data['payment-method'], data['use-balance']);
      closeModal?.();
      return;
    }

    if (!activeVacancy) return;

    try {
      updateBudgetCall({
        variables: {
          vacancyId: activeVacancy,
          input: {
            total_budget: newTotalBudget,
            resume_campaign: data['resume-campaign'] ?? false,
            use_balance: data['use-balance'] ?? false,
            payment_method: data['payment-method'],
          },
        },
      }).then((response) => {
        resetForm();

        const stripeId = response.data?.updatedBudget.session_id;
        if (stripeId) redirectToStripeCheckout(stripeId);

        onSubmit?.(newTotalBudget, data['payment-method'], data['use-balance']);

        if (data['use-balance']) refetchCustomer();
      });
    } catch (error) {
      console.error(error);
    } finally {
      closeModal?.();
    }
  };

  return (
    <form id="budget-form" tw="py-3" onSubmit={handleSubmit(submitHandler)}>
      <div>
        <Title2>{title}</Title2>
        <Subhead>{subtitle}</Subhead>
      </div>

      <div tw="my-6">
        <Controller
          id="extra-budget"
          name="extra-budget"
          control={control}
          defaultValue={400}
          rules={{
            min: MIN_AD_BUDGET,
            max: MAX_AD_BUDGET,
          }}
          render={({ value, onChange }) => (
            <BudgetSelector
              id="extra-budget"
              onChange={onChange}
              currentValue={value || MIN_AD_BUDGET}
              allowCustomValue={true}
              options={[400, 600, 800, 1000]}
              minValue={MIN_AD_BUDGET}
              maxValue={MAX_AD_BUDGET}
            />
          )}
        />
        {errors['extra-budget'] && (
          <Error>
            This budget is not allowed, please fill in at least €100 and maximum
            €5.000.
          </Error>
        )}
      </div>

      <div tw="mt-8">
        <Title3>Select payment method</Title3>
        <div tw="flex flex-row items-center justify-between px-4 py-2 bg-gray-50 rounded-md">
          <Controller
            id="use-balance"
            name="use-balance"
            control={control}
            defaultValue={customerBalance > 0}
            render={({ value, onChange }) => (
              <Checkbox
                disabled={customerBalance <= 0}
                label={`Use account balance (budget left: €${customerBalance.toLocaleString()})`}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <InformationToolTip
            name="budget_modal_account_balance_explanation"
            text="Make budget payments even easier by leveraging sympl's account balance feature. Head over to your account settings and top-up your balance via bank transfer."
          />
        </div>
        {remainingBudgetAfterBalance > 0 && (
          <div tw="mt-4 animate-fade-in-up">
            {useBalanceSelected && (
              <Label htmlFor="payment-method">Pay remainder via:</Label>
            )}
            <Controller
              id="payment-method"
              name="payment-method"
              control={control}
              render={({ onChange }) => (
                <RadioGroup
                  name="payment-method-radiogroup"
                  onChange={onChange}
                  items={[
                    {
                      id: PaymentMethod.CREDIT_CARD,
                      label: 'Credit Card (Mastercard, Visa...)',
                      value: PaymentMethod.CREDIT_CARD,
                    },
                    {
                      id: PaymentMethod.WIRE_TRANSFER,
                      label: 'Bank Transfer',
                      value: PaymentMethod.WIRE_TRANSFER,
                    },
                  ].filter(
                    (item) =>
                      // TODO: remove legacy
                      !customer?.is_legacy || // All options available for non-legacy
                      item.id === customer?.payment_method // Only customer.payment_method available for legacy
                  )}
                  /** TODO: replaces filter + legacy lines above
                    ...(wireTransferEnabled
                      ? [
                          {
                            id: PaymentMethod.WIRE_TRANSFER,
                            label: 'Bank Transfer',
                            value: PaymentMethod.WIRE_TRANSFER,
                          },
                        ]
                      : []),
                       */
                />
              )}
            />
          </div>
        )}
      </div>

      {showResume && (
        <div tw="mt-6">
          <Controller
            id="resume-campaign"
            name="resume-campaign"
            control={control}
            defaultValue={true}
            render={({ value, onChange }) => (
              <Checkbox
                label="Resume campaign right away"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      )}

      {oldTotalBudget > 0 && (
        <div tw="flex justify-end mt-4">
          <Subhead isBold>
            Your new total budget will be set to €{' '}
            {(oldTotalBudget + (watch('extra-budget') ?? 0)).toLocaleString()}.
          </Subhead>
        </div>
      )}

      <div tw="float-right pt-2 pb-4">
        <Button type="submit" loading={updatingBudget}>
          {cta}
        </Button>
      </div>
    </form>
  );
};

export default Budget;
