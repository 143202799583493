import tw, { TwStyle, styled } from 'twin.macro';
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { X } from '@phosphor-icons/react';
import { createGlobalStyle } from 'styled-components';

const Globals = createGlobalStyle`
  .enter-backdrop {
    ${tw`ease-out duration-300`}
  }
  .enter-from-backdrop {
    ${tw`opacity-0`}
  }
  .enter-to-backdrop {
    ${tw`opacity-100`}
  }
  .leave-backdrop {
    ${tw`ease-in duration-200`}
  }
  .leave-from-backdrop {
    ${tw`opacity-100`}
  }
  .leave-to-backdrop {
    ${tw`opacity-0`}
  }

  .enter-panel {
    ${tw`ease-out duration-300`}
  }
  .enter-from-panel {
    ${tw`opacity-0 scale-95`}
  }
  .enter-to-panel {
    ${tw`opacity-100 scale-100`}
  }
  .leave-panel {
    ${tw`ease-in duration-200`}
  }
  .leave-from-panel {
    ${tw`opacity-100 scale-100`}
  }
  .leave-to-panel {
    ${tw`opacity-0 scale-95`}
  }
`;

export type ModalProps = {
  show: boolean;
  initialFocus?: React.RefObject<HTMLElement>;
  onClose?: () => void;
  children: React.ReactNode;
  hideCloseButton?: boolean;
  twclass?: TwStyle;
  variant?: string; // for now, the only non-standard variant is sideBar. This might be changed in the future
};

export const Modal = ({
  show,
  initialFocus,
  onClose,
  children,
  hideCloseButton = false,
  twclass,
  variant,
}: ModalProps) => (
  <>
    <Globals />
    <Transition show={show} as={Fragment}>
      <Dialog
        initialFocus={initialFocus}
        onClose={() => onClose?.()}
        tw="relative z-[140]"
      >
        <Transition.Child
          as={Fragment}
          enter="enter-backdrop"
          enterFrom="enter-from-backdrop"
          enterTo="enter-to-backdrop"
          leave="leave-backdrop"
          leaveFrom="leave-from-backdrop"
          leaveTo="leave-to-backdrop"
        >
          <div tw="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="enter-panel"
          enterFrom="enter-from-panel"
          enterTo="enter-to-panel"
          leave="leave-panel"
          leaveFrom="leave-from-panel"
          leaveTo="leave-to-panel"
        >
          <ModalContainer variant={variant}>
            <ModalContent variant={variant}>
              {
                //! Do not change the overflow behavior
                //! Use the floating-ui package to 'break out' of the modal, see dropdown component for example implementation
              }
              <StyledDialogPanel twclass={twclass}>
                {children}
                {!hideCloseButton && (
                  <X
                    weight="bold"
                    tw="absolute top-0 right-0 cursor-pointer bg-gray-100 hover:bg-gray-200/80 active:bg-gray-300/70 mt-4 mr-4 rounded p-2 z-20"
                    size={32}
                    onClick={onClose}
                    data-testid="modal-close-button"
                  />
                )}
              </StyledDialogPanel>
            </ModalContent>
          </ModalContainer>
        </Transition.Child>
      </Dialog>
    </Transition>
  </>
);

const StyledDialogPanel = styled(Dialog.Panel)<{
  twclass?: TwStyle;
  variant?: string;
}>`
  ${tw`
    relative 
    mx-auto
    h-[clamp(50vh, 100%, 80vh)]
    max-h-[80vh]
    max-w-[90vw] 
    overflow-y-auto 
    rounded 
    bg-white 
    px-4 
    py-2 
    sm:(px-8 py-4) 
    md:max-w-none 
    shadow-xl
  `}
  ${({ twclass }) => twclass}
  ${({ variant }) =>
    variant === 'sideBar' &&
    tw`w-full bg-red-400 h-screen max-w-none rounded-none`}
`;

const ModalContainer = styled.div<{ variant?: string }>`
  ${tw`fixed inset-0 flex items-center justify-center p-4`}
  ${({ variant }) => variant === 'sideBar' && tw`pr-0 ml-auto mr-0`}
`;

const ModalContent = styled.div<{ variant?: string }>`
  ${tw`flex min-h-full items-center justify-center`}
  ${({ variant }) =>
    variant === 'sideBar' && tw`pr-0 ml-auto mr-0 w-1/4 sm:min-w-[640px]`}
`;
