import tw, { css, styled } from 'twin.macro';
import React, { useEffect, useMemo, useState } from 'react';
import { AdEditorSavablePreviewState } from 'types/adEditorTypes';
import {
  AD_CHANNELS,
  AD_PLACEMENTS,
  AdChannel,
  AdCreativeType,
  AdPlacement,
} from 'types/adTypes';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Button from 'components/button/Button';
import TextEditor from 'components/form/text-editor/TextEditor';
import ResourceUpload from 'components/upload/ResourceUpload';
import { CustomerResource } from 'types/apiTypes';
import useNavigationContext from 'hooks/context/nav-context';
import { useMutation } from 'hooks/sympl-mutation';
import {
  DELETE_VARIANTS,
  UPDATE_EDITOR_DATA,
} from 'graphql/ad-variants/mutations';
import {
  AdEditorDeleteVariantPayload,
  AdEditorUpdatePayload,
} from './AdBuilder';

import { Error } from 'components/typography/Typography';
import tinycolor from 'tinycolor2';
import { isTextEditorEmpty, popConfetti } from 'utils/baseHelpers';
import { createPortal } from 'react-dom';
import { CheckCircle, ShareFat } from '@phosphor-icons/react';
import { DEFAULT_VARIANT, getFormattedChannel } from 'utils/adEditorHelpers';
import { useSymplCookie } from 'hooks/symplCookie';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import { UPDATE_VACANCY_ADVERTISEMENT } from 'graphql/vacancies/mutations';
import { fireEvent } from 'utils/eventHelper';
import { FileTypes } from 'types/fileTypes';
import SocialIcon from 'components/icons/SocialIcon';
import { ChatGPTMessage } from 'utils/openAiHelpers';
import { generatePromptEssenceShort } from 'utils/vacancy-generator/prompts';
import SvgEmptyCircle from 'components/svg/SvgEmptyCircle';
import useGetStartedContext from 'hooks/context/get-started-context';
import extractColors from 'utils/extractColorsFromImage';

const getChannelIcon = (channel: AdChannel) => {
  const ICON_SIZE = '40px';
  return AD_CHANNELS.indexOf(channel) !== -1 ? (
    <SocialIcon
      company={channel}
      width={ICON_SIZE}
      height={ICON_SIZE}
      useNaturalColor
    />
  ) : (
    <ShareFat weight="fill" />
  );
};

interface FormData {
  channels: AdChannel[];
  copy: string;
  logo: CustomerResource[];
  content: CustomerResource[];
  primaryColor: string;
  secondaryColor: string;
}

const VariantGenerator = ({
  refetchEditorData,
}: {
  refetchEditorData: () => void;
}) => {
  const [usedAiGen, setUsedAiGen] = useSymplCookie('usedAiGen');

  const { activeVacancy, currentVacancy, refetchCurrentVacancy } =
    useNavigationContext();

  const activeBrand = currentVacancy?.brand;

  const { payload, variants } = useAdEditorContext();
  const { refetchCheckList } = useGetStartedContext();

  const formMethods = useForm<FormData>();

  const watchChannels = formMethods.watch('channels');
  const watchCopy = formMethods.watch('copy');
  const watchLogo = formMethods.watch('logo');
  const watchContent = formMethods.watch('content');

  const [step, setStep] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false); // Is generating variants or ad copy
  const [primaryColor, setPrimaryColor] = useState('#292524');
  const [secondaryColor, setSecondaryColor] = useState('#44403C');

  const canUseGenerateAI = useMemo(() => {
    // get the companyName if it does not exist in the payload
    if (payload && !payload?.companyName)
      payload!.companyName = currentVacancy?.company ?? '';
    return (
      payload &&
      !!payload.jobFunction &&
      !!payload.companyName &&
      !!payload.location &&
      !!payload.jobCategory &&
      !!payload.experienceLevel &&
      !!payload.language
    );
  }, [payload]);

  const [updateVariants] = useMutation<{}, AdEditorUpdatePayload>(
    UPDATE_EDITOR_DATA
  );

  const [updateAdvertisement] = useMutation<
    {},
    { vacancyId: number; input: { channels: string } }
  >(UPDATE_VACANCY_ADVERTISEMENT);

  const [deleteVariants] = useMutation<{}, AdEditorDeleteVariantPayload>(
    DELETE_VARIANTS
  );

  const generateVariants = async ({
    channels,
    copy,
    logo,
    content,
  }: FormData) => {
    if (!payload) return;

    setIsGenerating(true);

    const newVariants: AdEditorSavablePreviewState[] = [];

    const bannerTitle = activeBrand?.default_banner_title ?? 'WANTED';
    const generateVariant = (
      pathId: number,
      placement: AdPlacement,
      creativeType: AdCreativeType
    ) =>
      newVariants.push({
        ...DEFAULT_VARIANT,
        path_id: pathId,
        logo_id: placement === AdPlacement.REELS ? null : logo[0].id,
        function: payload.jobFunction,
        company: payload.companyName,
        text:
          placement === AdPlacement.FEED
            ? copy.replaceAll('<br>', '\n')
            : placement === AdPlacement.REELS
            ? `${bannerTitle}: ${payload.jobFunction} in ${payload.location}`
            : '',
        creative_type: creativeType,
        placement,
        display_link: activeBrand?.default_display_link,
        //DUMMY DATA
        logo_position: 'northeast',
        banner: true,
        banner_position: 'south',
        banner_title: bannerTitle,
        banner_font_family: 'Arial',
        banner_font_size: '55',
        primary_color: primaryColor,
        secondary_color: secondaryColor,
        tertiary_color: 'white',
      } as AdEditorSavablePreviewState);

    // Generate image variants for each image uploaded
    content
      .filter(({ type }) => type === 'image')
      .forEach(({ id }) =>
        [AdPlacement.FEED, AdPlacement.STORIES].forEach((placement) =>
          generateVariant(id, placement, AdCreativeType.IMAGE)
        )
      );

    // Generate video variants for each video uploaded
    content
      .filter(({ type }) => type === 'video')
      .forEach(({ id }) =>
        AD_PLACEMENTS.forEach((placement) =>
          generateVariant(id, placement, AdCreativeType.VIDEO)
        )
      );

    const variantsToDelete = variants
      .filter(({ isDeleted }) => isDeleted)
      .filter(({ id }) => id);

    // Delete any old variants marked for deletion
    if (variantsToDelete.length) {
      await Promise.all(
        variantsToDelete.map(async (variant) => {
          try {
            const { errors } = await deleteVariants({
              variables: {
                variantId: variant.id!,
                input: {
                  vacancy_id: activeVacancy as number,
                },
              },
            });

            return errors ? Promise.reject() : Promise.resolve();
          } catch (_) {
            setIsGenerating(false);
            return Promise.reject();
          }
        })
      );
    }

    try {
      await Promise.all([
        // TODO: we might be able to delete this
        updateVariants({
          variables: {
            input: {
              vacancy_id: activeVacancy as number,
              adVariants: newVariants,
            },
          },
        }),
        updateAdvertisement({
          variables: {
            vacancyId: activeVacancy as number,
            input: {
              channels: channels.join(','),
            },
          },
        }),
      ]);

      refetchEditorData();
    } finally {
      setIsGenerating(false);
      refetchCheckList();
      refetchCurrentVacancy();
    }
  };

  // Initial prompt for the varaint generator
  const initialPrompt: ChatGPTMessage[] | undefined = useMemo(
    () => payload && generatePromptEssenceShort(payload),
    [payload]
  );

  const onFinished = () => {
    if (!usedAiGen) {
      popConfetti();
      setUsedAiGen(true);
    }

    fireEvent('ai_ad_wizard');
  };

  useEffect(() => {
    if (!watchLogo || !watchLogo.length || watchLogo.some((l) => !l)) return;

    (async () => {
      // Fetch the logo
      const logo = new Image();
      logo.crossOrigin = 'Anonymous';
      logo.src = watchLogo[0].thumbnail_path ?? watchLogo[0].path;

      const colors = await extractColors(logo.src).catch((err) => {
        console.error('extractColors error', err);
        return [];
      });

      const READABILITY_THRESHOLD = 1.3;

      // Set the primary and secondary color to the two most dominant colors
      if (tinycolor.readability(colors[0], '#fff') > READABILITY_THRESHOLD)
        setPrimaryColor(colors[0]);
      if (tinycolor.readability(colors[1], '#fff') > READABILITY_THRESHOLD)
        setSecondaryColor(colors[1]);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLogo]);

  return (
    <>
      {createPortal(
        <div tw="absolute top-0 left-0 h-full w-full grid place-items-center bg-black/30">
          <FormProvider {...formMethods}>
            <form
              tw="p-8 shadow-xl relative rounded bg-white mx-6 min-w-[80vw] lg:min-w-[48rem] max-w-[48rem]"
              onSubmit={formMethods.handleSubmit(generateVariants)}
            >
              <Section id="first-section" show={step === 0}>
                <h2 tw="text-2xl font-bold text-gray-800">
                  Advertising Channels
                </h2>
                <Subtitle>
                  Select the channels you want to enable for this campaign.
                </Subtitle>
                <Controller
                  name="channels"
                  defaultValue={[
                    AdChannel.FACEBOOK,
                    AdChannel.INSTAGRAM,
                    AdChannel.INDEED,
                  ]}
                  rules={{
                    validate: (value) => !!value.length,
                  }}
                  render={({
                    value,
                    onChange,
                  }: {
                    value: AdChannel[];
                    onChange: (value: AdChannel[]) => void;
                  }) => (
                    <div
                      tw="grid grid-cols-2 lg:grid-cols-3 p-1 gap-4 lg:gap-8 my-8 overflow-y-auto"
                      id="channelButtons"
                    >
                      {AD_CHANNELS.map((channel) => (
                        <div tw="relative" key={channel}>
                          <ChannelButton
                            type="button"
                            selected={value.includes(channel)}
                            onClick={() =>
                              onChange(
                                value.includes(channel)
                                  ? value.filter((c) => c !== channel)
                                  : [...value, channel]
                              )
                            }
                          >
                            <div tw="flex flex-col items-center space-y-2">
                              {getChannelIcon(channel)}
                              <p tw="text-lg font-medium text-gray-800">
                                {getFormattedChannel(channel)}
                              </p>
                            </div>
                          </ChannelButton>
                          <div
                            tw="absolute top-2 right-2 pointer-events-none"
                            css={[
                              value.includes(channel)
                                ? tw`text-green-500`
                                : tw`text-gray-200`,
                            ]}
                          >
                            {value.includes(channel) ? (
                              <CheckCircle weight="fill" size={24} />
                            ) : (
                              <SvgEmptyCircle />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
                {formMethods.errors['channels'] && (
                  <Error>This field is required</Error>
                )}
              </Section>

              <Section show={step === 1}>
                <div>
                  <h2 tw="text-2xl font-bold text-gray-800">Ad Text</h2>
                  <Subtitle>
                    Write a short text for your advertising. You want the right
                    candidates to click on your advertising, so be very clear
                    who you're looking for. But mention also what you can offer
                    them.
                  </Subtitle>
                </div>
                <Controller
                  name="copy"
                  defaultValue=""
                  rules={{
                    validate: (value) => !isTextEditorEmpty(value),
                  }}
                  render={({
                    value,
                    onChange,
                  }: {
                    value: string;
                    onChange: (value: string) => void;
                  }) => (
                    <div tw="mt-4 [.editor-class]:max-h-[16rem]">
                      <TextEditor
                        key={isGenerating ? value : undefined} // Re-render TextEditor when generating new copy
                        defaultValue={value}
                        onChange={onChange}
                        disabled={isGenerating}
                        aiEnabled={
                          (!!value && !isGenerating) ||
                          (payload && canUseGenerateAI)
                        }
                        initialPrompt={initialPrompt}
                        identifier="ad_builder"
                        onAIGenFinished={onFinished}
                      />
                    </div>
                  )}
                />
                {formMethods.errors['copy'] && (
                  <Error>This field is required</Error>
                )}
              </Section>

              <Section show={step === 2}>
                <h2 tw="text-2xl font-bold text-gray-800">Logo</h2>
                <Subtitle>
                  Upload or select your company logo. (only .png or .jpg images
                  are allowed)
                </Subtitle>
                <Controller
                  name="logo"
                  defaultValue={
                    activeBrand?.default_logo ? [activeBrand?.default_logo] : []
                  }
                  rules={{
                    validate: (value) => !!value.length,
                  }}
                  render={({
                    value,
                    onChange,
                  }: {
                    value: CustomerResource[];
                    onChange: (newResources: CustomerResource[]) => void;
                  }) => (
                    <div
                      data-testid="variant-generator-logo"
                      tw="mt-4"
                      css={css`
                        > div {
                          min-height: 0;
                          overflow: hidden;
                        }
                        > div > div {
                          max-height: 20rem;
                        }
                      `}
                    >
                      <ResourceUpload
                        fileTypes={[
                          FileTypes.PNG,
                          FileTypes.JPG,
                          FileTypes.JPEG,
                        ]}
                        maxFiles={1}
                        isLogo={true}
                        defaultValue={value}
                        onChange={onChange}
                      />
                    </div>
                  )}
                />
                {formMethods.errors['logo'] && (
                  <Error>This field is required</Error>
                )}
              </Section>

              <Section show={step === 3}>
                <h2 tw="text-2xl font-bold text-gray-800">Creatives</h2>
                <Subtitle>
                  Upload the creatives you want to use for advertising. We
                  recommend <b>minimum 2 pictures</b>. Videos are optional, but
                  can be a great addition to your campaign. (only .png, .jpg and
                  .mp4 files are allowed)
                </Subtitle>
                <Controller
                  name="content"
                  defaultValue={
                    activeBrand?.default_creatives?.slice(0, 3) ?? []
                  }
                  rules={{
                    validate: (value) => !!value.length,
                  }}
                  render={({
                    value,
                    onChange,
                  }: {
                    value: CustomerResource[];
                    onChange: (newResources: CustomerResource[]) => void;
                  }) => (
                    <div
                      data-testid="variant-generator-images"
                      tw="mt-4"
                      css={css`
                        > div {
                          min-height: 0;
                          overflow: hidden;
                        }
                        > div > div {
                          max-height: 20rem;
                        }
                      `}
                    >
                      <ResourceUpload
                        fileTypes={[
                          FileTypes.JPEG,
                          FileTypes.JPG,
                          FileTypes.PNG,
                          FileTypes.MP4,
                        ]}
                        defaultValue={value}
                        onChange={onChange}
                        maxFiles={6}
                      />
                    </div>
                  )}
                />
                {formMethods.errors['content'] && (
                  <Error>This field is required</Error>
                )}
              </Section>
              <div tw="flex justify-start">
                <div tw="flex flex-col gap-2">
                  {step === 3 && (
                    <>
                      {watchContent?.length > 4 && (
                        <Message>
                          <p tw="text-sm text-gray-600 font-medium">
                            ⚠️ We recommend max. 4 visuals.
                          </p>
                        </Message>
                      )}
                      <Message>
                        <p tw="text-sm text-gray-600 font-medium">
                          {watchChannels?.some(
                            (item) => item === AdChannel.TIKTOK
                          ) &&
                            watchContent?.length >= 1 &&
                            !watchContent?.some(
                              ({ type }) => type === 'video'
                            ) &&
                            "⚠️ TikTok requires a video. It won't be included at this moment."}
                        </p>
                      </Message>
                    </>
                  )}
                </div>

                <div tw="flex space-x-1 ml-auto flex-wrap-reverse">
                  {step > 0 && (
                    <Button
                      variant="outline"
                      disabled={isGenerating}
                      onClick={() => setStep((step) => step - 1)}
                    >
                      Back
                    </Button>
                  )}
                  {step >= 0 && step < 3 && (
                    <Button
                      testId="variant-generator-continue"
                      disabled={
                        isGenerating ||
                        (step === 0 && !watchChannels?.length) ||
                        (step === 1 &&
                          (!watchCopy || isTextEditorEmpty(watchCopy))) ||
                        (step === 2 && !watchLogo?.length)
                      }
                      onClick={() => setStep((step) => step + 1)}
                    >
                      Continue
                    </Button>
                  )}
                  {step === 3 && (
                    <Button
                      testId="variant-generator-submit"
                      type="submit"
                      loading={isGenerating}
                      disabled={isGenerating || !watchContent?.length}
                    >
                      Show my ads&nbsp;&rarr;
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </FormProvider>
        </div>,
        document.getElementById('content-wrapper') ?? document.body
      )}
    </>
  );
};

const ChannelButton = styled.button<{
  selected: boolean;
}>`
  ${tw`rounded-md border-2 py-7 text-gray-700 text-5xl flex place-content-center w-full focus:outline-none hover:bg-gray-50`}

  ${({ selected }) => selected && tw`ring ring-green-500 border-transparent`}
`;

const Section = styled.section<{
  show: boolean;
}>`
  ${tw`max-h-[30rem] [:not(#first-section)]:mb-8`}
  ${({ show }) => !show && tw`hidden`}
`;

const Subtitle = styled.p(tw`text-sm text-gray-500`);

const Message = styled.div(() => [tw`flex items-center`]);

export default VariantGenerator;
