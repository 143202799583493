import React from 'react';

interface Notification {
  id: string;
  type: NotificationTypes;
  description?: string;
}

export interface AppNotification extends Notification {
  actionLabel?: string;
  customAction?: () => void;
  customIcon?: React.ReactNode;
  date: Date;
  filter?: NotificationFilterTypes;
  id: string;
  status: NotificationStatus;
  title: string;
  type: NotificationTypes;
  url?: string;
}

export enum NotificationStatus {
  NEW,
  SEEN,
  READ,
}

export enum NotificationTypes {
  INVALID_REQUEST,
  PROCEDURE_OVERVIEW,
  COMMUNICATION_INCOMING,
}

enum NotificationFilterTypes {
  SOCIAL = 'Social',
  APPLICATION = 'Application',
}

export enum ToastTypes {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  ACTION = 'action',
  SUCCESS = 'success',
}
