import 'twin.macro';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { RouteGuards, Routes as RoutePaths } from 'types/routeTypes';

import {
  ConfirmPassword,
  Dashboard,
  ErrorPage,
  ForgotPassword,
  Login,
  SocialMedia,
} from 'views';

import GuardedRoute from './GuardedRoute';
import { SocialMediaProvider } from 'context/SocialMediaContext';
import EditorPage from 'views/vacancy/config/EditorPage';
import { AdEditorProvider } from 'context/AdEditorContext';
import SurveyPage from 'views/vacancy/survey/SurveyPage';
import Register from 'views/register/Register';
import TargetingConfig from 'views/targeting/TargetingConfig';
import PreferencesConfig from 'views/preferences/PreferencesConfig';
import { ConfirmEmail } from 'views/ConfirmEmail';
import GetStarted from 'views/get-started/GetStarted';
import Settings from 'views/settings/Settings';
import Payment from 'views/payment/Payment';
import Subscription from 'views/subscription/Subscription';
import { pageView } from 'utils/eventHelper';
import { getLocalAppSession } from 'utils/storageHelpers';
import ProceduresRouter from 'views/candidates/ProceduresRouter';
import LeadDetailRouter from 'views/leads/LeadDetailRouter';
import useSymplRoute from 'hooks/symplRoute';
import Academy from 'views/academy/Academy';
import { CandidateProvider } from 'context/CandidateContext';
import AdEditor from 'views/ad-builder/AdBuilder';
import Leads from 'views/leads/Leads';
import CampaignPublished from 'views/get-started/CampaignPublished';
import { VacancyProvider } from 'context/VacancyContext';
import useNavigationContext from 'hooks/context/nav-context';

import { SurveyContextProvider } from 'context/SurveyContext';
import CareersRouter from 'views/careers/CareersRouter';
import { JobTypesProvider } from 'context/JobTypesContext';
import SSOCallback from 'views/auth/sso/SSOCallback';
import TwoFactor from 'views/auth/two-factor/TwoFactor';
import Privacy from 'views/other/Privacy';
import Terms from 'views/other/Terms';
import CandidatesOverview from 'views/candidates/CandidatesOverview';
import Reporting from 'views/reporting/Reporting';
import { ReportingProvider } from 'context/ReportingContext';
import { PermissionDenied } from 'views/PermissionDenied';
import { PERMISSIONS } from 'context/PermissionContext';
import PublishSuccess from 'views/published/Published';

interface Page {
  path: RoutePaths | string;
  component: React.ReactNode;
  unauthenticated?: boolean;
  allowedPermissions?: string[];
  requiredPermissionLevel?: 'brand' | 'customer' | 'vacancy';
}

const PAGES: Page[] = [
  {
    path: RoutePaths.ROOT,
    component: (
      <CandidateProvider>
        <Dashboard />
      </CandidateProvider>
    ),
  },
  {
    path: RoutePaths.SOCIAL_MEDIA,
    component: (
      <VacancyProvider>
        <SocialMediaProvider>
          <SocialMedia />
        </SocialMediaProvider>
      </VacancyProvider>
    ),
    allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
  },
  {
    path: RoutePaths.CANDIDATES,
    component: (
      <GuardedRoute guard={RouteGuards.AUTHENTICATED}>
        <CandidatesOverview />
      </GuardedRoute>
    ),
    allowedPermissions: [PERMISSIONS.MANAGE_CANDIDATES],
  },
  {
    path: RoutePaths.LEADS,
    component: <Leads />,
    allowedPermissions: [PERMISSIONS.MANAGE_CANDIDATES],
  },
  {
    path: `${RoutePaths.LEAD_DETAIL}/*`,
    component: <LeadDetailRouter />,
    allowedPermissions: [PERMISSIONS.MANAGE_CANDIDATES],
  },
  {
    path: `${RoutePaths.PROCEDURES}/*`,
    component: <ProceduresRouter />,
    allowedPermissions: [PERMISSIONS.MANAGE_CANDIDATES],
  },
  {
    path: RoutePaths.AD_EDITOR,
    component: (
      <AdEditorProvider>
        <AdEditor />
      </AdEditorProvider>
    ),
    allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
  },
  {
    path: RoutePaths.JOB_POSTING,
    component: (
      <VacancyProvider>
        <EditorPage />
      </VacancyProvider>
    ),
    allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
  },
  {
    path: RoutePaths.INTERVIEW,
    component: (
      <SurveyContextProvider>
        <SurveyPage />
      </SurveyContextProvider>
    ),
    allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
  },
  {
    path: RoutePaths.TARGETING,
    component: (
      <JobTypesProvider>
        <TargetingConfig />
      </JobTypesProvider>
    ),
    allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
  },
  {
    path: RoutePaths.PREFERENCES,
    component: <PreferencesConfig />,
    allowedPermissions: [
      PERMISSIONS.MANAGE_CAMPAIGNS,
      PERMISSIONS.MANAGE_CANDIDATES,
    ],
  },
  {
    path: RoutePaths.GETTING_STARTED,
    component: <GetStarted />,
  },
  {
    path: RoutePaths.CAMPAIGN_PUBLISHED,
    component: <CampaignPublished />,
  },
  {
    path: `${RoutePaths.SETTINGS}/*`,
    component: <Settings />,
  },
  {
    path: RoutePaths.ACADEMY,
    component: <Academy />,
  },
  {
    path: RoutePaths.PAYMENT_SUCCESS,
    component: <Payment variant="success" />,
  },
  {
    path: RoutePaths.PAYMENT_FAILED,
    component: <Payment variant="failed" />,
  },
  {
    path: RoutePaths.SUBSCRIPTION_SUCCESS,
    component: <Subscription variant="success" />,
  },
  {
    path: RoutePaths.SUBSCRIPTION_FAILED,
    component: <Subscription variant="failed" />,
  },
  {
    path: RoutePaths.SUBSCRIPTION_UPDATED,
    component: <Subscription variant="updated" />,
  },
  {
    path: RoutePaths.LOGIN,
    component: <Login />,
    unauthenticated: true,
  },
  {
    path: RoutePaths.CONFIRM_PASSWORD,
    component: <ConfirmPassword />,
    unauthenticated: true,
  },
  {
    path: RoutePaths.FORGOT_PASSWORD,
    component: <ForgotPassword />,
    unauthenticated: true,
  },
  {
    path: RoutePaths.REGISTER,
    component: <Register />,
    unauthenticated: true,
  },
  {
    path: RoutePaths.DEMO,
    component: <Navigate to={RoutePaths.REGISTER} />,
    unauthenticated: true,
  },
  {
    path: RoutePaths.CONFIRM_EMAIL,
    component: <ConfirmEmail />,
    unauthenticated: true,
  },
  {
    path: RoutePaths.SSO_CALLBACK,
    component: <SSOCallback />,
    unauthenticated: true,
  },
  {
    path: RoutePaths.REPUBLISH_SUCCESS,
    component: <PublishSuccess />,
  },
  {
    path: RoutePaths.TWO_FACTOR,
    component: <TwoFactor />,
  },
  {
    path: `${RoutePaths.CAREERS}/*`,
    component: (
      <JobTypesProvider>
        <CareersRouter />
      </JobTypesProvider>
    ),
    allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
    requiredPermissionLevel: 'brand',
  },
  {
    path: `${RoutePaths.REPORTING}/*`,
    component: (
      <ReportingProvider>
        <Reporting />,
      </ReportingProvider>
    ),
    allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
  },
  {
    path: RoutePaths.PRIVACY_POLICY,
    component: <Privacy />,
    unauthenticated: true,
  },
  {
    path: RoutePaths.TERMS_AND_CONDITIONS,
    component: <Terms />,
    unauthenticated: true,
  },
];

const AppRouter = () => {
  const location = useLocation();
  const { prevLocation, setPrevLocation } = useNavigationContext();

  const { vacancyId, setActiveVacancyFromParam } = useSymplRoute();

  useEffect(() => {
    const session = getLocalAppSession();

    if (prevLocation !== location.pathname) {
      pageView(prevLocation, location.pathname, {
        email: session?.email,
        activeVacancy: session?.activeVacancy,
        activeCustomer: session?.activeCustomer,
      });

      setPrevLocation(location.pathname);
    }
  }, [location.pathname, prevLocation, setPrevLocation]);

  useEffect(() => {
    if (vacancyId) setActiveVacancyFromParam();
  }, [setActiveVacancyFromParam, vacancyId]);

  return (
    <Routes>
      {PAGES.map((page) => (
        <Route
          key={page.path}
          path={page.path}
          element={
            <GuardedRoute
              guard={
                page.unauthenticated
                  ? RouteGuards.UNAUTHENTICATED
                  : RouteGuards.AUTHENTICATED
              }
              allowedPermissions={page.allowedPermissions}
              requiredPermissionLevel={page.requiredPermissionLevel}
            >
              {page.component}
            </GuardedRoute>
          }
        />
      ))}
      <Route path={RoutePaths.NOT_ALLOWED} element={<PermissionDenied />} />
      <Route path={RoutePaths.ERROR} element={<ErrorPage />} />
      <Route path="*" element={<Navigate to={RoutePaths.ERROR} />} />
    </Routes>
  );
};

export default AppRouter;
