import tw, { styled } from 'twin.macro';
import AdEditorUploadForm from 'components/ad-builder/UploadForm';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import React, { useState } from 'react';
import { AdEditorSideBarTabs } from './AdBuilder';
import Button from 'components/button/Button';
import AdEditorImageCropper from 'components/ad-builder/ad-editor-cropper/Cropper';
import Dropdown from 'components/dropdown/Dropdown';
import ColorPicker from 'components/form/ColorPicker';
import Title from 'components/title/Title';
import { Subhead } from 'components/typography/Typography';
import fontFamilies from 'data/fonts';
import {
  TextAlignCenter,
  TextAlignLeft,
  TextAlignRight,
  X,
} from '@phosphor-icons/react';
import { AdCreativeType, AdPlacement } from 'types/adTypes';
import Input from 'components/form/input/Input';
import { ResourcesModalProps } from 'components/resources-modal/ResourcesModal';
import { PlacementMenu } from 'components/ad-builder/PlacementMenu';
import { CTALabel, ctaLabels } from 'data/adEditorCTA';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';

const LayerButtons: React.FC = () => {
  const { sendBackwards, bringForward } = useAdEditorContext();

  return (
    <div tw="grid grid-cols-2 gap-4">
      <Button onClick={sendBackwards} stretch variant="outline">
        Send backwards
      </Button>
      <Button onClick={bringForward} stretch variant="outline">
        Bring forward
      </Button>
    </div>
  );
};

const Buttons: React.FC = () => {
  const { removeActiveObject, duplicateActiveObject } = useAdEditorContext();

  return (
    <div tw="flex flex-col gap-2">
      <Button onClick={removeActiveObject} variant="danger" stretch>
        Remove object
      </Button>
      <Button onClick={duplicateActiveObject} variant="inverted" stretch>
        Duplicate object
      </Button>
    </div>
  );
};

const Sidebar = () => {
  const {
    activeTab,
    currentVariant,
    uploadMode: showResourcesModal,
    setUploadMode: setShowResourcesModal,
    updateCurrentVariant,
    updateActiveObject,
    activeObject,
    canvasWidth,
    canvasHeight,
    cropBackground,
  } = useAdEditorContext();
  const { addToast } = useToastNotifications();

  const creativeType = currentVariant?.creative_type;
  const path = currentVariant?.path?.path;
  const logo = currentVariant?.logo?.path;

  const [uploadMode, setUploadMode] = useState<ResourcesModalProps['mode']>();

  return !!activeTab ? (
    <>
      <AdEditorUploadForm
        show={showResourcesModal}
        mode={uploadMode}
        onClose={() => {
          setShowResourcesModal(false);
          setUploadMode(undefined);
        }}
        onSubmit={() => {}}
      />

      <div tw="w-full p-5 xl:w-[350px] h-full max-h-[85vh] overflow-y-auto no-scrollbar">
        {activeTab === AdEditorSideBarTabs.VISUAL_OPTIONS && (
          <div tw="mx-auto">
            <div tw="mb-8 flex flex-col overflow-x-hidden">
              <div tw="flex flex-col justify-center items-center w-full gap-2">
                {creativeType === AdCreativeType.IMAGE && (
                  <div tw="flex w-full flex-wrap gap-1 p-2">
                    <div tw="flex-1">
                      <Button
                        variant={logo ? 'success' : 'primary'}
                        onClick={() => {
                          setUploadMode('logo');
                          setShowResourcesModal(true);
                        }}
                        disabled={
                          currentVariant?.creative_type === 'video' ||
                          !currentVariant?.path
                        }
                        testId="ad-builder-sidebar-change-logo"
                        stretch
                      >
                        {logo ? 'Change logo' : 'Choose a logo'}
                      </Button>
                    </div>
                    {logo && (
                      <Button
                        variant="inverted"
                        onClick={() => updateCurrentVariant('logo', null)}
                        disabled={currentVariant?.creative_type === 'video'}
                        testId="ad-builder-sidebar-close"
                      >
                        <X weight="bold" tw="text-gray-600" />
                      </Button>
                    )}
                  </div>
                )}
                <div tw="flex w-full flex-wrap gap-1 p-2">
                  <div tw="flex-1">
                    <Button
                      variant={path ? 'success' : 'primary'}
                      onClick={() => {
                        setUploadMode(
                          currentVariant?.placement === AdPlacement.REELS
                            ? 'video'
                            : 'visual'
                        );
                        setShowResourcesModal(true);
                      }}
                      testId="ad-builder-sidebar-change-creative"
                      stretch
                    >
                      {currentVariant?.placement === AdPlacement.REELS
                        ? 'Change video'
                        : 'Change image/video'}
                    </Button>
                  </div>
                  {path && (
                    <Button
                      variant="inverted"
                      onClick={() => updateCurrentVariant('path', null)}
                    >
                      <X weight="bold" tw="text-gray-600" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {path && creativeType === AdCreativeType.IMAGE && (
              <>
                <div tw="mb-3 border-b-2 border-gray-100" id="ad-image-resize">
                  <Title>Resize</Title>
                </div>
                <div tw="mt-3">
                  <AdEditorImageCropper
                    uuid={currentVariant.uuid}
                    image={path}
                    crop={null}
                    onCrop={cropBackground}
                    aspect={canvasWidth / canvasHeight}
                  />
                </div>
              </>
            )}
          </div>
        )}
        {activeTab === AdEditorSideBarTabs.OVERLAY_OPTIONS && (
          <PlacementMenu key={currentVariant?.placement} />
        )}
        {activeTab === AdEditorSideBarTabs.TEXT_OPTIONS && (
          <div tw="flex flex-col gap-4">
            <Title>Text</Title>

            <div tw="flex flex-col items-center gap-2">
              <div tw="w-full flex-1">
                <Input
                  textarea
                  enableEmoji={false}
                  autoFocus
                  inputStyles={tw`w-full !overflow-y-auto`}
                  value={(activeObject as fabric.Text).text}
                  onChange={({ target: { value } }) => {
                    const result = value.match(
                      /([\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu
                    );

                    if (result)
                      addToast({
                        type: ToastTypes.ERROR,
                        description: 'Emojis are not supported in this text',
                      });

                    const filteredValue = value.replaceAll(
                      /([\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu,
                      ''
                    );

                    updateActiveObject('text', filteredValue);
                  }}
                />
              </div>
              <ColorPicker
                customStyles={tw`w-full`}
                value={(activeObject?.fill as string) ?? '#000'}
                ariaInvalid={false}
                format="rgb"
                onChange={(color: string) => updateActiveObject('fill', color)}
              />
            </div>

            <div tw="flex items-center gap-4">
              <div tw="flex flex-col space-y-2 w-1/2">
                <Subhead>Font family</Subhead>
                <Dropdown
                  label={(activeObject as fabric.Text).fontFamily || 'Arial'}
                  value={(activeObject as fabric.Text).fontFamily || 'Arial'}
                  items={fontFamilies.map((family) => {
                    return {
                      ...family,
                      customStyle: { fontFamily: family.key },
                    };
                  })}
                  onChange={(event) => {
                    const fontFamily = event as string;
                    const weight = fontFamily === 'Roboto' ? 500 : 700;

                    updateActiveObject('fontFamily', fontFamily);
                    updateActiveObject('fontWeight', weight);
                  }}
                />
              </div>
              <div tw="flex flex-col space-y-2 w-1/2">
                <Subhead>Font size</Subhead>
                <Input
                  type="number"
                  value={(activeObject as fabric.Text)?.fontSize ?? ''}
                  onChange={({ target: { value } }) => {
                    updateActiveObject(
                      'fontSize',
                      isNaN(parseInt(value)) ? undefined : parseInt(value)
                    );
                  }}
                />
              </div>
            </div>
            {false && (
              <div>
                <Subhead>Text alignment</Subhead>
                <div tw="flex flex-row gap-1 mt-2">
                  {['left', 'center', 'right'].map((alignment) => (
                    <TextAlignButton
                      key={alignment}
                      active={
                        (activeObject as fabric.Text).textAlign === alignment
                      }
                      onClick={() => updateActiveObject('textAlign', alignment)}
                    >
                      {alignment === 'left' && <TextAlignLeft size={20} />}
                      {alignment === 'center' && <TextAlignCenter size={20} />}
                      {alignment === 'right' && <TextAlignRight size={20} />}
                    </TextAlignButton>
                  ))}
                </div>
              </div>
            )}
            <div>
              <Subhead>Layer</Subhead>
              <div tw="mt-2">
                <LayerButtons />
              </div>
            </div>

            <div tw="mt-4">
              <Buttons />
            </div>
          </div>
        )}
        {activeTab === AdEditorSideBarTabs.SHAPE_OPTIONS && (
          <div tw="flex h-full flex-col gap-4">
            <Title>Background color</Title>
            <div tw="flex w-full flex-row gap-2">
              <ColorPicker
                align="left"
                format="rgb"
                value={(activeObject?.fill as string) ?? '#ffffff'}
                variant="button"
                ariaInvalid={false}
                onChange={(color: string) => updateActiveObject('fill', color)}
                tw="flex-1"
              />
              {![0, 'NaN'].includes(
                parseFloat((activeObject?.fill as string).split(',')[3] ?? '')
              ) && (
                <Button
                  onClick={() => updateActiveObject('fill', 'rgb(0,0,0,0)')}
                  variant="inverted"
                  stretch
                >
                  Remove background
                </Button>
              )}
            </div>

            <Title>Layers</Title>
            <div tw="w-full">
              <LayerButtons />
            </div>

            <Buttons />
          </div>
        )}
        {activeTab === AdEditorSideBarTabs.BANNER_OPTIONS && (
          <div tw="flex h-full flex-col gap-4">
            <Title>CTA (call to action)</Title>
            <div tw="flex items-center gap-4">
              <div tw="flex flex-col space-y-2 w-1/2">
                <Dropdown
                  testId="sidebar-sign-up-dropdown"
                  label={currentVariant?.cta || CTALabel.SIGN_UP}
                  value={currentVariant?.cta || CTALabel.SIGN_UP}
                  items={ctaLabels}
                  onChange={(value) =>
                    updateCurrentVariant(
                      'cta',
                      CTALabel[value as keyof typeof CTALabel]
                    )
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

const TextAlignButton = styled.button<{ active: boolean }>`
  ${tw`p-2 rounded-md bg-gray-100`}

  ${({ active }) => active && tw`bg-gray-600 text-white`}
`;

export default Sidebar;
