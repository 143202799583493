import 'twin.macro';

import Input from 'components/form/input/Input';
import Label from 'components/form/Label';
import AuthHeader from 'components/other/AuthHeader';
import { Error, Title2 } from 'components/typography/Typography';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Routes } from 'types/routeTypes';
import { ConfirmPasswordData } from 'types/userTypes';
import { showIntercom } from 'utils/intercomHelper';
import Button from 'components/button/Button';
import useUserContext from 'hooks/context/user-context';
import { useNavigate, useLocation, Link } from 'react-router-dom';

type ConfirmPasswordId = 'email' | 'password' | 'password_confirmation';

interface ConfirmPasswordFormError {
  passwordsDontMatch?: boolean;
  passwordFieldsCantBeEmpty?: boolean;
}

export const ConfirmPassword: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { handleSubmit, errors, control, watch } = useForm();

  const [urlIsExpired, setUrlIsExpired] = useState(false);
  const [formErrors, setFormErrors] = useState<ConfirmPasswordFormError>();

  const { resetPassword, error, loading } = useUserContext();

  const params = new URLSearchParams(window.location.search);

  const token = params.get('token');
  const email = params.get('email');

  const pwd = watch('password');

  const resetUrlIsValid = () => {
    setUrlIsExpired(false);
    return token && email;
  };

  const onSubmit = (data: ConfirmPasswordData) => {
    if (!resetUrlIsValid() || !validate(data)) return;

    resetPassword({
      email: email ?? '',
      token: token ?? '',
      password: data.password_confirmation,
      password_confirmation: data.password_confirmation,
      login: true,
    })
      .then((authenticated?: boolean) => {
        if (authenticated) {
          // @ts-ignore
          const referrer = location?.state?.referrer;
          if (referrer) navigate(referrer);
          else navigate(Routes.ROOT);
        }
      })
      .catch(() => {
        setUrlIsExpired(true);
      });
  };

  const validate = (data: {
    [K in ConfirmPasswordId]?: string;
  }): boolean => {
    setFormErrors({});

    if (
      !data['password']?.toString().length ||
      !data['password_confirmation']?.toString().length
    ) {
      setFormErrors({ ...formErrors, passwordFieldsCantBeEmpty: true });
      return false;
    } else if (
      data['password']?.toString() !== data['password_confirmation']?.toString()
    ) {
      setFormErrors({ ...formErrors, passwordsDontMatch: true });
      return false;
    }
    return true;
  };

  return (
    <div tw="bg-gray-50 flex flex-col justify-center h-screen">
      <AuthHeader />
      <div tw="mt-8 mx-auto w-full max-w-md">
        <div tw="bg-white py-8 shadow rounded-lg px-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Title2 isBold={false}>{'Confirm your password'}</Title2>
            <div tw="mt-6">
              <Label htmlFor="email">{'Email'}</Label>
              <div tw="mt-1 rounded-md shadow-sm">
                <Controller
                  id="email"
                  name="email"
                  control={control}
                  defaultValue={email}
                  rules={{
                    required: true,
                  }}
                  render={({ onChange, value }) => (
                    <Input
                      disabled
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="email"
                      value={value ?? ''}
                      ariaInvalid={errors['email'] !== undefined}
                      onChange={(e) => onChange(e)}
                      onBlur={(e) => onChange(e)}
                    />
                  )}
                />
              </div>
              {errors['email'] && errors['email'].type === 'required' && (
                <Error>{'This field is required'}</Error>
              )}
            </div>

            <div tw="mt-6">
              <Label htmlFor="password">{'Password'}</Label>
              <div tw="mt-1 rounded-md shadow-sm">
                <Controller
                  id="password"
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern:
                      /^(?=.*[0-9])(?=.*[\W])(?=.*[A-Z])[a-zA-Z0-9\W]{8,}$/,
                  }}
                  render={({ onChange, value }) => (
                    <Input
                      type="password"
                      id="password"
                      name="password"
                      autoComplete="password"
                      value={value}
                      ariaInvalid={errors['password'] !== undefined}
                      onChange={(e) => onChange(e)}
                      onBlur={(e) => onChange(e)}
                    />
                  )}
                />
              </div>
              {formErrors?.passwordsDontMatch && (
                <Error>Passwords don't match</Error>
              )}
              {errors['password'] && errors['password'].type === 'required' && (
                <Error>{'This field is required'}</Error>
              )}

              {errors['password'] && errors['password'].type === 'pattern' && (
                <Error>
                  Password must contain at least 8 characters, 1 uppercase
                  letter, 1 number and 1 special character
                </Error>
              )}
            </div>

            <div tw="mt-6">
              <Label htmlFor="password_confirmation">
                {'Confirm password'}
              </Label>
              <div tw="mt-1 rounded-md shadow-sm">
                <Controller
                  id="password_confirmation"
                  name="password_confirmation"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: (value) =>
                      value === pwd || 'The passwords do not match',
                  }}
                  render={({ onChange, value }) => (
                    <Input
                      type="password"
                      id="password_confirmation"
                      name="password_confirmation"
                      autoComplete="password_confirmation"
                      value={value}
                      ariaInvalid={
                        errors['password_confirmation'] !== undefined
                      }
                      onChange={(e) => onChange(e)}
                      onBlur={(e) => onChange(e)}
                    />
                  )}
                />
              </div>
              {errors['password_confirmation'] &&
                errors['password_confirmation'].type === 'required' && (
                  <Error>{'This field is required'}</Error>
                )}
              {errors['password_confirmation'] &&
                errors['password_confirmation'].type === 'validate' && (
                  <Error>{errors['password_confirmation'].message}</Error>
                )}
            </div>

            <div tw="mt-6">
              <Button type="submit" variant="indigo" stretch>
                Submit
              </Button>
            </div>

            <div tw="w-full flex justify-center mt-4 text-sm text-gray-700 tracking-tight">
              <Link to={Routes.LOGIN}>Sign in</Link>
              <span tw="ml-2 mr-2 font-medium">or</span>
              <span tw="cursor-pointer" onClick={() => showIntercom()}>
                Contact us
              </span>
            </div>
          </form>
        </div>

        <p tw="w-full text-center mt-3 text-gray-600 h-4">
          {loading && !error && 'Processing password'}
          {urlIsExpired && 'The password reset link seems to be expired'}
        </p>

        <p tw="w-full text-center mt-3 text-gray-600 h-4">
          {!urlIsExpired && error && error.message}
        </p>
      </div>
    </div>
  );
};
