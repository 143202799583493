import { GET_JOBTYPES_SUBJOBTYPES } from 'graphql/jobtypes/queries';
import { useQuery } from 'hooks/sympl-query';
import React, { FC, ReactNode, createContext } from 'react';
import { JobType } from 'types/targetingTypes';

export type TJobSubJobResponse = { jobTypes: JobType[] };

type JobTypesContextType = {
  jobsAndSubjobTypes?: TJobSubJobResponse;
};

export const JobTypesContext = createContext<JobTypesContextType>(
  {} as JobTypesContextType
);

export const JobTypesProvider: FC<ReactNode> = ({ children }) => {
  const { data: jobsAndSubjobTypes } = useQuery<{ jobTypes: JobType[] }, {}>(
    GET_JOBTYPES_SUBJOBTYPES
  );

  return (
    <JobTypesContext.Provider
      value={{
        jobsAndSubjobTypes,
      }}
    >
      {children}
    </JobTypesContext.Provider>
  );
};
