import React, { useCallback, useRef, useState } from 'react';
import { Plus } from '@phosphor-icons/react';
import tw, { styled } from 'twin.macro';

import Pill from '../Pill';
import Button from 'components/button/Button';
import { capitalize } from 'utils/baseHelpers';

interface PillGroupProps {
  id?: string;
  /** All the options */
  options?: string[];
  /** The selected options */
  selected?: string[];
  maxSelected?: number;
  enableNewOptions?: boolean;
  onChange?: (
    options: {
      key: string;
      selected: boolean;
    }[]
  ) => void;
  testId?: string;
}

const PillGroup: React.FC<PillGroupProps> = ({
  id,
  selected,
  options,
  maxSelected,
  enableNewOptions = false,
  onChange,
  testId,
}) => {
  const createPillRef = useRef<HTMLInputElement>(null);

  const [addOptionIsShown, setAddOptionIsShown] = useState(false);

  const pillClickHandler = (config: {
    option?: string | null;
    mode: 'add' | 'toggle';
  }) => {
    const { option, mode } = config;

    // Get the selected options with their respective keys
    const updatedOptions = (options ?? [])?.map((opt) => ({
      key: opt,
      selected: maxSelected !== 1 ? isOptionSelected(opt) : false,
    }));

    if (mode === 'add') {
      const newOption = createPillRef.current?.value;
      // Skip invalid options
      if (newOption?.constructor !== String) return;
      // Skip duplicate options
      if (updatedOptions.find((o) => o.key === newOption) !== undefined) return;
      // Add the newly created option
      updatedOptions.push({
        key: capitalize(newOption),
        selected: true,
      });
    } else if (mode === 'toggle') {
      // Toggle the existing option
      updatedOptions.forEach((opt) => {
        if (opt.key === option) opt.selected = !opt.selected;
      });
    }

    // Validate max selected items
    if (
      maxSelected?.constructor === Number &&
      updatedOptions.filter((o) => o.selected).length > maxSelected
    )
      return;

    if (mode === 'add') {
      // Hides & clears the add form
      setAddOptionIsShown(false);
      createPillRef!.current!.value = '';
    }

    onChange?.(updatedOptions);
  };

  const isOptionSelected = useCallback(
    (option: string) => selected?.includes(option) ?? false,
    [selected]
  );

  return (
    <>
      <select hidden value={selected} multiple onChange={() => {}}>
        {options?.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>

      <ul tw="flex flex-wrap" data-testid={testId}>
        {options?.map((option) => (
          <li tw="mr-1 mb-1 mt-1" key={option}>
            <Pill
              onClick={() => pillClickHandler({ option, mode: 'toggle' })}
              selected={isOptionSelected(option)}
            >
              {option}
            </Pill>
          </li>
        ))}
        {enableNewOptions && (
          <li tw="mr-1 mb-1 mt-1" key="add">
            <Pill onClick={() => setAddOptionIsShown(true)}>
              <Plus weight="bold" tw="my-1" />
            </Pill>
          </li>
        )}
      </ul>

      <AddWrapper isShown={addOptionIsShown}>
        <input
          tw="block w-full mb-2 ring-1 ring-black/5"
          id={`pillgroup-${id}-add-option`}
          type="input"
          ref={createPillRef}
          style={{ minHeight: 50 }}
        />
        <div tw="flex ml-auto">
          <div tw="mr-1">
            <Button onClick={() => setAddOptionIsShown(false)}>
              <p tw="flex items-center px-3">Cancel</p>
            </Button>
          </div>
          <Button onClick={() => pillClickHandler({ mode: 'add' })}>
            <p tw="flex items-center px-3">Add</p>
          </Button>
        </div>
      </AddWrapper>
    </>
  );
};

const AddWrapper = styled.div<{ isShown: boolean }>`
  ${tw`flex flex-col mt-3`}
  ${({ isShown }) => !isShown && tw`hidden invisible`}
`;

export default PillGroup;
