import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import 'twin.macro';

import VacancyContentInput from './VacancyContentInput';
import VacancyEditorColor, { FloatingButton } from './VacancyEditorColor';
import useDefocusHandler from 'hooks/defocus';
import useVacancyContext from 'hooks/context/vacancy-context';
import { wait, removeStyling, isTextEditorEmpty } from 'utils/baseHelpers';
import ImagePlaceholder from 'components/vacancy/ImagePlaceholder';
import ResourcesModal from 'components/resources-modal/ResourcesModal';
import { MagicWand, Link } from '@phosphor-icons/react';
import * as VacancyPreviewerStyles from './VacancyPreviewerStyles';
import VacancyHeader from './VacancyHeader';
import { TEditorModalTypes } from 'views/vacancy/config/EditorPage';
import useNavigationContext from 'hooks/context/nav-context';
import { useLazyQuery } from 'hooks/sympl-query';
import { EXPERIENCE, JobType } from 'types/targetingTypes';
import { GET_JOBTYPES_SUBJOBTYPES } from 'graphql/jobtypes/queries';
import { TargetingConfiguration } from 'views/targeting/TargetingConfig';
import { GET_TARGETING_CONFIG } from 'graphql/vacancies/queries';
import { VacancyAIPromptPayload } from 'utils/vacancy-generator/prompts';
import useApiList from 'hooks/useApiList';
import { PostHogFeature } from 'posthog-js/react';

export const MAX_INPUT_LENGTH = 191; // Max amount of characters allowed in input

interface VacancyPreviewerProps {
  validTargetingData: boolean;
  setShowModal: (show: TEditorModalTypes) => void;
}

const VacancyPreviewer: FC<VacancyPreviewerProps> = ({
  validTargetingData,
  setShowModal,
}) => {
  const { copyData, setCopyData, dummyTxt, generatingFields } =
    useVacancyContext();

  const { vacIsPublished, activeVacancy, brands, currentVacancy } =
    useNavigationContext();

  const [isEditing, setIsEditing] = useState(false);
  const [openSymbolPickerIndex, setOpenSymbolPickerIndex] = useState<number>();
  const [uploadMode, setUploadMode] = useState<'logo' | 'cb_1' | 'cb_3'>();

  const TextEditorRef = useRef<HTMLDivElement>(null);

  useDefocusHandler(
    TextEditorRef,
    () => wait(0.05).then(() => setIsEditing(false)) // To prevent the onChange event of the TextEditor to not be triggered
  );

  const isTextEssenceEmpty = useMemo(
    () =>
      !copyData ||
      !copyData.vac_text_essence?.trim() ||
      isTextEditorEmpty(removeStyling(copyData.vac_text_essence)),
    [copyData]
  );

  // Set available css variables that can be used in the templates
  const cssVariables = `
    --primary-color: ${copyData?.primary_color};
    --secondary-color: ${copyData?.secondary_color};
    --tertiary-color: ${copyData?.tertiary_color};
  `;

  const handleCtaChange = (value: string) => {
    setCopyData((prev) => ({
      ...prev!,
      page_cta: value,
    }));
  };

  // === VACANCY AI PROMPT PAYLOAD ===
  const [fetchJobTypes] = useLazyQuery<{ jobTypes: JobType[] }, {}>(
    GET_JOBTYPES_SUBJOBTYPES
  );
  const [fetchTargetingData] = useLazyQuery<
    TargetingConfiguration,
    { vacancyId: number }
  >(GET_TARGETING_CONFIG, {
    skip: !activeVacancy,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { vacancyId: activeVacancy ?? 0 },
  });
  const { data: languages } = useApiList('languages');

  // Construct payload
  const [vacancyAIPayload, setVacancyAIPayload] =
    useState<VacancyAIPromptPayload>();

  useEffect(() => {
    Promise.all([fetchTargetingData(), fetchJobTypes()]).then(
      ([{ data: targetingData }, { data: jobTypesData }]) => {
        const targeting = targetingData?.targetingConfig.targeting;
        const jobTypes = jobTypesData?.jobTypes ?? [];
        const language =
          languages.find(({ key }) => key === targeting?.language_id)?.label ??
          'English';
        const jobFunction = targeting?.vac_name!;
        const jobType = jobTypes?.find(({ id }) => id === targeting?.job_type);
        const jobCategory =
          (targeting?.sub_job_type
            ? jobType?.sub_job_types?.find(
                ({ id }) => id === targeting?.sub_job_type
              )?.name
            : jobType?.name) ?? '';
        const companyName = brands.find(({ id }) => id === targeting?.brand_id)
          ?.name!;
        const location = targeting?.locations?.[0]?.name ?? '';
        const experienceLevel =
          targeting?.experience
            ?.map((code) => {
              return EXPERIENCE.find(({ code: c }) => c === code)?.name;
            })
            .join(', ') ?? 'None';
        setVacancyAIPayload({
          language,
          jobFunction,
          jobCategory,
          companyName,
          location,
          companyDescription: '/',
          experienceLevel,
          toneOfVoice: 'fun, informal, enthousiastic',
          offers: [],
        });
      }
    );
  }, []);
  // === VACANCY AI PROMPT PAYLOAD END ===

  if (!copyData) {
    // maybe create a skeleton loader here
    return <></>;
  }

  return (
    <div tw="max-h-full h-full overflow-y-auto no-scrollbar relative">
      <VacancyPreviewerStyles.Section cssVariables={cssVariables}>
        <div tw="absolute right-10 top-5">
          <FloatingButton
            loading={!!generatingFields.length}
            disabled={!validTargetingData || !!generatingFields.length}
            onClick={() => setShowModal('ai')}
          >
            <span tw="text-xl leading-6">
              <MagicWand weight="bold" />
            </span>
          </FloatingButton>
          {!vacIsPublished && (
            <PostHogFeature flag="byov" match={true}>
              <FloatingButton
                loading={!!generatingFields.length}
                onClick={() => setShowModal('custom-vacancy-url-no-back')}
              >
                <span tw="text-xl leading-6">
                  <Link weight="bold" />
                </span>
              </FloatingButton>
            </PostHogFeature>
          )}
          <VacancyEditorColor />
        </div>
        <ResourcesModal
          show={uploadMode === 'logo'}
          mode="logo"
          defaultValue={
            copyData.pictures?.logo ? [copyData.pictures?.logo] : []
          }
          maxFiles={1}
          onSubmit={(urls) => {
            setCopyData(
              (prev) =>
                ({
                  ...prev,
                  pictures: {
                    ...prev?.pictures,
                    logo: urls[0],
                  },
                } as any)
            );
          }}
          onClose={() => setUploadMode(undefined)}
          brandId={currentVacancy?.brand_id}
        />
        <VacancyHeader
          ref={TextEditorRef}
          isTextEssenceEmpty={isTextEssenceEmpty}
          dummyTxt={dummyTxt}
          openSymbolPickerIndex={openSymbolPickerIndex}
          copyData={copyData}
          vacancyAIPayload={vacancyAIPayload}
          isEditing={isEditing}
          generatingFields={generatingFields}
          onSetUploadMode={setUploadMode}
          onSetCopyData={setCopyData}
          onSetIsEditing={setIsEditing}
          onSetOpenSymbolPickerIndex={setOpenSymbolPickerIndex}
        />

        <main id="main" className="wrapper">
          <div>
            <VacancyContentInput
              keyName="vac_text_todo"
              title={copyData.todo_title}
              text={copyData.vac_text_todo}
              cta={copyData.page_cta}
              vacancyAIPayload={vacancyAIPayload}
              onTitleChange={(value) =>
                setCopyData((prev) => ({
                  ...prev!,
                  todo_title:
                    prev && value && value.length > MAX_INPUT_LENGTH
                      ? prev.todo_title
                      : value ?? '',
                }))
              }
              onTextChange={(value) =>
                setCopyData((prev) => ({
                  ...prev!,
                  vac_text_todo: value,
                }))
              }
              onCtaChange={handleCtaChange}
            />
            <>
              <ResourcesModal
                show={uploadMode === 'cb_1'}
                mode="visual"
                defaultValue={
                  copyData.pictures?.image_cb1
                    ? [copyData.pictures?.image_cb1]
                    : []
                }
                maxFiles={1}
                onSubmit={(urls) => {
                  setCopyData(
                    (prev) =>
                      ({
                        ...prev,
                        pictures: {
                          ...prev?.pictures,
                          image_cb1: urls[0],
                        },
                      } as any)
                  );
                }}
                onClose={() => setUploadMode(undefined)}
                brandId={currentVacancy?.brand_id}
              />
              <div className="img-container">
                {copyData.pictures?.image_cb1 ? (
                  copyData.pictures?.image_cb1.type === 'video' ? (
                    <video
                      className="picture highlight-white"
                      src={copyData.pictures?.image_cb1?.path}
                      autoPlay
                      muted
                      controls
                      onClick={() => setUploadMode('cb_1')}
                    />
                  ) : (
                    <img
                      className="picture highlight-white"
                      src={copyData.pictures?.image_cb1?.path}
                      alt="Content block 1"
                      loading="lazy"
                      onClick={() => setUploadMode('cb_1')}
                    />
                  )
                ) : (
                  <ImagePlaceholder onClick={() => setUploadMode('cb_1')} />
                )}
              </div>
            </>
            <VacancyContentInput
              keyName="vac_text_expectations"
              title={copyData.expectations_title}
              text={copyData.vac_text_expectations}
              cta={copyData.page_cta}
              vacancyAIPayload={vacancyAIPayload}
              onTitleChange={(value) =>
                setCopyData((prev) => ({
                  ...prev!,
                  expectations_title:
                    prev && value && value.length > MAX_INPUT_LENGTH
                      ? prev.expectations_title
                      : value ?? '',
                }))
              }
              onTextChange={(value) =>
                setCopyData((prev) => ({
                  ...prev!,
                  vac_text_expectations: value,
                }))
              }
              onCtaChange={handleCtaChange}
            />
            <VacancyContentInput
              keyName="vac_text_offer"
              title={copyData.offer_title}
              text={copyData.vac_text_offer}
              cta={copyData.page_cta}
              vacancyAIPayload={vacancyAIPayload}
              onTitleChange={(value) =>
                setCopyData((prev) => ({
                  ...prev!,
                  offer_title:
                    prev && value && value.length > MAX_INPUT_LENGTH
                      ? prev.expectations_title
                      : value ?? '',
                }))
              }
              onTextChange={(value) =>
                setCopyData((prev) => ({
                  ...prev!,
                  vac_text_offer: value,
                }))
              }
              onCtaChange={handleCtaChange}
            />
            <>
              <ResourcesModal
                show={uploadMode === 'cb_3'}
                mode="visual"
                defaultValue={
                  copyData.pictures?.image_cb3
                    ? [copyData.pictures?.image_cb3]
                    : []
                }
                maxFiles={1}
                onSubmit={(urls) => {
                  setCopyData(
                    (prev) =>
                      ({
                        ...prev,
                        pictures: {
                          ...prev?.pictures,
                          image_cb3: urls[0],
                        },
                      } as any)
                  );
                }}
                onClose={() => setUploadMode(undefined)}
                brandId={currentVacancy?.brand_id}
              />
              <div className="img-container">
                {copyData.pictures?.image_cb3 ? (
                  copyData.pictures?.image_cb3.type === 'video' ? (
                    <video
                      className="picture highlight-white"
                      src={copyData.pictures?.image_cb3?.path}
                      autoPlay
                      muted
                      controls
                      onClick={() => setUploadMode('cb_3')}
                    />
                  ) : (
                    <img
                      className="picture highlight-white"
                      src={copyData.pictures?.image_cb3?.path}
                      alt="Content block 3"
                      loading="lazy"
                      onClick={() => setUploadMode('cb_3')}
                    />
                  )
                ) : (
                  <ImagePlaceholder onClick={() => setUploadMode('cb_3')} />
                )}
              </div>
            </>
            <VacancyContentInput
              id="about"
              keyName="vac_text_company"
              title={copyData.company_title}
              text={copyData.vac_text_company}
              cta={copyData.page_cta}
              vacancyAIPayload={vacancyAIPayload}
              onTitleChange={(value) =>
                setCopyData((prev) => ({
                  ...prev!,
                  company_title:
                    prev && value && value.length > MAX_INPUT_LENGTH
                      ? prev.expectations_title
                      : value ?? '',
                }))
              }
              onTextChange={(value) =>
                setCopyData((prev) => ({
                  ...prev!,
                  vac_text_company: value,
                }))
              }
              onCtaChange={handleCtaChange}
            />
          </div>
          <div id="side-article">
            <VacancyContentInput
              title={copyData.procedure_title}
              text={copyData.procedure_text}
              vacancyAIPayload={vacancyAIPayload}
              onTitleChange={(value) =>
                setCopyData(
                  (prev) =>
                    ({
                      ...prev!,
                      procedure_title: value,
                    } as any)
                )
              }
              onTextChange={(value) =>
                setCopyData((prev) => ({
                  ...prev!,
                  procedure_text: value,
                }))
              }
            />
          </div>
        </main>
      </VacancyPreviewerStyles.Section>
    </div>
  );
};

export default VacancyPreviewer;
