import 'twin.macro';
import React, { forwardRef, useState } from 'react';

interface ImgProps {
  /** Custom error message */
  errorMessage?: string;
  /** Custom error component, overwrites the error message */
  errorComponent?: React.ReactNode;
}

const Img = forwardRef<
  HTMLImageElement,
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > &
    ImgProps
>(({ errorMessage, errorComponent, ...props }, ref) => {
  const [isError, setIsError] = useState(false);

  return (
    <>
      {!isError ? (
        <img
          {...props}
          ref={ref}
          loading="lazy"
          onError={(e) => {
            setIsError(true);
            props.onError?.(e);
          }}
        >
          {props.children}
        </img>
      ) : (
        <>
          {errorComponent ?? (
            <div tw="w-full h-full flex justify-center items-center bg-white p-2 text-gray-800">
              {errorMessage ?? 'Unable to load image'}
            </div>
          )}
        </>
      )}
    </>
  );
});

export default Img;
