import React from 'react';
import tw, { styled, TwStyle } from 'twin.macro';

interface AutoTableProps {
  columns?:
    | (string | JSX.Element | (string | JSX.Element)[])[]
    | (string | JSX.Element)[];
  rows?: Array<Array<JSX.Element>>;
  summationRow?: Array<JSX.Element>;
  headerStyling?: TwStyle;
  firstElementStyling?: TwStyle;
  elementStyling?: TwStyle;
  onRowClicked?: (rowElement: EventTarget, rowIndex: number) => void;
  scrollable?: boolean;
  alternate?: boolean;
  emptyLabel?: string;
  emptyLabelStyling?: TwStyle;
}

const AutoTable: React.FC<AutoTableProps> = ({
  columns = [],
  rows = [],
  summationRow,
  headerStyling,
  firstElementStyling,
  elementStyling,
  onRowClicked,
  scrollable = false,
  alternate = false,
  emptyLabel = 'No results found.',
  emptyLabelStyling,
}) => {
  return (
    <div tw="flex flex-col overflow-x-auto shadow sm:rounded-lg">
      <table tw="min-w-full overflow-x-auto divide-y divide-gray-200">
        <thead tw="bg-gray-50">
          <tr
            tw="h-full"
            style={
              columns && columns.some((col) => Array.isArray(col))
                ? { display: 'flex' }
                : {}
            }
          >
            {columns?.map((element, index) => {
              if (Array.isArray(element)) {
                return (
                  <>
                    {element.map((el, ind) => (
                      <TableHeading
                        scope="col"
                        key={ind}
                        customStyle={
                          index === 0 && ind === 0
                            ? firstElementStyling ?? tw``
                            : headerStyling ?? tw``
                        }
                      >
                        {el}
                      </TableHeading>
                    ))}
                    {/* Vertical divider - not for the last column group */}
                    {index !== columns.length - 1 ? (
                      <TableHeading
                        key={`divider-${index}`}
                        scope="col"
                        customStyle={tw`w-0.5 bg-gray-300 mx-8 px-0 h-[24px] mt-2`}
                      />
                    ) : null}
                  </>
                );
              } else {
                return (
                  <TableHeading
                    scope="col"
                    key={index}
                    customStyle={headerStyling ?? tw``}
                  >
                    {element}
                  </TableHeading>
                );
              }
            })}
          </tr>
        </thead>
        <TableBody scrollable={scrollable}>
          {rows.map((rowElements, rowIndex) => (
            <TableRow
              key={rowIndex}
              onClick={(event) =>
                onRowClicked ? onRowClicked?.(event.target, rowIndex) : null
              }
              customStyle={
                columns && columns.some((col) => Array.isArray(col))
                  ? tw`flex`
                  : tw``
              }
              index={rowIndex}
              alternate={alternate}
            >
              {rowElements.map((element, elementIndex) => {
                const isDivider = element.props?.['data-divider'];
                return (
                  <RowElement
                    customStyle={
                      elementIndex == 0
                        ? firstElementStyling ?? tw``
                        : isDivider
                        ? tw`w-0.5 ml-8 mr-6 px-0`
                        : elementStyling ?? tw``
                    }
                    key={elementIndex}
                  >
                    {element}
                  </RowElement>
                );
              })}
            </TableRow>
          ))}
          {summationRow && (
            <>
              {/* This is an extra row to make a separation */}
              <TableRow customStyle={tw`h-16 bg-gray-400`} />
              <TableRow customStyle={Array.isArray(columns) ? tw`flex` : tw``}>
                {summationRow.map((element, eindex) => {
                  const isDivider = element.props?.['data-divider'];
                  return (
                    <RowElement
                      customStyle={
                        eindex === 0
                          ? firstElementStyling ?? tw``
                          : isDivider
                          ? tw`w-0.5 mx-8 px-0`
                          : elementStyling ?? tw``
                      }
                      key={eindex}
                    >
                      {element}
                    </RowElement>
                  );
                })}
              </TableRow>
            </>
          )}
        </TableBody>
      </table>
      {rows.length === 0 && (
        <EmptyLabel customStyle={emptyLabelStyling}>
          <span>{emptyLabel}</span>
        </EmptyLabel>
      )}
    </div>
  );
};

const TableHeading = styled.th<{ customStyle: TwStyle }>`
  ${tw`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider truncate`}
  ${({ customStyle }) => customStyle && customStyle}
`;

const TableBody = styled.tbody<{ scrollable: boolean }>`
  ${tw`bg-white divide-y divide-gray-200`}
  ${({ scrollable }) => scrollable && tw`overflow-y-scroll`}
`;

const EmptyLabel = styled.div<{ customStyle?: TwStyle }>`
  ${tw`flex items-center justify-center text-gray-500 py-4`}
  ${({ customStyle }) => customStyle && customStyle}
`;

const TableRow = styled.tr<{
  customStyle: TwStyle;
  index?: number;
  alternate?: boolean;
}>`
  ${({ customStyle }) => customStyle && customStyle}
  ${({ alternate, index }) =>
    alternate && index && index % 2 !== 0 && tw`bg-gray-100`}
`;

const RowElement = styled.td<{ customStyle: TwStyle }>`
  ${tw`px-6 py-4 whitespace-nowrap`}
  ${({ customStyle }) => customStyle && customStyle}
`;

export default AutoTable;
