import {
  fireEvent as fireEventInGTM,
  pageView as pageViewInGTM,
} from './gtmHelper';

import { post } from '../api/api';
import { getCurrentAuthToken } from './authHelpers';
import { getLocalAppSession } from './storageHelpers';
import posthog from 'posthog-js';

type payloadType = {
  [key: string]: any;
};

/**
 * Push event
 * @param event Event identifier
 * @param payload Optional custom payload
 */
export const fireEvent = (event: string, payload: payloadType = {}) => {
  if (!import.meta.env.DEV) {
    const newPayload = {
      ...payload,
      activeCustomer: getLocalAppSession().activeCustomer,
      activeVacancy: getLocalAppSession().activeVacancy,
    };
    fireEventInGTM(event, newPayload);

    postToAPI(event, newPayload);

    posthog.capture(event, payload);
  } else {
    console.log('🔥', event, payload);
  }
};

/**
 * Push page view event
 * @param page Page
 * @param payload Optional custom payload
 */
export const pageView = (pageFrom: string, pageTo: string, payload = {}) => {
  payload = {
    ...payload,
    'from-route': pageFrom,
  };

  if (!import.meta.env.DEV) {
    pageViewInGTM(pageTo, payload);

    postToAPI('pageview', { route: pageTo, ...payload });
  } else {
    console.log('🔎', pageTo, payload);
  }
};

const postToAPI = (type: string, payload: object) => {
  // Check if user is logged in
  const token = getCurrentAuthToken();
  if (!token) return;

  post('/v4/events', {
    type: type,
    payload: payload,
    origin: 'APP',
  });
};
