import { convertToRaw, EditorState, Modifier } from 'draft-js';
import { Routes } from 'types/routeTypes';
import draftToHtml from 'draftjs-to-html';
import JSConfetti from 'js-confetti';

export const wait = (seconds: number) =>
  new Promise((resolve) => setTimeout(() => resolve(true), seconds * 1000));

export const routeIsStructural = (
  route: string,
  ingoredRoutes: string[] = []
) => {
  let isStructural = false;

  const structuralRoutes = [
    Routes.LOGIN.toString(),
    Routes.CONFIRM_PASSWORD.toString(),
    Routes.FORGOT_PASSWORD.toString(),
  ];

  structuralRoutes.forEach((r) => {
    if (route.includes(r)) isStructural = true;
  });

  ingoredRoutes.forEach((r) => {
    if (route.includes(r)) isStructural = true;
  });

  return isStructural;
};

export const roundMoney = (money: number) => {
  return Math.round((money + Number.EPSILON) * 100) / 100;
};

export const numberToDecimalNotation = (
  number: number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
) => {
  return new Intl.NumberFormat('nl-BE', {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(number);
};

export const capitalize = (s?: string) => {
  if (s === undefined) return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function groupBy<K, V>(
  list: Array<V>,
  keyGetter: (input: V) => K
): Map<K, Array<V>> {
  const map = new Map();

  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) map.set(key, [item]);
    else collection.push(item);
  });

  return map;
}

/**
 * Adds the number zero as a prefix to numbers smaller than ten
 * @param  {number} number The input number
 * @result {number} The resulting number as a string
 */
export const addZeroPrefixToNumber = (number: number) =>
  number < 10 ? `0${number}` : number;

/**
 * Checks if a string is a valid URL
 * @param  {string} value The value to check
 * @return {boolean} A boolean that indicates whether the string is a valid URL
 */
export const isValidUrl = (value: string): boolean => {
  return (
    value.replace(/(?:[\w/:@-]+\.[\w/:@.-]*)+(?=\s|$)/g, (s) =>
      /.*@.*/.test(s) ? 'other' : 'url'
    ) === 'url'
  );
};

/**
 * Checks if the text editor is empty by checking for common (visually) empty HTML content
 * @returns A boolean that indicates whether the text editor is empty
 */
export const isTextEditorEmpty = (html: string) =>
  [
    '',
    '<p></p>',
    '<p></p>\n',
    '<p><br></p>',
    '<p><br/></p>',
    '<Body><br></Body>',
  ].includes(html);

export function timeSince(date: Date, mode: 'normal' | 'short' = 'normal') {
  const intervals = [
    { label: 'year', labelShort: 'year', seconds: 31536000 },
    { label: 'month', labelShort: 'month', seconds: 2592000 },
    { label: 'day', labelShort: 'day', seconds: 86400 },
    { label: 'hour', labelShort: 'hour', seconds: 3600 },
    { label: 'minute', labelShort: 'min', seconds: 60 },
    { label: 'second', labelShort: 'sec', seconds: 1 },
  ];

  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
  const interval = intervals.find((i) => i.seconds < seconds);
  const count = Math.floor(seconds / (interval ? interval.seconds : 1));

  return `${count} ${
    interval
      ? mode === 'normal'
        ? interval.label
        : interval.labelShort
      : 'second'
  }${count !== 1 ? 's' : ''} ago`;
}

export const swapNewlines = (value?: string): string => {
  if (!value || value === '') {
    return '';
  } else if (value.includes('<br />')) {
    return value.replaceAll('<br />', '\n');
  } else if (value.includes('<br/>')) {
    return value.replaceAll('<br/>', '\n');
  } else if (value.includes('<br>')) {
    return value.replaceAll('<br>', '\n');
  } else if (value.includes('\n')) {
    return value.replaceAll('\n', '<br />');
  } else return value;
};

/**
 * Checks if a given array contains at least one element of an other array.
 * @param arrayA The values to check
 * @param arrayB The array that should contain the values
 * @returns A boolean indicating whether the array contains at least one of the given values
 */
export function arrayContainsValuesFromArray<T>(
  arrayA: T[],
  arrayB: T[]
): boolean {
  return arrayA.map((v) => arrayB.includes(v)).find((v) => v) !== undefined;
}

export const stripHtml = (text: string) => text.replace(/<[^>]*>?/gm, '');

export const removeStyling = (text: string) =>
  text.replace(/( style=[^=]*")|(<style[^>]*>)|( class=[^=]*")/g, '');

export const textToHtml = (text: string) => {
  const editorState = EditorState.createEmpty();

  const currentContent = editorState.getCurrentContent(),
    currentSelection = editorState.getSelection();

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    text
  );

  const newEditorState = EditorState.push(
    editorState,
    newContent,
    'insert-characters'
  );

  return draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
};

export const getVideoMetadata = (url: string) => {
  return new Promise<{
    width: number;
    height: number;
    duration: number;
    hasAudio: boolean;
  }>((resolve) => {
    // Create the video element
    const video = document.createElement('video');

    // Add a listener to resolve the promise when the metadata has been loaded
    video.addEventListener(
      'loadeddata',
      () => {
        // Retrieve the dimensions
        const width = video.videoWidth;
        const height = video.videoHeight;
        const duration = video.duration;

        // Check if video has audio (https://stackoverflow.com/a/75271034)
        const anyVideo = video as any;
        const hasAudio =
          (typeof anyVideo.mozHasAudio !== 'undefined' &&
            anyVideo.mozHasAudio) ||
          (typeof anyVideo.webkitAudioDecodedByteCount !== 'undefined' &&
            anyVideo.webkitAudioDecodedByteCount > 0) ||
          Boolean(anyVideo.audioTracks?.length);

        // Send back the result
        resolve({ width, height, duration, hasAudio });
      },
      false
    );

    // Start downloading the video
    video.preload = 'auto';
    video.src = url;
  });
};

export const popConfetti = () => {
  new JSConfetti().addConfetti({
    confettiColors: [
      '#fd4c5a',
      '#fd4c5a',
      '#fd4c5a',
      '#fd4c5a',
      '#0f97ff',
      '#ffdc42',
      '#2cc985',
      '#ae4db1',
    ],
    confettiRadius: 6,
    confettiNumber: 500,
  });
};
