import React from 'react';
import tw, { styled } from 'twin.macro';
import { swapNewlines } from 'utils/baseHelpers';
import Input from 'components/form/input/Input';
import { useAdInput } from 'hooks/useAdInput';
import useAdEditorContext from 'hooks/context/ad-editor-context';

const AdEditorTextArea: React.FC = () => {
  const { currentVariant, updateCurrentVariant } = useAdEditorContext();

  const adInput = useAdInput(currentVariant?.text, updateCurrentVariant);

  return (
    <AdTextContainer editMode={adInput.editMode}>
      {adInput.editMode ? (
        <div tw="flex flex-col w-full">
          <Input
            id="ad-text-input"
            autoGrow
            autoFocus
            rows={5}
            textarea={true}
            enableEmoji={true}
            ariaInvalid={false}
            value={adInput.adTextEditorValue}
            onChange={adInput.adTextChangeHandler}
            onEmojiChange={adInput.adTextEmojiChangeHandler}
            onBlur={adInput.adTextUpdateHandler}
          />
        </div>
      ) : (
        <AdText
          className="dangerouslySetInnerHTML"
          dangerouslySetInnerHTML={{
            __html:
              swapNewlines(adInput.adTextHtml).replace(/\s+/g, '').length !== 0
                ? adInput.adTextHtml
                : '',
          }}
          id="ad-text-label"
          onClick={() => adInput.setEditMode(true)}
        />
      )}
    </AdTextContainer>
  );
};

const AdTextContainer = styled.div<{ editMode: boolean }>`
  ${tw`flex w-full p-4 pt-1`}
  ${({ editMode }) => (!editMode ? tw`justify-center` : tw`justify-start`)}
`;

const AdText = styled.label`
  ${tw`w-full min-h-[50px] text-sm cursor-pointer hover:(ring-2 ring-blue-400 bg-indigo-50 rounded-sm)`}
`;

export default AdEditorTextArea;
