import tw, { css, theme } from 'twin.macro';

import React from 'react';

import { Image } from '@phosphor-icons/react';

interface ImagePlaceholderProps
  extends React.BaseHTMLAttributes<HTMLDivElement> {
  type?: 'logo' | 'image';

  color?: string;
}

export const ImagePlaceholder = ({
  type = 'image',

  color,

  ...props
}: ImagePlaceholderProps) => {
  return (
    <div
      tw="max-w-fit border-4 rounded-lg border-dashed grid place-items-center opacity-75 transition-all"
      css={[
        css({ borderColor: color ?? theme`colors.gray.300` }),

        type === 'image'
          ? tw`mx-auto min-h-[20rem] hover:(cursor-pointer bg-black/5)`
          : tw`min-h-[15rem] hover:(cursor-pointer bg-white/20)`,
      ]}
      {...props}
    >
      <div>
        <div
          tw="text-center"
          css={{
            '*': {
              strokeWidth: '20px',
            },
          }}
        >
          <Image
            size={128}
            color={color ?? theme`colors.gray.300`}
            style={{
              margin: '0 auto',
            }}
          />
        </div>

        <p
          tw="font-semibold text-2xl"
          css={[
            type === 'image' ? tw`px-12` : tw`px-8`,

            css({ color: color ?? theme`colors.gray.300` }),
          ]}
        >
          Click here to select your {type}
        </p>
      </div>
    </div>
  );
};

export default ImagePlaceholder;
