import JsFileDownloader from 'js-file-downloader';
import { FilePreview, FileTypes, MimeTypes } from 'types/fileTypes';

/**
 * Converts a file type to a mime type
 * @param type The file type to convert
 * @returns The file type as a mime type
 */
export const fileTypeToMimeType = (type: FileTypes): MimeTypes => {
  switch (type) {
    case FileTypes.DOCX:
      return MimeTypes.DOCX;
    case FileTypes.JPEG:
      return MimeTypes.JPEG;
    case FileTypes.JPG:
      return MimeTypes.JPG;
    case FileTypes.MP4:
      return MimeTypes.MP4;
    case FileTypes.PDF:
      return MimeTypes.PDF;
    case FileTypes.PNG:
      return MimeTypes.PNG;
    default:
      return MimeTypes.PNG;
  }
};
/**
 * Changes the file name property of a file object
 * @param  {File} originalFile The file object that needs to be changed
 * @param  {string} newName The new file name (optional)
 * @returns {File} The updated file object
 */
export const renameFile = (originalFile: File, newName?: string): File => {
  return new File(
    [originalFile],
    newName ??
      originalFile.name + '-' + Math.random().toString(36).substr(2, 5),
    {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    }
  );
};

/**
 * Lowercases the file type of a given file
 * @param file The file to modify
 * @returns The modified file
 */
export const lowercaseFileType = (file: File): File => {
  return new File([file], file.name, {
    type: file.type.toLowerCase(),
    lastModified: file.lastModified,
  });
};

/**
 * Tries to download a file in the browser using
 * a specified file URL
 * @param url The file url
 */
export const downloadFile = (
  url: string,
  filename?: string,
  headers?: { name: string; value: string }[]
) => {
  try {
    new JsFileDownloader({
      url: decodeURI(url),
      filename: filename,
      headers: headers ?? [],
    });
  } catch (_) {}
};

export const checkMaxFileSize = (
  files: FileList,
  maxFileSizeInMB: number = 10
): boolean => {
  const maxSize = maxFileSizeInMB * 1000 * 1000; // In megabytes, not mebibytes

  // if the total size of the files is greater than the max size, return true
  if (Array.from(files).reduce((acc, file) => acc + file.size, 0) > maxSize)
    return true;

  return false;
};

export const getFormattedFileSize = (size: string) => {
  let fileSize = parseFloat(size);
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];

  let unitIndex = 0;
  while (fileSize >= 1024) {
    fileSize /= 1024;
    unitIndex++;
  }

  return `${fileSize.toFixed(1)} ${units[unitIndex]}`;
};

/**
 * Converts a file path (string) to a FilePreview object
 * @param file The file to convert
 * @returns The file as a FilePreview object
 */
export const filePathToFilePreview = (file: string): FilePreview => {
  return {
    fileName: file.split('/').reverse()[0],
    fileType: ('.' + file.split('.').reverse()[0].toLowerCase()) as FileTypes,
    path: file,
  };
};

/**
 * Checks if a given file extension is an image extension
 * @param extension The extension to check
 * @returns A boolean indicating whether the extension is an image
 */
export const isImageFileExtension = (extension: string) =>
  extension === FileTypes.JPG ||
  extension === FileTypes.JPEG ||
  extension === FileTypes.PNG;

export const getType = (src: string) => {
  const extension = src.split('.').reverse()[0].toLowerCase();

  switch (extension) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      return 'img';
    case 'pdf':
      return 'pdf';
    default:
      return null;
  }
};

export const getBackgroundColourByFileType = (file: File) => {
  const extension = getExtension(file);
  let color = '#E4E4E4';

  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'tiff':
      color = '#D3EDF7';
      break;
    case 'xls':
    case 'xlsx':
    case 'csv':
    case 'ods':
      color = '#CDFBCD';
      break;
    case 'pdf':
      color = '#F2C3CA';
      break;
    case 'ppt':
    case 'pptx':
    case 'odp':
      color = '#FFBD8E';
      break;
    case 'doc':
    case 'docx':
    case 'odt':
      color = '#D4D3FC';
      break;
    case 'zip':
    case 'rar':
    case '7z':
      color = '#F2DAAA';
      break;
    default:
      color = '#E4E4E4';
      break;
  }

  return color;
};

const getExtension = (file: File) => {
  return file.name.split('.').reverse()[0];
};
