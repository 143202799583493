import React from 'react';
import tw, { styled } from 'twin.macro';

interface BadgeProps {
  label?: string;
  color?: BadgeColor;
}

export type BadgeColor =
  | 'white'
  | 'green'
  | 'gray'
  | 'yellow'
  | 'sympl'
  | 'indigo'
  | 'blue'
  | 'pink';

export const colorStyles = (color: string) => {
  switch (color) {
    case 'white':
      return tw`bg-white text-gray-700`;
    case 'sympl':
      return tw`bg-sympl text-gray-50`;
    case 'green':
      return tw`bg-green-100 text-green-800`;
    case 'gray':
      return tw`bg-gray-100 text-gray-800`;
    case 'yellow':
      return tw`bg-yellow-100 text-yellow-800`;
    case 'indigo':
      return tw`bg-indigo-100 text-indigo-800`;
    case 'blue':
      return tw`bg-blue-100 text-blue-800`;
    case 'pink':
      return tw`bg-pink-100 text-pink-800`;
    default:
      return tw`bg-gray-300 text-gray-700`;
  }
};

const Badge: React.FC<BadgeProps> = ({ label, color }) => {
  return <BadgeWrapper color={color}>{label}</BadgeWrapper>;
};

const BadgeWrapper = styled.span<{ color?: BadgeColor }>(() => [
  tw`px-3 inline-flex text-xs leading-5 font-semibold rounded-full justify-center items-center`,

  ({ color }) => color && colorStyles(color),
]);

export default Badge;
