import React, { createContext, useCallback, useMemo, useState } from 'react';
import { Modal, ModalProps } from 'components/page/app-page/Modal';
import { omit } from 'lodash';

interface ModalProp extends ModalProps {
  key: string;
}

type ModalsContextType = {
  modalList: ModalProp[];
  pushModal: (newModal: ModalProp) => void;
  removeModal: (key: string) => void;

  hasOpenedModals: boolean;
};

export const ModalsContext = createContext<ModalsContextType>(undefined!);

export const ModalsProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [modalList, setModalsList] = useState<ModalProp[]>([]);

  const pushModal = useCallback(
    (newModal: ModalProp) => {
      setModalsList((prev) => {
        const toPushModal = {
          ...newModal,
          show: true,
          key: `modal${newModal.key ? `-${newModal.key}` : ''}-${
            prev.length + 1
          }`,
        };
        return [...prev, toPushModal];
      });
    },
    [modalList, setModalsList]
  );

  const removeModal = useCallback((key: string) => {
    setModalsList((prev) => {
      const filtered = [...prev].filter((modal) => !modal.key.includes(key));
      return filtered;
    });
  }, []);

  const memoizedModalList = useMemo(() => modalList, [modalList]);

  const renderModal = useCallback(() => {
    return memoizedModalList.map((modal, index) => {
      return (
        <div key={`${index} ${modal.key}`}>
          <Modal
            {...omit(modal, ['onClose', 'key'])}
            onClose={() => {
              if (modal.onClose) {
                modal.onClose();
              }
              removeModal(modalList[modalList.length - 1].key);
            }}
          />
        </div>
      );
    });
  }, [memoizedModalList]);

  return (
    <ModalsContext.Provider
      value={{
        modalList: memoizedModalList,
        pushModal,
        removeModal,

        // checkings and booleans
        hasOpenedModals: modalList.length > 0,
      }}
    >
      {renderModal()}
      {children}
    </ModalsContext.Provider>
  );
};
