import { gql } from '@apollo/client';

export const GET_PROCEDURES_FROM_VACANCY = gql`
  query getCandidates($vacId: ID!) {
    candidates(id: $vacId)
      @rest(type: "[Candidate]", path: "/v4/vacancies/{args.id}/procedures") {
      id
      procedureId
      name
      hasCV
      hasCommunications
      procDate
      source
      state
      color
      notes
      decoded_notes
    }
  }
`;

export const GET_CANDIDATE = gql`
  query getCandidate($procedureId: ID!) {
    candidate(procedureId: $procedureId)
      @rest(
        type: "Candidate"
        path: "/v4/procedures/{args.procedureId}?include=communications,candidateSurvey,procedureActivities,files,color"
      ) {
      id
      procedureId
      vacancyId
      customerId
      city
      phone
      nationality
      email
      name
      firstname
      lastname
      communications
      candidateSurvey
      procedureActivities
      source
      state
      notes
      files @type(name: "CandidateFile") {
        name
        url
      }
      color
    }
  }
`;

export const GET_MAIL_TEMPLATES = gql`
  query getMailTemplates($procedureId: ID!) {
    templates(procedureId: $procedureId)
      @rest(
        type: "[MailTemplate]"
        path: "/v4/procedures/{args.procedureId}/templates"
      ) {
      name
      body
      subject
    }
  }
`;

export const SEARCH_CANDIDATES = gql`
  query searchCandidates($search: String!) {
    searchCandidates(search: $search)
      @rest(
        type: "[Candidate]"
        path: "/v4/candidates/search?q={args.search}"
      ) {
      id
      firstname
      lastname
      phone
      procedures {
        id
        vacancy_id
        vacancy_name
        customer_name
        created_at
        source
        vacancy {
          id
          name
          language_id
        }
      }
    }
  }
`;
