import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ChatGPTMessage, getOpenAiThreadsResponse } from 'utils/openAiHelpers';
import useDefocusHandler from 'hooks/defocus';
import {
  ArrowClockwise,
  ArrowRight,
  Translate,
  Sparkle,
  Eraser,
  PencilSimple,
  ArrowBendUpLeft,
} from '@phosphor-icons/react';
import { v4 as uuid } from 'uuid';

import { EditorState } from 'draft-js';
import { createStateFromDefaultValue } from 'utils/templateHelpers';
import useNavigationContext from 'hooks/context/nav-context';

import tw, { styled } from 'twin.macro';
import { TextEditorIdentifier } from './TextEditor';
import Input from '../input/Input';
import { useSymplCookie } from 'hooks/symplCookie';

export type AIAction = {
  icon?: JSX.Element;
  label?: string;
  prompt: string;
  instantPrompt?: boolean;
  highlighted?: boolean;
};

const DEFAULT_AI_ACTIONS: AIAction[] = [
  {
    icon: <ArrowBendUpLeft weight="bold" />,
    label: 'Undo previous action',
    prompt:
      "If the last action was getting the most important words, undo the last 2 actions. If it wasn't, undo the last action.",
    instantPrompt: true,
  },
  {
    icon: <ArrowClockwise weight="bold" />,
    label: 'Apply following feedback ...',
    prompt: 'Apply following feedback: ',
  },
  {
    icon: <Eraser weight="bold" />,
    prompt: 'Correct Spelling',
    instantPrompt: true,
  },
  {
    icon: <PencilSimple weight="bold" />,
    prompt: 'Improve Writing',
    instantPrompt: true,
  },
  {
    icon: <Translate weight="bold" />,
    label: 'Translate to ...',
    prompt: 'Translate to ',
  },
];

export interface TextEditorAIPluginProps {
  aiSuggestedActions?: AIAction[];
  initialPrompt?: ChatGPTMessage[];
  initialContext?: ChatGPTMessage[];
  identifier?: TextEditorIdentifier;
  insideControls?: boolean;
  editorState: EditorState;
  setEditorState: (value: EditorState) => void;
  onChange?: (value: string) => void;
  onFinished?: (
    finalContents: string,
    thread: string
  ) => void | string | Promise<void | string>;
}

const TextEditorAIPlugin: React.FC<TextEditorAIPluginProps> = ({
  aiSuggestedActions = [],
  initialPrompt,
  initialContext = [],
  insideControls = false,
  identifier = 'ai_plugin_editor',
  editorState,
  setEditorState,
  onChange,
  onFinished,
}) => {
  const getStringValue = (editorState: EditorState) => {
    return editorState
      .getCurrentContent()
      .getBlocksAsArray()
      .map((block) => block.getText())
      .join('\n');
  };

  const { activeVacancy } = useNavigationContext();

  const [isGenerating, setIsGenerating] = useState(false);
  const [showAI, setShowAI] = useState(false);
  const [prompt, setPrompt] = useState('');
  const aiComponentRef = useRef(null);
  const aiPromptInputRef = useRef<HTMLInputElement>(null);

  const [usedAiGen] = useSymplCookie('usedAiGen');

  const [activeThread, setActiveThread] = useState<string>('');

  // Starts as true: if you come load up a TextEditor with text in it,
  // there will be no thread_id associated and the assistant should receive the current value
  const [editorDirty, setEditorDirty] = useState(true);

  useDefocusHandler(aiComponentRef, () => setShowAI(false));

  const updateEditorState = (val: string) => {
    const newContentState =
      createStateFromDefaultValue(val).getCurrentContent();
    const newEditorState = EditorState.createWithContent(newContentState);

    // Set the new editor state
    setEditorState(newEditorState);

    // Move the cursor to the end of the inserted content
    EditorState.forceSelection(
      newEditorState,
      newContentState.getSelectionAfter()
    );
  };

  const onPrompt = (customPrompt?: string) => {
    // Use the custom prompt if provided, else the prompt set by the state
    const p = !!customPrompt ? customPrompt : prompt;

    setShowAI(false);

    const currentValue = getStringValue(editorState);

    setIsGenerating(true);

    const useDefaultPrompt = (editorIsEmpty || !p.length) && !!initialPrompt;

    const threadId = useDefaultPrompt ? undefined : activeThread;

    // TODO: think this through again and write it out
    /*
     * Idea:
     * If the editor is empty, you use the initial prompt (if possible)
     * If the editor is not empty, you allow feedback to the assistant, HOWEVER
     * if the editor contents have been modified by the user, update the assistant's knowledge
     * by updating the current value
     */
    const messages = useDefaultPrompt
      ? initialPrompt!
      : [
          ...(!threadId ? initialContext : []), // Add some initial context on the first message
          ...(editorDirty && !!currentValue.length // If the editor is dirty and has contents, send those contents
            ? [
                {
                  role: 'assistant' as const,
                  content: `The current value is: ${currentValue}.`,
                },
              ]
            : []),
          { role: 'user' as const, content: p },
        ];

    getOpenAiThreadsResponse({
      vacancy_id: activeVacancy as number,
      thread_id: threadId, // If the default prompt is used, a new thread should start
      stream: true,
      messages,
      identifier,
      onChange: (val) => {
        updateEditorState(val);
        onChange?.(val);
      },
    })
      .then(async (response) => {
        // If the onFinished callback modifies the content, update the editorstate
        let result = onFinished?.(response.response, response.thread_id);
        if (result instanceof Promise) {
          result = await result;
        }
        if (!!result) {
          updateEditorState(result);
          onChange?.(result);
        }
        setActiveThread(response.thread_id);
        setEditorDirty(false);
      })
      .finally(() => {
        setIsGenerating(false);
      });

    // Reset prompt
    setPrompt('');
  };

  const editorIsEmpty = useMemo(() => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    return !blockMap.some((block) => block?.getText().trim() !== '');
  }, [editorState]);

  const showActionsList =
    prompt.length < 3 && (!editorIsEmpty || !!aiSuggestedActions.length);

  const handleClick = () => {
    editorIsEmpty && !!initialPrompt ? onPrompt() : setShowAI(!showAI);
  };

  // Indicate if the user changed the editor contents
  useEffect(() => {
    if (!isGenerating) {
      setEditorDirty(true);
    }
  }, [editorState.getCurrentContent().getPlainText()]);

  return (
    <AiDiv
      insideControls={insideControls}
      aiVisible={showAI}
      ref={aiComponentRef}
      style={insideControls && showAI ? { top: 'auto' } : {}}
    >
      <div
        tw="relative flex rounded-b-md bg-transparent h-full"
        style={
          !insideControls
            ? {
                flexDirection: 'row-reverse',
                boxSizing: 'border-box',
                alignItems: 'flex-end',
              }
            : { boxSizing: 'border-box', alignItems: 'center' }
        }
      >
        <div
          tw="relative flex my-auto h-4 cursor-pointer items-center rounded-b-md text-xs font-semibold p-0"
          onClick={handleClick}
          id="text-editor-ai-icon"
        >
          {!usedAiGen && (
            <AiGenSpan
              visible={editorIsEmpty && !isGenerating}
              insideControls={insideControls}
            >
              Generate using AI
            </AiGenSpan>
          )}
          <IconWrapper isGenerating={isGenerating}>
            <Sparkle
              weight="fill"
              tw="rounded-full bg-white hover:bg-indigo-50 p-1"
              style={
                !insideControls
                  ? {
                      boxSizing: 'border-box',
                    }
                  : {
                      maxHeight: '45px',
                      boxSizing: 'border-box',
                      padding: '0.25rem',
                    }
              }
              size={28}
              onClick={() => setShowAI(!showAI)}
            />
          </IconWrapper>
        </div>
        {showAI && (
          <AIEditorPopup
            insideControls={insideControls}
            aiPromptInputRef={aiPromptInputRef}
            prompt={prompt}
            setPrompt={setPrompt}
            onPrompt={onPrompt}
            hasDefaultPrompt={!!initialPrompt}
            showActionsList={showActionsList}
            editorIsEmpty={editorIsEmpty}
            aiSuggestedActions={aiSuggestedActions}
          />
        )}
      </div>
    </AiDiv>
  );
};

interface IRenderActionListItem {
  action: AIAction;
  onPrompt: (customPrompt?: string) => void;
  setPrompt: Dispatch<SetStateAction<string>>;
  aiPromptInputRef: RefObject<HTMLInputElement>;
}

const RenderActionListItem = ({
  action,
  onPrompt,
  setPrompt,
  aiPromptInputRef,
}: IRenderActionListItem) => {
  const onActionClicked = (action: AIAction) => {
    setPrompt(action.prompt);
    if (action.instantPrompt) {
      onPrompt(action.prompt); // TODO: figure out why the setprompt does not update the prompt state in time? Is this just how react state updates work?
    } else {
      if (aiPromptInputRef.current) aiPromptInputRef.current?.focus();
    }
  };

  return (
    <li
      key={uuid()}
      tw="flex flex-row items-center gap-x-2 rounded-md p-2 hover:bg-gray-100"
      onClick={() => onActionClicked(action)}
    >
      {action.icon ?? <Sparkle weight="fill" />}
      <span>{action.label ?? action.prompt}</span>
    </li>
  );
};

interface IAIEditorPopup {
  insideControls?: boolean;
  aiPromptInputRef: RefObject<HTMLInputElement>;
  prompt: string;
  setPrompt: Dispatch<SetStateAction<string>>;
  onPrompt: (customPrompt?: string) => void;
  hasDefaultPrompt: boolean;
  showActionsList: boolean;
  editorIsEmpty: boolean;
  aiSuggestedActions?: AIAction[];
}

export const AIEditorPopup = ({
  insideControls = false,
  aiPromptInputRef,
  prompt,
  setPrompt,
  onPrompt,
  hasDefaultPrompt,
  showActionsList,
  editorIsEmpty,
  aiSuggestedActions = [],
}: IAIEditorPopup) => {
  const renderActionItem = useCallback(
    (action: AIAction) =>
      RenderActionListItem({ action, onPrompt, setPrompt, aiPromptInputRef }),
    [onPrompt, setPrompt, aiPromptInputRef]
  );

  const popupRef = useRef(null);

  return (
    <AiPopup ref={popupRef} insideControls={insideControls}>
      <Input
        textarea
        rows={1}
        bgTransparent
        ref={aiPromptInputRef}
        placeholder="Ask symplGPT to write anything ..."
        inputStyles={tw`placeholder-gray-400 font-normal text-[0.9rem] border p-1 m-3 focus:outline-none w-[350px] text-gray-800`}
        autoFocus={true}
        autoGrow
        submitOnEnter
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        onKeyDown={({ key }) => key === 'Enter' && onPrompt()}
      />
      <button
        tw="w-fit"
        onClick={() => (hasDefaultPrompt || !!prompt) && onPrompt()}
      >
        <ArrowRight weight="bold" tw="cursor-pointer text-gray-400" size={20} />
      </button>
      {showActionsList && (
        <AiDropdown insideControls={insideControls}>
          <ul tw="p-1 cursor-pointer space-y-1 list-none text-indigo-600">
            {hasDefaultPrompt &&
              renderActionItem({
                icon: <Sparkle weight="fill" />,
                prompt: '',
                label: 'Generate text',
                instantPrompt: true,
              })}
            {!!aiSuggestedActions.length && editorIsEmpty
              ? aiSuggestedActions.map(renderActionItem)
              : DEFAULT_AI_ACTIONS.map(renderActionItem)}
          </ul>
        </AiDropdown>
      )}
    </AiPopup>
  );
};

const AiDiv = styled.div<{
  insideControls: boolean;
  aiVisible: boolean;
}>`
  ${tw`max-md:hidden h-11 z-[100] w-fit absolute right-1 top-0 bottom-0 mx-2 grid place-items-center text-indigo-600`}
  ${({ insideControls }) =>
    insideControls && tw`right-auto left-[433px] top-auto mb-1.5 max-h-fit`}
  ${({ aiVisible }) => aiVisible && tw`top-0`}
  ${({ insideControls, aiVisible }) =>
    insideControls && aiVisible && tw`bottom-0`}
`;

const AiPopup = styled.div<{ insideControls: boolean }>`
  ${tw`absolute bg-white shadow-lg overflow-hidden -left-2 -translate-x-full grid grid-cols-[1fr_40px] items-center rounded-lg border`}
  ${({ insideControls }) => (insideControls ? tw`bottom-0` : tw`-top-4`)}
`;

const AiDropdown = styled.div<{
  insideControls: boolean;
}>`
  ${tw`col-span-2 bg-white p-1 text-sm text-gray-600`}
  ${({ insideControls }) =>
    insideControls ? tw`border-b-[1px] -order-1` : tw`border-t-[1px]`}
`;

const AiGenSpan = styled.span<{ visible: boolean; insideControls: boolean }>`
  ${tw`pt-0.5 overflow-hidden w-0`}
  ${({ visible }) => visible && tw`w-fit ml-1 mr-2`}
  ${({ insideControls, visible }) =>
    insideControls && visible && tw`xl:w-0 2xl:w-fit order-1`}
`;

const IconWrapper = styled.div<{ isGenerating: boolean }>`
  ${({ isGenerating }) => isGenerating && tw`animate-pulse`}
`;

export default TextEditorAIPlugin;
