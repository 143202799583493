import tw from 'twin.macro';
import React from 'react';
import { useMutation } from 'hooks/sympl-mutation';

import Button from 'components/button/Button';
import { Routes } from 'types/routeTypes';
import { fireEvent } from 'utils/eventHelper';
import AppPage from 'components/page/app-page/AppPage';
import { CREATE_VACANCY } from 'graphql/vacancies/mutations';
import { Error, Title3 } from 'components/typography/Typography';
import useNavigationContext from 'hooks/context/nav-context';
import useGetStartedContext from 'hooks/context/get-started-context';
import { Check } from '@phosphor-icons/react';
import { showIntercom } from 'utils/intercomHelper';
import { Controller, useForm } from 'react-hook-form';
import Input from 'components/form/input/Input';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import usePermissionContext from 'hooks/context/permission-context';

const GetStarted: React.FC = () => {
  const navigate = useNavigate();
  const state = useLocation().state as { demo: boolean };

  const { checklist } = useGetStartedContext();
  const { refetchPermissions } = usePermissionContext();

  const { control, errors, handleSubmit } = useForm();

  const {
    activeCustomer,
    vacSpendEnabled,
    hasVacancies,
    setActiveVacancy,
    refetchCustomer,
  } = useNavigationContext();

  const [createVacancy, { loading: creatingVacancy }] =
    useMutation(CREATE_VACANCY);

  const steps = [
    {
      title: 'Targeting 🎯',
      description:
        'Create your ideal applicant profile to target the right audience',
      item: checklist.find(({ key }) => key === 'targeting'),
      route: Routes.TARGETING,
    },
    ...(vacSpendEnabled
      ? [
          {
            title: 'Advertising 📢',
            description: 'Build your ads',
            item: checklist.find(({ key }) => key === 'advertising'),
            route: Routes.AD_EDITOR,
          },
        ]
      : []),
    {
      title: 'Vacancy Page 💼',
      description: 'Set up your vacancy page to convince visitors to apply',
      item: checklist.find(({ key }) => key === 'vacancy_page'),
      route: Routes.JOB_POSTING,
    },
    {
      title: 'Application Form 💬',
      description:
        'Build your online questionnaire so you can easily screen candidates',
      item: checklist.find(({ key }) => key === 'typeform'),
      route: Routes.INTERVIEW,
    },
  ];

  const onSubmit = async (data: { 'vacancy-name': string }) => {
    const response = await createVacancy({
      variables: {
        input: {
          name: data['vacancy-name'],
          customer_id: activeCustomer,
          demo: !!state?.demo,
          enable_spend: true,
        },
      },
    });

    if (!response?.data?.createVacancy?.id) return;

    refetchCustomer();
    refetchPermissions();
    setActiveVacancy({
      vacancyId: response.data.createVacancy.id as number,
    });
  };

  const nextUpIndex = checklist.findIndex(({ completed }) => !completed);

  if (!hasVacancies) {
    return (
      <div tw="grid place-items-center w-full h-screen">
        <form
          tw="shadow-lg rounded-lg p-10 bg-white"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Title3 mb={0}>Which profile are your looking for? 🔍</Title3>

          <div tw="flex flex-col space-y-5 w-auto sm:w-96">
            <Controller
              id="vacancy-name"
              name="vacancy-name"
              control={control}
              defaultValue={''}
              rules={{ required: true }}
              render={({ value, onChange }) => (
                <div tw="mt-4">
                  <Input
                    type="text"
                    data-testid="getting-started-input"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    disabled={creatingVacancy}
                    placeholder="e.g. Digital Marketeer"
                    autoFocus
                  />
                </div>
              )}
            />
            {errors['vacancy-name'] && (
              <Error>{'This field is required'}</Error>
            )}

            <div>
              <Button
                type="submit"
                disabled={creatingVacancy}
                loading={creatingVacancy}
              >
                Get started &rarr;
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  return (
    <AppPage>
      <div tw="shadow-lg rounded-lg p-10 w-full">
        <h1 tw="text-2xl font-semibold" data-testid="getting-started-steps">
          Setup your campaign in {steps.length} easy steps
        </h1>
        <div tw="my-10">
          <ol tw="overflow-hidden">
            {steps.map((step, stepIdx) => (
              <li key={step.title} tw="relative pb-10">
                <>
                  {stepIdx < steps.length - 1 && (
                    <div
                      css={[
                        tw`absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300`,
                        step.item?.completed && tw`bg-indigo-600`,
                      ]}
                      aria-hidden="true"
                    />
                  )}
                  <Link
                    to={step.route}
                    className="group"
                    tw="relative flex items-start"
                  >
                    <span tw="flex h-8 items-center">
                      {step.item?.completed ? (
                        <span tw="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                          <Check
                            weight="bold"
                            size={16}
                            tw="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      ) : (
                        <span
                          css={[
                            tw`relative z-10 flex h-8 w-8 items-center justify-center rounded-full font-medium border-2 border-gray-300 bg-white text-gray-400 group-hover:border-gray-400`,
                            stepIdx === nextUpIndex &&
                              tw`border-indigo-300 bg-indigo-50 text-indigo-400 group-hover:border-indigo-400`,
                          ]}
                        >
                          {stepIdx + 1}
                        </span>
                      )}
                    </span>
                    <div tw="ml-4 flex min-w-0 flex-col">
                      <span tw="text-lg font-medium">{step.title}</span>
                      <span tw="text-gray-500">{step.description}</span>
                      <div tw="mt-4 flex flex-row gap-1">
                        {nextUpIndex === stepIdx && (
                          <Button id="get-started">
                            Get Started &nbsp;&rarr;
                          </Button>
                        )}
                      </div>
                    </div>
                  </Link>
                </>
              </li>
            ))}
          </ol>
        </div>
        <section tw="w-full">
          <Title3>Need help?</Title3>
          <div tw="mt-4 flex flex-row gap-2 w-full justify-between">
            <Button variant="inverted" onClick={() => showIntercom()}>
              Chat with our experts
            </Button>
            <Button
              variant="inverted"
              onClick={() => {
                fireEvent('ss_calendly_clicked');
                window.open(
                  'https://calendly.com/koen-commissaris/sympl-update-meeting?utm_campaign=getting-started',
                  '_blank'
                );
              }}
            >
              Schedule a demo
            </Button>
            <div tw="ml-auto">
              <Button
                variant="outline"
                onClick={() => navigate(Routes.PREFERENCES)}
              >
                Delete Campaign
              </Button>
            </div>
          </div>
        </section>
      </div>
    </AppPage>
  );
};

export default GetStarted;
