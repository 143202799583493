import 'twin.macro';

import StatList from 'components/dashboard/StatList';
import AppPage from 'components/page/app-page/AppPage';
import { SkeletonList1 } from 'components/skeleton/skeleton-list/SkeletonList';
import { Title3 } from 'components/typography/Typography';
import { GET_WEEKLY_METRICS } from 'graphql/dashboard/queries';
import { useQuery } from 'hooks/sympl-query';
import React, { useMemo } from 'react';
import { WeeklyMetricsResponse } from 'types/dashboard/types';
import useNavigationContext from 'hooks/context/nav-context';
import DashboardMetricsSection from 'components/dashboard/DashboardMetricsSection';

export const Dashboard: React.FC = () => {
  const { activeCustomer } = useNavigationContext();

  const { loading, data } = useQuery<WeeklyMetricsResponse>(
    GET_WEEKLY_METRICS,
    {
      fetchPolicy: 'network-only',
      skip: !activeCustomer,
    }
  );

  const metrics = useMemo(() => data?.campaignMetrics, [data?.campaignMetrics]);

  return (
    <AppPage heading="Dashboard">
      <div tw="h-full w-full overflow-y-auto no-scrollbar">
        <div tw="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-6 h-full">
          <div tw="col-span-2">
            <div tw="flex flex-col">
              <Title3>Weekly numbers</Title3>
              {loading ? (
                <div tw="flex gap-4 flex-nowrap">
                  {[...Array(3)].map((_, i) => (
                    <div key={i} tw="w-full">
                      <SkeletonList1 rows={1} />
                    </div>
                  ))}
                </div>
              ) : (
                <StatList metrics={metrics} />
              )}
            </div>
            <DashboardMetricsSection />
          </div>
        </div>
      </div>
    </AppPage>
  );
};
