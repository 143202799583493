export enum AdChannel {
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  INDEED = 'INDEED',
  GOOGLE = 'GOOGLE',
  OTHER = 'OTHER',
}

/** To be able to check if data matches any of our supported AD_CHANNELS */
export const AD_CHANNELS = [
  AdChannel.FACEBOOK,
  AdChannel.INSTAGRAM,
  AdChannel.INDEED,
  AdChannel.LINKEDIN,
  AdChannel.TIKTOK,
  AdChannel.GOOGLE,
];

export const VISUAL_AD_CHANNELS = [
  AdChannel.FACEBOOK,
  AdChannel.INSTAGRAM,
  AdChannel.LINKEDIN,
  AdChannel.TIKTOK,
];

export enum AdPlacement {
  FEED = 'feed',
  STORIES = 'story',
  REELS = 'reels',
}

/** To be able to check if data matches any of our supported AD_PLACEMENTS */
export const AD_PLACEMENTS = [
  AdPlacement.FEED,
  AdPlacement.STORIES,
  AdPlacement.REELS,
];

export enum AdCreativeType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export type AdDateRange =
  | 'Last week'
  | 'Last 14 days'
  | 'Last month'
  | 'Last quarter'
  | 'Last year';

export type AdStatus =
  | 'ACTIVE'
  | 'PAUSED'
  | 'ARCHIVED'
  | 'DRAFT'
  | 'COMPLETED'
  | 'REVISION'
  | 'INACTIVE';

type AdCurrency = 'EUR';

export type Ad = {
  id: number;
  priority?: number;
  channel: AdChannel;
  name?: string;
  status?: AdStatus;
  currency: AdCurrency;
  adMetric?: AdMetric;
  stencil?: string;
  file_type?: string;
  thumbnail_url?: string;
  placement: string;
};

type AdMetric = Pick<
  Metrics,
  | 'impressions'
  | 'visits'
  | 'leads'
  | 'spend'
  | 'applications'
  | 'apmi'
  | 'convs3075'
  | 'clicks'
> & {};

export type Metrics = {
  date: string;
  spend?: number;
  applications?: number;
  impressions?: number;
  reach?: number;
  visits?: number;
  leads?: number;
  apmi?: number;
  convs3075?: number;
  clicks?: number;
};
