const formatNumber = (number: number | string) => {
  if (number === '-') return number;
  const [integers, decimals] = number.toString().split('.');
  const formattedIntegerPart = integers.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return decimals
    ? `${formattedIntegerPart},${decimals}`
    : formattedIntegerPart;
};

export const roundNumber = (
  number: number,
  isBudget: boolean = false,
  isPercentage: boolean = false,
  noRounding: boolean = false
) => {
  let res;
  if (noRounding && number < 1000) {
    if (number == 0) return '-';
    else res = number.toFixed(0);
  } else if (number == 0) return '-';
  else if (number < 10) {
    res = number.toFixed(2); // Numbers less than 10 are shown with 2 decimal places
  } else if (number < 100) {
    res = number.toFixed(1); // Numbers between 10 and 100 are shown with 1 decimal place
  } else if (number < 1000) {
    res = number.toFixed(0); // Numbers between 100 and 1000 are shown with no decimal places
  } else if (number < 1000000) {
    res = (number / 1000).toFixed(1) + 'k'; // Numbers between 1000 and 1000000 are shown with 'k' suffix and 1 decimal place
  } else {
    res = (number / 1000000).toFixed(1) + 'M'; // Numbers 1000000 and above are shown with 'M' suffix and 1 decimal place
  }

  res = formatNumber(res);

  if (isBudget) return `€ ${res}`;
  else if (isPercentage) return `${res}%`;

  return res;
};
