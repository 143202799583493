import React from 'react';
import tw, { css, styled } from 'twin.macro';

import EditableCounter from '../form/editable-counter/EditableCounter';
import { TargetingLocation } from 'types/geolocationTypes';
import { Trash } from '@phosphor-icons/react';

interface LocationListProps {
  searchRef: React.RefObject<HTMLInputElement>;
  locations: TargetingLocation[];
  minRadius: number;
  maxRadius: number;
  onDelete: (locationIndex: number) => void;
  onRadiusChange: (locationIndex: number, radius: number) => void;
}

const LocationList: React.FC<LocationListProps> = ({
  searchRef,
  locations,
  minRadius,
  maxRadius,
  onDelete,
  onRadiusChange,
}) => {
  const radiusChangeHandler = (radius: string, locationIndex: number) => {
    const r = parseInt(radius);
    if (r > 0) onRadiusChange(locationIndex, r);
  };

  const deleteHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
    locationIndex: number
  ) => {
    e.preventDefault();
    onDelete(locationIndex);
  };

  if (!locations.length) {
    return (
      <div
        tw="h-[85%] flex flex-col items-center cursor-pointer p-4"
        onClick={() => searchRef?.current?.focus?.()}
      >
        <p tw="text-gray-500 text-sm font-medium flex flex-row items-center">
          Add a location to get started...
        </p>
      </div>
    );
  }

  return (
    <List>
      {locations.map((location, index) => (
        <ListItem key={index} tabIndex={0}>
          <Grid>
            <LocationName title="Go to location">{location.name}</LocationName>

            <LocationRadius>
              {!location.isCountry && (
                <>
                  <EditableCounter
                    id={`radius-${index}`}
                    name={`radius-${index}`}
                    title={'Change location radius'}
                    increaseTooltip={'Increase location radius'}
                    decreaseTooltip={'Decrease location radius'}
                    tabIndex={0}
                    min={minRadius}
                    max={maxRadius}
                    defaultValue={location.radius ?? minRadius}
                    onChange={(r) => radiusChangeHandler(r, index)}
                  />
                  <label
                    htmlFor={`radius-${location.name}`}
                    tw="text-sm cursor-text ml-2 font-medium hidden invisible sm:(block visible)"
                    style={{ lineHeight: 'unset' }}
                  >
                    km
                  </label>
                </>
              )}
            </LocationRadius>
            <div
              title={'Delete location'}
              tw="justify-self-end h-full flex justify-center items-center"
            >
              <button onClick={(e) => deleteHandler(e, index)}>
                <Trash weight="bold" tw="text-gray-500" size={18} />
              </button>
            </div>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
};

export const List = styled.ul`
  ${tw`flex flex-col flex-wrap gap-4 py-4 xl:p-4`}
`;

export const ListItem = styled.li`
  ${tw`bg-white border p-3 text-sm rounded-md text-gray-700`}
  ${css`
    @media (min-width: 1280px) {
      padding: 0.5rem;
    }
    @media (min-width: 1536px) {
      padding: 0.75rem;
    }
  `}
`;

export const Grid = styled.div`
  ${tw`w-full grid items-center`}
  ${css`
    grid-template-columns: 110px repeat(2, minmax(0, 1fr));

    @media (min-width: 425px) {
      grid-template-columns: 150px repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 520px) {
      grid-template-columns: 200px repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 640px) {
      grid-template-columns: 220px repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 900px) {
      grid-template-columns: 320px repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 1280px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    @media (min-width: 1536px) {
      grid-template-columns: 240px repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 2800px) {
      grid-template-columns: auto repeat(2, minmax(0, 1fr));
    }
  `}
`;

export const LocationName = styled.p`
  ${tw`truncate ml-2`}
  ${css`
    max-width: 80px;

    @media (min-width: 425px) {
      max-width: 110px;
    }
    @media (min-width: 520px) {
      max-width: 180px;
    }
    @media (min-width: 640px) {
      max-width: 185px;
    }
    @media (min-width: 900px) {
      max-width: 250px;
    }
    @media (min-width: 1280px) {
      max-width: 100px;
    }
    @media (min-width: 1536px) {
      max-width: 180px;
    }
    @media (min-width: 2300px) {
      max-width: unset;
    }
  `}
`;

const LocationRadius = styled.div`
  ${tw`flex justify-center gap-1 items-center`}
  ${css`
    @media (min-width: 1280px) {
      gap: 0;
    }
  `}
`;

export default LocationList;
