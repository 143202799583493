import React, { useCallback, useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { FileRejection, useDropzone } from 'react-dropzone';

import {
  MimeTypes,
  SymplFileRejection,
  SymplFileRejectionCode,
} from 'types/fileTypes';
import { UploadSimple } from '@phosphor-icons/react';
import ProgressCircle from 'components/form/ProgressCircle';

import imageCompression from 'browser-image-compression';
import ToolTip from 'components/tooltip/ToolTip';

export type DropzoneFileType =
  | '.png'
  | '.jpg'
  | '.jpeg'
  | '.mp4'
  | '.docx'
  | '.pdf';

type DropzoneVariant = 'normal' | 'success';

export interface DropzoneProps {
  id?: string;
  name?: string;
  maxFiles?: number;
  loading?: boolean;
  uploadProgress?: {
    [key: string]: number; // Each key is a file name with the progress in percentage as value
  };
  variant?: DropzoneVariant;
  fileTypes?: DropzoneFileType[];
  onUpload?: (files: File[]) => void;
  onError?: (rejections: SymplFileRejection[]) => void;
  onDrop?: () => void;
}

const MAX_FILE_SIZE = 40_000_000; // 40 MB

const Dropzone: React.FC<DropzoneProps> = ({
  id,
  name,
  maxFiles,
  loading = false,
  uploadProgress,
  variant = 'normal',
  fileTypes,
  onUpload,
  onError,
  onDrop: _onDrop,
}) => {
  const getFileErrorCode = (code: string): SymplFileRejectionCode | string => {
    switch (code) {
      case 'file-too-large':
        return 'too-large';
      case 'file-too-small':
        return 'too-small';
      case 'too-many-files':
        return 'too-many';
      case 'file-invalid-type':
        return 'invalid-type';
      default:
        return code;
    }
  };

  const parseFileRejections = useCallback(
    (fileRejections: FileRejection[]): SymplFileRejection[] =>
      fileRejections.map((rejection) => ({
        file: rejection.file,
        errors: rejection.errors.map((error) => ({
          message:
            error.code === 'file-too-large'
              ? `Only files smaller than ${
                  MAX_FILE_SIZE / 1_000_000
                } MB are allowed.`
              : error.message,
          code: getFileErrorCode(error.code),
        })),
      })),
    []
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      _onDrop?.();

      if (acceptedFiles.length) {
        const compressedFiles = await Promise.all(
          acceptedFiles.map(async (file) =>
            (
              [MimeTypes.JPEG, MimeTypes.JPG, MimeTypes.PNG] as string[]
            ).includes(file.type)
              ? imageCompression(file, {
                  maxSizeMB: file.size > 10_000_000 ? 10 : 2,
                  alwaysKeepResolution: true,
                })
              : file
          )
        );

        onUpload?.(compressedFiles);
      }
      if (fileRejections.length) {
        onError?.(parseFileRejections(fileRejections));
      }
    },
    [_onDrop, onError, onUpload, parseFileRejections]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: fileTypes,
    maxFiles,
    maxSize: MAX_FILE_SIZE,
    onDrop,
  });

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (uploadProgress && Object.keys(uploadProgress).length) {
      const progresses = Object.values(uploadProgress);
      const progressesSum = progresses.reduce(
        (total, current) => total + current,
        0
      );

      const calculatedProgress = Math.round(progressesSum / progresses.length);

      if (calculatedProgress > progress) setProgress(calculatedProgress);
    } else {
      setProgress(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadProgress]);

  return (
    <ToolTip text="The maximum file size is 40 MB." placement="bottom" arrow>
      <Wrapper
        tabIndex={0}
        {...getRootProps()}
        variant={variant}
        isLoading={loading}
      >
        {!loading ? (
          <>
            <input id={id} name={name} {...getInputProps()} />
            {isDragActive ? (
              <p tw="text-gray-700">Browse files</p>
            ) : (
              <p tw="text-gray-400">
                <UploadSimple weight="bold" size={48} />
              </p>
            )}
          </>
        ) : progress ? (
          <ProgressCircle
            radius={38}
            stroke={5}
            progress={progress > 100 ? 100 : progress}
            onClick={() => {}}
          />
        ) : (
          <p tw="text-gray-700">Compressing files ...</p>
        )}
      </Wrapper>
    </ToolTip>
  );
};

const Wrapper = styled.div<{
  variant: DropzoneVariant;
  isLoading: boolean;
}>`
  ${tw`h-full px-6 py-8 flex justify-center items-center text-center border-2 border-dashed rounded-md`}

  ${({ variant }) => variant === 'normal' && tw`border-gray-200 bg-gray-100`}
  ${({ variant }) => variant === 'success' && tw`border-green-200 bg-green-50`}

  ${({ isLoading }) => (!isLoading ? tw`cursor-pointer` : tw`cursor-wait`)}
`;

export default Dropzone;
