import { CaretRight } from '@phosphor-icons/react';
import { models, PermissionLevel } from 'types/permissionTypes';
import PartialCheckbox from 'components/checkbox/partialCheckbox/PartialCheckbox';
import React, { useState } from 'react';
import 'twin.macro';
import ToolTip from 'components/tooltip/ToolTip';
import usePermissionContext from 'hooks/context/permission-context';
import { PERMISSIONS } from 'context/PermissionContext';
import tw, { styled } from 'twin.macro';

type Role = {
  id: number;
  name: string;
};

type AccordionNodeProps = {
  node: PermissionLevel;
  roles: Role[];
  level?: number;
  userId?: number;
  onChange: (
    nodeId: number,
    roleName: string,
    model: (typeof models)[keyof typeof models]
  ) => void;
};

const AccordionNode: React.FC<AccordionNodeProps> = ({
  node,
  roles,
  level = 0,
  userId,
  onChange,
}) => {
  const [expanded, setExpanded] = useState(level < 1);

  const { allRoles, userManagers, setUserManagers, userHasPermission } =
    usePermissionContext();

  const getPermissionLevel = (modelType: string) => {
    switch (modelType) {
      case models.CUSTOMER:
        return 'customer';
      case models.BRAND:
        return 'brand';
      case models.VACANCY:
        return 'vacancy';
      default:
        return 'customer';
    }
  };

  const toggleNode = () => {
    if (node.model_type === models.CUSTOMER) return;
    setExpanded((prev) => !prev);
  };

  const isRoleSelected = (roleName: string): boolean | 'partial' => {
    const allSelected = node.roles.includes(roleName);
    let someSelected = false;

    const hideCheckboxes = !allRoles
      ?.find((role) => role.name === roleName)
      ?.available_models?.includes(node.model_type);

    if (node.children?.length && !hideCheckboxes) {
      node.children.forEach((child) => {
        if (child.roles.includes(roleName)) someSelected = true;
      });
    }

    if (!hideCheckboxes && allSelected) return true;
    if (!hideCheckboxes && someSelected) return 'partial';
    return false; // Default fallback return value
  };

  return (
    <Row level={level}>
      {/* Node Header */}
      <div tw="flex items-center gap-4">
        <div
          tw="shrink-0 py-3 min-w-[250px] flex cursor-pointer truncate items-center"
          style={{ paddingLeft: `${level * 16}px` }}
          onClick={toggleNode}
        >
          {/* Customer node is always open */}
          {node.model_type !== models.CUSTOMER && node.children?.length ? (
            <CaretRight
              width={16}
              height={16}
              weight="bold"
              tw="mr-2"
              style={{
                transform: `rotate(${expanded ? 90 : 0}deg)`,
                transition: 'transform 0.2s ease',
              }}
            />
          ) : (
            <div tw="pl-2"></div>
          )}

          <ToolTip text={node.name} placement="right" arrow>
            <p tw="w-fit max-w-full truncate">{node.name}</p>
          </ToolTip>
        </div>

        {/* Role Checkboxes */}
        {roles.map((role) => {
          const hideCheckboxes =
            !allRoles
              ?.find((r) => r.name === role.name)
              ?.available_models?.includes(node.model_type) ||
            (!userHasPermission(PERMISSIONS.MANAGE_USERS, 'customer') &&
              !allRoles?.some((r) => r.name === role.name));

          if (hideCheckboxes) return null;

          const checkBoxDisabled =
            userManagers?.length === 1 &&
            userManagers.includes(userId ?? 0) &&
            role.name === 'user-manager' &&
            isRoleSelected(role.name) === true;
          const disabledMessage = checkBoxDisabled
            ? 'This is the last remaining user manager. You cannot remove this role right now'
            : 'You have no permission to change this role.';

          if (
            checkBoxDisabled ||
            !userHasPermission(
              PERMISSIONS.MANAGE_USERS,
              getPermissionLevel(node.model_type)
            )
          ) {
            return (
              <ToolTip text={disabledMessage} key={role.id}>
                <div
                  key={role.id}
                  tw="p-2 min-w-[200px] shrink-0 grid place-items-center cursor-not-allowed"
                >
                  <PartialCheckbox
                    checked={isRoleSelected(role.name)}
                    disabled
                    onChange={() => {}}
                  />
                </div>
              </ToolTip>
            );
          }

          return (
            <div
              key={role.id}
              tw="p-2 min-w-[200px] shrink-0 grid place-items-center"
            >
              <PartialCheckbox
                checked={isRoleSelected(role.name)}
                onChange={() => {
                  onChange(node.id, role.name, node.model_type);
                  if (role.name === 'user-manager') {
                    setUserManagers(
                      !(userManagers ?? []).includes(userId!)
                        ? node.model_type === models.CUSTOMER
                          ? [...(userManagers ?? []), userId!]
                          : userManagers ?? []
                        : (userManagers ?? []).filter((id) => id !== userId!)
                    );
                  }
                }}
              />
            </div>
          );
        })}
      </div>

      {/* Children Nodes */}
      <div style={{ maxHeight: expanded ? 'unset' : '0px' }}>
        {expanded && node.children && (
          <div tw="mt-2">
            {node.children.map((child) => (
              <AccordionNode
                userId={userId}
                key={child.id}
                node={child}
                roles={roles}
                level={level + 1}
                onChange={onChange}
              />
            ))}
          </div>
        )}
      </div>
    </Row>
  );
};
const Row = styled.div<{
  level: number;
}>`
  ${tw`flex flex-col font-medium border-t border-t-gray-200`}
  ${({ level }) => level === 0 && tw`bg-[#F5F5F5] font-semibold`}
  ${({ level }) => level === 1 && tw`bg-[#F9F9FA] font-medium`}
  ${({ level }) => level === 2 && tw`bg-[#FFF] text-sm`}
`;
export default AccordionNode;
