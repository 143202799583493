const faker = {
  emojis: ['🏬', '🚀', '🔥', '💡', '🎉', '🌟', '🎶', '🛠️', '✅', '🧩'],
  buzzNouns: [
    'synergy',
    'momentum',
    'paradigm',
    'vision',
    'growth',
    'innovation',
    'scalability',
    'strategy',
    'optimization',
    'transformation',
  ],
  paragraphs: [
    'In a world driven by innovation, businesses must adapt to ever-changing landscapes. The key to success lies in strategic thinking and embracing new technologies.',
    'Collaboration fosters creativity, enabling teams to achieve extraordinary results. By leveraging diverse perspectives, organizations can drive meaningful change.',
    'Customer-centric approaches have redefined industries. Understanding user needs and tailoring solutions ensures long-term engagement and brand loyalty.',
    'Agile methodologies empower teams to iterate quickly, responding to challenges with flexibility. This approach enhances efficiency and fosters continuous improvement.',
    'Effective leadership is the cornerstone of high-performing organizations. Visionary leaders inspire teams, cultivate growth, and drive impactful change.',
    'The digital era demands adaptability. Companies embracing automation and data-driven insights position themselves ahead of the competition.',
    'Remote work has revolutionized productivity, proving that innovation thrives beyond traditional office spaces. Flexible work environments drive employee satisfaction.',
    'Sustainable practices are no longer optional; they are essential. Businesses incorporating eco-friendly initiatives contribute to a greener future.',
    'Marketing strategies evolve with consumer behavior. Data analytics enables targeted campaigns, maximizing engagement and conversions.',
    'Technology continues to bridge gaps, connecting people and ideas globally. The power of connectivity fuels innovation and drives progress.',
  ],
  buzzVerbs: [
    'streamline',
    'enhance',
    'optimize',
    'revolutionize',
    'leverage',
    'automate',
    'transform',
    'accelerate',
    'synergize',
    'innovate',
  ],
  buzzPhrases: [
    'Driving innovation through synergy',
    'Unlocking the potential of digital transformation',
    'Empowering businesses with data-driven insights',
    'Harnessing the power of automation',
    'Optimizing workflows for maximum efficiency',
    'Revolutionizing industries with cutting-edge solutions',
    'Scaling businesses through strategic growth',
    'Enhancing customer experience through personalization',
    'Redefining success with agile methodologies',
    'Fostering collaboration for sustainable innovation',
  ],
  avatars: [
    'https://randomuser.me/api/portraits/men/1.jpg',
    'https://randomuser.me/api/portraits/women/2.jpg',
    'https://randomuser.me/api/portraits/men/3.jpg',
    'https://randomuser.me/api/portraits/women/4.jpg',
    'https://randomuser.me/api/portraits/men/5.jpg',
    'https://randomuser.me/api/portraits/women/6.jpg',
    'https://randomuser.me/api/portraits/men/7.jpg',
    'https://randomuser.me/api/portraits/women/8.jpg',
    'https://randomuser.me/api/portraits/men/9.jpg',
    'https://randomuser.me/api/portraits/women/10.jpg',
  ],
  firstNames: [
    'James',
    'Olivia',
    'Liam',
    'Emma',
    'Noah',
    'Ava',
    'William',
    'Sophia',
    'Benjamin',
    'Isabella',
  ],
  jobTitles: [
    'Software Engineer',
    'Product Manager',
    'UX Designer',
    'Marketing Specialist',
    'Data Scientist',
    'Business Analyst',
    'Financial Consultant',
    'HR Manager',
    'Sales Executive',
    'DevOps Engineer',
  ],
  cities: [
    'Brussels',
    'Antwerp',
    'Ghent',
    'Bruges',
    'Liège',
    'Namur',
    'Leuven',
    'Amsterdam',
    'Paris',
    'New York',
  ],
  lastNames: [
    'Smet',
    'Vandebroek',
    'De Jong',
    'Bos',
    'De Vries',
    'Peeters',
    'Janssens',
    'Vandenbroucke',
    'Sanders',
    'Vanhoutte',
  ],
  phones: [
    '+32 493 65 45 23',
    '+32 485 34 56 12',
    '+32 479 12 34 67',
    '+32 493 22 55 11',
    '+32 476 23 11 33',
    '+32 495 11 22 33',
    '+32 473 44 55 66',
    '+32 472 23 44 88',
    '+32 476 12 23 54',
    '+32 478 11 22 77',
  ],
  companies: [
    'Acme Software',
    'Acme Technologies',
    'Acme Industries',
    'Acme Consulting',
    'Acme Group',
    'Acme Solutions',
    'Acme Enterprises',
    'Acme Ventures',
    'Acme Innovations',
    'Acme Labs',
  ],
};

export const getEmoji = () =>
  faker.emojis[Math.floor(Math.random() * faker.emojis.length)];

export const getBuzzNoun = () =>
  faker.buzzNouns[Math.floor(Math.random() * faker.buzzNouns.length)];

export const getParagraph = () =>
  faker.paragraphs[Math.floor(Math.random() * faker.paragraphs.length)];

export const getBuzzVerb = () =>
  faker.buzzVerbs[Math.floor(Math.random() * faker.buzzVerbs.length)];

export const getBuzzPhrase = () =>
  faker.buzzPhrases[Math.floor(Math.random() * faker.buzzPhrases.length)];

export const getAvatar = () =>
  faker.avatars[Math.floor(Math.random() * faker.avatars.length)];

export const getFirstName = () =>
  faker.firstNames[Math.floor(Math.random() * faker.firstNames.length)];

export const getJobTitle = () =>
  faker.jobTitles[Math.floor(Math.random() * faker.jobTitles.length)];

export const getCity = () =>
  faker.cities[Math.floor(Math.random() * faker.cities.length)];

export const getLastName = () =>
  faker.lastNames[Math.floor(Math.random() * faker.lastNames.length)];

export const getPhone = () =>
  faker.phones[Math.floor(Math.random() * faker.phones.length)];

export const getCompany = () =>
  faker.companies[Math.floor(Math.random() * faker.companies.length)];
