import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { CustomerResource } from 'types/apiTypes';
import useMetaQuery from 'hooks/metaQuery';
import { DropzoneFileType } from 'components/upload/dropzone/Dropzone';
import { FileTypes } from 'types/fileTypes';
import { BaseResourceUploadProps } from 'components/upload/ResourceUpload';
import { GET_CUSTOMER_RESOURCES } from 'graphql/customer-resources/queries';
import InfiniteScroll from 'react-infinite-scroll-component';

import useNavigationContext from 'hooks/context/nav-context';
import { AdChannel, AdCreativeType } from 'types/adTypes';
import { getVideoMetadata } from 'utils/baseHelpers';
import { isVideoInvalid } from 'utils/resourceHelpers';
import { IResourcesFilterProps } from 'components/resources-modal/ResourcesModal';
import * as Styles from './ResourcePickerStyles';
import {
  FetchableResourceType,
  PaginatedResourceRequestPayload,
  PaginatedResources,
  TypedCustomerResource,
  isResourceEnabledFn,
  isResourceSelectedFn,
  selectHandlerFn,
} from './ResourceTypes';
import ResourceItem from './ResourceItem';

interface ResourcePickerProps extends BaseResourceUploadProps {
  files?: (Omit<CustomerResource, 'id'> & { resourceId: number })[];
  channel?: AdChannel;
  creativeType?: AdCreativeType;
  fileTypes?: DropzoneFileType[];
  resourceTypes: FetchableResourceType;
  disableAdd?: boolean;
  onlyShowLogos?: boolean;
  maxSelected?: number;
  defaultValue?: CustomerResource[] | number[];
  uploadedResources?: CustomerResource[];
  /** Returns an array of image indices for the 'multi' mode or a single index for 'single' */
  onSelect?: (resources: CustomerResource[]) => void;
  addButton: React.ReactNode;
  resourcesFilter?: IResourcesFilterProps;
  enableToggle: boolean;
}

const PAGINATION_PAGE_SIZE = 50;

const ResourcePicker: React.FC<ResourcePickerProps> = ({
  files,
  channel,
  creativeType,
  maxSelected,
  defaultValue = [],
  uploadedResources = [],
  resourceTypes,
  fileTypes = [],
  onlyShowLogos = false,
  emptySelectionStrategy = 'select-none',
  onSelect,
  onLoad,
  addButton,
  resourcesFilter,
  enableToggle,
}) => {
  const { setClearResourceSelection } = useNavigationContext();

  const hasFetchedOnce = useRef<boolean>(false);
  const hasLoadedResources = useRef<boolean>(false);
  const isUsingDefaultValue = useRef<boolean>(false);

  const [invalidResources, setInvalidResources] = useState<number[]>([]);
  const [selectedResources, setSelectedResources] = useState<
    CustomerResource[]
  >([]);

  const selectHandler: selectHandlerFn = (e, resource) => {
    e.preventDefault();
    const selected = Array.from(new Set(toggleSelected(resource)));
    setSelectedResources(selected);
    onSelect?.(selected);
  };

  const [paginationPage, setPaginationPage] = useState(1);
  const [resources, setResources] = useState<TypedCustomerResource[]>([]);

  const {
    loading: loadingResources,
    data: resourceData,
    error: resourceError,
    meta: resourceMeta,
    refetch: resourceRefetch,
  } = useMetaQuery<PaginatedResources, PaginatedResourceRequestPayload>(
    GET_CUSTOMER_RESOURCES,
    {
      fetchPolicy: 'network-only',
      variables: {
        pageSize: PAGINATION_PAGE_SIZE,
        currentPage: paginationPage,
        types: resourceTypes.join(','),
        vacancyFilter: resourcesFilter?.vacancy ?? null,
        brandFilter: resourcesFilter?.brand ?? null,
      },
    }
  );

  const isMultiFileUpload = useMemo(
    () => (maxSelected ? maxSelected > 1 : true),
    [maxSelected]
  );

  const toggleSelected = (resource: CustomerResource): CustomerResource[] => {
    let updatedSelected = [...selectedResources];
    const selectedIndex = selectedResources.findIndex(
      (s) => s.id === resource.id
    );

    if (!isMultiFileUpload) {
      if (selectedIndex === -1 || updatedSelected.length === 0) {
        // Reset selected if resource is different & add the element
        updatedSelected = [resource];
      } else {
        // Remove the resource if it's already selected
        // TODO: remove condition
        if (enableToggle) updatedSelected = [];
      }
    } else {
      if (selectedIndex !== -1) {
        // Remove the element
        // TODO: remove condition
        if (enableToggle) updatedSelected.splice(selectedIndex, 1);
      } else {
        // Add the element
        updatedSelected.push(resource);
      }
    }

    return updatedSelected;
  };

  // TODO: remove
  useEffect(() => {
    setClearResourceSelection(() => () => {
      onSelect?.([]);
      setSelectedResources([]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResources, setClearResourceSelection]);

  const isResourceSelected: isResourceSelectedFn = useCallback(
    ({ id }, selected): boolean => {
      return (
        (selected ?? selectedResources).find((r) => r.id === id) !== undefined
      );
    },
    [selectedResources]
  );

  const isResourceEnabled: isResourceEnabledFn = (file) => {
    // Always enable resources for single file upload to allow easy toggle
    if (!isMultiFileUpload) return true;

    // Check the file amount
    if (maxSelected && selectedResources.length >= maxSelected)
      return isResourceSelected(file);

    const fileType = '.' + file.path.split('.').reverse()[0].toLowerCase();
    const isValidType = fileTypes.includes(fileType as DropzoneFileType);

    // Check the file type
    if (onlyShowLogos) return isValidType && file.type === 'logo';
    else return isValidType;
  };

  const typedResourceMapper = (
    resource: CustomerResource
  ): TypedCustomerResource => {
    return {
      ...resource,
      type: resource?.type ?? 'image',
      name: resource.path.split('/').reverse()[0].toLowerCase(),
      extension: `.${resource.path
        .split('.')
        .reverse()[0]
        .toLowerCase()}` as FileTypes,
    };
  };

  /**
   * Sets the resources - Sorts them based on whether they are selected or not.
   * Selected items get ordered based on their index the the selection array.
   */
  const setSortedResources = useCallback(
    (
      updatedResources: CustomerResource[],
      selectedResources: CustomerResource[]
    ) => {
      setResources(() =>
        [...updatedResources]
          .sort((a, b) => {
            // First, check if either a or b is selected
            const aSelected = isResourceSelected(a, selectedResources);
            const bSelected = isResourceSelected(b, selectedResources);

            // Use the ternary operator to return the appropriate value
            // based on the selected state of a and b
            return aSelected === bSelected
              ? Date.parse(b.created_at!) - Date.parse(a.created_at!)
              : aSelected
              ? -1
              : 1;
          })
          .map(typedResourceMapper)
      );
    },
    [isResourceSelected]
  );

  const getSelectedResourcesFromDefaultValue = useCallback(
    (res: TypedCustomerResource[]) => {
      if (defaultValue.length <= 0 || defaultValue.some((r) => !r))
        return emptySelectionStrategy === 'select-all' ? [...res] : [];

      const isResourceArray = typeof defaultValue[0] !== 'number';
      if (isResourceArray) {
        if (!Array.isArray(defaultValue)) defaultValue = [defaultValue];
      }
      const resourceIds = isResourceArray
        ? (defaultValue as CustomerResource[]).map((r) => r.id)
        : (defaultValue as number[]);

      return res.filter((r) => resourceIds.includes(r.id));
    },
    [defaultValue, emptySelectionStrategy]
  );

  // Resource fetching flow
  useEffect(() => {
    if (
      !loadingResources &&
      resourceData &&
      !resourceError &&
      !hasLoadedResources.current
    ) {
      const isCreationFlow =
        resources.length > 0 &&
        !isEqual(
          resources.map((r) => r.id),
          resourceData?.customerResources?.map((r) => r.id)
        );

      const assets = !files
        ? resourceData?.customerResources ?? []
        : files.map((f) => ({ ...f, id: f.resourceId }));

      // Only save unique resources
      const updatedResources = uniqBy(
        !isCreationFlow ? [...resources, ...assets] : [...assets],
        'id'
      );

      // Default value flow
      if (hasFetchedOnce.current) {
        isUsingDefaultValue.current = false;
        setSortedResources(
          uniqBy([...resources, ...updatedResources], 'id'), // Add the new resources to the existing ones
          selectedResources
        );
      } else {
        const res = updatedResources.map(typedResourceMapper);
        const selected = getSelectedResourcesFromDefaultValue(res);

        // Update both the selected and normal resources
        setSelectedResources(selected);
        setSortedResources(updatedResources, selected);
        onLoad?.(selected);

        isUsingDefaultValue.current = true;
      }

      if (creativeType === AdCreativeType.VIDEO) {
        const videos = resourceData.customerResources.filter(
          ({ type }) => type === 'video'
        );
        (async () => {
          const invalidVideoIds = await Promise.all(
            videos.map(async (resource) => {
              const metadata = await getVideoMetadata(resource.path);

              if (channel && isVideoInvalid(channel, metadata))
                return resource.id;
            })
          ).then((results) =>
            videos.filter((_v, index) => results[index]).map((v) => v.id)
          );
          setInvalidResources((prev) => [...prev, ...invalidVideoIds]);
        })();
      }

      hasLoadedResources.current = true;
      hasFetchedOnce.current = true;
    }
  }, [
    resourceData,
    defaultValue,
    resourceError,
    files,
    getSelectedResourcesFromDefaultValue,
    loadingResources,
    onLoad,
    resources,
    selectedResources,
    setSortedResources,
    channel,
    creativeType,
  ]);

  const isResourcesDirty = useMemo(() => {
    const oldResources = resources.map((r) => r.id);
    const newResources = resourceData?.customerResources?.map((r) => r.id);

    return !isEqual(oldResources, newResources);
  }, [resources, resourceData]);

  useEffect(() => {
    if (isResourcesDirty && resourceData) {
      const customerResources =
        resourceData.customerResources.map(typedResourceMapper);

      setResources((prev) => uniqBy(prev.concat(customerResources), 'id'));
    }
  }, [getSelectedResourcesFromDefaultValue, isResourcesDirty, resourceData]);

  // New resource flow
  useEffect(() => {
    if (uploadedResources.length) {
      // Refetch all the resources
      resourceRefetch().then(async () => {
        // Add newly uploaded resources to the selection if isMultiFileUpload === true else only select the newly uploaded resources
        let selected = isMultiFileUpload
          ? selectedResources.concat(uploadedResources)
          : uploadedResources;

        if (creativeType === AdCreativeType.VIDEO) {
          selected = await Promise.all(
            selected.map(async (resource) => {
              if (resource.type !== 'video') return resource;

              const metadata = await getVideoMetadata(resource.path);

              return channel && isVideoInvalid(channel, metadata)
                ? null
                : resource;
            })
          ).then((results) => selected.filter((_v, index) => results[index]));
        }

        setSelectedResources(selected.slice(0, maxSelected));

        // Don't trigger on select when loading default values
        if (
          ![...defaultValue]
            .map((r: number | CustomerResource) =>
              typeof r === 'number' ? r : r.id
            )
            .includes(uploadedResources[0].id)
        )
          onSelect?.(selected);

        // Reset the fetch flow
        hasLoadedResources.current = false;
      });
    } else resourceRefetch().then(() => (hasLoadedResources.current = false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedResources]);

  useEffect(() => {
    setPaginationPage(1);
    setResources(selectedResources.map(typedResourceMapper));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourcesFilter]);

  return (
    <Styles.Wrapper>
      <Styles.GridWrapper id="scrollableDiv">
        <InfiniteScroll
          dataLength={resources.length}
          next={() => setPaginationPage((prev) => prev + 1)}
          hasMore={
            resourceMeta.pagination?.total_pages
              ? paginationPage < resourceMeta.pagination?.total_pages
              : false
          }
          loader={
            <div tw="text-center mt-4">
              {loadingResources ? (
                <p>Loading...</p>
              ) : (
                <button onClick={() => setPaginationPage((prev) => prev + 1)}>
                  Load More
                </button>
              )}
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <Styles.Grid>
            {addButton && (
              <Styles.ResourceListItem>{addButton}</Styles.ResourceListItem>
            )}
            {resources.map((resource, key) => (
              <ResourceItem
                key={`${resource.id} ${resource.type} ${key}`}
                resource={resource}
                invalidResources={invalidResources}
                isResourceEnabled={isResourceEnabled}
                isResourceSelected={isResourceSelected}
                onSelectHandler={selectHandler}
              />
            ))}
          </Styles.Grid>
        </InfiniteScroll>
      </Styles.GridWrapper>
    </Styles.Wrapper>
  );
};

export default ResourcePicker;
