import React, { FC, useEffect } from 'react';
import { Routes } from 'types/routeTypes';
import { popConfetti } from 'utils/baseHelpers';
import 'twin.macro';

const PublishSuccess: FC = () => {
  useEffect(() => {
    popConfetti();
  }, []);

  return (
    <div tw="flex h-full w-full items-center justify-center bg-gray-50">
      <div tw="w-full max-w-[500px] space-y-8 rounded-xl bg-white p-10 shadow-[0px_0px_12px_-5px_rgba(0,0,0,0.3)]">
        <div tw="text-center">
          <h2 tw="mt-6 text-3xl font-extrabold text-gray-900">
            Congratulations
          </h2>
          <p tw="mt-2 text-sm text-gray-600">Your campaign is now live</p>
        </div>
        <div tw="mt-8 space-y-6 flex flex-col gap-8">
          <a
            tw="mt-5 text-indigo-500 border border-indigo-500 px-4 py-2 mx-auto cursor-pointer hover:bg-indigo-50"
            tabIndex={0}
            href={Routes.ROOT}
          >
            Return to Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default PublishSuccess;
