import 'twin.macro';
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';

import SaveButton from 'components/save-button/SaveButton';
import useUnsavedFields from 'hooks/unsaved';
import AppPage from 'components/page/app-page/AppPage';
import ConfigForm from './ConfigForm';
import { useQuery } from 'hooks/sympl-query';
import { useMutation } from 'hooks/sympl-mutation';
import { GET_PREFERENCES_CONFIG } from 'graphql/vacancies/queries';
import {
  UPDATE_VACANCY_PREFERENCES,
  DELETE_VACANCY,
} from 'graphql/vacancies/mutations';
import useNavigationContext from 'hooks/context/nav-context';
import { GET_EXTERNAL_JOBS } from 'graphql/integrations/queries';
import {
  LINK_VACANCY_TO_EXTERNAL_JOB,
  UNLINK_VACANCY_TO_EXTERNAL_JOB,
} from 'graphql/integrations/mutations';
import {
  PreferencesFormData,
  PreferencesConfiguration,
  PreferencesFormQuestionKey,
  PreferencesPayload,
  DeleteVacancyPayload,
  handleUnlinkExternalJobFn,
} from './PreferencesConfigTypes';
import * as QUESTIONS from './questions';
import PreferencesIntegrations from './PreferencesIntegrations';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';
import { Routes } from 'types/routeTypes';
import { ArrowRight } from '@phosphor-icons/react';
import usePermissionContext from 'hooks/context/permission-context';
import { PERMISSIONS } from 'context/PermissionContext';
import { Footnote } from 'components/typography/Typography';
import { PostHogFeature } from 'posthog-js/react';

const PreferencesConfig: React.FC = () => {
  const formMethods = useForm();
  const navigate = useNavigate();

  const { activeVacancy, unsetActiveVacancy } = useNavigationContext();
  const { addToast } = useToastNotifications();
  const { userHasPermission } = usePermissionContext();

  const [formKey, setFormKey] = useState(0);
  const [isDoneUpdating, setIsDoneUpdating] = useState(false);
  const [selectedExternalJob, setSelectedExternalJob] = useState<string>();
  const [formData, setFormData] = useState<PreferencesFormData[]>();
  const [shouldSaveChanges, resetUnsavedFlag] = useUnsavedFields(formMethods);
  const preferencesData = useMemo(() => {
    const allowedSections = new Set();
    if (userHasPermission(PERMISSIONS.MANAGE_CANDIDATES)) {
      allowedSections.add('Automated Candidate Mails').add('Notifications');
    }
    if (userHasPermission(PERMISSIONS.MANAGE_CAMPAIGNS)) {
      formData?.forEach((section) => {
        if (section.section !== 'Notifications') {
          allowedSections.add(section.section);
        }
      });
    }

    return formData?.filter((section) => allowedSections.has(section.section));
  }, [formData]);

  const {
    data: configData,
    loading: loadingConfig,
    refetch: refetchConfigData,
  } = useQuery<PreferencesConfiguration, { vacancyId: number }>(
    GET_PREFERENCES_CONFIG,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: !activeVacancy,
      variables: { vacancyId: activeVacancy ?? 0 },
    }
  );
  const {
    data: externalJobsData,
    loading: loadingExternalJobs,
    refetch: refetchExternalJobs,
  } = useQuery(GET_EXTERNAL_JOBS);

  const [linkExternalJob] = useMutation(LINK_VACANCY_TO_EXTERNAL_JOB);
  const [unlinkExternalJob] = useMutation(UNLINK_VACANCY_TO_EXTERNAL_JOB);

  const externalJobs = externalJobsData?.externalJobs ?? [];

  /**
   * Updates the form field value using a given section & question index.
   * @param sectionIndex The form section index
   * @param questionIndex The form question index
   * @param key The form question key
   * @param value The updated value
   */
  const formDataChangeHandler = (
    sectionIndex: number,
    questionIndex: number,
    _key: PreferencesFormQuestionKey,
    value: string | number | boolean
  ) => {
    const data = formData ? [...formData] : [];
    if (!data?.[sectionIndex]?.questions?.[questionIndex]) return;
    data[sectionIndex].questions[questionIndex].value = value;

    setFormData(data);
  };

  /**
   * Gets a question value using it's corresponding key
   * @param key The question key
   * @returns The question values
   */
  const getConfigValueByKey = (key: PreferencesFormQuestionKey) => {
    const questions = formData?.flatMap((s) => s.questions);

    // case where mails are turned off
    if (
      [
        'vacancy_template_done_survey',
        'vacancy_template_ask_for_cv',
        'vacancy_template_got_cv',
      ].includes(key) &&
      questions?.find((q) => q.key === key)?.value === 'null'
    )
      return null;

    return questions?.find((q) => q.key === key)?.value;
  };

  const [
    updateConfig,
    { loading: updateConfigLoading, error: updateConfigError },
  ] = useMutation<undefined, PreferencesPayload>(UPDATE_VACANCY_PREFERENCES);

  const [deleteVacancy] = useMutation<undefined, DeleteVacancyPayload>(
    DELETE_VACANCY
  );

  const submitForm = async () => {
    const isValid = await formMethods.trigger();
    if (loadingConfig || !isValid || !activeVacancy) return Promise.reject();

    const input: PreferencesPayload['input'] = {
      name: getConfigValueByKey('name') as string,
      lead_recruiter_id: (getConfigValueByKey('lead_recruiter') as number) ?? 0,
      excluded_from_careers_page: getConfigValueByKey(
        'hide_from_careers_page'
      ) as boolean,
      notification_preferences: {
        email_on_candidate: getConfigValueByKey(
          'email_on_candidate'
        ) as boolean,
        email_on_communication: getConfigValueByKey(
          'email_on_communication'
        ) as boolean,
      },
      vacancy_templates: {
        enabled: getConfigValueByKey('vacancy_template_enabled') as boolean,
        done_survey: getConfigValueByKey('vacancy_template_done_survey') as
          | number
          | null,
        ask_for_cv: getConfigValueByKey('vacancy_template_ask_for_cv') as
          | number
          | null,
        got_cv: getConfigValueByKey('vacancy_template_got_cv') as number | null,
      },
    };

    updateConfig({
      variables: {
        vacancyId: activeVacancy,
        input,
      },
    }).then(() => {
      addToast({
        type: ToastTypes.SUCCESS,
        description: 'Campaign preferences successfully updated',
      });
      resetUnsavedFlag();
      return Promise.resolve();
    });

    resetUnsavedFlag();
    return Promise.resolve();
  };

  const deleteVacancyHandler = useCallback(() => {
    if (loadingConfig || !activeVacancy) return;

    deleteVacancy({
      variables: {
        vacancyId: activeVacancy,
      },
    }).then(() => {
      addToast({
        type: ToastTypes.SUCCESS,
        description: 'Campaign successfully deleted',
      });
      unsetActiveVacancy();
    });
  }, [
    activeVacancy,
    deleteVacancy,
    navigate,
    loadingConfig,
    unsetActiveVacancy,
  ]);

  useEffect(() => {
    const config = configData?.preferencesConfig;
    const data: PreferencesFormData[] = [
      {
        section: 'General',
        questions: [
          {
            ...QUESTIONS.name,
            value: config?.name,
          },
          {
            ...QUESTIONS.lead_recruiter,
            value: config?.lead_recruiter_id ?? 0,
          },
          {
            ...QUESTIONS.hide_from_careers_page,
            value: config?.excluded_from_careers_page ?? false,
          },
        ],
      },
      {
        section: 'Automated Candidate Mails',
        questions: [
          {
            ...QUESTIONS.vacancy_template_done_survey,
            value:
              config?.vacancyTemplates.done_survey === null
                ? 'null'
                : config?.vacancyTemplates.done_survey ?? undefined,
          },
          {
            ...QUESTIONS.vacancy_template_ask_for_cv,
            value:
              config?.vacancyTemplates.ask_for_cv === null
                ? 'null'
                : config?.vacancyTemplates.ask_for_cv ?? undefined,
          },
          {
            ...QUESTIONS.vacancy_template_got_cv,
            value:
              config?.vacancyTemplates.got_cv === null
                ? 'null'
                : config?.vacancyTemplates.got_cv ?? undefined,
          },
        ],
        content: (
          <PostHogFeature flag="templates-editor" match={true}>
            <div tw="mt-6">
              <Link
                to={Routes.TEMPLATE_SETTINGS}
                tw="flex flex-row items-center gap-1 text-sm font-medium text-indigo-800"
              >
                Create your own templates <ArrowRight />
              </Link>
            </div>
          </PostHogFeature>
        ),
      },
      {
        section: 'Notifications',
        questions: [
          {
            ...QUESTIONS.email_on_candidate,
            value: config?.notificationPreferences.email_on_candidate,
          },
          {
            ...QUESTIONS.email_on_communication,
            value: config?.notificationPreferences.email_on_communication,
          },
        ],
        content: (
          <div tw="mt-6">
            <Footnote>
              These notification settings apply to your personal account.
            </Footnote>
          </div>
        ),
      },
      {
        section: 'Other',
        questions: [
          {
            ...QUESTIONS.delete_vacancy,
            handler: deleteVacancyHandler,
          },
        ],
      },
    ];
    setFormData(data);
    setIsDoneUpdating(true);
    setFormKey(Math.random());
  }, [configData?.preferencesConfig, deleteVacancyHandler]);

  const handleLinkExternalJob = () => {
    linkExternalJob({
      variables: {
        input: {
          vacancy_id: activeVacancy,
          integration_id: externalJobs.find(
            (j: any) => j.id === selectedExternalJob
          )?.integration_id,
          external_job_id: selectedExternalJob,
        },
      },
    }).then(() => {
      setSelectedExternalJob(undefined);
      refetchConfigData();
    });
  };

  const handleUnlinkExternalJob: handleUnlinkExternalJobFn = (externalJob) => {
    unlinkExternalJob({
      variables: {
        input: {
          vacancy_id: activeVacancy,
          integration_id: externalJob.integration_id,
          external_job_id: externalJob.id,
        },
      },
    }).then(() => {
      refetchConfigData();
    });
  };

  return (
    <AppPage
      heading="Campaign preferences"
      loading={!isDoneUpdating}
      cta={
        <SaveButton
          shouldSave={shouldSaveChanges}
          loading={updateConfigLoading}
          error={updateConfigError}
          onClick={submitForm}
        />
      }
    >
      <FormProvider {...formMethods}>
        <ConfigForm
          key={formKey}
          formData={preferencesData}
          onChange={formDataChangeHandler}
        />
      </FormProvider>
      {userHasPermission(PERMISSIONS.MANAGE_CAMPAIGNS) && (
        <PostHogFeature flag="integrations" match={true}>
          <PreferencesIntegrations
            configData={configData}
            externalJobs={externalJobs}
            loadingExternalJobs={loadingExternalJobs}
            selectedExternalJob={selectedExternalJob}
            onHandleLinkExternalJob={handleLinkExternalJob}
            onSetSelectedExternalJob={setSelectedExternalJob}
            onRefetchExternalJobs={refetchExternalJobs}
            onHandleUnlinkExternalJob={handleUnlinkExternalJob}
          />
        </PostHogFeature>
      )}
    </AppPage>
  );
};

export default PreferencesConfig;
