import {
  getSymbolPickerIcons,
  PickerIcons,
} from 'components/form/symbol/SymbolPicker';

export const updateInputGroupValue = (
  value: string[],
  data: string,
  inputIndex: number
): string[] => {
  // Find the max size the array should be
  const maxSize = Math.max(value.length, inputIndex + 1);

  return [...Array(maxSize)].map((_, index) => {
    // Set the value if index matches
    if (index === inputIndex) return data;

    // Return the value if index does not match or a default value if the value is undefined
    return value[index] ?? '';
  });
};

export const pickerIcons = [
  'FaPencilAlt',
  'FaChartLine',
  'FaThumbsUp',
  'FaLightbulb',
  'FaDesktop',
  'FaDollarSign',
  'FaBuilding',
  'FaStar',
];

const getPickerIconKeyForSymbolKey = (symbolKey: string) => {
  return (
    (pickerIcons as PickerIcons[]).find((k) => k === symbolKey) ?? 'FaPencilAlt'
  );
};

export const getJsxForSymbol = (symbolKey: string) => {
  const jsxArray = getSymbolPickerIcons();
  const iconKey = getPickerIconKeyForSymbolKey(symbolKey);
  return jsxArray.find((i) => i.key === iconKey)?.node;
};
