import 'twin.macro';
import React, {
  lazy,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/page/app-page/Modal';
import { Body } from 'components/typography/Typography';
import Img from 'components/image/Image';
import { getType } from 'utils/fileHelpers';
import { FileArrowDown } from '@phosphor-icons/react';
import ToolTip from 'components/tooltip/ToolTip';
import tw from 'twin.macro';

const Document = lazy(() =>
  import('react-pdf').then((mod) => ({ default: mod.Document }))
);

const Page = lazy(() =>
  import('react-pdf').then((mod) => ({ default: mod.Page }))
);

const FilePreviewer = ({
  name,
  url,
  type,
  isShown,
  showDownload = false,
  closeModal,
  onDocumentLoad,
  onDocumentError,
}: {
  name: string;
  url: string;
  type: string;
  isShown: boolean;
  showDownload?: boolean;
  closeModal: () => void;
  onDocumentLoad: () => void;
  onDocumentError: () => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState<number>();
  const [pageScale, setPageScale] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    onDocumentLoad();
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = name;
    link.click();
  };

  const updateScale = (pageWidth: number, pageHeight: number) => {
    if (!containerRef.current) return;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    if (pageWidth < windowWidth && pageHeight < windowHeight) return;

    const { clientWidth, clientHeight } = containerRef.current;
    const widthScale = (clientWidth - 64) / pageWidth;
    const heightScale = clientHeight / pageHeight;

    setPageScale(Math.min(widthScale, heightScale));
  };

  const file = useMemo(() => {
    return {
      url,
    };
  }, [url]);

  useEffect(() => {
    import('react-pdf/dist/esm/Page/TextLayer.css');
  }, []);

  useEffect(() => {
    if (getType(url) === 'pdf') {
      import('react-pdf').then((pdfjsModule) => {
        pdfjsModule.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsModule.pdfjs.version}/pdf.worker.js`;
      });
    }
  }, [url]);

  return (
    <Modal show={isShown} onClose={closeModal} tw="relative">
      <div tw="flex flex-col justify-center items-center max-w-[95vw] max-h-[calc(80vh-2rem)]">
        {type === 'img' && (
          <Img
            src={url}
            alt={`${name}-preview`}
            tw="object-contain max-h-full max-w-full w-full h-full min-h-0"
          />
        )}
        {type === 'pdf' && (
          <div tw="max-w-full max-h-[calc(80vh-2rem)]" ref={containerRef}>
            <Suspense fallback={<div>Loading PDF...</div>}>
              <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentError}
              >
                {Array.from(new Array(numPages), (_, index) => (
                  <div key={index}>
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      scale={pageScale}
                      onLoadSuccess={({ width, height }) =>
                        updateScale(width, height)
                      }
                    />
                    <Body>
                      Page {index + 1} of {numPages}
                    </Body>
                    <div tw="w-full border-2 " />
                  </div>
                ))}
              </Document>
            </Suspense>
          </div>
        )}
      </div>
      {showDownload && (
        <div
          tw="w-fit rounded-full bg-gray-700 text-gray-100 p-2 sticky bottom-4 right-0 cursor-pointer"
          style={{ marginLeft: 'auto', marginRight: '0rem' }} // To keep it aligned to the right inside the modal
          onClick={handleDownload}
        >
          <ToolTip
            content="Download this file"
            customStyle={tw`text-white text-sm`}
          >
            <FileArrowDown size="24" weight="bold" />
          </ToolTip>
        </div>
      )}
    </Modal>
  );
};

export default FilePreviewer;
