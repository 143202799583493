import { MAIL_REGEX, URL_REGEX } from './regexHelpers';

const stringValidator = (text: string) => !!text.trim();

/**
 * Used for React Hook Form Validate
 * @param  {string} email the email to validate
 */
export const emailValidator = (email?: string) => {
  return email && stringValidator(email) && !!email.match(MAIL_REGEX);
};

export const urlValidator = (link?: string) => {
  return link && stringValidator(link) && !!link.match(URL_REGEX);
};
