import React, { forwardRef, useMemo, useRef, useState } from 'react';
import { Minus, Plus } from '@phosphor-icons/react';
import tw, { styled, theme } from 'twin.macro';

interface EditableCounterProps
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'> {
  increaseTooltip?: string;
  decreaseTooltip?: string;
  onChange?: (value: string) => void;
}

const EditableCounter: React.FC<EditableCounterProps> = forwardRef<
  HTMLInputElement,
  EditableCounterProps
>(({ onChange: _onChange, ...props }, ref) => {
  const fallbackRef = useRef<HTMLInputElement>(null);

  const customRef = useMemo(
    () => ref && (ref as React.MutableRefObject<HTMLInputElement>),
    [ref]
  );

  const inputRef = (customRef ?? fallbackRef)!;

  const [isMinusDisabled, setIsMinusDisabled] = useState(
    props.min && props.defaultValue ? props.defaultValue <= props.min : false
  );
  const [isPlusDisabled, setIsPlusDisabled] = useState(
    props.max && props.defaultValue ? props.defaultValue >= props.max : false
  );

  const onChange = (value: string) => {
    // Toggle the button states
    setIsMinusDisabled(
      Number(props.min) ? parseInt(value) <= Number(props.min) : false
    );
    setIsPlusDisabled(
      Number(props.max) ? parseInt(value) >= Number(props.max) : false
    );
    // Event handling
    _onChange?.(value);
  };

  const stepDownHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    inputRef.current!.stepDown();
    onChange?.(inputRef.current!.value);
  };

  const stepUpHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    inputRef.current!.stepUp();
    onChange?.(inputRef.current!.value);
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val: string | number = 0;

    try {
      const { min, max } = props;

      const value = e.target.value;
      const numericValue = parseInt(value);

      if (min && numericValue < Number(min)) val = min;
      if (max && numericValue > Number(max)) val = max;
    } catch (_) {
    } finally {
      val = typeof val === 'number' ? val.toString() : val;
      onChange?.(val);
    }
  };

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { min, max } = props;

    let value: string | number =
      e.currentTarget.value ?? props?.min?.toString() ?? '0';
    const numericValue = parseInt(value);

    if (min && numericValue < Number(min)) value = min;
    if (max && numericValue > Number(max)) value = max;

    if (inputRef.current?.value) inputRef.current.value = value.toString();
    onChange?.(value.toString());

    props?.onBlur?.(e);
  };

  return (
    <Wrapper>
      <Button onClick={stepDownHandler} disabled={isMinusDisabled}>
        <Minus
          weight="bold"
          // title={decreaseTooltip ?? 'Decrease'}
          style={{
            color: isMinusDisabled
              ? theme`colors.gray.400`
              : theme`colors.gray.800`,
          }}
        />
      </Button>
      <Input
        {...(props as any)}
        ref={customRef ?? fallbackRef}
        type="number"
        onChange={changeHandler}
        onBlur={blurHandler}
        style={{
          MozAppearance: 'textfield', // Hide input number arrows (Firefox)
        }}
      />
      <Button onClick={stepUpHandler} disabled={isPlusDisabled}>
        <Plus
          weight="bold"
          // title={increaseTooltip ?? 'Increase'}
          style={{
            color: isPlusDisabled
              ? theme`colors.gray.400`
              : theme`colors.gray.800`,
          }}
        />
      </Button>
    </Wrapper>
  );
});

const Wrapper = styled.div(tw`border border-gray-300 rounded-lg inline-flex`);

const Input = styled.input(
  tw`max-w-[5rem] p-2 border border-y-0 border-gray-300 text-lg h-7 font-medium text-center focus:outline-none
  [::-webkit-inner-spin-button]:appearance-none [::-webkit-outer-spin-button]:appearance-none` // Hide input number arrows (Chrome, Safari, Edge, Opera)
);

const Button = styled.button(
  tw`
    outline-none appearance-none bg-white border-none items-center justify-center cursor-pointer m-0 relative flex h-7 w-6
    first-of-type:(rounded-tl-md rounded-bl-md) last-of-type:(rounded-tr-md rounded-br-md) disabled:cursor-not-allowed
  `
);

export default EditableCounter;
