import React from 'react';
import {
  Target,
  GraduationCap,
  Faders,
  Briefcase,
  ChatCircleDots,
  SignOut,
  PencilSimple,
  ShareNetwork,
  UsersThree,
  SquaresFour,
  User,
  ChartLine,
} from '@phosphor-icons/react';

import { Routes } from 'types/routeTypes';
import { VacancyChecklistItem } from 'context/GetStartedContext';
import { PERMISSIONS } from 'context/PermissionContext';

const AdvertisementFill = (size: number) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    width={size}
    height={size}
  >
    <path d="M7.55197 13 8.39897 10.8852 9.24398 13H7.55197ZM16 12H17V14H16C15.4477 14 15 13.5523 15 13 15 12.4477 15.4477 12 16 12ZM21 3H3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V4C22 3.44772 21.5523 3 21 3ZM12.598 16H10.443L10.043 15H6.75297L6.35297 16H4.19897L5.39797 13.002 5.39897 13 7.39897 8H9.39897L12.598 16ZM17 8H19V16H16C14.3431 16 13 14.6569 13 13 13 11.3431 14.3431 10 16 10H17V8Z"></path>
  </svg>
);

export interface Link {
  to: string;
  isActive?: boolean;
  isFinished?: boolean;
  icon: JSX.Element;
  label: string;
  visible?: boolean;
  cta?: string;
  allowedPermissions?: string[];
  requiredPermissionLevel?: 'brand' | 'customer' | 'vacancy';
}

interface MenuNavGroup {
  label: string;
  visible?: boolean;
  links: Link[];
  hideLabel?: boolean;
}

const defaultIcon = {
  weight: 'bold' as const,
  size: 18,
};

export const getMenuGroups = (
  currentLink: string,
  checklist: VacancyChecklistItem[],
  hasPublishedVacancy: boolean,
  vacIsPublished: boolean,
  vacSpendEnabled: boolean,
  hasSymplVacancyPage: boolean,
  featureFlags: string[]
): MenuNavGroup[] => [
  {
    label: 'general',
    visible: hasPublishedVacancy,
    links: [
      {
        to: Routes.ROOT,
        isActive: currentLink === Routes.ROOT,
        icon: <SquaresFour {...defaultIcon} />,
        label: 'Dashboard',
        visible: hasPublishedVacancy,
      },
      {
        to: Routes.CAREERS,
        isActive: currentLink.includes('careers'),
        icon: <Briefcase {...defaultIcon} />,
        label: 'Careers',
        visible: hasPublishedVacancy && featureFlags.includes('careers-page'),
        allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
        requiredPermissionLevel: 'brand',
      },
      {
        to: Routes.REPORTING,
        visible: featureFlags.includes('reporting'),
        isActive: currentLink.includes('reporting'),
        icon: <ChartLine {...defaultIcon} />,
        label: 'Analytics',
        allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
        requiredPermissionLevel: 'vacancy',
      },
    ],
  },
  {
    label: 'campaigns',
    links: [
      {
        to: Routes.TARGETING,
        isActive: currentLink.includes('targeting'),
        icon: <Target {...defaultIcon} />,
        label: 'Targeting',
        isFinished: !!checklist.find(({ key }) => key === 'targeting')
          ?.completed,
        cta: "Let's start by configuring our target audience",
        allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
        requiredPermissionLevel: 'vacancy',
      },
      {
        to: Routes.AD_EDITOR,
        isActive: currentLink.includes('ad-editor'),
        icon: AdvertisementFill(18),
        label: 'Advertising',
        isFinished: !!checklist.find(({ key }) => key === 'advertising')
          ?.completed,
        cta: "Let's create the ads next",
        visible: vacSpendEnabled,
        allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
        requiredPermissionLevel: 'vacancy',
      },
      {
        to: Routes.JOB_POSTING,
        isActive: currentLink.includes('job-posting'),
        icon: <PencilSimple {...defaultIcon} />,
        label: 'Vacancy Page',
        isFinished: !!checklist.find(({ key }) => key === 'vacancy_page')
          ?.completed,
        cta: "Let's create the vacancy page next",
        allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
        requiredPermissionLevel: 'vacancy',
      },
      {
        to: Routes.INTERVIEW,
        isActive: currentLink.includes('interview'),
        icon: <ChatCircleDots {...defaultIcon} />,
        label: 'Application Form',
        isFinished: checklist.find(({ key }) => key === 'typeform')?.completed,
        cta: 'Finish by configuring the application form',
        allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
        requiredPermissionLevel: 'vacancy',
      },
      {
        to: Routes.PREFERENCES,
        isActive: currentLink.includes('preferences'),
        icon: <Faders {...defaultIcon} />,
        label: 'Preferences',
        visible: vacIsPublished,
        allowedPermissions: [
          PERMISSIONS.MANAGE_CAMPAIGNS,
          PERMISSIONS.MANAGE_CANDIDATES,
        ],
        requiredPermissionLevel: 'vacancy',
      },
      {
        to: Routes.SOCIAL_MEDIA,
        isActive: currentLink.includes('social-media'),
        icon: <ShareNetwork {...defaultIcon} />,
        label: 'Social Media',
        visible: vacIsPublished && vacSpendEnabled,
        allowedPermissions: [PERMISSIONS.MANAGE_CAMPAIGNS],
        requiredPermissionLevel: 'vacancy',
      },
      {
        to: Routes.CANDIDATES,
        isActive: currentLink.includes('candidates'),
        icon: <UsersThree {...defaultIcon} />,
        label: 'Candidates',
        visible: vacIsPublished && hasSymplVacancyPage,
        allowedPermissions: [PERMISSIONS.MANAGE_CANDIDATES],
        requiredPermissionLevel: 'vacancy',
      },
    ],
  },
  {
    label: 'other',
    hideLabel: true,
    links: [
      {
        to: Routes.ACADEMY,
        isActive: currentLink.includes('academy'),
        icon: <GraduationCap {...defaultIcon} />,
        label: 'Academy',
      },
      {
        to: Routes.SETTINGS,
        isActive: currentLink.includes('account'),
        icon: <User {...defaultIcon} />,
        label: 'Account',
      },
      {
        to: Routes.ROOT,
        isActive: currentLink.includes('logout'),
        icon: <SignOut {...defaultIcon} data-testid="logout" />,
        label: 'Logout',
      },
    ],
  },
];
