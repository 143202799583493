import 'twin.macro';
import React, { useEffect, useRef, useState } from 'react';
import { InputProps } from './input/Input';
import MaskedInput from './masked-input/MaskedInput';
import { COUNTRIES } from 'data/geo/countries';
import { EUROPE_VAT_MASKS } from 'data/vat';

interface VatInputProps
  extends Omit<
    InputProps,
    | 'rows'
    | 'textarea'
    | 'enableEmoji'
    | 'onEmojiChange'
    | 'onChange'
    | 'onBlur'
    | 'onError'
  > {
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    validator?: () => (vat?: string) => boolean
  ) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement>,
    validator?: () => (vat?: string) => boolean
  ) => void;
  onError?: (
    e: React.ReactEventHandler<HTMLInputElement>,
    validator?: () => (vat?: string) => boolean
  ) => void;
}

const VatInput: React.FC<VatInputProps> = ({
  id,
  name,
  type,
  value,
  ariaInvalid,
  onChange,
  onBlur,
  onError,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const flagSelectRef = useRef<HTMLSelectElement>(null);
  const maskedInputRef = useRef<HTMLInputElement>(null);
  const [countryCode, setCountryCode] = useState<string>('BE');
  const [countryMask, setCountryMask] = useState<string>(
    EUROPE_VAT_MASKS[1].vat_mask
  );

  // Sets default value
  useEffect(() => {
    if (!value) {
      setInputValue('');
      return;
    }
    const mask = EUROPE_VAT_MASKS.find(
      (evm) =>
        evm.vat_mask.substring(0, 2) === (value as string).substring(0, 2)
    );
    setInputValue(value as string);
    setCountryCode(mask?.key ?? '');
    setCountryMask(mask?.vat_mask ?? '');
    flagSelectRef.current!.value = (value as string).substring(0, 2);
  }, [value]);

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) =>
    onBlur?.(e, getValidatorForMask(countryMask));

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value.replace(/[_]/g, '').replace(' ', ''));
    onChange?.(e, getValidatorForMask(countryMask));
  };

  const errorHandler = (e: React.ReactEventHandler<HTMLInputElement>) =>
    onError?.(e, getValidatorForMask(countryMask));

  const getValidatorForMask = (mask?: string) => {
    if (!mask) return undefined;
    return EUROPE_VAT_MASKS.find(({ vat_mask }) => mask === vat_mask)
      ?.validator;
  };

  const changeCountryHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCountryCode(e.target.value);
    setInputValue('');
    setCountryMask(
      EUROPE_VAT_MASKS.find((m) => m.key === e.target.value)?.vat_mask ?? ''
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    const lastCharacter = inputValue.split('_').filter((c) => c !== '');
    const selectionStart = e.currentTarget.selectionStart;
    const selectionEnd = e.currentTarget.selectionEnd;

    if (e.key === 'ArrowRight') {
      if (inputValue[selectionEnd!] === '_') {
        e.preventDefault();
      }
      if (
        inputValue[selectionStart!] === '_' ||
        inputValue[selectionStart!] === undefined
      ) {
        e.currentTarget.setSelectionRange(
          lastCharacter[0].length,
          lastCharacter[0].length
        );
      }
    }
    if (e.key === 'ArrowLeft') {
      if (
        inputValue[selectionEnd!] === '_' ||
        inputValue[selectionEnd!] === undefined
      ) {
        if (selectionStart! > lastCharacter[0].length) {
          e.currentTarget.setSelectionRange(
            lastCharacter[0].length + 1,
            lastCharacter[0].length + 1
          );
        }
      }
    }
  };

  const focusHandler = (
    e: React.FocusEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>
  ) => {
    if (!inputValue) return;
    const currentValue = e.currentTarget.value;
    const startSnapshot = e.currentTarget.selectionStart;
    const p = currentValue.replaceAll('_', '');

    if (
      inputValue[startSnapshot!] === '_' ||
      inputValue[startSnapshot!] === undefined
    ) {
      e.currentTarget.setSelectionRange(p.length, p.length);
    }
  };

  useEffect(() => {
    if (countryCode === 'AT') maskedInputRef!.current!.setSelectionRange(3, 3);
    else
      maskedInputRef!.current!.setSelectionRange(
        countryCode.length,
        countryCode.length
      );
  }, [countryCode]);

  return (
    <div tw="flex gap-2 w-full p-3 text-gray-700 font-medium text-sm rounded ring-1 ring-black/5 focus-within:(outline-none outline outline-blue-500)">
      <select
        tw="bg-white cursor-pointer focus:outline-none"
        onChange={changeCountryHandler}
        ref={flagSelectRef}
        placeholder={'BE'}
        defaultValue={countryCode}
      >
        {COUNTRIES.filter(({ region }) => region === 'EU')
          .sort((a, b) => a.key.localeCompare(b.key))
          .map((c) => (
            <option value={c.key === 'GR' ? 'EL' : c.key} key={c.label}>
              {c.flag}
            </option>
          ))}
      </select>
      <MaskedInput
        id={id}
        name={name}
        ref={maskedInputRef}
        ariaInvalid={ariaInvalid}
        type={type}
        mask={countryMask}
        value={inputValue ?? ''}
        onBlur={blurHandler}
        onChange={changeHandler}
        onError={errorHandler as never}
        onKeyDown={handleKeyDown}
        onFocus={focusHandler}
        onClick={focusHandler}
      />
    </div>
  );
};

export default VatInput;
