import {
  Candidate,
  CandidateProcedure,
  CandidateState,
  CandidateStates,
} from 'types/candidates/types';

export const CANDIDATE_COLUMN_NAMES = {
  applied: 'Applied',
  interesting: 'Interesting',
  in_discussion: 'In discussion',
  end_procedure: 'End procedure',
  hired: 'Hired',
};

export const CANDIDATE_STATES: CandidateStates[] = [
  'applied',
  'interesting',
  'in_discussion',
  'end_procedure',
  'hired',
];

/**
 * Gets a candidate state for a given candidate procedure
 * @param  {CandidateProcedure} procedure The candidate procedure that needs to be converted
 * @returns CandidateStates The resulting candidate state
 */
export const getStateForCandidateProcedure = (
  procedure: CandidateProcedure
): CandidateStates => {
  switch (procedure) {
    case 'survey_done':
    case 'cv_pending':
      return 'applied';
    case 'follow_up_phone':
      return 'interesting';
    case 'propose_to_customer':
      return 'in_discussion';
    case 'end_procedure':
      return 'end_procedure';
    case 'accepted_by_candidate':
      return 'hired';
    default:
      return 'applied';
  }
};

/**
 * Gets the next candidate state relative to the current procedure
 * @param  {CandidateProcedure} procedure The current candidate procedure
 * @param  {Candidate} candidate The candidate that belongs to the provided procedure
 * @param  {mode} mode End procedure mode overwrites the next state
 * @returns {CandidateStates} The candidate state & allowed transition
 */
export const getNextState = (
  procedure: CandidateProcedure
): CandidateStates => {
  switch (procedure) {
    case 'survey_done':
    case 'cv_pending':
      return 'interesting';
    case 'follow_up_phone':
      return 'in_discussion';
    case 'end_procedure':
      return 'applied';
    default:
      return 'applied';
  }
};

const getApplicants = (candidates: Candidate[]) => {
  const applicants = candidates as unknown as Omit<Candidate, 'state'> &
    {
      state: CandidateProcedure;
    }[];

  // Map the candidate states to the friendly ones
  const stateFriendlyApplicants = applicants.map((applicant) => ({
    ...applicant,
    state: getStateForCandidateProcedure(applicant.state),
  })) as unknown as Candidate[];

  // Map the base states
  const applicantsMap = new Map<CandidateStates, Candidate[]>();
  CANDIDATE_STATES.forEach((state) => applicantsMap.set(state, []));

  // Concat the base states and the passed candidates
  stateFriendlyApplicants.forEach((applicant) => {
    const stateApplicants = applicantsMap.get(applicant.state);
    if (!stateApplicants) return;
    stateApplicants.push(applicant);
    applicantsMap.set(applicant.state, stateApplicants);
  });

  return applicantsMap;
};

/**
 * Map's the candidate's state from a candidate procedure to a user-friendly candidate-state
 * @param  {Candidate[]} candidates - The list of candidates
 * @returns CandidateState - The updated candidate state list
 */
export const mapCandidateStatesToFriendlyStates = (
  candidates: Candidate[]
): CandidateState[] => {
  const applicants = getApplicants(candidates);

  // Return the candidates with their respective state
  return Array.from(applicants).map(([state, cndts]) => ({
    state,
    candidates: cndts,
  }));
};

/**
 * Sorts the candidates based on filters
 */
type GetFilteredCandidatesFn = (params: {
  candidates: Candidate[];
  sortBy: string;
  searchFilter?: string;
}) => Candidate[];

export const getFilteredCandidates: GetFilteredCandidatesFn = ({
  candidates,
  sortBy,
  searchFilter,
}) => {
  const filteredCandidates = searchFilter
    ? candidates.filter((c) =>
        c.name.toLowerCase().includes(searchFilter.toLowerCase())
      )
    : candidates;
  switch (sortBy) {
    case 'date':
      return filteredCandidates.sort(
        (a, b) =>
          new Date(b.procDate!).getTime() - new Date(a.procDate!).getTime()
      );
    default:
      return filteredCandidates.sort((a, b) => a.name.localeCompare(b.name));
  }
};
