import React from 'react';
import tw, { styled } from 'twin.macro';

interface LabelProps {
  htmlFor: string;
  children: React.ReactNode;
  fullWidth?: boolean;
}

const Label: React.FC<LabelProps> = ({
  htmlFor,
  children,
  fullWidth = true,
}) => {
  return (
    <div tw="flex flex-col" css={[fullWidth && tw`w-full`]}>
      <LabelContentsWrapper>
        <Text htmlFor={htmlFor}>{children}</Text>
      </LabelContentsWrapper>
    </div>
  );
};

const LabelContentsWrapper = styled.div(() => [
  tw`
    flex flex-row flex-wrap justify-between items-center w-full mb-2
    sm:mt-px sm:pt-2
  `,
]);

const Text = styled.label`
  ${tw`flex flex-col font-medium leading-5 w-full sm:w-auto text-gray-600`}
`;

export default Label;
