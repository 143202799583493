import { gql } from '@apollo/client';

export const GET_CUSTOMERS_PAGINATED = gql`
  query getCustomersPaginated(
    $pageSize: Int!
    $currentPage: Int!
    $filterName: String!
  ) {
    customers(
      pageSize: $pageSize
      currentPage: $currentPage
      filterName: $filterName
    )
      @rest(
        type: "[Customer]"
        path: "/v4/customers/paginated?sort=-id&page%5Bsize%5D={args.pageSize}&page%5Bnumber%5D={args.currentPage}&filter%5Bname%5D={args.filterName}"
      ) {
      id
      name
      colors
    }
  }
`;

export const GET_CUSTOMER = gql`
  query getCustomer($customerId: ID!) {
    customer(customerId: $customerId)
      @rest(
        type: "Customer"
        path: "/v4/customers/{args.customerId}?include=subscription,invoice_address"
      ) {
      id
      name
      vat
      balance
      invoicing_email
      invoicing_name
      legal_form
      invoice_address
      subscription
      colors
      default_po_number
      gdpr_deletion_period
      payment_method
      last_vacancy_id
      is_legacy
      two_factor_enabled
      has_vacancies
      has_published_vacancy
      has_custom_overlays
    }
  }
`;

export const GET_CUSTOMER_USERS = gql`
  query getCustomerUsers($customerId: ID!, $include: String!) {
    customer(customerId: $customerId, include: $include)
      @rest(
        type: "Customer"
        path: "/v4/customers/{args.customerId}?include={args.include}"
      ) {
      id
      name
      vat
      invoicing_email
      legal_form
      users {
        id
        firstname
        lastname
        fullname
        job_position
        email
        verified
        language_id
        roles {
          id
          name
          model_type
          roles
          children
        }
      }
    }
  }
`;

export const GET_TRANSACTIONS_PAGINATED = gql`
  query getTransactions($pageSize: Int!, $currentPage: Int!, $customerId: ID!) {
    transactions(
      pageSize: $pageSize
      currentPage: $currentPage
      customerId: $customerId
    )
      @rest(
        type: "Transaction"
        path: "/v4/customers/{args.customerId}/transactions?sort=-id&page%5Bsize%5D={args.pageSize}&page%5Bnumber%5D={args.currentPage}"
      ) {
      key
      type
      from_type
      from_id
      from_name
      from_brand
      to_type
      to_id
      to_name
      to_brand
      amount
      created_by_name
      created_at
      meta {
        pagination {
          count
          current_page
          links {
            previous
            next
          }
          per_page
          total
          total_pages
        }
      }
    }
  }
`;
