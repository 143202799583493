import React from 'react';
import { ChartBar } from '@phosphor-icons/react';
import 'twin.macro';

import { AdDateRange } from 'types/adTypes';
import Dropdown from '../dropdown/Dropdown';

interface DateButtonProps {
  value?: AdDateRange;
  onChange?: (key: number | string) => void;
  onClear?: () => void;
}

const DateButton: React.FC<DateButtonProps> = ({
  value,
  onChange,
  onClear,
}) => {
  const filterOptions: AdDateRange[] = [
    'Last week',
    'Last 14 days',
    'Last month',
    'Last quarter',
    'Last year',
  ];
  return (
    <div tw="flex items-center bg-gray-50 rounded-md w-max float-right focus:ring-2 mb-0">
      <Dropdown
        label={value ?? 'Select a period'}
        searchable={false}
        value={value}
        items={filterOptions.map((i) => ({ key: i, label: i }))}
        onChange={(key) => onChange?.(key)}
        onClear={() => onClear?.()}
        icon={<ChartBar size="22" tw="text-gray-700 mr-2" />}
      />
    </div>
  );
};

export default DateButton;
