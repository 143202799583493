import React, { ReactElement } from 'react';
import tw, { styled } from 'twin.macro';
import ToolTip, { ToolTipPlacementType } from '../tooltip/ToolTip';

type IconWeight = 'thin' | 'light' | 'regular' | 'bold' | 'fill' | 'duotone';
interface IconProps {
  alt?: string;
  color?: string;
  size?: string | number;
  weight?: IconWeight;
  mirrored?: boolean;
}

interface ToolbarItemProps {
  hidden?: boolean;
  disabled?: boolean;
  tooltip?: {
    enabled: boolean;
    text?: string;
    placement?: ToolTipPlacementType;
    arrow?: boolean;
  };
  Icon: IconProps | ReactElement;
  iconStyle?: React.CSSProperties;
  iconSize?: number;
  onClick?: () => void;
  mode?: 'inline' | 'floating';
  spinOnDisabled?: boolean;
}

export const ToolbarItem: React.FC<ToolbarItemProps> = ({
  hidden = false,
  disabled = false,
  tooltip,
  Icon,
  onClick,
  mode = 'inline',
  spinOnDisabled = false,
}) => {
  const onClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick?.();
  };
  return (
    <Container hidden={hidden} mode={mode}>
      {tooltip?.enabled ? (
        <ToolTip {...tooltip}>
          <Button
            onClick={onClickHandler}
            spinOnDisabled={spinOnDisabled}
            disabled={disabled}
          >
            {Icon}
          </Button>
        </ToolTip>
      ) : (
        <Button
          onClick={onClickHandler}
          spinOnDisabled={spinOnDisabled}
          disabled={disabled}
        >
          {Icon}
        </Button>
      )}
    </Container>
  );
};

const Container = styled.li<{ mode: 'inline' | 'floating' }>`
  ${({ mode }) => mode === 'floating' && tw`first:mt-0 sm:mt-2`}
  ${({ mode }) => mode === 'inline' && tw`mx-1`}
  ${({ mode }) =>
    mode === 'floating' &&
    tw`last-of-type:(border-l border-gray-200 sm:(border-t border-l-0))`}
`;

const Button = styled.button<{ spinOnDisabled: boolean; disabled: boolean }>`
  ${tw`rounded-full p-2 grow flex justify-center first-of-type:mt-2 last-of-type:mt-0 sm:p-1`}
  ${({ disabled }) =>
    disabled
      ? tw`cursor-not-allowed opacity-50 hover:bg-white`
      : tw`hover:bg-gray-100`}
  ${({ spinOnDisabled }) => spinOnDisabled && tw`animate-spin`}
`;

export default ToolbarItem;
