import 'twin.macro';
import React from 'react';

import { Error } from '../../typography/Typography';

interface SettingsInputProps {
  label: string;
  tooltip?: React.ReactNode;
  errorMessage: string;
  hasError: boolean;
}

const SettingsInput: React.FC<SettingsInputProps> = ({
  errorMessage,
  label,
  tooltip,
  children,
  hasError,
}) => {
  return (
    <div tw="py-2 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
      <dt tw="text-base font-medium text-gray-500 flex flex-col">
        <div tw="flex flex-row items-center gap-2">
          {label} {tooltip}
        </div>
        {hasError && <Error>{errorMessage}</Error>}
      </dt>
      <dd tw="mt-1 flex text-base sm:mt-0 sm:col-span-2">{children}</dd>
    </div>
  );
};

export default SettingsInput;
