import { TestimonialKeyFields } from '@symplbe/sympl-components';
import { WithPlaceholder } from 'types/careers-page/section';
import { getSectionHeaderDefaultConfig } from '../shared/defaultConfig';
import { getAvatar, getFirstName, getJobTitle, getParagraph } from 'data/faker';

export const defaultTestimonialsSettings: TestimonialKeyFields &
  WithPlaceholder = {
  ...getSectionHeaderDefaultConfig({
    placeholderValue: {
      title: 'Some kind words from our colleagues',
      subTitle: '',
      description: '',
      primaryButtonLabel: '',
      linkButtonLabel: '',
    },
  }),
  cards: [...Array(3)].map(() => ({
    avatar: getAvatar(),
    name: getFirstName(),
    jobTitle: getJobTitle(),
    content: getParagraph(),
    isPlaceholder: true,
  })),
};
