import { getLocalAppSession } from 'utils/storageHelpers';

import client from '../setupApollo';
import { Routes } from 'types/routeTypes';
import { resetApiConfig } from '../api/api';
import useNavigationContext from 'hooks/context/nav-context';
import { fireEvent } from 'utils/eventHelper';
import { logoutUser } from 'api/auth/authApi';
import useUserContext from './context/user-context';
import posthog from 'posthog-js';

/**
 * Gets the current App Session from localStorage
 * @returns The App Session
 */
const useAppSession = () => {
  return getLocalAppSession() ?? undefined;
};

/**
 * Hook that allows us to clear the app session data
 */
export const useLogout = () => {
  const { reset: resetUsers } = useUserContext();
  const { resetContext: resetNavCtx } = useNavigationContext();

  const logout = async () => {
    fireEvent('logout');

    await client.clearStore().then(async () => {
      // Revoke token
      await logoutUser();

      // Reset the current store
      resetUsers();

      posthog.reset();

      // Clear the store cache
      localStorage.clear();
      // Clear the persistor cache
      // Reset axios
      resetApiConfig();
      // Reset the navigation context
      resetNavCtx();
      // Go to the login route
      window.location.replace(Routes.LOGIN);
    });
  };

  return { logout };
};

export default useAppSession;
