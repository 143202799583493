import { useContext } from 'react';
import { NotificationContext } from 'context/NotificationContext';

export function useToastNotifications() {
  const { addToast } = useContext(NotificationContext);

  return {
    addToast,
  };
}
