// DOCS: https://developers.intercom.com/installing-intercom/docs/intercom-javascript

const defaultConfig = {
  app_id: import.meta.env.VITE_INTERCOM_ID,
};

export const initIntercom = (alignment = 'right') => {
  (window as any).Intercom('boot', {
    ...defaultConfig,
    alignment,
  });
};

export const identifyIntercom = (email: string) => {
  (window as any).Intercom('update', {
    ...defaultConfig,
    email,
    id: email,
  });
};

export const updateIntercom = (email: string, payload = {}) => {
  (window as any).Intercom('update', {
    ...defaultConfig,
    email,
    id: email,
    ...flatten(payload),
  });
};

export const showIntercom = (message?: string) => {
  (window as any).Intercom('update', { hide_default_launcher: false });
  (window as any).Intercom('showNewMessage', message);
};

export const hideIntercom = () => {
  (window as any).Intercom('hide');
  (window as any).Intercom('update', { hide_default_launcher: true });
};

const flatten = (
  obj: Record<string, any>,
  prefix = '',
  res: Record<string, any> = {}
): Record<string, any> => {
  for (const [key, val] of Object.entries(obj)) {
    const newKey = prefix ? `${prefix}_${key}` : key;
    if (val && typeof val === 'object' && !Array.isArray(val)) {
      flatten(val, newKey, res);
    } else {
      res[newKey] = val;
    }
  }
  return res;
};
