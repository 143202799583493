import React from 'react';

import Dropdown, { DropdownItem } from '../Dropdown';
import { ApiListType } from 'types/apiTypes';
import useApiList from 'hooks/useApiList';
import tw, { TwStyle } from 'twin.macro';

export interface ApiDropdownProps {
  type: ApiListType;
  value?: string | number;
  mode?: 'key' | 'value';
  disabled?: boolean;
  placeholder?: string;
  noResultsLabel?: string;
  enableCustom?: boolean;
  enableSearch?: boolean;
  customStyle?: TwStyle;
  onChange?: (value?: DropdownItem | string) => void;
  onClear?: () => void;
  onCreate?: () => void;
  createLabel?: string;
  clearFilterButton?: boolean;
  testId?: string;
}

const ApiDropdown: React.FC<ApiDropdownProps> = ({
  type,
  value,
  mode,
  disabled,
  enableCustom = false,
  enableSearch = true,
  noResultsLabel,
  placeholder,
  customStyle,
  onChange,
  onClear,
  onCreate,
  createLabel,
  clearFilterButton,
  testId,
}) => {
  const { loading, data: apiList } = useApiList(type);

  const changeHandler = (keyOrValue: string | number) => {
    if (enableCustom) {
      onChange?.(keyOrValue.toString());
      return;
    }
    onChange?.(apiList.find((i) => i.key.toString() === keyOrValue.toString()));
  };

  const getValue = (): string | number | undefined => {
    return value
      ? mode === 'key'
        ? apiList.find((i) => i.key.toString() === value.toString())?.label
        : value
      : undefined;
  };

  return (
    <Dropdown
      items={apiList}
      loading={loading}
      value={getValue()}
      label={getValue()?.toString() ?? placeholder ?? ''}
      onChange={changeHandler}
      onClear={onClear}
      disabled={disabled}
      enableCustom={enableCustom}
      noResultsLabel={noResultsLabel}
      searchable={enableSearch || enableCustom}
      customStyle={customStyle}
      onCreate={onCreate}
      createLabel={createLabel}
      clearFilterButton={clearFilterButton}
      dropDownContainerStyle={
        type === 'vacancies' ? tw`overflow-x-hidden` : tw``
      }
      testId={testId}
    />
  );
};

export default ApiDropdown;
