import React, { useContext, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import tw from 'twin.macro';

import Button from 'components/button/Button';
import Checkbox from 'components/form/checkbox/Checkbox';
import { Error } from 'components/typography/Typography';
import Input from 'components/form/input/Input';
import Label from 'components/form/Label';
import {
  Choice,
  FormQuestion,
  FormQuestionType,
} from '../form-questions-list/FormQuestionsList';
import { SurveyContext } from 'context/SurveyContext';
import Dropdown from 'components/dropdown/Dropdown';
import { Trash } from '@phosphor-icons/react';
import { PostHogFeature } from 'posthog-js/react';
import { v4 as uuid } from 'uuid';

interface FormQuestionItemProps {
  question: FormQuestion;
}
interface FormData {
  value: string;
  required: boolean;
  type: FormQuestionType;
  choices?: Choice[];
}

export const QuestionItemForm: React.FC<FormQuestionItemProps> = ({
  question,
}) => {
  const formMethods = useForm();
  const { changeQuestion, cancelEditQuestion, deleteQuestion } =
    useContext(SurveyContext);

  const { control, handleSubmit, formState, setError } = formMethods;

  const questionForm = useWatch({ control });

  const errors = useMemo(() => formState.errors, [formState.errors]);

  const typeId = 'type';
  const requiredId = 'required';
  const valueId = 'value';
  const optionsId = 'choices';

  const onSubmit = (data: FormData) => {
    const { value, type, required, choices } = data;

    const parsedValue = value.trim();

    if (parsedValue === '') {
      setError(valueId, { type: 'required' });
      return;
    }

    const updatedProperties = {
      ...question.properties,
      ...(type === 'multiple_choice' && {
        randomize: false,
        allow_multiple_selection: false,
        allow_other_choice: false,
        vertical_alignment: false,
        choices: choices ?? question.properties?.choices, // Overwrite only if choices is provided
      }),
      ...(type === 'rating' && {
        shape: 'star',
        steps: 5,
      }),
    };

    changeQuestion?.({
      ...question,
      label: parsedValue,
      required,
      type: type ?? question.type,
      properties:
        Object.keys(updatedProperties).length === 0 ? null : updatedProperties,
    });
  };

  const cancelHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!question) return;
    cancelEditQuestion?.();
  };

  const deleteHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    deleteQuestion?.(question?.ref!);
  };

  return (
    <form tw="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div tw="flex flex-col">
          <Label htmlFor={valueId}>Question</Label>
          <Controller
            id={valueId}
            name={valueId}
            rules={{ required: true }}
            control={control}
            defaultValue={question?.label}
            render={({ value, onChange }) => (
              <Input defaultValue={value} onChange={onChange} />
            )}
          />
          {errors[valueId] !== undefined && (
            <div tw="flex justify-start mb-4">
              <Error>The question value is required</Error>
            </div>
          )}
        </div>

        <PostHogFeature flag="application-form-question-types" match={true}>
          <div tw="flex flex-col mt-2">
            <Label htmlFor={typeId}>Type</Label>
            <Controller
              id={typeId}
              name={typeId}
              rules={{ required: true }}
              control={control}
              defaultValue={question?.type ?? 'long_text'}
              render={({ value, onChange }) => (
                <Dropdown
                  items={[
                    { key: 'long_text', label: 'Long text' },
                    { key: 'short_text', label: 'Short text' },
                    { key: 'rating', label: 'Score (out of 5)' },
                    { key: 'yes_no', label: 'Yes / No' },
                    { key: 'multiple_choice', label: 'Multiple choice' },
                    { key: 'file_upload', label: 'File' },
                  ]}
                  defaultValue={value}
                  onChange={onChange}
                  disabled={!question.custom}
                />
              )}
            />

            {errors[typeId] !== undefined && (
              <div tw="flex justify-start mb-4">
                <Error>The question type is required</Error>
              </div>
            )}
          </div>
        </PostHogFeature>

        {(questionForm.type ?? question.type) === 'multiple_choice' &&
          question.custom && (
            <div tw="flex flex-col mt-2 ml-4">
              <Label htmlFor={optionsId}>Options</Label>
              <Controller
                id={optionsId}
                name={optionsId}
                control={control}
                defaultValue={
                  question?.properties?.choices ?? [
                    {
                      ref: uuid(),
                      label: '',
                    },
                  ]
                }
                render={({ value, onChange }) => (
                  <div>
                    {value.map((choice: Choice, index: number) => (
                      <div
                        key={index}
                        tw="flex items-center mb-2 gap-4 cursor-auto"
                      >
                        <Input
                          fullWidth={false}
                          inputStyles={tw`w-2/3`}
                          value={choice.label ?? ''}
                          onChange={(e) => {
                            const newChoices = value.map(
                              (c: Choice, i: number) =>
                                i === index
                                  ? { ...c, label: e.target.value }
                                  : c
                            );
                            onChange(newChoices);
                          }}
                        />
                        <Trash
                          weight="bold"
                          color="red"
                          tw="cursor-pointer"
                          onClick={() => {
                            const newChoices = value.filter(
                              (_: any, i: number) => i !== index
                            );
                            onChange(newChoices);
                          }}
                        >
                          Remove
                        </Trash>
                      </div>
                    ))}
                    <Button
                      variant="primary"
                      customStyle={tw`text-xs px-3 py-2`}
                      onClick={() =>
                        onChange([
                          ...value,
                          {
                            ref: uuid(),
                            label: '',
                          },
                        ])
                      }
                    >
                      Add Option
                    </Button>
                  </div>
                )}
              />
            </div>
          )}

        <div tw="flex flex-col mt-2">
          <Label
            htmlFor={requiredId}
            tw="text-sm pb-2 w-min md:pb-0"
            fullWidth={false}
          >
            Required
          </Label>
          <Controller
            id={requiredId}
            name={requiredId}
            rules={{ required: false }}
            control={control}
            defaultValue={question.required}
            render={({ value, onChange }) => (
              <Checkbox
                type="checkbox"
                id={requiredId}
                name={requiredId}
                checked={value}
                onCheck={onChange}
              />
            )}
          />
        </div>
      </div>

      <div tw="w-full flex mt-4">
        <Button variant="danger" onClick={deleteHandler}>
          Delete
        </Button>

        <div tw="flex space-x-2 ml-auto">
          <Button variant="outline" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </div>
      </div>
    </form>
  );
};

export default QuestionItemForm;
