import ToolTip from 'components/tooltip/ToolTip';
import React, { FC } from 'react';
import { Check, VideoCamera, FileText } from '@phosphor-icons/react';
import tw from 'twin.macro';
import * as Styles from './ResourcePickerStyles';

import {
  TypedCustomerResource,
  isResourceEnabledFn,
  isResourceSelectedFn,
  selectHandlerFn,
} from './ResourceTypes';

type ResourceItemProps = {
  resource: TypedCustomerResource;
  invalidResources: number[];
  onSelectHandler: selectHandlerFn;
  isResourceEnabled: isResourceEnabledFn;
  isResourceSelected: isResourceSelectedFn;
};

const ResourceItem: FC<ResourceItemProps> = (props) => {
  const {
    resource,
    invalidResources,
    isResourceEnabled,
    isResourceSelected,
    onSelectHandler,
  } = props;
  return (
    <ToolTip
      text={decodeURI(resource.name)}
      key={resource.id}
      wordBreak={'break-all'}
      arrow
    >
      <Styles.ResourceListItem>
        <Styles.SelectableResource
          resourceType={resource.type}
          disabled={
            !isResourceEnabled(resource) ||
            invalidResources.includes(resource.id)
          }
          onClick={(e) => onSelectHandler(e, resource)}
        >
          {isResourceSelected(resource) && (
            <div tw="absolute top-2 left-2 p-1 rounded-full bg-indigo-500">
              <Check weight="bold" tw="text-indigo-200" size="18" />
            </div>
          )}
          {resource.thumbnail_path ? (
            <Styles.Img
              src={resource.thumbnail_path}
              loading="lazy"
              alt={decodeURI(resource.name)}
              className="resource-image"
              selected={isResourceSelected(resource)}
              isDisabled={!isResourceEnabled(resource)}
            />
          ) : resource.type === 'image' || resource.type === 'logo' ? (
            <Styles.Img
              src={resource.path}
              loading="lazy"
              alt={decodeURI(resource.name)}
              className="resource-image"
              selected={isResourceSelected(resource)}
              isDisabled={!isResourceEnabled(resource)}
            />
          ) : (
            <Styles.MediaResource
              selected={isResourceSelected(resource)}
              isDisabled={
                !isResourceEnabled(resource) ||
                invalidResources.includes(resource.id)
              }
            >
              <div
                css={[
                  isResourceEnabled(resource) &&
                  !invalidResources.includes(resource.id)
                    ? tw`text-gray-500`
                    : tw`text-gray-300`,
                ]}
              >
                <VideoCamera weight="bold" size={20} />
              </div>
              <span
                tw="text-sm truncate"
                css={[
                  isResourceEnabled(resource) &&
                  !invalidResources.includes(resource.id)
                    ? tw`text-gray-700`
                    : tw`text-gray-300`,
                ]}
                style={{ maxWidth: 74 }}
              >
                {decodeURI(resource.name)}
              </span>
            </Styles.MediaResource>
          )}
          {resource.type === 'document' && (
            <Styles.MediaResource
              selected={isResourceSelected(resource)}
              isDisabled={!isResourceEnabled(resource)}
            >
              <FileText weight="bold" tw="text-gray-500" size={20} />{' '}
              <span
                tw="text-sm text-gray-700 truncate"
                style={{ maxWidth: 74 }}
              >
                {resource.name}
              </span>
            </Styles.MediaResource>
          )}
        </Styles.SelectableResource>
      </Styles.ResourceListItem>
    </ToolTip>
  );
};

export default ResourceItem;
