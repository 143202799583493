import axios, { AxiosError, AxiosResponse } from 'axios';
import { ApiResponse, HttpStatusCodes } from 'types/requestTypes';
import { Routes } from 'types/routeTypes';
import { getCurrentAuthToken } from 'utils/authHelpers';
import { routeIsStructural } from 'utils/baseHelpers';

/*
  Implementation of the sympl API
  using Axios as the main data-fetching library
*/
export const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL_API,
  withCredentials: false,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const authInterceptor = api.interceptors.request.use((config) => {
  const token = getCurrentAuthToken();

  if (token) {
    config.headers = config.headers ?? {};
    config.headers['Authorization'] = `Bearer ${token}`;
  } else {
    delete config.headers?.['Authorization'];
    if (!routeIsStructural(window.location.pathname))
      window.location.replace(Routes.LOGIN);
  }

  return config;
});

export const resetApiConfig = () => {
  api.interceptors.request.eject(authInterceptor);
  api.interceptors.response.eject(errorInterceptor);
};

export const errorInterceptor = api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    const logError = { ...error };

    if (error.response?.status === HttpStatusCodes.UNAUTHORIZED) {
      localStorage.removeItem('token');
      if (error.config) delete error.config.headers?.['Authorization'];

      if (!routeIsStructural(window.location.pathname)) {
        console.log(
          `[ERROR] 🛑 Unauthorized request, redirecting to LOGIN ${JSON.stringify(
            logError
          )}`
        );

        window.location.replace(Routes.LOGIN);
      }
    } else if (error.response?.status === HttpStatusCodes.TWO_FACTOR_PENDING) {
      if (window.location.pathname !== Routes.TWO_FACTOR)
        window.location.replace(Routes.TWO_FACTOR);
    } else {
      if (logError.message !== 'canceled')
        console.log(`[ERROR] 🛑 Bad request ${JSON.stringify(logError)}`);
    }
  }
);

async function get<T>(url: string): Promise<AxiosResponse<ApiResponse<T>>> {
  return await api.get<ApiResponse<T>>(url);
}

async function post<T>(
  url: string,
  payload: any,
  isFileUpload?: boolean
): Promise<AxiosResponse<T>> {
  if (isFileUpload) {
    return await api.post<T>(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  return await api.post<T>(url, payload);
}

async function put<T>(url: string, payload: any): Promise<AxiosResponse<T>> {
  return await api.put<T>(url, payload);
}

export { get, post, put };
