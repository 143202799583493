import { Paperclip } from '@phosphor-icons/react';
import ToolTip from 'components/tooltip/ToolTip';
import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { addZeroPrefixToNumber } from 'utils/baseHelpers';
import { getFormattedFileSize } from 'utils/fileHelpers';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import FilePreviewer from 'components/file-previewer/FilePreviewer';

export interface CommunicationAttachment {
  name: string;
  url: string;
  size: string;
  extension: string;
}

const CandidateMailItem: React.FC<{
  text: string;
  sender: string;
  mailType: string;
  timestamp: Date;
  color: string;
  attachments?: CommunicationAttachment[];
}> = ({
  text,
  mailType,
  sender,
  timestamp,
  color = '#DDDBFA',
  attachments = [],
}) => {
  const [previewIsShown, setPreviewIsShown] = React.useState(false);
  const [fileData, setFileData] = React.useState({
    name: '',
    url: '',
    type: '',
  });
  const formatCommunicationTimeStamp = (date: Date) => {
    const isToday = new Date(date).getDate() === new Date().getDate();

    // if the date is today, return the time only, else return the formay 'MMM DD - HH:mm'
    return isToday
      ? `${addZeroPrefixToNumber(
          new Date(date).getHours()
        )}:${addZeroPrefixToNumber(new Date(date).getMinutes())}`
      : `${new Date(date).toLocaleDateString('nl-BE', {
          month: 'short',
          day: 'numeric',
        })} - ${addZeroPrefixToNumber(
          new Date(date).getHours()
        )}:${addZeroPrefixToNumber(new Date(date).getMinutes())}`;
  };

  const isImage = (extension: string) => {
    return ['png', 'jpg', 'jpeg'].includes(extension);
  };

  const getUserInitials = () => {
    const [senderFName, senderLName] = sender.split(' ');
    return (
      <ToolTip text={sender} placement="top" arrow>
        <span>
          {senderFName.charAt(0)}
          {senderLName?.charAt(0)}
        </span>
      </ToolTip>
    );
  };

  return (
    <MailContainer sentMail={mailType} color={color}>
      <div
        tw="flex items-end gap-4"
        style={
          mailType === 'RECEIVED'
            ? { flexDirection: 'row-reverse' }
            : { flexDirection: 'row' }
        }
      >
        <MailItem color={color} isSent={mailType !== 'RECEIVED'}>
          <div tw="flex flex-col items-start justify-start max-w-[100%]">
            <p tw="" dangerouslySetInnerHTML={{ __html: text }}></p>
          </div>
          <FilePreviewer
            name={fileData.name}
            url={fileData.url}
            type={fileData.type}
            isShown={previewIsShown}
            showDownload
            closeModal={() => setPreviewIsShown(false)}
            onDocumentLoad={() => {}}
            onDocumentError={() => {}}
          />
          {attachments.length > 0 && (
            <div
              tw="mt-6 flex gap-2 overflow-x-auto"
              css={{
                '&': { overflowX: 'auto' },
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                'scrollbar-color': 'darkgrey transparent',
              }}
            >
              {attachments.map((attachment) => {
                const isImg = isImage(attachment.extension);
                const handleClick = () => {
                  new Promise<void>((resolve, reject) => {
                    try {
                      setFileData({
                        name: attachment.name,
                        url: attachment.url,
                        type: isImg ? 'img' : 'pdf',
                      });
                      resolve();
                    } catch (error) {
                      reject(error);
                    }
                  })
                    .then(() => setPreviewIsShown(true))
                    .catch((error) =>
                      console.error('Failed to set file data:', error)
                    );
                };

                const AttachmentContent = (
                  <>
                    <span>
                      <Paperclip size={16} weight="bold" />
                    </span>
                    <ToolTip text={attachment.name} placement="top" arrow>
                      <p
                        tw="max-w-[15ch] truncate"
                        dangerouslySetInnerHTML={{ __html: attachment.name }}
                      />
                    </ToolTip>
                    <span tw="text-xs text-gray-600/70 flex min-w-fit">
                      {attachment.extension} -{' '}
                      {getFormattedFileSize(attachment.size)}
                    </span>
                  </>
                );

                return attachment.extension === 'pdf' || isImg ? (
                  <div
                    key={attachment.name}
                    tw="px-2 py-1 bg-gray-300/30 border-gray-800/10 border-2 rounded-md flex items-center gap-2 cursor-pointer"
                    onClick={handleClick}
                  >
                    {AttachmentContent}
                  </div>
                ) : (
                  <a
                    href={attachment.url}
                    key={attachment.name}
                    download="attachment.png"
                    rel="noreferrer"
                    tw="px-2 py-1 bg-gray-300/30 border-gray-800/10 border-2 rounded-md flex items-center gap-2"
                  >
                    {AttachmentContent}
                  </a>
                );
              })}
            </div>
          )}
        </MailItem>
        <Avatar tw="w-10 h-10">
          {/* TODO: Add user icon/image after it has been added in user settings - 18/09/2024 */}
          <AvatarImage src={''} />
          <AvatarFallback>{getUserInitials()}</AvatarFallback>
        </Avatar>
      </div>
      <p
        tw="text-sm font-medium text-gray-500 w-fit flex items-center gap-3 pt-2"
        style={
          mailType === 'RECEIVED'
            ? { marginLeft: '4rem' }
            : { marginRight: '4rem', alignSelf: 'flex-end' }
        }
      >
        {mailType === 'RECEIVED' && 'Email received at '}
        {mailType === 'SENT' && 'Email sent at '}
        {formatCommunicationTimeStamp(timestamp)}
      </p>
    </MailContainer>
  );
};

const MailContainer = styled.div<{ sentMail: string; color: string }>`
  ${tw`
    p-6 rounded-lg min-w-[15rem] max-w-[90%] lg:max-w-[80%] 2xl:max-w-[70%] first-of-type:mb-0 relative flex flex-col
  `}
  ${({ sentMail }) => {
    if (sentMail === 'SENT') return tw`self-end pr-0`;
    else if (sentMail === 'RECEIVED') return tw`self-start pl-0`;
    else if (sentMail === 'FAILED' || sentMail === 'OTHER')
      return tw`self-end pr-0`;
  }}
`;

const MailItem = styled.div<{ color: string; isSent: boolean }>`
  ${tw`
    relative p-6 rounded-lg bg-white [width: calc(100% - 4rem)]
  `}
  ${({ color }) => css`
    background-color: ${color};
  `}
  ${({ isSent }) => isSent && tw`rounded-br-none`}
`;

export default CandidateMailItem;
