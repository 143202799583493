enum LANGUAGES {
  Danish = 111,
  Dutch = 385,
  English = 141,
  Finnish = 161,
  French = 167,
  German = 115,
  'German(Switzerland)' = 116,
  Italian = 237,
  Polish = 425,
  Portuguese = 432,
  Spanish = 148,
  Swedish = 512,
}

export const LANGUAGE_MAP = Object.keys(LANGUAGES)
  .filter((value) => !isNaN(Number(value)))
  .map((key: any) => ({ key: parseInt(key), label: LANGUAGES[key] }));

export function getLanguageValue(key: number): string {
  return LANGUAGE_MAP.find((k) => k.key === key)?.label ?? '';
}
