import React, { useState } from 'react';
import { Modal } from 'components/page/app-page/Modal';

import Dropdown from 'components/dropdown/Dropdown';
import { SORT_BY_OPTIONS } from '../CandidatesOverview';
import Button from 'components/button/Button';
import tw, { styled } from 'twin.macro';

// Sortby in een interface proppen
interface CandidateOverviewModal {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  sortBy: string;
  setSortBy: (sorting: string) => void;
  setSorting: (sorting: string) => void;
}

export const CanidateOverViewModal: React.FC<CandidateOverviewModal> = ({
  showModal,
  setShowModal,
  sortBy,
  setSortBy,
  setSorting,
}) => {
  const [selectedValue, setSelectedValue] = useState('name');

  return (
    <Modal show={showModal} onClose={() => setShowModal(false)}>
      <Container autoFocus={false}>
        <h1 tw="mb-1 text-lg font-bold">Board Options</h1>
        <hr tw="mb-3" />
        <div tw="flex items-center py-2" autoFocus={false}>
          <div tw="pr-2 md:pr-5 text-center w-[27%] md:w-auto">Sort by</div>
          <Dropdown
            tw="absolute flex flex-col z-10"
            label={sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}
            items={SORT_BY_OPTIONS}
            onChange={(value) => setSelectedValue(value as string)}
          />
        </div>
      </Container>

      <div tw="float-right">
        <Button
          onClick={() => {
            setSorting(selectedValue);
            setSortBy(selectedValue);
            setShowModal(false);
          }}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

const Container = styled.div`
  ${tw`my-5 mb-2`}
`;
