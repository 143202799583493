import { useToggle } from 'hooks/useToggle';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  FilmStrip,
  PauseCircle,
  PlayCircle,
  SpeakerSimpleHigh,
  SpeakerSimpleX,
  GearSix,
  Trash,
  ArrowUUpLeft,
  MagicWand,
  Sparkle,
  Copy,
} from '@phosphor-icons/react';
import tw, { styled } from 'twin.macro';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import ToolTip from '../tooltip/ToolTip';

import { useSymplCookie } from 'hooks/symplCookie';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import { AdCreativeType, AdPlacement } from 'types/adTypes';
import { fireEvent } from 'utils/eventHelper';
import useNavigationContext from 'hooks/context/nav-context';
import { popConfetti } from 'utils/baseHelpers';
import { TextT, Rectangle } from '@phosphor-icons/react';
import useVideoGenContext from 'hooks/context/video-gen-context';
import { getOpenAiThreadsResponse } from 'utils/openAiHelpers';
import { generatePromptEssenceShort } from 'utils/vacancy-generator/prompts';
import { AIEditorPopup } from 'components/form/text-editor/TextEditorAIPlugin';
import useDefocusHandler from 'hooks/defocus';
import { PostHogFeature } from 'posthog-js/react';

export enum ToolbarOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

interface AdEditorToolbarProps {
  orientation?: ToolbarOrientation;
  divide?: boolean;
  customButtons?: JSX.Element[];
  setActiveTab?: (value?: AdEditorSideBarTabs) => void;
}

const AdEditorToolbar: React.FC<AdEditorToolbarProps> = ({
  orientation = ToolbarOrientation.VERTICAL,
  divide = true,
  customButtons,
  setActiveTab,
}) => {
  const [usedAiGen, setUsedAiGen] = useSymplCookie('usedAiGen');
  const [activeThread, setActiveThread] = useState<string>();

  const [showAI, setShowAI] = useState(false);
  const [prompt, setPrompt] = useState('');

  const { activeVacancy } = useNavigationContext();
  const {
    svgStack,
    currentVariant,
    payload,
    updateCurrentVariant,
    uploadMode,
    deleteCurrentVariant,
    videoRef,
    addCanvasElement,
    undoChange,
    duplicateCurrentVariant,
  } = useAdEditorContext();

  const [isVideoMuted, toggleIsVideoMuted] = useToggle(true);
  const [isVideoPlaying, toggleIsVideoPlaying] = useToggle(true);
  const [isGenerating, toggleIsGenerating] = useToggle(false);
  const aiPromptInputRef = useRef<HTMLInputElement | null>(null);

  const isVideo = useMemo(
    () => currentVariant?.creative_type === AdCreativeType.VIDEO,
    [currentVariant?.creative_type]
  );

  const isFeed = useMemo(
    () => currentVariant?.placement === AdPlacement.FEED,
    [currentVariant?.placement]
  );

  useEffect(() => {
    if (!isVideo) return;

    // If the video ref has been set (in VideoContainer), set the controls
    if (videoRef.current) {
      const videoElement = videoRef.current;

      if (isVideoPlaying) {
        const videoPromise = videoElement.play();
        try {
          if (videoPromise !== undefined) {
            videoPromise.catch(() => {
              videoElement.muted = true;
              videoElement.play();
            });
          }
        } catch (e) {
          console.warn(e);
        }
      } else {
        videoElement.pause();
      }

      videoElement.muted = isVideoMuted;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVideoMuted, isVideoPlaying, currentVariant?.path]);

  // If the uploade mode changes, reset the controls
  useEffect(() => {
    if (!isVideo) return;
    if (!isVideoPlaying) toggleIsVideoPlaying();
    if (!isVideoMuted) toggleIsVideoMuted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadMode]);

  const editorIsEmpty = useMemo(
    () => !currentVariant?.text,
    [currentVariant?.text, currentVariant]
  );

  // Starts as true: if you come load up a TextEditor with text in it,
  // there will be no thread_id associated and the assistant should receive the current value
  // HOWEVER, if the editor is empty, it should not be dirty?
  const [editorDirty, setEditorDirty] = useState(true);

  useEffect(() => {
    if (!isGenerating) {
      setActiveThread(undefined);
    }
  }, [currentVariant]);

  useEffect(() => {
    if (!isGenerating) {
      setEditorDirty(true);
    }
  }, [currentVariant?.text]);

  const defaultPrompt = useMemo(() => {
    return payload && generatePromptEssenceShort(payload);
  }, [payload]);

  const aiFocusRef = useRef(null);
  useDefocusHandler(aiFocusRef, () => setShowAI(false));

  const generateCopy = async () => {
    // TODO: check to reincorporate this, might be in the wrong spot now
    if (!activeVacancy || !payload || isGenerating) return;

    setShowAI(false);

    // You should use the default prompt if there is one and the editor is empty or there is no custom prompt
    const useDefaultPrompt =
      (editorIsEmpty || !prompt.length) && !!defaultPrompt;

    /*
     * 1. The text editor is empty and there is a default prompt:
     *    => Generate text
     * 2. The text editor is not empty => open popup
     * 3. The text editor is empty, but there is no default prompt => open popup
     */
    const messages = useDefaultPrompt
      ? defaultPrompt!
      : [
          ...(editorDirty
            ? [
                {
                  role: 'assistant' as const,
                  content: `The current value is: ${currentVariant?.text}.`,
                },
              ]
            : []),
          { role: 'user' as const, content: prompt },
        ];

    setShowAI(false);
    toggleIsGenerating();

    try {
      const { thread_id } = await getOpenAiThreadsResponse({
        onChange: (value) => updateCurrentVariant('text', value),
        identifier: 'ad_builder',
        messages: messages,
        thread_id: useDefaultPrompt ? undefined : activeThread, // If the default prompt is used, a new thread should start
        vacancy_id: activeVacancy,
        stream: true,
      });

      setActiveThread(thread_id);
    } finally {
      toggleIsGenerating();
      setEditorDirty(false);
      setPrompt('');
    }

    if (!usedAiGen) {
      popConfetti();
      setUsedAiGen(true);
    }

    fireEvent('ai_ad_toolbar');
  };

  const handleClick = () => {
    editorIsEmpty && !!defaultPrompt ? generateCopy() : setShowAI(!showAI);
  };

  const { videoGen, copyVideo } = useVideoGenContext();

  return (
    <Wrapper orientation={orientation}>
      {customButtons ? (
        customButtons.map((button, index) => (
          <ButtonItem key={index} divide={divide}>
            {button}
          </ButtonItem>
        ))
      ) : (
        <>
          {!!currentVariant?.path?.thread_id && (
            <PostHogFeature flag="ai-video" match={true}>
              <ButtonItem divide={divide}>
                <ToolTip text="Update video using AI" placement="right" arrow>
                  <AdEditorButton onClick={videoGen}>
                    <Sparkle weight="fill" size={32} tw="p-1 text-indigo-600" />
                  </AdEditorButton>
                </ToolTip>
                <ToolTip
                  text={`Copy video to ${
                    currentVariant.placement === AdPlacement.FEED
                      ? `${AdPlacement.STORIES} and ${AdPlacement.REELS}`
                      : AdPlacement.FEED
                  }`}
                  placement="right"
                  arrow
                >
                  <AdEditorButton onClick={copyVideo}>
                    <Copy weight="bold" size={32} tw="p-1 text-indigo-600" />
                  </AdEditorButton>
                </ToolTip>
              </ButtonItem>
            </PostHogFeature>
          )}

          <div ref={aiFocusRef} tw="relative">
            <ButtonItem divide={false} hidden={!isFeed}>
              <ToolTip text="Generate ad text using AI" placement="right" arrow>
                <AdEditorButton
                  isLoading={isGenerating}
                  isDisabled={!payload}
                  onClick={handleClick}
                >
                  <MagicWand weight="bold" size={32} tw="p-1" />
                </AdEditorButton>
              </ToolTip>
            </ButtonItem>
            {showAI && (
              <AIEditorPopup
                aiPromptInputRef={aiPromptInputRef}
                editorIsEmpty={editorIsEmpty}
                hasDefaultPrompt={!!defaultPrompt}
                onPrompt={generateCopy}
                prompt={prompt}
                setPrompt={setPrompt}
                showActionsList
              />
            )}
          </div>

          {!isVideo && (
            <>
              <ButtonItem divide={divide}>
                <ToolTip text="Advanced options" placement="right" arrow>
                  <AdEditorButton
                    onClick={() =>
                      setActiveTab?.(AdEditorSideBarTabs.VISUAL_OPTIONS)
                    }
                  >
                    <GearSix weight="bold" size={32} tw="p-1 text-gray-600" />
                  </AdEditorButton>
                </ToolTip>
              </ButtonItem>
              <ButtonItem divide={divide}>
                <ToolTip text="Undo changes" placement="right" arrow>
                  <AdEditorButton
                    isDisabled={svgStack.isEmpty()}
                    onClick={undoChange}
                  >
                    <ArrowUUpLeft weight="bold" size={32} tw="p-1" />
                  </AdEditorButton>
                </ToolTip>
              </ButtonItem>
            </>
          )}

          {isVideo && (
            <>
              <ButtonItem divide={divide}>
                <ToolTip text="Change video" placement="right" arrow>
                  <AdEditorButton
                    onClick={() =>
                      setActiveTab?.(AdEditorSideBarTabs.VISUAL_OPTIONS)
                    }
                  >
                    <FilmStrip
                      rotate={90}
                      weight="bold"
                      size={32}
                      tw="p-1 text-gray-600"
                    />
                  </AdEditorButton>
                </ToolTip>
              </ButtonItem>
              <ButtonItem divide={divide}>
                <ToolTip
                  text={isVideoMuted ? 'Unmute volume' : 'Mute volume'}
                  placement="right"
                  arrow
                >
                  <AdEditorButton onClick={toggleIsVideoMuted}>
                    {isVideoMuted ? (
                      <SpeakerSimpleX
                        weight="bold"
                        size={32}
                        tw="p-1 text-gray-600"
                      />
                    ) : (
                      <SpeakerSimpleHigh
                        weight="bold"
                        size={32}
                        tw="p-1 text-gray-600"
                      />
                    )}
                  </AdEditorButton>
                </ToolTip>
              </ButtonItem>
              <ButtonItem divide={divide}>
                <ToolTip
                  text={isVideoPlaying ? 'Pause video' : 'Play video'}
                  placement="right"
                  arrow
                >
                  <AdEditorButton onClick={toggleIsVideoPlaying}>
                    {isVideoPlaying ? (
                      <PauseCircle
                        weight="bold"
                        size={32}
                        tw="p-1 text-gray-600"
                      />
                    ) : (
                      <PlayCircle
                        weight="bold"
                        size={32}
                        tw="p-1 text-gray-600"
                      />
                    )}
                  </AdEditorButton>
                </ToolTip>
              </ButtonItem>
            </>
          )}

          <ButtonItem divide={divide}>
            <ToolTip text="Add text" placement="right" arrow>
              <AdEditorButton onClick={() => addCanvasElement('text')}>
                <TextT size="32" weight="bold" tw="p-1 text-gray-600" />
              </AdEditorButton>
            </ToolTip>
          </ButtonItem>

          <ButtonItem divide={divide}>
            <ToolTip text="Add a rectangle" placement="right" arrow>
              <AdEditorButton onClick={() => addCanvasElement('rectangle')}>
                <Rectangle size="32" weight="bold" tw="p-1 text-gray-600" />
              </AdEditorButton>
            </ToolTip>
          </ButtonItem>

          <ButtonItem divide={divide}>
            <ToolTip text="Duplicate variant" placement="right" arrow>
              <AdEditorButton onClick={duplicateCurrentVariant}>
                <Copy weight="bold" size={32} tw="p-1 text-gray-600" />
              </AdEditorButton>
            </ToolTip>
          </ButtonItem>
          <ButtonItem divide={divide}>
            <ToolTip text="Delete variant" placement="right" arrow>
              <AdEditorButton onClick={deleteCurrentVariant}>
                <Trash weight="bold" size={32} tw="p-1 text-gray-600" />
              </AdEditorButton>
            </ToolTip>
          </ButtonItem>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.ul<{
  orientation: ToolbarOrientation;
}>`
  ${tw`
    flex flex-row justify-between items-baseline border rounded-md shadow-md py-2
    sm:(justify-center border-0)
  `}
  ${({ orientation }) =>
    orientation === ToolbarOrientation.HORIZONTAL
      ? tw`w-fit mx-auto p-1 sm:(flex-row space-x-2)`
      : tw`px-1 sm:(flex-col space-y-2)`}
`;

const ButtonItem = styled.li<{
  divide: boolean;
}>`
  ${tw`bg-none`}
  ${({ divide }) =>
    divide &&
    tw`first-of-type:(border-r py-1 border-gray-200 sm:(border-r-0 border-b))
    last-of-type:(border-l py-1 border-gray-200 sm:(border-l-0 border-t))`}
`;

export const AdEditorButton = styled.button<{
  isActive?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}>`
  ${tw`rounded-full p-2 grow flex text-gray-600 justify-center sm:p-1 hover:bg-gray-100`}
  ${({ isActive, isDisabled }) => isActive && !isDisabled && tw`bg-gray-100`}
  ${({ isLoading }) => isLoading && tw`cursor-wait`}
  ${({ isDisabled }) =>
    isDisabled && tw`cursor-not-allowed pointer-events-none text-gray-200`}
  ${({ isLoading, isDisabled }) =>
    (isLoading || isDisabled) && tw`text-gray-300 hover:bg-white`}
`;

export default AdEditorToolbar;
