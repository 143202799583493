import React, { createContext, useState } from 'react';

export enum CandidateDetailTab {
  DETAILS = 'details',
  INTERVIEW = 'interview',
  COMMUNICATION = 'communication',
  NOTES = 'notes',
}
type CandidateContextType = {
  activeDetailTab: CandidateDetailTab;
  sidebarIsOpen: boolean;
  mailModalIsOpen: boolean;
  setSidebarIsOpen: (isOpen: boolean) => void;
  setMailModalIsOpen: (isOpen: boolean) => void;
  setActiveDetailTab: (tab: CandidateDetailTab) => void;
};

export const CandidateContext = createContext<CandidateContextType>(undefined!);

export const CandidateProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [mailModalIsOpen, setMailModalIsOpen] = useState(false);
  const [activeDetailTab, setActiveDetailTab] = useState<CandidateDetailTab>(
    CandidateDetailTab.INTERVIEW
  );

  return (
    <CandidateContext.Provider
      value={{
        activeDetailTab,
        setActiveDetailTab,
        sidebarIsOpen,
        setSidebarIsOpen,
        mailModalIsOpen,
        setMailModalIsOpen,
      }}
    >
      {children}
    </CandidateContext.Provider>
  );
};
