import React, { forwardRef } from 'react';
import tw, { theme, css, styled } from 'twin.macro';

interface CheckboxProps {
  ariaInvalid?: boolean;
  checked?: boolean;
  onCheck?: (isChecked: boolean) => void;
}

export const Checkbox = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > &
    CheckboxProps
>(({ ariaInvalid, checked, onCheck, ...props }, ref) => {
  return (
    <CustomCheckbox onClick={() => onCheck?.(!checked)}>
      <input
        {...props}
        ref={ref}
        checked={checked}
        type="checkbox"
        aria-invalid={ariaInvalid}
        onChange={() => {}}
      />
      <span />
    </CustomCheckbox>
  );
});

const CustomCheckbox = styled.div(() => [
  tw`block relative cursor-pointer`,
  css`
    font-size: 22px;
    user-select: none;

    width: 25px;
    height: 25px;

    &:hover input ~ span {
      background-color: ${theme`colors.white`};
    }

    input:checked ~ span:after {
      display: block;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    span {
      position: absolute;
      border-radius: 5px;
      border: 2px solid ${theme`colors.gray.200`};
      left: 0;
      height: 25px;
      width: 25px;
      background-color: ${theme`colors.gray.50`};

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 8px;
        top: 3px;
        width: 6px;
        height: 12px;
        border: solid ${theme`colors.gray.500`};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  `,
]);

export default Checkbox;
