import React, { useEffect, useMemo, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { Routes } from 'types/routeTypes';
import MenuLink from '../menu-link/MenuLink';
import { fireEvent } from 'utils/eventHelper';
import CreateCampaignButton from './CreateCampaignButton';
import { useLogout } from 'hooks/session';
import useGetStartedContext from 'hooks/context/get-started-context';
import useNavigationContext from 'hooks/context/nav-context';
import Button from 'components/button/Button';
import { Vacancy } from 'types/vacancyTypes';
import { Modal } from 'components/page/app-page/Modal';
import Budget, { PaymentMethod } from 'components/payment/Budget';
import GeneralSettings from 'views/settings/general/GeneralSettings';
import { useMutation } from 'hooks/sympl-mutation';
import { UPDATE_BUDGET_SUBSCRIPTION } from 'graphql/subscriptions/mutations';
import { redirectToStripeCheckout } from 'utils/paymentHelpers';
import { addressValidationHandler } from 'utils/addressHelpers';
import { PUBLISH_VACANCY } from 'graphql/vacancies/mutations';
import { getMenuGroups, Link } from 'data/menuGroups';
import { ArrowRight } from '@phosphor-icons/react';
import { daysLeft } from 'utils/subscriptions/helpers';
import { useToastNotifications } from 'hooks/notificationHooks';
import { ToastTypes } from 'types/notificationTypes';
import { useNavigate, Link as ReactLink } from 'react-router-dom';
import ApiDropdown from 'components/dropdown/api-dropdown/ApiDropdown';
import usePermissionContext from 'hooks/context/permission-context';
import ToolTip from 'components/tooltip/ToolTip';
import PermissionWrapper from 'components/permissions/permissionWrapper/PermissionWrapper';
import { PERMISSIONS } from 'context/PermissionContext';
import { useActiveFeatureFlags } from 'posthog-js/react';

const STATUS_SYNCED = 'synced';

const Menu: React.FC<{
  isOpen?: boolean;
  isMobile: boolean;
}> = ({ isOpen, isMobile }) => {
  const navigate = useNavigate();

  const { logout } = useLogout();

  const {
    activeVacancy,
    customer,
    refetchCustomer,
    currentVacancy,
    vacIsPublished,
    vacSpendEnabled,
    setActiveVacancy,
    hasVacancies,
    hasPublishedVacancy,
    hasReachedVacsLimit,
    hasSymplVacancyPage,
    subscription,
  } = useNavigationContext();

  const { userHasPermission } = usePermissionContext();

  const { checklist } = useGetStartedContext();
  const { addToast } = useToastNotifications();

  const menuItemClickHandler = async (link: Link) => {
    if (!currentVacancy?.published)
      fireEvent('ss_roam_' + link.to.substr(1).replace('-', '_'));

    if (link.label === 'Logout') logout();
  };

  const dropdownChangeHandler = (key: number) => {
    setActiveVacancy({
      vacancyId: key,
    });
  };

  const createVacancyHandler = (vacancyId: number) => {
    setActiveVacancy({ vacancyId });
    navigate(Routes.GETTING_STARTED);
  };

  // Keep the current vacancy label in state to avoid having no label when changing vacancies
  const [vacancyLabel, setVacancyLabel] = useState<string>('');

  useEffect(() => {
    if (currentVacancy && currentVacancy?.label)
      setVacancyLabel(currentVacancy.label);
  }, [currentVacancy]);

  const readyToPublish = !checklist.filter(({ completed }) => !completed)
    .length;

  const [modalStep, setModalStep] = useState<'address' | 'budget'>();

  const [checkout] = useMutation<
    {
      updateBudgetSubscription: {
        session_id?: string;
      };
    },
    {
      vacancyId: number;
      input: {
        budget: number;
        payment_method?: PaymentMethod;
        use_balance?: boolean;
      };
    }
  >(UPDATE_BUDGET_SUBSCRIPTION);
  const [publish] = useMutation(PUBLISH_VACANCY);

  const isCompanyDetailsValid = useMemo(() => {
    return (
      customer &&
      !!customer.invoice_address &&
      addressValidationHandler(true, customer.invoice_address) &&
      !!customer.legal_form
    );
  }, [customer]);

  // TODO: merge into publishHandler
  const setBudget = async (
    budget: number,
    paymentMethod?: PaymentMethod,
    useBalance?: boolean
  ) => {
    const { data } = await checkout({
      variables: {
        vacancyId: activeVacancy ?? 0,
        input: {
          budget,
          payment_method: paymentMethod,
          use_balance: useBalance,
        },
      },
    });

    const sessionId = data?.updateBudgetSubscription.session_id;

    if (sessionId) {
      fireEvent('ss_stripe_checkout');

      await redirectToStripeCheckout(sessionId);
    } else {
      postPublishHandler();
    }
  };

  const hasCustomFacebookPage = useMemo(() => {
    return !!currentVacancy?.brand?.default_facebook_page_id;
  }, [currentVacancy]);

  const facebookPageVerified = useMemo(() => {
    return currentVacancy?.brand?.facebookPage?.status === STATUS_SYNCED;
  }, []);

  const vacSpendEnabledWithoutBudget =
    vacSpendEnabled && !currentVacancy?.budget;

  const publishable = isCompanyDetailsValid && !vacSpendEnabledWithoutBudget;

  const onPublishButtonClicked = () => {
    if (subscription?.is_trial) {
      navigate(Routes.SUBSCRIPTION_SETTINGS);
      addToast({
        description: 'Start a subscription to publish your campaign',
        type: ToastTypes.WARNING,
      });
      return;
    }

    if (publishable) {
      publishHandler();
    } else {
      if (!isCompanyDetailsValid) {
        setModalStep('address');
      } else if (vacSpendEnabledWithoutBudget) {
        setModalStep('budget');
      } else {
        alert('Something went wrong. Please try again.');
      }
    }
  };

  const addressUpdateHandler = () => {
    refetchCustomer().then(() => {
      if (vacSpendEnabledWithoutBudget) {
        setModalStep('budget');
      } else {
        publishHandler();
      }
    });
  };

  const publishHandler = () => {
    publish({
      variables: {
        vacancyId: activeVacancy,
        input: {},
      },
    }).then(() => {
      postPublishHandler();
    });
  };

  const postPublishHandler = () => {
    setModalStep(undefined);
    navigate(Routes.CAMPAIGN_PUBLISHED);
  };

  const featureFlags = useActiveFeatureFlags();

  const menuGroups = useMemo(
    () =>
      getMenuGroups(
        window.location.pathname,
        checklist,
        hasPublishedVacancy,
        vacIsPublished,
        vacSpendEnabled,
        hasSymplVacancyPage,
        featureFlags ?? []
      ),
    [checklist, hasPublishedVacancy, vacIsPublished, customer]
  );

  const renderAddressModalStep = () => (
    <GeneralSettings
      saveLabel="Continue"
      onSave={addressUpdateHandler}
      minimal={true}
    />
  );

  const renderBudgetModalStep = () => (
    <Budget
      title="Choose your advertising budget"
      subtitle={
        <div tw="text-sm leading-5 text-gray-500">
          <p>
            Approx. 50 euros will be spent per day. You can control your budget
            at any time during the campaign.
          </p>
        </div>
      }
      cta="Publish"
      updateBudget={false}
      onSubmit={(budget, paymentMethod, useBalance) => {
        setBudget(budget, paymentMethod, useBalance);
      }}
    />
  );

  const renderCampaignsToolbar = () => (
    <PermissionWrapper permission={PERMISSIONS.MANAGE_CAMPAIGNS} on="brand">
      <div tw="flex flex-row items-center">
        <CreateCampaignButton
          onCampaignCreate={createVacancyHandler}
          buttonText="Create campaign"
        />
      </div>
    </PermissionWrapper>
  );

  const showCampaignSetupButtons = activeVacancy && !vacIsPublished;
  const renderCampaignSetupButtons = () => (
    <div tw="px-4 flex flex-col items-center">
      <div tw="w-full pt-3 relative">
        <Button
          id="menu-campaign-publish"
          disabled={
            !readyToPublish ||
            hasReachedVacsLimit ||
            (hasCustomFacebookPage && !facebookPageVerified)
          }
          onClick={onPublishButtonClicked}
          stretch
          tooltip={
            hasCustomFacebookPage && !facebookPageVerified
              ? {
                  text: `Please verify your Facebook page for the brand "${currentVacancy?.brand?.name}" before publishing your campaign`,
                  placement: 'right',
                  arrow: true,
                }
              : undefined
          }
        >
          Publish Campaign
        </Button>
      </div>
      <span
        onClick={() => navigate(Routes.GETTING_STARTED)}
        tw="text-xs text-gray-400 font-medium mt-2 cursor-pointer"
      >
        Need help?
      </span>
    </div>
  );

  const renderTrialWidget = () =>
    subscription?.is_trial ? (
      <ReactLink
        to={Routes.SUBSCRIPTION_SETTINGS}
        tw="flex flex-row items-center justify-center mx-4 ring-2 ring-indigo-400 text-indigo-500 font-medium text-sm rounded-md py-1.5 text-center gap-2"
      >
        {daysLeft(subscription)} left in trial
        <ArrowRight weight="bold" />
      </ReactLink>
    ) : null;

  const renderMenu = () => (
    <div tw="flex flex-col flex-auto w-full sm:h-full">
      {menuGroups
        .filter(({ visible = true }) => visible)
        .map(({ label, links, hideLabel }) => (
          <MenuGroup key={label}>
            <>
              <div tw="flex flex-row justify-between items-center">
                {!hideLabel && <GroupLabel tw="ml-4">{label}</GroupLabel>}
                {label === 'campaigns' && renderCampaignsToolbar()}
              </div>

              {label === 'campaigns' && hasVacancies && (
                <div tw="pt-4 px-2">
                  <ApiDropdown
                    type="vacancies"
                    enableSearch
                    value={currentVacancy?.label ?? ''}
                    placeholder={vacancyLabel}
                    mode="key"
                    onChange={(item) => {
                      const newVacancyData = item as Vacancy;
                      if (newVacancyData?.key)
                        dropdownChangeHandler(newVacancyData?.key);
                    }}
                    customStyle={tw`w-[240px] overflow-x-hidden`}
                  />
                </div>
              )}
              {label === 'other' && renderTrialWidget()}
              <ul tw="flex flex-col">
                {links
                  .filter(({ visible = true }) => visible)
                  .map((link, key) => {
                    const isAllowed = !!(
                      Array.isArray(link.allowedPermissions) &&
                      !!link.allowedPermissions.length &&
                      userHasPermission(
                        link.allowedPermissions,
                        link.requiredPermissionLevel
                      )
                    );
                    const menuLinkProps = {
                      icon: link.icon,
                      isActive: link.isActive,
                      isCampaign: label === 'campaigns',
                      isDisabled:
                        Array.isArray(link.allowedPermissions) &&
                        !!link.allowedPermissions.length &&
                        !isAllowed,
                      isCompleted: link.isFinished,
                      label: link.label,
                      to: {
                        pathname: link.to,
                        search: window.location.search,
                      },
                    };
                    return (
                      <LinkItem
                        key={`${link.to}-${key}`}
                        onClick={() => menuItemClickHandler(link)}
                      >
                        {Array.isArray(link.allowedPermissions) &&
                        !isAllowed ? (
                          <ToolTip
                            // TODO: Change tooltip text - 18/12/2024
                            text="You don't have permission to access this page"
                            placement="right"
                            arrow
                          >
                            <div className="w-full">
                              <MenuLink {...menuLinkProps} />
                            </div>
                          </ToolTip>
                        ) : (
                          <MenuLink {...menuLinkProps} />
                        )}
                      </LinkItem>
                    );
                  })}

                {label === 'campaigns' &&
                  showCampaignSetupButtons &&
                  renderCampaignSetupButtons()}
              </ul>
            </>
          </MenuGroup>
        ))}
    </div>
  );

  return (
    <>
      <Modal show={!!modalStep} onClose={() => setModalStep(undefined)}>
        {modalStep === 'address' && renderAddressModalStep()}
        {modalStep === 'budget' && renderBudgetModalStep()}
      </Modal>
      {(!isMobile || (isMobile && isOpen)) && renderMenu()}
    </>
  );
};

const LinkItem = styled.li(tw`flex items-center mt-2 px-2`);

const MenuGroup = styled.div(
  tw`border-t-2 border-gray-100 py-4 flex-none first-of-type:border-t-0 last-of-type:mt-auto`
);

const GroupLabel = styled.p(() => [
  tw`
    text-xs tracking-tighter font-semibold text-gray-400 uppercase
  `,
]);

export default Menu;
