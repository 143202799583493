import React, { useState } from 'react';
import { CheckSquare, Square } from '@phosphor-icons/react';
import tw, { styled } from 'twin.macro';

interface CheckboxProps {
  label?: string;
  value?: boolean;
  color?: string;
  size?: number;
  disabled?: boolean;
  onChange?: (value: boolean) => unknown;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  value = false,
  color = 'currentColor',
  size = 16,
  disabled = false,
  onChange,
}) => {
  const [checked, setChecked] = useState(value);

  const update = (value: boolean) => {
    if (disabled) return;
    setChecked(value);
    onChange?.(value);
  };

  return (
    <CheckboxWrapper disabled={disabled} onClick={() => update(!checked)}>
      <Box>
        {checked ? (
          <CheckSquare weight="bold" size={size} color={color} />
        ) : (
          <Square weight="bold" size={size} />
        )}
      </Box>
      <div>{label}</div>
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled.div<{
  disabled: boolean;
}>`
  ${tw`flex flex-row items-center cursor-pointer text-gray-800`}

  ${({ disabled }) => disabled && tw`text-gray-500 cursor-not-allowed`}
`;

const Box = styled.div(() => [tw`mr-2`]);

export default Checkbox;
