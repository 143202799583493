import tw, { styled } from 'twin.macro';
import React, { useMemo, useState, useRef, useEffect } from 'react';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import { AD_PLACEMENTS, AdCreativeType } from 'types/adTypes';
import { getVariantIcon } from 'utils/adEditorHelpers';
import ToolTip from 'components/tooltip/ToolTip';
import { Warning, CaretDown, CaretUp, Plus } from '@phosphor-icons/react';
import { AdVariant } from 'types/adEditorTypes';

const AdEditorNavBar: React.FC = () => {
  const {
    setCurrentVariant,
    variants,
    addVariantForPlacement,
    currentVariant,
  } = useAdEditorContext();

  const [showMobileNavBar, toggleMobileNavBar] = useState(false);
  const newVariantRef = useRef<HTMLButtonElement | null>(null);

  const toggleHandler = () => toggleMobileNavBar(!showMobileNavBar);

  const nonDeletedVariants = useMemo(
    () => variants.filter(({ isDeleted }) => !isDeleted),
    [variants]
  );

  useEffect(() => {
    newVariantRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [currentVariant]);

  return (
    <AdVariantNavbarWrapper>
      <VariantOverview toggleNavbar={showMobileNavBar}>
        {AD_PLACEMENTS.map((placement) => {
          const placementVariants = nonDeletedVariants.filter(
            (variant) => variant.placement === placement
          );

          return (
            <PlacementSection key={placement} id={`ad-placement-${placement}`}>
              <SectionHeader>
                <p>
                  {placement.toUpperCase()}
                  {placementVariants.length > 6 && (
                    <ToolTipWrapper>
                      <ToolTip
                        text="We suggest using max. 6 variants."
                        placement="right"
                        arrow
                      >
                        <Warning weight="bold" size={17} />
                      </ToolTip>
                    </ToolTipWrapper>
                  )}
                </p>
                <AddVariantButton
                  type="button"
                  onClick={() => {
                    addVariantForPlacement(placement);
                    toggleHandler();
                  }}
                  id={`add-variant-button-${placement}`}
                >
                  <Plus weight="bold" size={20} />
                </AddVariantButton>
              </SectionHeader>
              <VariantsGrid id={`${placement}-variants`}>
                {placementVariants.map((variant) => (
                  <VariantTabButton
                    key={variant.uuid}
                    ref={
                      variant.uuid === currentVariant?.uuid
                        ? newVariantRef
                        : null
                    }
                    isSelected={variant.uuid === currentVariant?.uuid}
                    onClick={() => {
                      setCurrentVariant(variant);
                      toggleHandler();
                    }}
                  >
                    <VariantIcon variant={variant} />
                  </VariantTabButton>
                ))}
              </VariantsGrid>
            </PlacementSection>
          );
        })}
      </VariantOverview>
      <ToggleSelectNavButton onClick={toggleHandler}>
        {!showMobileNavBar ? (
          <CaretDown weight="bold" tw="mt-1" size={36} />
        ) : (
          <CaretUp weight="bold" tw="mt-1" size={36} />
        )}
      </ToggleSelectNavButton>
    </AdVariantNavbarWrapper>
  );
};

const VariantIcon: React.FC<{ variant: AdVariant }> = ({ variant }) => {
  // TODO: tweak h-16 w-16 to match canvasWidth / canvasHeight ratio
  return (
    <picture tw="[svg]:(h-24 w-auto)">
      {variant.svg ? (
        <div dangerouslySetInnerHTML={{ __html: variant.svg }} />
      ) : variant.path?.thumbnail_path ? (
        <img
          src={variant.path.thumbnail_path}
          css={
            variant.placement === 'feed'
              ? tw`h-24 w-24 object-cover`
              : tw`h-24 w-14 object-cover`
          } // Adjust styles as needed
        />
      ) : (
        getVariantIcon(
          variant.placement,
          variant.creative_type ?? AdCreativeType.IMAGE
        )
      )}
    </picture>
  );
};

const AdVariantNavbarWrapper = styled.aside`
  ${tw`w-full relative left-0 z-20 bg-white h-auto -top-2 lg:(top-0 h-full min-w-[150px] max-w-[250px])`}
  ${tw`grid grid-rows-[auto 1fr] lg:(border-none border-r border-r-gray-200)`}
`;

const VariantOverview = styled.div<{ toggleNavbar?: boolean }>`
  ${tw`grid grid-rows-[repeat(3, 1fr)] pb-0 overflow-y-hidden`}
  ${({ toggleNavbar }) => !toggleNavbar && tw`hidden lg:grid`}
`;

const PlacementSection = styled.div`
  ${tw`flex flex-col overflow-y-auto h-full`}
`;

const SectionHeader = styled.div`
  ${tw`lg:(sticky top-0) flex justify-between text-sm font-medium text-gray-500 items-center border-b bg-gray-50 w-full px-6 py-1`}
`;

const AddVariantButton = styled.button`
  ${tw`p-2 -mr-3 hover:text-black`}
`;

const VariantsGrid = styled.div`
  ${tw`grid grid-cols-1 xl:grid-cols-2 gap-4 overflow-y-auto p-4 place-items-center`}
`;

const VariantTabButton = styled.button<{ isSelected: boolean }>`
  ${tw`flex items-center justify-center cursor-pointer`}
  ${({ isSelected }) => isSelected && tw`ring-[3px] ring-indigo-500`}
`;

const ToolTipWrapper = styled.div`
  ${tw`relative left-1 top-0.5 inline-block`}
`;

const ToggleSelectNavButton = styled.button`
  ${tw`flex justify-center text-gray-500 lg:(hidden flex-none order-none) flex-auto items-center order-1`}
`;

export default AdEditorNavBar;
