import tw, { styled } from 'twin.macro';
import ReactCrop from 'react-image-crop';
import { Crop, PercentCrop } from 'react-image-crop/dist/types';
import 'react-image-crop/dist/ReactCrop.css';
import React, { useEffect, useState } from 'react';

import { Error } from 'components/typography/Typography';

interface AdEditorImageCropperProps {
  uuid: string;
  image: string;
  crop: Crop | null;
  aspect?: number;
  onCrop?: (crop: Crop | null) => void;
}

const AdEditorImageCropper: React.FC<AdEditorImageCropperProps> = ({
  uuid,
  image,
  crop,
  onCrop,
  aspect = 1,
}) => {
  const [tempCrop, setTempCrop] = useState<Crop>({
    ...(crop as Crop),
    unit: '%',
  });

  const [hasErrors] = useState(false);

  useEffect(
    () => setTempCrop({ ...(crop as Crop), unit: crop?.unit! }),
    [uuid]
  );

  const cropHandler = (_crop: Crop, percentCrop: PercentCrop) => {
    onCrop?.({
      unit: '%',
      x: percentCrop?.x ?? 0,
      y: percentCrop?.y ?? 0,
      width: percentCrop?.width ?? 100,
      height: percentCrop?.height ?? 100,
    });
  };

  return (
    <>
      <CropperWrapper>
        <ReactCrop
          crop={tempCrop}
          aspect={aspect}
          ruleOfThirds={true}
          onChange={(_cr: Crop, percentCrop: PercentCrop) => {
            if (_cr.width !== 0 || _cr.height !== 0) setTempCrop(percentCrop);
          }}
          onComplete={cropHandler}
        >
          <img src={image} alt={image} />
        </ReactCrop>
        {hasErrors && (
          <div tw="mt-3">
            <Error>
              {`Something went wrong while uploading your cropped image, please
              try again.`}
            </Error>
          </div>
        )}
      </CropperWrapper>
    </>
  );
};

const CropperWrapper = styled.div`
  ${tw`flex flex-col object-contain w-fit h-auto max-w-[90vw] max-h-[80vw] lg:(max-w-[400px] max-h-[none])`}
`;

export default AdEditorImageCropper;
