import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import tw, { styled } from 'twin.macro';

import { Error } from 'components/typography/Typography';
import Input from '../input/Input';
import Button from 'components/button/Button';

interface RegisterFormProps {
  loading?: boolean;
  onSubmit?: (data: RegisterFormData) => void;
  buttonLabel: string;
  searchParams?: Map<never, string>;
}

export interface RegisterFormData {
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  password: string;
  phone: string;
  url?: string;
}

type RegisterFormId =
  | 'register-company'
  | 'register-password'
  | 'register-email'
  | 'register-first-name'
  | 'register-last-name'
  | 'register-phone'
  | 'register-vacancies'
  | 'register-url';

const RegisterForm: React.FC<RegisterFormProps> = ({
  loading,
  onSubmit,
  buttonLabel,
  searchParams,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isAiEnabled = searchParams?.get('enable' as never) === 'ai';

  const submitHandler = (data: {
    [K in RegisterFormId]?: string | number;
  }) => {
    onSubmit?.({
      company: data['register-company']?.toString() ?? '',
      email: data['register-email']?.toString() ?? '',
      password: data['register-password']?.toString() ?? '',
      firstName: data['register-first-name']?.toString() ?? '',
      lastName: data['register-last-name']?.toString() ?? '',
      phone: data['register-phone']?.toString() ?? '',
      ...(isAiEnabled && { url: data['register-url']?.toString() ?? '' }),
    });
  };

  const isPersonalEmail = (email: string) => {
    return [
      'hotmail',
      'yahoo',
      'icloud',
      'gmail',
      'outlook',
      'aol',
      'yandex',
      'zohomail',
      'protonmail',
      'mailfence',
    ].find((item) => email.includes(item));
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <div tw="w-full">
        <div>
          <Label htmlFor="register-email">Email</Label>
          <div tw="mt-2">
            <Controller
              id="register-email"
              name="register-email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                validate: isPersonalEmail,
              }}
              render={({ onChange, value }) => (
                <Input
                  type="email"
                  id="register-email"
                  name="register-email"
                  autoComplete="email"
                  autoFocus
                  value={value}
                  placeholder="joey.tribbiani@daysofourlives.com"
                  ariaInvalid={errors['register-email'] !== undefined}
                  onChange={(e) => onChange(e)}
                  onBlur={(e) => onChange(e)}
                />
              )}
            />
          </div>
          {errors['register-email'] &&
            errors['register-email'].type === 'validate' && (
              <Error>{'Please use your work email'}</Error>
            )}
          {errors['register-email'] &&
            errors['register-email'].type === 'required' && (
              <Error>{'This field is required'}</Error>
            )}
        </div>
      </div>
      <div tw="flex flex-col md:(grid grid-cols-2 gap-4) mt-5">
        <div>
          <Label htmlFor="register-first-name">First name</Label>
          <div tw="mt-2">
            <Controller
              id="register-first-name"
              name="register-first-name"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Input
                  id="register-first-name"
                  name="register-first-name"
                  placeholder="Joey"
                  autoComplete="given-name"
                  value={value}
                  ariaInvalid={errors['register-first-name'] !== undefined}
                  onChange={(e) => onChange(e)}
                  onBlur={(e) => onChange(e)}
                />
              )}
            />
          </div>
          {errors['register-first-name'] !== undefined && (
            <Error>{'This field is required'}</Error>
          )}
        </div>
        <div tw="mt-5 md:(mt-0)">
          <Label htmlFor="register-last-name">Last name</Label>
          <div tw="mt-2">
            <Controller
              id="register-last-name"
              name="register-last-name"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Input
                  id="register-last-name"
                  name="register-last-name"
                  placeholder="Tribbiani"
                  autoComplete="family-name"
                  value={value}
                  ariaInvalid={errors['register-last-name'] !== undefined}
                  onChange={(e) => onChange(e)}
                  onBlur={(e) => onChange(e)}
                />
              )}
            />
          </div>
          {errors['register-last-name'] !== undefined && (
            <Error>{'This field is required'}</Error>
          )}
        </div>
      </div>
      <div tw="mt-5">
        <div>
          <Label htmlFor="register-phone">Phone Number</Label>
          <div tw="mt-2">
            <Controller
              id="register-phone"
              name="register-phone"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Input
                  id="register-phone"
                  name="register-phone"
                  autoComplete="tel"
                  value={value}
                  ariaInvalid={errors['register-phone'] !== undefined}
                  onChange={(e) => onChange(e)}
                  onBlur={(e) => onChange(e)}
                />
              )}
            />
          </div>
          {errors['register-phone'] !== undefined && (
            <Error>{'This field is required'}</Error>
          )}
        </div>
      </div>
      <div tw="mt-5">
        <div>
          <Label htmlFor="register-company">Company</Label>
          <div tw="mt-2">
            <Controller
              id="register-company"
              name="register-company"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Input
                  id="register-company"
                  name="register-company"
                  value={value}
                  ariaInvalid={errors['register-company'] !== undefined}
                  onChange={(e) => onChange(e)}
                  onBlur={(e) => onChange(e)}
                />
              )}
            />
          </div>
          {errors['register-company'] !== undefined && (
            <Error>{'This field is required'}</Error>
          )}
        </div>
      </div>
      <div tw="w-full mt-5">
        <div>
          <Label htmlFor="register-password">Choose a password</Label>
          <span tw="block text-sm text-gray-400 max-w-md">
            Special characters, capital letters and numbers make your password
            extra secure.
          </span>
          <div tw="mt-2">
            <Controller
              id="register-password"
              name="register-password"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: /^(?=.*[0-9])(?=.*[\W])(?=.*[A-Z])[a-zA-Z0-9\W]{8,}$/,
              }}
              render={({ onChange, value }) => (
                <Input
                  type="password"
                  id="register-password"
                  name="register-password"
                  autoComplete="new-password"
                  value={value}
                  ariaInvalid={errors['register-password'] !== undefined}
                  onChange={(e) => onChange(e)}
                  onBlur={(e) => onChange(e)}
                />
              )}
            />
          </div>
          {errors['register-password'] !== undefined && (
            <Error>
              {`
                Password must contain one uppercase character, one special
                character, one digit and be at least 8
                characters long
                `}
            </Error>
          )}
        </div>
      </div>
      {isAiEnabled && (
        <div tw="mt-5">
          <div>
            <Label htmlFor="register-url">
              <span>Website Url</span>
              <span tw="mt-2 ml-[1ch] text-[10px] text-gray-500 uppercase leading-none">
                (Optional)
              </span>
            </Label>
            <div tw="mt-2">
              <Controller
                id="register-url"
                name="register-url"
                control={control}
                defaultValue=""
                render={({ onChange, value }) => (
                  <Input
                    id="register-url"
                    name="register-url"
                    value={value}
                    ariaInvalid={errors['register-url'] !== undefined}
                    onChange={(e) => onChange(e)}
                    onBlur={(e) => onChange(e)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
      <div tw="flex w-full justify-end mt-6 2xl:mt-14">
        <Button type="submit" disabled={loading} loading={loading} stretch>
          {buttonLabel}
        </Button>
      </div>
    </Form>
  );
};

const Form = styled.form(tw`mt-5 w-full min-w-[300px] max-w-[500px]`);

const Label = styled.label(tw`font-medium leading-5 text-gray-600`);

export default RegisterForm;
