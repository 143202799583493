import React, { useMemo, useState } from 'react';
import tw, { styled } from 'twin.macro';

import AppPage from 'components/page/app-page/AppPage';
import { groupBy } from 'utils/baseHelpers';
import { fireEvent } from 'utils/eventHelper';
import Img from 'components/image/Image';
import Tabs from 'components/tabs/Tabs';
import { Title1 } from 'components/typography/Typography';
import Button from 'components/button/Button';

interface Resource {
  type: string;
  name: string;
  thumbnail: string;
  description: string;
  url: string;
  year?: number;
  buttonLabel?: string;
  secondButtonLabel?: string;
  secondButtonUrl?: string;
}

const RESOURCES: Resource[] = [
  // ONBOARDINGS
  {
    type: 'onboarding',
    name: 'Tutorial #6 - Managing Candidates',
    thumbnail:
      'https://a.storyblok.com/f/137496/1721x1216/11868696aa/sympl-onboarding-thumbnail.png',
    description:
      'In this video you will learn about the options in the Candidates menu.',
    url: 'https://youtu.be/XtQVcFHivs8',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'onboarding',
    name: 'Tutorial #5 - Publish Campaign',
    thumbnail:
      'https://a.storyblok.com/f/137496/1721x1216/11868696aa/sympl-onboarding-thumbnail.png',
    description:
      'This video explains how publish your campaign and how to add budget,',
    url: 'https://youtu.be/EGeK0hV3hLk',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'onboarding',
    name: 'Tutorial #4 - Application Form',
    thumbnail:
      'https://a.storyblok.com/f/137496/1721x1216/11868696aa/sympl-onboarding-thumbnail.png',
    description:
      'This explainer video shows how to create and adjust the Application Form.',
    url: 'https://youtu.be/qehHO38wZo0',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'onboarding',
    name: 'Tutorial #3 - Vacancy Page',
    thumbnail:
      'https://a.storyblok.com/f/137496/1721x1216/11868696aa/sympl-onboarding-thumbnail.png',
    description:
      "This explainer video shows how to create and update your campaign's Vacancy Page.",
    url: 'https://youtu.be/a-BCRfLNVl4',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'onboarding',
    name: 'Tutorial #2 - Advertising',
    thumbnail:
      'https://a.storyblok.com/f/137496/1721x1216/11868696aa/sympl-onboarding-thumbnail.png',
    description:
      'This explainer video shows how to create and update the ads of your campaign.',
    url: 'https://youtu.be/VXv7GEJXJ-0',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'onboarding',
    name: 'Tutorial #1 - Targeting',
    thumbnail:
      'https://a.storyblok.com/f/137496/1721x1216/11868696aa/sympl-onboarding-thumbnail.png',
    description:
      'This explainer video shows how to create a new campaign and how to set the Targeting.',
    url: 'https://youtu.be/iuKOeaukw_A',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'onboarding',
    name: 'sympl onboarding tutorial',
    thumbnail:
      'https://a.storyblok.com/f/137496/3340x2920/ef555ef856/academy.png',
    description: `In this video, we guide you through the <b>onboarding process</b> for sympl software. Additionally, we provide an <b>introduction to its various features</b>, aiming to assist you in <b>initiating your first recruitment campaign</b>.`,
    url: 'https://komododecks.com/recordings/ovmJLNTE2ZE2q2SVRmGj?onlyRecording=1',
    buttonLabel: 'Watch now!',
    secondButtonLabel: 'Download ',
    secondButtonUrl:
      'https://a.storyblok.com/f/137496/x/3f8019524c/sympl-onboarding-guide.pdf',
  },
  {
    type: 'onboarding',
    name: 'Social Media Checklist',
    thumbnail:
      'https://a.storyblok.com/f/137496/1456x972/26ffdac38d/social-media-checklist-thumbnail.png',
    description: `When setting up a <b>recruitment campaign</b> for <b>social media</b>, you may wonder how to set your <b>targeting</b> and what your <b>advertising</b> should look like. This <b>checklist</b> is all about <b>creating a successful social media campaign</b>.`,
    url: 'https://a.storyblok.com/f/137496/x/5422b2a351/social-media-checklist.pdf',
    buttonLabel: 'Download',
  },
  // WEBINARS
  {
    type: 'webinars',
    year: 2025,
    name: `Paypal's global recruitment marketing secrets`,
    thumbnail:
      'https://a.storyblok.com/f/137496/1920x1080/c6bc62221d/webinar-paypal-visual.jpg',
    description: `Call us crazy, but we are giving away PayPal's recruitment marketing strategy! PayPal, as in the company that changed the way the world moves money? Yes and best of all, they also have done the same with recruitment marketing. <br /><br /> In this webinar, you'll get the unfiltered truth about attracting top talent globally from one of the biggest players in the game. Noa Ferber, Global Talent Attraction Manager at PayPal, will reveal how they're transforming talent acquisition. Unlock PayPal's hiring playbook, discover it here!`,
    url: 'https://youtu.be/MH3QvcB1h4Q?si=EXTawedD7nrxAii5',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2024,
    name: `Must watch: Cargill's blueprint for global marketing revealed! `,
    thumbnail:
      'https://a.storyblok.com/f/137496/447x252/7d8b78feeb/global-recruitment-with-cargill.png ',
    description: `In this webinar, Danielle Trawick, Associate Director for Global Employer Branding at Cargill, will take you on a deep dive into the world of recruitment strategy and share how one of the world's largest food companies approaches this challenge. <br /><br /> With over 19 years of international experience, she will provide actionable tips to help you create meaningful impact in your recruitment marketing.`,
    url: 'https://www.youtube.com/watch?v=CQW7a_ZNUoA',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2024,
    name: 'Recruitment Marketing in 2025',
    thumbnail:
      'https://a.storyblok.com/f/137496/1920x1080/74456947ae/recruitment-marketing-2025.png',
    description: `This webinar delves into the biggest trends for recruitment marketing in 2025, highlighting strategies to strengthen your employer brand, accelerate hiring with AI, and enhance candidate experience as a key advantage. Learn how to future-proof your recruitment efforts and attract top talent in a rapidly evolving market!`,
    url: 'https://youtu.be/7P0Stc5Jd-8',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2024,
    name: 'Big changes on Indeed! Are you ready?',
    thumbnail:
      'https://a.storyblok.com/f/137496/1920x1080/b05e2c7e3a/li-live.png/m/1000x563/smart/filters:quality(75)',
    description: `Did you know Indeed is changing? Discover how these latest changes are set to revolutionise your online recruitment strategy. In this session, sympl's marketing experts dive deep into everything new on Indeed for paid and organic campaigns. In just 30 minutes, discover how these updates affect your online recruitment strategy and how to stay ahead!`,
    url: 'https://youtu.be/1zntJ5uOgZk',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2024,
    name: 'How to set up your first social media recruitment campaign in 30 minutes',
    thumbnail:
      'https://a.storyblok.com/f/137496/1920x1080/4555739f07/workshop-set-up-your-first-social-media-recruitment-campaign-in-30-minutes.png',
    description: `This webinar explores how to launch <b>social media recruitment</b> campaigns in <b>just 30 minutes</b>. Learn why traditional job boards fall short and how platforms like Facebook, Instagram, and TikTok can help you reach great candidates. Gain <b>practical tips</b> to optimise your campaigns for <b>maximum impact!</b>`,
    url: 'https://youtu.be/e_Ry8_rAX98',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2024,
    name: 'Stop recruiting on LinkedIn',
    thumbnail:
      'https://a.storyblok.com/f/137496/1920x1080/e93a820a40/visual-webinar-stop-li.jpg',
    description: `Are you still recruiting on <b>LinkedIn</b>? In this webinar, our digital experts explain why you should stop! Discover why LinkedIn is <b>no longer the golden ticket</b> for finding talent and explore the <b>untapped potential of Facebook and Instagram</b>.`,
    url: 'https://youtu.be/FMNfnLFd9Rs',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2024,
    name: '100 golden tips to recruit on social media',
    thumbnail:
      'https://a.storyblok.com/f/137496/1920x1080/9e6227db3a/webinar-100-golden-tips.png',
    description: `Wanna know the best tips to recruit on social media? In this webinar, our experts share invaluable insights based on 9.000 recruitment campaigns in the past 5 years. Boost your recruitment efforts on Facebook, Instagram and TikTok!`,
    url: 'https://youtu.be/U8Bgm5Z1rlc',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2023,
    name: 'Effective employer branding in the social media era',
    thumbnail:
      'https://a.storyblok.com/f/137496/1920x1080/66aafa7fe5/webinar-effective-employer-branding-in-the-social-media-era.png',
    description: `
    Looking for new ways to make your employer branding even more effective on social media? Watch this webinar with our special guest Hung Lee — the recruitment and employer branding expert. <br />
    In this webinar, you'll get a deep dive into the latest employer branding trends and strategies. And we'll guide you on how to execute employer branding on varied social media platforms.`,
    url: 'https://youtu.be/15Ddpr-8KIA',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2023,
    name: 'How to build your employer brand through social media',
    thumbnail:
      'https://a.storyblok.com/f/137496/1920x1005/41055f8d3e/how-to-build-employer-brand.png',
    description: `
    Ready to unleash the power of social media for employer branding?
    Together <b>with James Ellis</b> - the leading authority on employer branding, we revealed <b>strategies</b> and <b>tactics</b> to build a <b>powerful employer brand</b> on <b>social media</b>. During this webinar you'll discover why you need employer branding and how you leverage social media in this process.`,
    url: 'https://youtu.be/VBSdaRaDF7M',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2023,
    name: 'Online recruiting during summer',
    thumbnail:
      'https://a.storyblok.com/f/137496/1920x1080/d470488255/online-recruiting-summer.png',
    description: `
    Want to discover the secrets of <b>summer recruiting</b>? Watch this!
    We'll decode the mysteries of summer job-hopping and how to <b>leverage</b> that for <b>recruiting</b>. You'll also learn how to <b>launch campaigns</b> smoother than sunscreen and recruit like a pro. Plus, we'll reveal <b>hot cost-cutting tricks</b>.`,
    url: 'https://youtu.be/Xm_5K3ze8Mg',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2023,
    name: 'Talent Acquisition is a revenue driver, not a cost centre',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/dfc81ac55f/talent-acquisition-revenue-driver.png',
    description: `
    Are you tired of Talent Acquisition being viewed as just another cost centre?
    This webinar challenges that traditional point of view. We'll equip you with arguments to <b>digitise</b> your <b>recruitment funnel</b> and demonstrate the true value of HR to your CEO.`,
    url: 'https://youtu.be/9amDNmwOZ28',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2023,
    name: 'Recruiting with ChatGPT',
    thumbnail:
      'https://a.storyblok.com/f/137496/1280x800/cea42be4bb/webinar-recruiting-with-chatgpt.png',
    description: `
    Are you already using <b>ChatGPT</b> to make your online <b>recruitment more efficient?</b>
    During this webinar, you'll learn how to create an engaging vacancy in <b>lighting</b> speed, see how our software creates a full online recruitment campaign in less than 5 minutes and learn from our mistakes and get to know our <b>tips</b> & <b>tricks!</b>`,
    url: 'https://youtu.be/DgZFrS8x4TQ',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2023,
    name: 'Recruiting on Instagram',
    thumbnail:
      'https://a.storyblok.com/f/137496/1024x768/0ffe62098d/webinar-recruiting-on-instagram.png',
    description: `
    Have you ever considered using <b>Instagram</b> as a platform to <b>find new candidates</b>? <br />
    In this webinar we'll give you our insights of recruiting on this platform. You'll learn how to create your <b>first recruitment campaign</b>, how to <b>target</b> the ideal <b>audience</b> for your vacancy and our <b>key learnings</b> from our successful campaigns`,
    url: 'https://youtu.be/PMxYp35D_WU',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2022,
    name: 'Joint webinar: Recruiting on TikTok',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/8dde88cd4f/joint-weebinar-recruiting-on-tikok1.png',
    description: `
    Together with Alexandra from TikTok, we hosted our first joint webinar on the ins and outs of this platform.<br />
    In this webinar you'll learn how to <b> create </b> your first<b> TikTok recruitment campaign</b>, what the TikTok pixel is all about and the<b> key learnings </b> from our first <b> successful experiments</b>`,
    url: 'https://youtu.be/9xoJV79BtI4',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2022,
    name: 'How to start recruiting with our software',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/45267ca57b/start-online-recruiting-campaigns-with-our-software.png',
    description: `Want to start online recruitment on Facebook & Instagram, but don't know where to start? Take a look at this sneak peek of our software and see how our software can help you fill all your vacancies ASAP. We'll show you how our software can target and reach your ideal candidates. You'll also learn how to create your first online recruitment campaign in under 30 minutes.`,
    url: 'https://youtu.be/0W0q2f1rt08',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2022,
    name: 'How to recruit on LinkedIn now that sponsored inmail is gone',
    thumbnail:
      'https://a.storyblok.com/f/137496/2274x1380/df487e1501/how-to-recruit-on-linkedin-now-sponsored-inmail-is-gone.png',
    description: `
    Don't know how to target your ideal candidates now Sponsored Inmail (widely used by recruiters) is no longer in use? No worries, we hosted a webinar on how to recruit on LinkedIn since advertising options have changed. You'll learn what the advertising alternatives are to target potential candidates and how you can boost your recruitment strategy on LinkedIn. We'll also give some key takeaways from our own LinkedIn experiments.`,
    url: 'https://youtu.be/LoibIV97IG4',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2022,
    name: 'Ask us anything',
    thumbnail:
      'https://a.storyblok.com/f/137496/1727x1078/745725f2ee/recruitment-campaigns-facebook-versus-tiktok.png',
    description: `The ins and outs of online recruitment aren't always obvious, which is why we are here. In this webinar, our experts answered all your burning questions about online recruitment and how to reach candidates for your vacancy online. We covered questions on the power of the facebook pixel, successful vacancy pages, targeting, recruitment software, and more.`,
    url: 'https://youtu.be/xJ3i9xNKWGg',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2022,
    name: 'Recruitment campaigns: Facebook vs TikTok',
    thumbnail:
      'https://a.storyblok.com/f/137496/1727x1078/745725f2ee/recruitment-campaigns-facebook-versus-tiktok.png',
    description: `
    <b>Facebook</b> is established <b>social media royalty</b>. But have you thought about recruiting on the up-and-coming social media platform – TikTok? In this webinar, we <b>compare</b> these two platforms and help you select the right one in order to target your <b>ideal candidate</b>.`,
    url: 'https://youtu.be/swwZ3ul5jlM',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2022,
    name: 'Recruiting on TikTok',
    thumbnail:
      'https://a.storyblok.com/f/137496/657x408/218b4545e7/tiktok-recruiting.png',
    description: `
    Do you want to <b>reach your ideal candidates</b> on a new vibrant platform?<br />
    In this webinar we'll give you our insights of recruiting on TikTok.
    You'll learn how to <b>create</b> your first <b>TikTok recruitment campaign</b>, what the TikTok pixel is all about and the <b>key learnings</b> from our first <b>successful experiments</b>.
    `,
    url: 'https://youtu.be/IxNCk0KDCWU',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2021,
    name: 'Successful advertising visuals for recruitment campaigns',
    thumbnail:
      'https://a.storyblok.com/f/137496/890x554/358c77a319/facebook-recruiting.png',
    description: `
    On average Belgians scroll 160m per day on Facebook & Instagram... And it's up to you to stand out with your job ad. <br />
    In this webinar you'll learn which visuals work best and tips and tricks to create thumb-stopping visuals for your <b>recruitment campaigns</b>
    `,
    url: 'https://www.youtube.com/watch?v=6L3fgpEjtTU',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2021,
    name: 'Special Ad Category and Targeting changes on Facebook',
    thumbnail:
      'https://a.storyblok.com/f/137496/1018x638/7b7461c668/facebook-recruiting-special-ads-category.png',
    description: `
    Do you want to know more on the <b>special ad category</b> for <b>recruitment</b> and employment campaigns? In this webinar you'll learn what the <b>new targeting policy</b> of Facebook is and the impact it causes for your recruitment campaigns. Learn how to <b>set-up</b> your <b>campaign correctly</b> and how to use the <b>Special Ad Audiences</b>.
    `,
    url: 'https://youtu.be/0me_tIG0zq8?t=1',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2021,
    name: 'How to analyse your recruitment campaigns',
    thumbnail:
      'https://a.storyblok.com/f/137496/2287x1266/140dddd1e7/analyse-your-recruitment-campaign.png',
    description: `
    Do you want to learn more about <b>measuring the success</b> of your recruitment campaigns on Facebook & Instagram?<br />
    This free webinar reveals the exact process you can use to <b>analyse your recruitment campaigns</b>.
    `,
    url: 'https://youtu.be/41n2Wv3s4Ts?utm_source=sympl&utm_medium=symplsite&utm_campaign=data-driven-recruitment-specialist-worden&utm_term=resources-page-data-driven-recruitment-white-paper-aangevraagd-image',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2021,
    name: 'How to recruit on Facebook & Instagram',
    thumbnail:
      'https://a.storyblok.com/f/137496/1280x800/e0c379f8c3/passive-candidates.jpg',
    description: `
    Do you want to <b>reach applicants</b> where they are most present?<br />
    In less than an hour you will learn how to reach <b>passive looking applicants</b>, what the power of the <b></b>Facebook Pixel is and how recruiting on social media <b>increases your ROI</b>.
    `,
    url: 'https://youtu.be/g4Z2vCKccKU?utm_source=sympl&utm_medium=symplsite&utm_campaign=data-driven-recruitment-specialist-worden&utm_term=resources-page-data-driven-recruitment-white-paper-aangevraagd-image',
    buttonLabel: 'Watch now!',
  },
  {
    type: 'webinars',
    year: 2021,
    name: 'Data-driven recruitment',
    thumbnail:
      'https://a.storyblok.com/f/137496/1280x800/a7e9d810c3/data-driven-talent-acquisition.jpg',
    description: `
    Do you want to get <b>more inflow</b> for your vacancy?<br />
    In less than an hour you'll learn <b>how to set up ads</b> on social media, <b>how to use data</b> to get more inflow and <b>how to create dashboards</b> for your recruitment funnel.`,
    url: 'https://youtu.be/BUSTq6WzJfM?utm_source=sympl&utm_medium=symplsite&utm_campaign=data-driven-recruitment-specialist-worden&utm_term=resources-page-data-driven-recruitment-white-paper-aangevraagd-image',
    buttonLabel: 'Watch now!',
  },
  // Checklists
  {
    type: 'checklists',
    name: 'How to incorporate TikTok into your recruitment strategy',
    thumbnail:
      'https://a.storyblok.com/f/137496/1200x675/fc0cfb4664/checklist_tiktok.png',
    description: `TikTok for recruitment marketing? Absolutely! With over <b>1.5 billion users</b>, TikTok is no longer just a platform for Gen Z, it's the <b>ultimate tool</b> to connect with candidates of all ages and backgrounds. Curious about how to get started? We've got you covered. Check out our comprehensive <b>42 tips</b> checklist, packed with <b>meaningful insights</b> to help you incorporate TikTok into your recruitment strategy.`,
    url: 'https://a.storyblok.com/f/137496/x/a3a5e38ddb/42-tips-for-recruitment-on-tiktok.pdf',
    buttonLabel: 'Download',
  },
  {
    type: 'checklists',
    name: '42 tips for recruitment on Facebook and Instagram',
    thumbnail:
      'https://a.storyblok.com/f/137496/1200x675/706d6a5be0/checklist-meta.png',
    description: `After <b>5 years</b> of research and analysing <b>over 10.000</b> recruitment campaigns, we've compiled the <b>ultimate checklist</b> with actionable tips that will <b>transform your recruitment</b> completely. From learning the creative tricks that make your ads <b>stand out</b> to discovering the one call to action that's bringing in <b>twice the applicants</b>.These tips will change your recruitment game!`,
    url: 'https://a.storyblok.com/f/137496/x/3cc510f0e5/42-hands-on-tips-for-recruitment-campaigns-on-facebook-and-instagram.pdf',
    buttonLabel: 'Download',
  },
  {
    type: 'checklists',
    name: 'Tips for a successful vacancy',
    thumbnail:
      'https://a.storyblok.com/f/137496/1200x675/441f51ada9/thumbnail-website-checklist-2.png',
    description: `
          A good vacancy page is about more than just attractive copy… From crafting a <b>mobile friendly vacancy page</b> to how to use the <b>Facebook Pixel</b>. Check out which points you score well in and discover what you can still finetune!
        `,
    url: 'https://a.storyblok.com/f/137496/x/c6d56c8f37/how-do-i-create-a-successful-vacancy-page.pdf?utm_source=sympl&utm_medium=symplsite&utm_campaign=checklist-tips-for-successful-vacancy&utm_term=sympl%E2%80%94academy-checklist-tips-for-succesful-vacancy-request',
  },
  {
    type: 'checklists',
    name: 'Social Media Checklist',
    thumbnail:
      'https://a.storyblok.com/f/137496/1456x972/26ffdac38d/social-media-checklist-thumbnail.png',
    description: `When setting up a <b>recruitment campaign</b> for <b>social media</b>, you may wonder how to set your <b>targeting</b> and what your <b>advertising</b> should look like. This <b>checklist</b> is all about <b>creating a successful social media campaign</b>.`,
    url: 'https://a.storyblok.com/f/137496/x/5422b2a351/social-media-checklist.pdf',
    buttonLabel: 'Download',
  },
  // White papers
  {
    type: 'white papers',
    name: 'Recruit via Facebook & Instagram 2.0',
    thumbnail:
      'https://a.storyblok.com/f/137496/2115x2550/dddd694359/whitepaper-2023-visual-2.png',
    description: `Our <b>upgraded</b> 'Recruit via Facebook & Instagram' whitepaper is here. It's <i>the</i> go-to guide for all recruiters looking to level up their (online) recruiting game. You'll learn how to turn <b>ChatGPT</b> into your trusty recruitment sidekick, get the most out of the <b>Special Ad Categories</b>, leverage <b>employer branding</b> and much more.`,
    url: 'https://a.storyblok.com/f/137496/x/f496f14d99/final_sympl_whitepaper_okt2023.pdf',
  },
  {
    type: 'white papers',
    name: 'Q&A - How to recruit on Facebook and Instagram',
    thumbnail:
      'https://a.storyblok.com/f/137496/1280x896/a9d02c5af3/white-paper-q-a-a-recruiting-on-fb-and-instagram.png',
    description: `
          Have a <b>question</b> about <b>recruiting on Facebook & Instagram</b>? We've probably answered it in this Q&A E-book. Deemed our <b>'Holy Bible'</b> of <b>online recruitment</b>, dig into this E-book to become an expert on all things about recruiting on Facebook & Instagram!`,
    url: 'https://a.storyblok.com/f/137496/x/bf9c2a2cb4/q-a_recruiting-on-facebook-and-instagram.pdf?utm_source=sympl&utm_medium=symplsite&utm_campaign=q-and-a-recruiting-on-facebook-and-instagram-sympl-academy-q&a-request',
  },
  {
    type: 'white papers',
    name: 'Data-driven recruitment',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x916/595d64897f/whitepaper-recruiting-on-fb-and-ig.png',
    description: `
          The <b>acquisition and management</b> of data plays an increasingly important role. Also in the online recruitment process.<br><br>
          This involves more than just the cost per application. We want to know every step a potential applicant takes.<br><br>
          With data-driven recruitment you learn how to map out these steps. Ready to win the race for talent? &nbsp;🏎 &nbsp;&nbsp;Because we are!`,
    url: 'https://a.storyblok.com/f/137496/x/493159eb7b/sympl-data-driven-recruitment-white-paper.pdf?utm_source=sympl&utm_medium=symplsite&utm_campaign=data-driven-recruitment-specialist-worden&utm_term=resources-page-data-driven-recruitment-white-paper-aangevraagd-image',
  },
  {
    type: 'white papers',
    name: 'How to recruit on Facebook & Instagram',
    thumbnail:
      'https://a.storyblok.com/f/137496/3833x2420/d7fdc5a4e3/recruiting-facebook-and-instagram-white-paper.png',
    description: `
          <b>Attracting and retaining</b> new talent has a huge impact on the <b>success of an organisation</b>. HR professionals often come into contact with actively seeking applicants, whereas it is more interesting to reach candidates who are <b>passively looking</b> for a new job.<br><br>
          They cannot be <b>reached</b> via the traditional channels, but can be reached via… <b>Social Media</b>!<br><br>
          Curious about how you can reach these <b>passively seeking applicants</b>?`,
    url: 'https://a.storyblok.com/f/137496/x/7038dc3e9c/whitepaper-rekruteren-op-facebook-en-instagram.pdf?utm_source=sympl&utm_medium=symplsite&utm_campaign=data-driven-recruitment-specialist-worden&utm_term=resources-page-data-driven-recruitment-white-paper-aangevraagd-image',
  },
  // Tools
  {
    type: 'tools',
    name: 'Newsletters',
    thumbnail:
      'https://a.storyblok.com/f/137496/1200x675/cc7e2064df/tools_newsletter.png',
    description: `Ready to take your recruitment marketing to the next level? Join thousands of recruiters who are staying ahead of the curve with our must-read monthly newsletter. Each edition keeps you ahead with the latest news in social advertising, ensuring you're among the first to stay informed about trends in the world of recruitment marketing. <br /> <br /> You'll also gain access to exclusive resources, as  webinars and white papers. Plus, stay updated with exciting updates `,
    url: 'https://gosympl.com/newsletter-social-recruiting',
  },
  {
    type: 'tools',
    name: 'Recruitment Marketing Budgetizer',
    thumbnail:
      'https://a.storyblok.com/f/137496/1200x675/49132d4c0c/budgetizer-1.png',
    description: `Struggling to align your recruitment budget for 2025? At sympl, we've developed a smart tool for HR professionals, allowing you to easily and strategically calculate your recruitment budget for 2025. With sympl's Recruitment Marketing Budgetizer, you have everything you need to stay ahead and set your recruitment budget efficiently. Discover it here!`,
    url: 'https://gosympl.com/recruitment-marketing-budgetizer?_storyblok=584033855&_storyblok',
  },
  {
    type: 'tools',
    name: 'Online Recruitment Bootcamp',
    thumbnail:
      'https://a.storyblok.com/f/137496/1200x675/6beeb5bfff/recruitment-marketing-strategy.png',
    description: `Would you like to take your HR skills to the next level? In just a few weeks you will learn how to create a successful online vacancy and how to get more inflow on your vacancies. Register for free and receive 2-weekly tips & tricks in your mailbox!`,
    url: 'https://gosympl.com/online-recruitment-bootcamp',
  },
  {
    type: 'tools',
    name: 'FFF - Financial Facebook Formula',
    thumbnail:
      'https://sympl-files.ams3.cdn.digitaloceanspaces.com/visuals/fff.png',
    description: `
          Do you want to set up <b>your first job ad on Facebook and Instagram</b>, but no idea how much budget to allocate? Guess no more! With the <b>Financial Facebook Formula</b>, you'll calculate your budget in no time. Not only does the formula calculate the budget you need, but also how much it would cost if your results were great or, unfortunately, a little disappointing. This way you get <b>an optimal insight into your budget</b>.
        `,
    url: 'https://docs.google.com/spreadsheets/d/1rt_4b960Bcne0ugVuMlOfpDAMDlPFmj5H4KETCMc9ck/edit#gid=0?utm_source=sympl&utm_medium=symplsite&utm_campaign=financiele-facebook-formule&utm_term=resources-page-financiele-facebook-formule-aangevraagd',
  },
  // Use cases
  {
    type: 'use cases',
    name: 'Nolmans',
    thumbnail:
      'https://a.storyblok.com/f/137496/800x450/056123527e/testimonial-nolmans.png',
    description: `Recruiting for a diverse range of roles across multiple brands and locations is no easy task, something Marc Corijn & his team at Nolmans Retail Support know. Faced with the challenges of traditional job platforms and a complex hiring process, Nolmans turned to the sympl software for a fresh, social media driven approach that transformed the way they attracted talent. From corporate roles to shop floor positions, the sympl software offered the efficiency and reach Nolmans needed to build a winning `,
    url: 'https://gosympl.com/testimonials/testimonial-nolmans',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'Busch',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/7f6f436695/online-rekrutering-busch.png',
    description: `Busch had been searching for the perfect recruitment solution for a long time. After multiple attempts with different recruiters and an underwhelming social media campaign, they still hadn't found the right match. Skeptical but hopeful, they decided to try the sympl software and it turned out to be exactly what they needed. With 669 page views and 21 candidates, Busch quickly realized that sympl delivers on its promises.  Making recruitment more efficient and effective than ever before.`,
    url: 'https://gosympl.com/testimonials/testimonial-busch',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'De Gavermeersen',
    thumbnail:
      'https://a.storyblok.com/f/137496/800x450/cddee93f9c/testimonial-de-gavermeersen.jpg',
    description: `Like many in the healthcare sector, De Gavermeersen, faced growing challenges with recruitment. Traditional job postings yielded few responses, and connecting with a younger workforce felt increasingly out of reach. Change was inevitable. Enter sympl, a software that didn't just streamline their hiring process but revolutionized it, quickly becoming their go-to recruitment platform.`,
    url: 'https://gosympl.com/testimonials/testimonial-de-gavermeersen',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'MMPumps',
    thumbnail:
      'https://a.storyblok.com/f/137496/800x450/0a518a53e5/testimonial-mmpumps.png',
    description: `At MMPumps, recruitment has always been a daunting challenge. Finding skilled professionals in their niche industry often felt like searching for a needle in a haystack, while rising costs made the process unsustainable. They knew something had to change.That's when we discovered the sympl software, a transformative solution that delivers top-tier candidates faster than they ever thought possible.`,
    url: 'https://gosympl.com/testimonials/testimonial-mmpumps',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'Digital Dot',
    thumbnail:
      'https://a.storyblok.com/f/137496/800x450/423c8b4716/testimonial-sympl_digital-dot.png',
    description: `Digital Dot struggled to attract candidates despite multiple recruitment methods. sympl's user-friendly platform, brand-aligned campaigns, and targeted ads have transformed Digital Dot's hiring process, enabling faster, more efficient recruitment. Now relying solely on sympl, the company is set to meet its yearly staffing goals.`,
    url: 'https://gosympl.com/testimonials/testimonial-digital-dot',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'NSX Normalized Systems',
    thumbnail:
      'https://a.storyblok.com/f/137496/800x451/803c2ded02/testimonial-nsx-normalized-systems-thumbnail.png',
    description: `NSX sought to professionalize their recruitment process without the support of a dedicated HR department. Sympl's user-friendly interface and customizable features made this possible. Additionally, by expanding their reach to passive job seekers, NSX successfully hired specialized talent.`,
    url: 'https://gosympl.com/testimonials/testimonial-nsx-normalized-systems',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'Twinplast',
    thumbnail:
      'https://a.storyblok.com/f/137496/801x451/9fbbdfb9de/testimonial-twinplast-thumbnail.png',
    description: `Twinplast was facing a challenge: increasing the reach as an employer within a niche market and attracting the right talent. It was important to do this while communicating the unique company culture. With sympl they found a cost efficient solution with more control of the recruitment process.`,
    url: 'https://gosympl.com/testimonials/testimonial-twinplast',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'Brouwerij Westmalle',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/35066e7a13/online-rekrutering-westmalle.png',
    description: `
        Following disappointing results from the interim office, Brouwerij Westmalle decided to partner with sympl as their new partner. Due to sympl's <b>targeted</b> and <b>digital approach</b>, the company has already received over <b>200 job applications</b> for 3 different positions.`,
    url: 'https://gosympl.com/testimonials/testimonial-brouwerij-westmalle',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'Pointerpro',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/44883718bb/online-rekrutering-pointerpro.png',
    description: `Pointepro is <b>growing rapidly </b>with often vacancies to fill. However, traditional communication channels did not have the desired reach, so they decided to turn to the sympl software for <b>a more innovative and digital approach</b>.`,
    url: 'https://gosympl.com/testimonials/testimonial-pointerpro',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'Sumitomo Drive technologies',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/51c7f11949/onine-rekrutering-sumitomo-drive-technologie.png',
    description: `Sumitomo Drive Technologies is mainly working with traditional communication channels for job dissemination, however, they were not <b>efficient</b> and <b>effective</b> enough. They decided to use a targeted recruitment approach via social media through the sympl software.`,
    url: 'https://gosympl.com/testimonials/testimonial-sumitomo-drive-technologies',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'Sarawak Belux',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/75a30dd864/online-rekrutering-sarawak.png',
    description: `Sarawak Belux posted more than 20 recruitment marketing campaigns online through the sympl software. Thanks to our <b>smart </b>algorithm and automatic budget optimization on online ads, Sarawak Belux received <b>514 applications</b>.`,
    url: 'https://gosympl.com/testimonials/testimonial-sarawak-belux',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'Kris De Leeneer',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/29dd9b5984/online-rekrutering-kris-de-leeneer.png',
    description: `Kris De Leeneer used to publish job ads off - and online. However, they noticed that these campaigns did not have the expected effect. A different, more <b>targeted approach </b>was needed. With the sympl software they found a tool to revolutionise their recruitment.`,
    url: 'https://gosympl.com/testimonials/testimonial-kris-de-leeneer',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'Brussels Airport',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/b73bdfa13a/brussels-airport-customer-success-story.png',
    description: `Brussels Airport sought a software solution to automate the most time-consuming aspects of their recruitment process. The sympl software actively <b>boosts recruitment marketing</b> through its technology.`,
    url: 'https://gosympl.com/testimonials/testimonial-brussels-airport',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'ERC',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/45e7094432/onine-rekrutering-european-resuscitation-council.png',
    description: `The European Resuscitation Council is a small organisation with a very specific purpose. This sometimes makes it <b>difficult</b> to fill vacancies. With the sympl software, they found the solution to improve their recruitment: targeted social media campaigns that can reach their <b>target audience.</b>`,
    url: 'https://gosympl.com/testimonials/testimonial-erc',
    buttonLabel: 'Read more',
  },

  {
    type: 'use cases',
    name: 'Futech',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/3b96750d0c/online-rekrutering-futech.png',
    description: `Futech was struggling to fill vacancies in their industry. Construction workers are hard to find. However, with the sympl's software, they were able to improve their <b>recruitment marketing efforts</b>: they reached the right audience and <b>targeted passive job seekers</b> with the right ads.`,
    url: 'https://gosympl.com/testimonials/testimonial-futech',
    buttonLabel: 'Read more',
  },
  {
    type: 'use cases',
    name: 'Homeras',
    thumbnail:
      'https://a.storyblok.com/f/137496/1000x563/701f060608/online-rekrutering-homeras.png',
    description: `Homeras <b>needs</b> a lot of employees on a regular basis. However, this is a <b>challenging job. </b>Setting up a recruitment campaign is a <b>time-consuming task </b>and outsourcing can be expensive. With the sympl software, they found a more <b>efficient and flexible </b>solution.`,
    url: 'https://gosympl.com/testimonials/testimonial-homeras',
    buttonLabel: 'Read more',
  },
];

const Academy: React.FC = () => {
  const groupedResources = useMemo(
    () => Array.from(groupBy(RESOURCES, ({ type }) => type)),
    []
  );

  const [activeTab, setActiveTab] = useState(0);

  const itemsForActiveTab = groupedResources[activeTab][1];

  return (
    <AppPage heading="Academy">
      <Tabs
        onChange={(idx) => setActiveTab(idx)}
        activeTabIndex={activeTab}
        items={groupedResources.map(([key]) => (
          <span tw="capitalize">{key}</span>
        ))}
      ></Tabs>
      <ul tw="mt-6">
        <ResourceSection>
          <ResourceGrid>
            {itemsForActiveTab.map((r, resourceIndex) => (
              <ResourceCard
                key={resourceIndex}
                onClick={() =>
                  fireEvent('academy_resource_clicked', {
                    resource: r.type + ' - ' + r.name,
                  })
                }
              >
                <a href={r.url} target="_blank" rel="noreferrer">
                  {r.year && (
                    <p
                      tw="text-gray-400 text-sm"
                      dangerouslySetInnerHTML={{ __html: r.year }}
                    />
                  )}
                  <Title1 mb={2}>{r.name}</Title1>
                  <ResourceImageContainer>
                    <Img
                      tw="block w-full h-full object-contain object-center"
                      src={r.thumbnail}
                      alt={r.name}
                      key={`${resourceIndex}-${r.name
                        .toLowerCase()
                        .replace(' ', '-')}`}
                    />
                  </ResourceImageContainer>
                  <p
                    tw="text-gray-600"
                    className="dangerouslySetInnerHTML"
                    dangerouslySetInnerHTML={{ __html: r.description }}
                  />

                  <div tw="mt-4 float-right">
                    <Button variant="sympl">
                      {r.buttonLabel ?? 'Download'}
                    </Button>
                  </div>
                  {r.secondButtonLabel && r.secondButtonUrl && (
                    <a tw="mt-4 mr-4 float-right" href={r.secondButtonUrl}>
                      <Button variant="sympl">{r.secondButtonLabel}</Button>
                    </a>
                  )}
                </a>
              </ResourceCard>
            ))}
          </ResourceGrid>
        </ResourceSection>
      </ul>
    </AppPage>
  );
};

const ResourceImageContainer = styled.div`
  ${tw`
    flex
    flex-col
    flex-nowrap
    w-full
    h-[250px]
    my-8
  `}
`;

const ResourceGrid = styled.div(tw`grid gap-4 grid-cols-1 md:(grid-cols-2)`);

const ResourceSection = styled.ul(tw`mb-16 last-of-type:m-0`);

const ResourceCard = styled.li(
  tw`
    flex flex-col shadow p-8 rounded-md transition-shadow bg-gray-50
    hover:shadow-lg
    focus:shadow-lg
  `
);

export default Academy;
