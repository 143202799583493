export enum FileTypes {
  DOCX = '.docx',
  JPEG = '.jpeg',
  JPG = '.jpg',
  PDF = '.pdf',
  PNG = '.png',
  MP4 = '.mp4',
}

export enum MimeTypes {
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  JPG = 'image/jpeg',
  PNG = 'image/png',
  MP4 = 'video/mp4',
}

export const VIDEO_MIME_TYPES = [MimeTypes.MP4];
export const DOCUMENT_MIME_TYPES = [MimeTypes.DOCX, MimeTypes.PDF];
export const IMAGE_MIME_TYPES = [MimeTypes.JPEG, MimeTypes.JPG, MimeTypes.PNG];

export type FilePreview = {
  fileName: string;
  fileType: FileTypes;
  path: string;
};

export type SymplFileRejectionCode =
  | 'too-large'
  | 'too-small'
  | 'too-many-files'
  | 'invalid-type'
  | 'network';

interface SymplFileRejectionError {
  message?: string;
  code: SymplFileRejectionCode | string;
}

export interface SymplFileRejection {
  file?: File;
  errors: SymplFileRejectionError[];
}
