import {
  ConfirmPasswordData,
  Credentials,
  ForgotPasswordData,
  UserResponse,
} from 'types/userTypes';
import { apiError } from 'utils/apiHelpers';
import { getCurrentRefreshToken, getCurrentAuthToken } from 'utils/authHelpers';
import {
  RegistrationPayload,
  RegistrationResponse,
} from 'views/register/Register';
import { post } from '../api';

export const authenticateUser = async (
  userCredentials: Credentials
): Promise<UserResponse> => {
  try {
    const res = await fetch(
      `${import.meta.env.VITE_BASE_URL_API}/v4/auth/login`,
      {
        method: 'POST',
        body: JSON.stringify(userCredentials),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    const json = await res.json();

    if (!res.ok) return Promise.reject(json.message ?? 'Unauthorized');

    return json as UserResponse;
  } catch (error) {
    throw apiError(error as any);
  }
};

export const registerUser = async (
  payload: RegistrationPayload
): Promise<RegistrationResponse> => {
  try {
    const res = await fetch(
      `${import.meta.env.VITE_BASE_URL_API}/v4/auth/register`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    const json = await res.json();

    if (!res.ok) {
      return Promise.reject(
        json?.message ?? 'Something went wrong, please try again'
      );
    }

    return json.data as RegistrationResponse;
  } catch (error) {
    throw apiError(error as any);
  }
};

export const forgotUserPassword = async (
  forgotPasswordData: ForgotPasswordData
): Promise<void> => {
  try {
    const response = await post<void>(`/v4/auth/forgot`, forgotPasswordData);
    return response.data;
  } catch (error) {
    throw apiError(error as any);
  }
};

export const resetUserPassword = async (
  passwordConfirmation: ConfirmPasswordData
): Promise<UserResponse> => {
  try {
    const response = await post<UserResponse>(
      `/v4/auth/reset`,
      passwordConfirmation
    );

    return response.data;
  } catch (error) {
    throw apiError(error as any);
  }
};

export const getRefreshedToken = async (): Promise<{
  token: string;
  refreshToken: string;
}> => {
  try {
    const res = await fetch(
      `${import.meta.env.VITE_BASE_URL_API}/v4/auth/refresh-token`,
      {
        method: 'POST',
        body: JSON.stringify({ refresh_token: getCurrentRefreshToken() }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCurrentAuthToken()!}`,
        },
      }
    );

    const json = await res.json();

    if (!res.ok)
      return Promise.reject(json?.message ?? 'Unable to refresh token');

    return {
      token: json.data.access_token,
      refreshToken: json.data.refresh_token,
    };
  } catch (_) {
    return Promise.reject('Unable to refresh token');
  }
};

export const logoutUser = async (): Promise<void> => {
  try {
    await post(`/v4/auth/logout`, {});
  } catch (error) {
    throw apiError(error as any);
  }
};
