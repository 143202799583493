import React, { useEffect, useMemo, useState } from 'react';
import tw, { styled } from 'twin.macro';

import {
  DragDropContext,
  DraggableLocation,
  Droppable,
} from 'react-beautiful-dnd';

import {
  Candidate,
  CandidateState,
  CandidateStates,
} from 'types/candidates/types';
import CandidateColumn from 'components/candidates/column/CandidateColumn';
import {
  CANDIDATE_COLUMN_NAMES,
  CANDIDATE_STATES,
  getFilteredCandidates,
  mapCandidateStatesToFriendlyStates,
} from 'utils/candidateHelpers';
import AppPage from 'components/page/app-page/AppPage';
import Search from 'components/form/search/Search';
import useGetCandidatesQuery from 'hooks/candidates/getCandidates';
import useGetCandidateLazyQuery from 'hooks/candidates/getCandidate';
import useTransitionCandidateMutation from 'hooks/candidates/transitionCandidate';
import { Modal } from 'components/page/app-page/Modal';
import SendMailForm from 'components/candidates/send-mail-form/SendMailForm';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'components/button/Button';
import { GearSix, DownloadSimple } from '@phosphor-icons/react';
import { downloadFile } from 'utils/fileHelpers';
import { useSymplCookie } from 'hooks/symplCookie';
import { CanidateOverViewModal } from './components/CandidatesOverviewModal';
import useNavigationContext from 'hooks/context/nav-context';
import { Link } from 'react-router-dom';
import { Routes } from 'types/routeTypes';
import { fireEvent } from 'utils/eventHelper';

export interface IDraggable {
  source: DraggableLocation;
  destination: DraggableLocation;
}

export const SORT_BY_OPTIONS = [
  { value: 'date', label: 'Date', key: 'date' },
  { value: 'name', label: 'Name', key: 'name' },
];

const CandidatesOverview: React.FC = () => {
  const { addToast } = useToastNotifications();
  const formMethods = useForm();
  const { activeVacancy, activeCustomer } = useNavigationContext();
  const mailSubjectId = 'candidate-end-subject';
  const mailBodyId = 'candidate-end-body';
  const [sorting, setSorting] = useSymplCookie<string>(
    'candidates-overview-sorting'
  );

  const { candidatesData, loadingCandidates } =
    useGetCandidatesQuery(activeVacancy);

  const { getCandidate, candidateData, loadingCandidate } =
    useGetCandidateLazyQuery();

  const {
    transitionCandidate,
    transitioningCandidate,
    transitionCandidateError,
  } = useTransitionCandidateMutation();

  const [states, setStates] = useState<CandidateState[]>([]);
  const [isModalShown, setIsModalShown] = useState(false);
  const [newColumnState, setNewColumnState] = useState<CandidateStates>();
  const [searchFilter, setSearchFilter] = useState<string>();
  const [dragTargetCandidate, setDragTargetCandidate] = useState<Candidate>();
  const [boardIsBusy, setBoardIsBusy] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sortBy, setSortBy] = useState(sorting ?? 'name');
  const candidates = useMemo(
    () => candidatesData?.candidates ?? [],
    [candidatesData]
  );

  useEffect(() => {
    if (!loadingCandidates && !loadingCandidate)
      setStates(mapCandidateStatesToFriendlyStates(candidates));
  }, [candidates, candidateData, loadingCandidates, loadingCandidate]);

  const filteredStates = useMemo(() => {
    const newState: CandidateState[] = [];

    states.forEach(({ state, candidates }) => {
      newState.push({
        state,
        candidates: getFilteredCandidates({
          candidates,
          sortBy,
          searchFilter,
        }),
      });
    });

    return newState;
  }, [states, searchFilter, sortBy]);

  const stateCandidatesLengths = useMemo(() => {
    const lengths: { [key: string]: number } = {};
    states.forEach(({ state, candidates }) => {
      lengths[state] = candidates.length;
    });
    return lengths;
  }, [states]);

  const onDragEnd = async (result: any) => {
    const { source, destination }: IDraggable = result;

    // Check if new position is valid
    if (!dragPositionIsValid(destination, source)) return;

    // Find column from which the item was dragged from
    const oldColumn = states.find((s) => s.state === source.droppableId)?.state;

    // Find column in which the item was dropped
    const newColumn = states.find(
      (s) => s.state === destination.droppableId
    )?.state;

    // Exit on unknown columns
    if (!oldColumn || !newColumn) return;

    moveColumnItem(oldColumn, newColumn, source, destination);
  };

  const moveColumnItem = async (
    oldColumn: string,
    newColumn: string,
    source: DraggableLocation,
    destination: DraggableLocation
  ) => {
    // Move item
    if (oldColumn === newColumn) {
      moveItemInOriginalList(oldColumn, source.index, destination.index);
    } else {
      resetBoardState();

      const candidate = getCandidateForDragLocation(source);

      // Do some checks to ensure the transition can be fetched
      // Check if the candidate is able to transition
      if (!candidate) {
        await resetDragData('normal', candidate);
        return;
      }

      // Update modal data
      moveItemToNewList(oldColumn, newColumn, source.index, destination.index);
      setNewColumnState(newColumn as CandidateStates);

      // Set the current drag target
      setDragTargetCandidate(candidate);

      setBoardIsBusy(true);

      // Transition the candidate
      await transitionCandidateToNewState(newColumn as never, candidate);
    }
  };

  const transitionCandidateToNewState = async (
    newState: CandidateStates,
    candidate: Candidate
  ) => {
    try {
      // Only display modal if communication is required
      if (newState === 'end_procedure') {
        setIsModalShown(true);
      } else {
        await transitionCandidate({
          variables: {
            procedureId: candidate?.procedureId ?? 0,
            input: {
              new_state: newState as CandidateStates,
              trigger_comms: false,
            },
          },
        }).then(() => {
          if (newState === 'hired')
            fireEvent('candidate_hired', {
              candidate: candidate.id,
            });
        });
      }
    } catch (_error) {}

    if (transitionCandidateError) {
      // Reset the board
      await resetDragData('refetch', candidate);
    } else {
      getCandidate({
        variables: {
          vacId: activeVacancy ?? 0,
          procedureId: candidate.procedureId ?? 0,
        },
      });
      setBoardIsBusy(false);
    }
  };

  const resetDragData = async (
    mode: 'normal' | 'refetch' = 'normal',
    candidate?: Candidate
  ) => {
    if (candidate?.id && mode === 'refetch') {
      // Refetch the candidate
      getCandidate({
        variables: {
          vacId: activeVacancy ?? 0,
          procedureId: candidate.procedureId ?? 0,
        },
      });
    }

    setIsModalShown(false);
    resetBoardState();
  };

  const resetBoardState = () => {
    setDragTargetCandidate(undefined);
    setNewColumnState(undefined);
  };

  const dragPositionIsValid = (
    destination: DraggableLocation,
    source: DraggableLocation
  ): boolean => {
    if (!destination || !source) return false;

    const isItemDroppedOutsideList = !destination;

    const isPositionTheSame =
      destination.droppableId === source.droppableId &&
      destination.index === source.index;

    return !(isItemDroppedOutsideList || isPositionTheSame);
  };

  const moveItemInOriginalList = (
    oldColumn: string,
    oldIndex: number,
    newIndex: number
  ) => {
    const state = states.find((s) => s.state === oldColumn);

    if (!state) return;

    const stateIndex = states.findIndex((s) => s.state === state.state);

    const candidates = [...state.candidates];
    const [removedItem] = candidates.splice(oldIndex, 1);
    candidates.splice(newIndex, 0, removedItem);

    const updatedStates = [...states.filter((s) => s.state !== oldColumn)];
    updatedStates.splice(stateIndex, 0, { ...state, candidates });

    setStates(updatedStates);
  };

  const moveItemToNewList = (
    oldColumn: string,
    newColumn: string,
    oldIndex: number,
    newIndex: number
  ) => {
    // the idea is to get the index of the removed candidate from the updated column

    // get the old and new state columns
    const oldState = states.find((s) => s.state === oldColumn);
    const newState = states.find((s) => s.state === newColumn);

    if (!oldState || !newState) return;

    // since our states are arranged in arrays we have to find the index of the old and new state
    const oldStateIndex = states.findIndex((s) => s.state === oldState.state);
    const newStateIndex = states.findIndex((s) => s.state === newState.state);

    // get the value of the removed item
    // in our case we want to get the candidate from the filtered state
    // the reason why we are using the old index because the old index
    // is actually the index of the candidate in the filtered state
    const removedItem = filteredStates[oldStateIndex].candidates[oldIndex];
    const removedItemIndexFromActualState = oldState.candidates.findIndex(
      (c) => c.id === removedItem.id
    );

    // old items from the old column are then filtered
    const updatedOldItems = oldState.candidates.filter(
      (_c, index) => index !== removedItemIndexFromActualState
    );

    const updatedNewItems = [...newState.candidates];
    updatedNewItems.splice(newIndex, 0, removedItem);

    setStates((prevStates) =>
      getStatesInOldOrder(
        prevStates,
        oldStateIndex,
        newStateIndex,
        updatedOldItems,
        updatedNewItems
      )
    );
  };

  const getStatesInOldOrder = (
    prevStates: CandidateState[],
    oldStateIndex: number,
    newStateIndex: number,
    updatedOldItems: Candidate[],
    updatedNewItems: Candidate[]
  ) => {
    const updatedStates = [...prevStates];

    updatedStates[oldStateIndex] = {
      state: updatedStates[oldStateIndex].state,
      candidates: [...updatedOldItems],
    };

    updatedStates[newStateIndex] = {
      state: updatedStates[newStateIndex].state,
      candidates: [...updatedNewItems],
    };

    return updatedStates;
  };

  const getCandidateForDragLocation = (
    location: DraggableLocation
  ): Candidate | undefined => {
    const fromState = getStateFromDragLocation(location);

    // get the current data from the filtered state
    const filteredCandidateIndex = filteredStates.findIndex(
      (s) => s.state === location.droppableId
    );
    const candidateFromFilteredState =
      filteredStates[filteredCandidateIndex].candidates[location.index];

    // look for the candidate in the ACTUAL state
    const candidate =
      fromState?.candidates.find(
        (c) => c.id === candidateFromFilteredState.id
      ) || undefined;

    return candidate;
  };

  const getStateFromDragLocation = (location: DraggableLocation) => {
    return states.find(
      (state) => state.state === (location.droppableId as CandidateStates)
    );
  };

  const modalCancelHandler = (candidate?: Candidate) => {
    // Revert state changes using the saved snapshot
    if (newColumnState) {
      if (candidate) {
        // Refetch the candidate
        getCandidate({
          variables: {
            vacId: activeVacancy ?? 0,
            procedureId: candidate.procedureId ?? 0,
          },
        });
      }
      setIsModalShown(false);
    }
    setBoardIsBusy(false);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const modalTransitionHandler = (
    mode: 'start' | 'finish',
    procedureId: number
  ) => {
    if (mode === 'start') setBoardIsBusy(true);
    else if (mode === 'finish') {
      // Fetch the candidate details when the transition is finished
      getCandidate({ variables: { vacId: activeVacancy ?? 0, procedureId } });
      setBoardIsBusy(false);
    }
  };

  const submitTransitionFormHandler = async (skipEmail = false) => {
    const formData = formMethods.getValues();
    const email = {
      subject: formData[mailSubjectId],
      body: formData[mailBodyId],
    };

    modalTransitionHandler('start', dragTargetCandidate?.procedureId ?? 0);

    try {
      await transitionCandidate({
        variables: {
          procedureId: dragTargetCandidate?.procedureId ?? 0,
          input:
            email && !skipEmail
              ? {
                  new_state: newColumnState ?? 'applied',
                  trigger_comms: true,
                  sm_email: email,
                }
              : {
                  new_state: newColumnState ?? 'applied',
                  trigger_comms: false,
                },
        },
      });
      addToast({
        title: 'Candidate successfully moved.',
        description: 'The candidate has been moved to the next stage.',
        type: ToastTypes.SUCCESS,
      });

      setIsModalShown(false);
    } catch (error) {
      addToast({
        title: 'Something went wrong',
        description: 'Please try again. Contact us if this problem persists!',
        type: ToastTypes.ERROR,
      });
    }
    modalTransitionHandler('finish', dragTargetCandidate?.procedureId ?? 0);
  };

  return (
    <AppPage
      heading="My Candidates"
      loading={loadingCandidates}
      skeleton={
        <ul tw="w-full gap-4 h-screen flex flex-col xl:flex-row items-stretch justify-between my-16">
          {Array.from(Array(CANDIDATE_STATES.length).keys()).map((index) => (
            <div
              tw="h-full w-full bg-gray-200 rounded-md animate-pulse mb-6"
              key={index}
            />
          ))}
        </ul>
      }
      cta={
        <div tw="flex space-x-2">
          <Button
            onClick={() => {
              downloadFile(
                `${
                  import.meta.env.VITE_BASE_URL_API
                }/v4/vacancies/${activeVacancy}/export-procedures`,
                `candidates.csv`,
                [
                  {
                    name: 'Accept',
                    value: 'text/csv',
                  },
                  {
                    name: 'Active-Customer',
                    value: `${activeCustomer!}`,
                  },
                  {
                    name: 'Authorization',
                    value: `Bearer ${localStorage.getItem('token')}`,
                  },
                ]
              );
              fireEvent('export_candidates');
            }}
            variant="inverted"
          >
            Export&nbsp;
            <DownloadSimple weight="bold" size={16} />
          </Button>
          <Search
            value={searchFilter ?? ''}
            onClearFilter={() => setSearchFilter('')}
            onChange={(query) => setSearchFilter(query)}
            placeholder={'Search'}
          />
          <Button variant="secondary" onClick={toggleModal}>
            <p tw="flex justify-center w-full text-center">
              {<GearSix weight="bold" size={20} />}
            </p>
          </Button>
        </div>
      }
    >
      <Link
        tw="bg-gray-600 text-white px-4 py-2 inline-block border border-transparent text-sm leading-5 font-medium rounded-md transition ease-in-out duration-150"
        to={Routes.LEADS}
      >
        Check your Leads &#x203A;
      </Link>
      <div tw="w-full xl:overflow-y-auto flex pb-4 min-h-full h-full">
        <DragDropContext onDragEnd={onDragEnd}>
          <ColumnContainer>
            {filteredStates.map(
              ({ state: fState, candidates: fCandidates }, index) => (
                <Column key={index}>
                  <div tw="flex flex-row items-center md:items-baseline uppercase font-semibold text-gray-500 tracking-tight h-[2rem]">
                    <span tw="uppercase text-gray-500 whitespace-nowrap">
                      {CANDIDATE_COLUMN_NAMES[fState]}
                    </span>
                    <span tw="ml-4 md:ml-auto text-gray-700 bg-slate-200 md:bg-transparent rounded-full p-1 text-center md:text-right md:p-0 w-6 md:w-auto text-xs font-bold whitespace-nowrap">
                      {searchFilter &&
                      fCandidates.length !== stateCandidatesLengths[fState]
                        ? `showing ${fCandidates.length} of ${stateCandidatesLengths[fState]}`
                        : `${stateCandidatesLengths[fState]}`}
                    </span>
                  </div>
                  <Droppable key={index} droppableId={fState}>
                    {(provided: any, snapshot) => (
                      <CandidateColumn
                        snapshot={snapshot}
                        state={fState as CandidateStates}
                        boardIsBusy={boardIsBusy}
                        filteredCandidates={fCandidates}
                        provided={provided}
                      />
                    )}
                  </Droppable>
                </Column>
              )
            )}
          </ColumnContainer>
        </DragDropContext>

        <FormProvider {...formMethods}>
          <Modal
            show={isModalShown}
            onClose={() => modalCancelHandler(dragTargetCandidate)}
          >
            <SendMailFormWrapper>
              <SendMailForm
                variant="normal"
                procedureId={dragTargetCandidate?.procedureId ?? 0}
                subjectId={mailSubjectId}
                bodyId={mailBodyId}
                onSend={() => submitTransitionFormHandler(false)}
                onSendLoading={transitioningCandidate}
                skippable={true}
                onSkip={() => submitTransitionFormHandler(true)}
                candidate={dragTargetCandidate}
              />
            </SendMailFormWrapper>
          </Modal>
        </FormProvider>
      </div>
      <CanidateOverViewModal
        setShowModal={setShowModal}
        setSortBy={setSortBy}
        setSorting={setSorting}
        showModal={showModal}
        sortBy={sortBy}
      />
    </AppPage>
  );
};

const ColumnContainer = styled.ul`
  ${tw` flex flex-col flex-wrap mt-2 w-full max-h-[90vh] sm:w-full sm:h-full sm:flex-row sm:flex-nowrap sm:space-x-4`}
`;

const Column = styled.li`
  ${tw` w-[300px] h-auto flex flex-col flex-nowrap mr-2 md:mr-0 flex-shrink-0`}
`;

const SendMailFormWrapper = styled.div(tw`mt-2 max-w-4xl md:min-w-[56rem]`);

export default CandidatesOverview;
