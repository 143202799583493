import React, { useMemo } from 'react';
import Dropdown from '../Dropdown';
import { BrandModal, ModalType } from 'views/settings/brands/BrandModal';
import { fireEvent } from 'utils/eventHelper';
import { CREATE_BRAND } from 'graphql/brands/mutations';
import { useMutation } from 'hooks/sympl-mutation';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';
import useNavigationContext from 'hooks/context/nav-context';
import { PERMISSIONS } from 'context/PermissionContext';
import usePermissionContext from 'hooks/context/permission-context';

interface BrandCreationDropdownProps {
  value: string | number | undefined;
  label?: string;
  disabled?: boolean;
  enableCustom?: boolean;
  noResultsLabel?: string;
  defaultValue?: any;
  onChange?: (key?: any) => void;
}

interface BrandCreationResult {
  data: {
    createBrand: {
      id: number;
    };
  };
}

const BrandCreationDropdown: React.FC<BrandCreationDropdownProps> = ({
  value,
  label,
  disabled,
  enableCustom,
  noResultsLabel,
  defaultValue,
  onChange,
}) => {
  const [brandModalShown, setBrandModalShown] = React.useState<boolean>(false);
  const [createBrand, { loading }] = useMutation<
    {
      id: number;
    },
    {}
  >(CREATE_BRAND);
  const { addToast } = useToastNotifications();
  const { userPermissions, userHasPermission } = usePermissionContext();
  const { brands, refetchBrands } = useNavigationContext();

  const brandItems = React.useMemo(() => {
    return brands.map((brand) => ({
      key: brand.id,
      label: brand.name,
    }));
  }, [brands]);

  const brandCreationAllowed = useMemo(() => {
    return userHasPermission(PERMISSIONS.MANAGE_CAMPAIGNS, 'customer');
  }, [userPermissions]);

  return (
    <div>
      <Dropdown
        items={brandItems}
        value={value}
        label={label}
        disabled={disabled}
        noResultsLabel={noResultsLabel}
        enableCustom={enableCustom}
        onChange={onChange}
        onCreate={
          brandCreationAllowed ? () => setBrandModalShown(true) : undefined
        }
        createLabel={brandCreationAllowed ? 'Create new brand' : undefined}
        defaultValue={defaultValue ?? undefined}
        testId="brand-creation-dropdown"
      />
      <BrandModal
        modalType={ModalType.ADD}
        showModal={brandModalShown}
        loading={loading}
        closeModal={() => setBrandModalShown(false)}
        onClick={async (props) => {
          createBrand({
            variables: {
              input: {
                name: props?.brandName,
              },
            },
          })
            .then((id) => {
              refetchBrands();
              return id;
            })
            .then((id) => {
              onChange?.(
                (id as unknown as BrandCreationResult).data.createBrand.id
              );

              setBrandModalShown(false);
              fireEvent('create_new_brand', {
                brand: props?.brandName,
              });
              addToast({
                type: ToastTypes.SUCCESS,
                description: 'Brand created successfully.',
              });
            });
        }}
      />
    </div>
  );
};

export default BrandCreationDropdown;
