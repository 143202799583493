import React, { useCallback } from 'react';
import tw, { styled } from 'twin.macro';

import ConfigInput from './ConfigInput';
import { TargetingLocation } from 'types/geolocationTypes';
import { ConfigFormQuestionKey, TargetingFormData } from './TargetingConfig';
import { Subhead, Title2 } from 'components/typography/Typography';
import { useFormContext } from 'react-hook-form';
import { JobType } from 'types/targetingTypes';
import Button from 'components/button/Button';

interface ConfigFormProps {
  formData?: TargetingFormData[];
  isPublished?: boolean;
  onChange?: (
    sectionIndex: number,
    questionIndex: number,
    key: ConfigFormQuestionKey,
    value: string | number | TargetingLocation[] | string[] | null
  ) => void;
  suggestedJobType?: JobType;
  suggestedSubJobType?: JobType;
}

export type ConfigFormQuestionType =
  | 'experience'
  | 'geolocation'
  | 'job-type'
  | 'sub-job-type'
  | 'vac-name'
  | 'brand'
  | 'language'
  | 'number'
  | 'text';

const ConfigForm: React.FC<ConfigFormProps> = ({
  formData,
  isPublished = false,
  onChange,
  suggestedJobType,
  suggestedSubJobType,
}) => {
  const questionHasHelper = (helper?: string): boolean =>
    helper ? helper !== undefined && helper !== '' : false;

  const isInputDisabled = useCallback(
    (type: ConfigFormQuestionType) =>
      (type === 'vac-name' ||
        type === 'job-type' ||
        type === 'language' ||
        type === 'experience' ||
        type === 'brand') &&
      isPublished,
    [isPublished]
  );

  const formMethods = useFormContext();

  const handleSuggestedJobTypeClicked = () => {
    if (!suggestedJobType || !suggestedSubJobType) return;

    onChange?.(0, 2, 'job_type', suggestedJobType.id);
    onChange?.(0, 3, 'sub_job_type', suggestedSubJobType.id);

    formMethods.setValue('job_type', suggestedJobType.id);
    formMethods.setValue('sub_job_type', suggestedSubJobType.id);
  };

  return (
    <form tw="grid grid-cols-1 gap-4 md:grid-cols-2 h-full overflow-y-auto no-scrollbar">
      {formData?.map((formSection, sectionIndex) => (
        <Section key={sectionIndex}>
          <Title2>{formSection.section}</Title2>
          <ul>
            {formSection.questions.map((question, questionIndex) => (
              <ConfigInputItem key={questionIndex}>
                <Label hasHelper={questionHasHelper(question.helper)}>
                  {question.label}
                </Label>
                {question.helper && (
                  <Subhead isLight mb={1}>
                    {question.helper}
                  </Subhead>
                )}
                <ConfigInput
                  id={question.key}
                  isDisabled={isInputDisabled(question.type)}
                  type={question.type}
                  value={question.value}
                  formData={formData}
                  onChange={(val) =>
                    onChange?.(sectionIndex, questionIndex, question.key, val)
                  }
                  secondaryValue={
                    formSection.questions[questionIndex + 1]?.value as
                      | string
                      | number
                  }
                  onChangeSecondary={(val) => {
                    onChange?.(
                      sectionIndex,
                      questionIndex + 1,
                      'sub_job_type',
                      val
                    );
                    formMethods.setValue('sub_job_type', val);
                  }}
                />
                {question.type === 'job-type' && (
                  <>
                    {question.value === 18 && (
                      <span tw="text-sm text-orange-700 p-4 bg-orange-50 mt-2 rounded-xl block">
                        We advise to select a more specific function type if
                        applicable. This will increase inflow and quality of
                        candidates.
                      </span>
                    )}
                    {suggestedSubJobType && !question.value && (
                      <div tw="flex flex-row items-center gap-1 ease-in text-gray-600 text-sm mt-4">
                        Suggested:
                        <Button
                          variant="pill"
                          onClick={handleSuggestedJobTypeClicked}
                        >
                          {suggestedJobType?.name} → {suggestedSubJobType?.name}
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </ConfigInputItem>
            ))}
          </ul>
        </Section>
      ))}
    </form>
  );
};

const Section = styled.div(
  tw`border shadow-sm p-6 rounded-md bg-white col-[1/3] last-of-type:(m-0 col-[1/3]) md:col-auto`
);

const Label = styled.p<{ hasHelper: boolean }>`
  ${tw`text-base text-gray-700`}
  ${({ hasHelper }) => !hasHelper && tw`mb-2`}
`;

const ConfigInputItem = styled.li(tw`mb-6 last-of-type:m-0`);

export default ConfigForm;
