import jwt_decode from 'jwt-decode';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

import { Jwt } from 'types/authTypes';
import { setLocalAppSession } from 'utils/storageHelpers';
import { identifyIntercom } from 'utils/intercomHelper';
import { getRefreshedToken } from 'api/auth/authApi';
import { fireEvent } from './eventHelper';
import posthog from 'posthog-js';

export const getCurrentAuthToken = (
  expirationMode: 'delete' | 'preserve' = 'delete'
): string | null => {
  let token = localStorage.getItem('token');

  if (expirationMode === 'delete' && token && isTokenExpired(token)) {
    resetCurrentAuthToken();
    token = null;
  }

  return token;
};

export const getCurrentRefreshToken = (): string | null => {
  return localStorage.getItem('refresh_token');
};

export const resetCurrentAuthToken = () => {
  localStorage.removeItem('token');
};

export const isTokenExpired = (token: string): boolean => {
  const jwt = getDecodedJwt(token);
  return jwt ? Date.now().valueOf() / 1000 >= jwt['exp'] : true;
};

const getDecodedJwt = (token: string): Jwt | null => {
  try {
    return jwt_decode(token) as Jwt;
  } catch (error) {
    return null;
  }
};

export const extendUserSession = async () => {
  try {
    const { token, refreshToken } = await getRefreshedToken();
    localStorage.setItem('token', token);
    localStorage.setItem('refresh_token', refreshToken);
  } catch (_) {
    return Promise.reject('Unable to extend user session');
  }
};

/**
 * Identify logging (LogRocket, Sentry)
 * @param uid The uid the identify the user without
 * @param customData Extra data to be passed to LogRocket
 */
export const identifyUserSession = (email: string, name = 'Guest') => {
  if (import.meta.env.PROD) {
    LogRocket.identify(email, {
      email,
    });

    posthog.identify(email, {
      email,
      name,
    });
  }

  Sentry.setUser({ email });

  // Intercom
  identifyIntercom(email);
};

/**
 * Activates the user session - Sets token, i18n & logging
 * @param userSession The user session data
 * @returns A promise indicating if the activation was successful
 */
export const activateUserSession = (userSession: {
  token: string;
  uid?: string;
  firstName?: string;
  lastName?: string;
  userId?: string;
  vacancyId?: number;
  customerId?: number;
  isAdmin?: boolean;
  trackLogRocket?: boolean;
}) => {
  // Update the token
  localStorage.setItem('token', userSession.token);

  // Enable logrocket
  if (userSession?.trackLogRocket && userSession?.uid)
    identifyUserSession(
      userSession.uid,
      userSession.firstName + ' ' + userSession.lastName
    );

  // Update session data
  if (userSession?.vacancyId || userSession?.customerId) {
    setLocalAppSession({
      firstName: userSession?.firstName,
      lastName: userSession?.lastName,
      activeVacancy: userSession?.vacancyId,
      activeCustomer: userSession?.customerId,
      email: userSession.uid,
      isAdmin: userSession?.isAdmin,
    });
  }

  return Promise.resolve();
};

type AuthResponse = {
  access_token: string;
  refresh_token: string;
  email: string;
  first_name: string;
  last_name: string;
  id: number;
  is_sympl: boolean;
  active_customer: number;
  active_vacancy?: number;
};

export const handleAuth = (
  authenticationMethod: 'sso' | 'login',
  response: AuthResponse
) => {
  // Set Tokens
  localStorage.setItem('token', response.access_token);
  localStorage.setItem('refresh_token', response.refresh_token);

  identifyUserSession(
    response.email,
    response.first_name + ' ' + response.last_name
  );

  fireEvent('authenticated', {
    authenticationMethod,
    userId: response.id,
    email: response.email,
  });

  setLocalAppSession({
    firstName: response.first_name,
    lastName: response.last_name,
    userId: '' + response.id,
    email: response.email,
    isAdmin: response.is_sympl,
    activeCustomer: response.active_customer,
    activeVacancy: response.active_vacancy,
  });
};
