export enum Routes {
  // ----- general -----
  ROOT = '/',
  LOGIN = '/login',
  REGISTER = '/register',
  CONFIRM_EMAIL = '/confirm-email',
  CONFIRM_PASSWORD = '/confirm-password',
  FORGOT_PASSWORD = '/forgot-password',
  SSO_CALLBACK = '/sso/callback',
  TWO_FACTOR = '/2fa',
  DEMO = '/demo',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  ERROR = '/404',
  //  ----- payments -----
  PAYMENT_SUCCESS = '/payments/success',
  PAYMENT_FAILED = '/payments/failed',
  SUBSCRIPTION_SUCCESS = '/subscriptions/success',
  SUBSCRIPTION_FAILED = '/subscriptions/failed',
  SUBSCRIPTION_UPDATED = '/subscriptions/updated',
  // ----- careers -----
  CAREERS = '/careers',
  // ----- campaigns -----
  TARGETING = '/targeting',
  AD_EDITOR = '/ad-editor',
  JOB_POSTING = '/job-posting',
  SOCIAL_MEDIA = '/social-media',
  INTERVIEW = '/interview',
  PREFERENCES = '/preferences',
  CANDIDATES = '/candidates',
  PROCEDURES = '/can',
  LEADS = '/leads',
  LEAD_DETAIL = '/lead-detail',
  // ----- onboarding / setup -----
  ONBOARDING = '/onboarding',
  GETTING_STARTED = '/getting-started',
  CAMPAIGN_PUBLISHED = '/campaign-published',
  REPUBLISH_SUCCESS = '/campaign-republished',
  // ----- settings -----
  SETTINGS = '/settings',
  GENERAL_SETTINGS = '/settings/general',
  ACCOUNT_SETTINGS = '/settings/account',
  USER_SETTINGS = '/settings/users',
  BRAND_SETTINGS = '/settings/brands',
  INTEGRATION_SETTINGS = '/settings/integrations',
  SUBSCRIPTION_SETTINGS = '/settings/subscription',
  INVOICING_SETTINGS = '/settings/invoicing',
  MEDIA_SETTINGS = '/settings/media',
  TEMPLATE_SETTINGS = '/settings/templates',
  BALANCE_SETTINGS = '/settings/balance',
  // ----- knowledge base -----
  ACADEMY = '/academy',
  // ----- reporting -----
  REPORTING = '/reporting',
  GENERAL_REPORTING = '/reporting/general',
  CHANNELS_REPORTING = '/reporting/channels',
  VISUALS_REPORTING = '/reporting/creatives',
  // ----- misc -----
  NOT_ALLOWED = '/not-allowed',
}

export enum RouteGuards {
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
}
