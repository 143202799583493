import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Input from 'components/form/input/Input';
import { Body, Error, Title2 } from 'components/typography/Typography';
import { UPDATE_ACCOUNT_SETTINGS } from 'graphql/users/mutations';
import { GET_USER } from 'graphql/users/queries';
import { useQuery } from 'hooks/sympl-query';
import useUnsavedFields from 'hooks/unsaved';
import { Controller, useForm } from 'react-hook-form';
import Container from 'components/container/Container';
import ContainerHeader from 'components/container/container-header/ContainerHeader';
import ContainerAction from 'components/container/container-action/ContainerAction';
import SaveButton from 'components/save-button/SaveButton';

import { AccountSettingsForm } from '../AccountSettings';

import 'twin.macro';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';

interface AccountSettingsData {
  firstname?: string;
  lastname?: string;
  job_position?: string;
  signature_job_position?: string;
  current_password?: string;
  new_password?: string;
  new_password_compare?: string;
}

interface AccountSettingsPayload {
  input: AccountSettingsData;
}

type UserData = {
  firstname: string;
  lastname: string;
  job_position: string;
  signature: string;
};

interface AccountSettingsFormError {
  passwordsDontMatch?: boolean;
  passwordFieldsCantBeEmpty?: boolean;
}

const isPasswordField = (str: string): boolean => str.includes('password');

const toTitleCase = (str: string) => {
  return str
    .replace(/-/g, ' ')
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const GenericSettings: React.FC<{
  title: string;
  fields: AccountSettingsForm[];
}> = ({ title, fields }) => {
  const formMethods = useForm();
  const { control, handleSubmit, setValue } = formMethods;
  const { addToast } = useToastNotifications();

  const [shouldSaveChanges, resetUnsavedFlag] = useUnsavedFields(formMethods);

  const { data: userData, refetch: refetchUserData } = useQuery<{
    user: UserData;
  }>(GET_USER);

  useEffect(() => {
    setValue('firstname', userData?.user.firstname);
    setValue('lastname', userData?.user.lastname);
    setValue('job-position', userData?.user.job_position);
    setValue('signature-job-position', userData?.user.signature);
  }, [userData, setValue]);

  const [
    updateAccountSettings,
    { loading: accountSettingsLoading, error: accountSettingsError },
  ] = useMutation<undefined, AccountSettingsPayload>(UPDATE_ACCOUNT_SETTINGS);

  const [formErrors, setFormErrors] = useState<AccountSettingsFormError>();

  const submitHandler = async (data: {
    [K in AccountSettingsForm]?: string;
  }) => {
    if (!validate(data)) return;

    await updateAccountSettings({
      variables: {
        input: {
          firstname: data['firstname']?.toString() ?? '',
          lastname: data['lastname']?.toString() ?? '',
          job_position: data['job-position']?.toString() ?? '',
          signature_job_position:
            data['signature-job-position']?.toString() ?? '',
          current_password: data['password']?.toString() ?? '',
          new_password: data['new-password']?.toString() ?? '',
          new_password_compare: data['repeat-password']?.toString() ?? '',
        },
      },
    }).then(() => {
      addToast({
        type: ToastTypes.SUCCESS,
        description: 'Your settings have been successfully updated',
      });
      refetchUserData();
    });
  };

  const isRequiredField = (key: AccountSettingsForm) => {
    return key.includes('firstname') || key.includes('lastname');
  };

  function getDefaultValue(key: AccountSettingsForm): string | number {
    const defaultValues: Record<AccountSettingsForm, string | number> = {
      'new-password': '',
      'repeat-password': '',
      password: '',
      'job-position': userData?.user.job_position ?? '',
      firstname: userData?.user.firstname ?? '',
      lastname: userData?.user.lastname ?? '',
      'signature-job-position': userData?.user.signature ?? '',
    };

    return defaultValues[key] ?? '';
  }

  const validate = (data: {
    [K in AccountSettingsForm]?: string;
  }): boolean => {
    setFormErrors({});

    if (
      (data['password']?.toString().length &&
        !data['new-password']?.toString().length) ||
      (!data['password']?.toString().length &&
        data['new-password']?.toString().length)
    ) {
      setFormErrors({ ...formErrors, passwordFieldsCantBeEmpty: true });
      return false;
    } else if (
      data['new-password']?.toString() !== data['repeat-password']?.toString()
    ) {
      setFormErrors({ ...formErrors, passwordsDontMatch: true });
      return false;
    }

    return true;
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(submitHandler)} autoComplete="on">
        <>
          <ContainerHeader>
            <Title2 mb={3}>{title}</Title2>
          </ContainerHeader>

          <div tw="mt-1 flex flex-col gap-3 md:flex-row">
            {fields.map((field: AccountSettingsForm, i) => {
              const title = toTitleCase(field);
              return (
                <div tw="flex-1" key={i}>
                  <Body mb={1}>{title}</Body>
                  <Controller
                    id={field}
                    name={field}
                    control={control}
                    defaultValue={getDefaultValue(field)}
                    rules={{
                      pattern: isPasswordField(field)
                        ? /^(?=.*[0-9])(?=.*[\W])(?=.*[A-Z])[a-zA-Z0-9\W]{8,}$/
                        : undefined,
                    }}
                    render={({ onChange, value }) => {
                      return (
                        <Input
                          type={isPasswordField(field) ? 'password' : 'text'}
                          id={field}
                          name={field}
                          required={isRequiredField(field)}
                          defaultValue={value}
                          placeholder={title}
                          onChange={(e) => onChange(e)}
                        />
                      );
                    }}
                  />
                </div>
              );
            })}
            {formErrors?.passwordsDontMatch && (
              <Error>Passwords don't match</Error>
            )}
            {formErrors?.passwordFieldsCantBeEmpty && (
              <Error>
                {
                  'In order to change your password please fill in your current password and a new password'
                }
              </Error>
            )}
          </div>
        </>
        <ContainerAction>
          <div tw="ml-auto flex flex-row gap-2">
            {accountSettingsError && (
              <Body>{accountSettingsError.message}</Body>
            )}
            <SaveButton
              type="submit"
              loading={accountSettingsLoading}
              shouldSave={shouldSaveChanges && !accountSettingsError}
              onDone={resetUnsavedFlag}
            />
          </div>
        </ContainerAction>
      </form>
    </Container>
  );
};
