import { fabric } from 'fabric';
import tinycolor from 'tinycolor2';
import { AdVariant } from 'types/adEditorTypes';
import { AdPlacement } from 'types/adTypes';
import { Brand } from 'types/customer/types';

const insertNewLines = (
  text: string,
  maxLength = 25,
  fabric = true,
  fontSize: string | number = 55
) => {
  const words = text.split(' ');
  let currentLine = '';
  const result: string[] = [];
  let firstLine = true;
  let lineNumber = 0;
  const fSize = parseInt(`${fontSize}`, 10);

  for (const word of words) {
    if (currentLine.length + word.length + 1 > maxLength) {
      // For Fabric.js output
      if (fabric)
        result.push(
          `${firstLine ? '' : '{NEW_LINE_BREAK}'}${currentLine.trim()}`
        );
      else {
        const dy = fSize * lineNumber * 1.3;
        result.push(
          `<tspan x="25" dy="${
            firstLine ? '0' : dy
          }">${currentLine.trim()}</tspan>`
        );
      }
      currentLine = word + ' ';
      lineNumber++;
      firstLine = false;
    } else {
      currentLine += word + ' ';
    }
  }

  if (currentLine.trim().length > 0) {
    if (fabric) {
      result.push(
        `${firstLine ? '' : '{NEW_LINE_BREAK}'}${currentLine.trim()}`
      );
    } else {
      const dy = fSize * lineNumber * 1.3;
      result.push(
        `<tspan x="25" dy="${
          firstLine ? '0' : dy
        }">${currentLine.trim()}</tspan>`
      );
    }
  }

  return result.join('').replace(/^\{NEW_LINE_BREAK\}/, '');
};

/**
const injectText = (haystack: string, needle: string, number: number) =>
  haystack.replaceAll(needle, number.toString());
*/

interface OverlayConfig {
  width: number;
  height: number;
  logoHeight: number;
}

const storyConfig: OverlayConfig = {
  width: 1080,
  height: 1920,
  logoHeight: 150,
};

const feedConfig: OverlayConfig = {
  width: 1000,
  height: 1000,
  logoHeight: 120,
};

const extractFontSizeForPlaceholder = (
  svgString: string,
  placeholder: string
) => {
  const regex = /<text[^>]*font-size=['"]([^'"]+)['"][^>]*>(.*?)<\/text>/g;
  let match;

  while ((match = regex.exec(svgString)) !== null) {
    const fontSize = match[1]; // Extract font-size value
    const textContent = match[2].trim(); // Extract the content of the <text> element

    // Check if the content contains the placeholder (e.g., {VAC_NAME_LINE})
    if (textContent.includes(placeholder)) {
      return fontSize; // Return the font size if the placeholder is found
    }
  }
};

/**
 * Get an overlay function
 * @param overlay => Overlay string
 * @param currentVariant => Current active ad variant
 * @param colors? => Default colors for texts
 */
export const getOverlay = (
  overlay: string,
  currentVariant?: AdVariant,
  brand?: Brand,
  location?: string,
  fabric = true // we use this function for both fabric and svg (svg does not replace {NEW_LINE_BREAK})
) => {
  let rText = overlay;

  const primaryColor = brand?.default_primary_color ?? '#fd535a';
  const secondaryColor = brand?.default_secondary_color ?? 'rgb(200,200,200)';

  const isPrimaryBright = tinycolor(
    brand?.default_primary_color ?? 'rgb(200,200,200)'
  ).isLight();
  const isSecondaryBright = tinycolor(
    brand?.default_secondary_color ?? 'rgb(200,200,200)'
  ).isLight();

  const brightColor = isPrimaryBright
    ? primaryColor
    : isSecondaryBright
    ? secondaryColor
    : 'rgb(255,255,255)';
  const darkColor = !isPrimaryBright
    ? primaryColor
    : !isSecondaryBright
    ? secondaryColor
    : 'rgb(0,0,0)';

  const jobFunction =
    currentVariant?.function.replaceAll(' & ', ' &amp; ') ?? '[JOB]';
  const customer =
    currentVariant?.company.replaceAll(' & ', ' &amp; ') ?? '[COMPANY]';

  const isStories = currentVariant?.placement === AdPlacement.STORIES;

  const margin = 25;
  const width = isStories ? storyConfig.width : feedConfig.width;
  const height = isStories ? storyConfig.height : feedConfig.height;
  const logo = currentVariant?.logo;
  const logoRatio = logo ? logo.width! / logo.height! : 1;
  const logoHeight = isStories ? storyConfig.logoHeight : feedConfig.logoHeight;
  const logoWidth = logoRatio * logoHeight;

  // Assume original image dimensions come from currentVariant.path
  const originalImageWidth = currentVariant?.path?.width ?? 1920;
  const originalImageHeight = currentVariant?.path?.height ?? 1080;
  const containerAspect = width / height;
  const imageAspect = originalImageWidth / originalImageHeight;
  let imageX = 0;
  let imageY = 0;
  let displayWidth = isStories ? storyConfig.width : feedConfig.width;
  let displayHeight = isStories ? storyConfig.height : feedConfig.height;

  if (imageAspect > containerAspect) {
    // we need the viewbox width to calculate the offset
    const tempWidth = displayWidth;
    // Image is relatively wider than the container
    // Match container height, center horizontally
    const scale = displayHeight / originalImageHeight;
    displayWidth = originalImageWidth * scale;
    imageX = (tempWidth - displayWidth) / 2;
  } else {
    // we need the viewbox height to calculae the offset
    const tempHeight = displayHeight;
    // Image is relatively taller or equal
    // Match container width, center vertically
    const scale = displayWidth / originalImageWidth;
    displayHeight = originalImageHeight * scale;
    imageY = (tempHeight - displayHeight) / 2;
  }

  const removeLogo = (svg: string) => {
    const parser = new DOMParser();
    const serializer = new XMLSerializer();

    const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
    const images = svgDoc.getElementsByTagName('image');

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      if (image.getAttribute('width') === '{LOGO_WIDTH}')
        image.parentNode?.removeChild(image);
    }

    return serializer.serializeToString(svgDoc);
  };

  const variables = {
    '{IMAGE_URL}':
      currentVariant?.path?.path ??
      'https://t3.ftcdn.net/jpg/02/68/55/60/360_F_268556012_c1WBaKFN5rjRxR2eyV33znK4qnYeKZjm.jpg',
    '{LOGO_URL}': currentVariant?.logo?.path ?? '',
    '{WIDTH}': width.toString(),
    '{HEIGHT}': height.toString(),
    '{MARGIN}': margin.toString(),
    '{IMAGE_X}': imageX.toString(),
    '{IMAGE_Y}': imageY.toString(),
    '{DISPLAY_WIDTH}': displayWidth.toString(),
    '{DISPLAY_HEIGHT}': displayHeight.toString(),
    '{LOGO_RATIO}': logoRatio.toString(),
    '{LOGO_WIDTH}': logoWidth.toString(),
    '{LOGO_WIDTH_SMALL}': (logoWidth / 2).toString(),
    '{LOGO_X}': (width - margin - logoWidth).toString(),
    '{LOGO_X_CENTER}': (width / 2 - logoWidth / 2).toString(),
    '{BANNER_TITLE}': brand?.default_banner_title ?? 'WANTED',
    '{VAC_NAME_LINE}': insertNewLines(
      jobFunction,
      undefined,
      fabric,
      extractFontSizeForPlaceholder(overlay, '{VAC_NAME_LINE}')
    ),
    '{CUSTOMER}': customer,
    '{VAC_NAME}': jobFunction,
    '{PRIMARY_COLOR}': primaryColor,
    '{SECONDARY_COLOR}': secondaryColor,
    '{BRIGHT_COLOR}': brightColor,
    '{DARK_COLOR}': darkColor,
    '{FUNCTION_FONT_SIZE}': jobFunction?.length > 25 ? '60px' : '80px',
    // vvvvv Properties for custom overlays below vvvvv
    '{LOCATION}': location ?? '',
    '{LOCATION_X_RIGHT}':
      (isStories ? storyConfig.width : feedConfig.width) -
      calculateTextWidth(location ?? '', 48) -
      50,
    '{PIN_RIGHT_OFFSET}':
      (isStories ? storyConfig.width : feedConfig.width) -
      calculateTextWidth(location ?? '', 55) -
      100,
    '{VAC_NAME_RIGHT}': insertNewLines(
      jobFunction,
      15,
      fabric,
      extractFontSizeForPlaceholder(overlay, '{VAC_NAME_RIGHT}')
    ),
    '{VAC_NAME_RIGHT_OFFSET}':
      (isStories ? storyConfig.width : feedConfig.width) -
      calculateTextWidth(
        jobFunction.length >= 15 ? jobFunction.split(/[\s\n]/)[0] : jobFunction,
        55
      ) -
      50,
    '{BANNER_TITLE_RIGHT_OFFSET}':
      (isStories ? storyConfig.width : feedConfig.width) -
      calculateTextWidth(brand?.default_banner_title ?? 'WANTED', 55) -
      50,
    '{LOGO_X_RIGHT}':
      (isStories ? storyConfig.width : feedConfig.width) - logoWidth / 2 - 50,
  };

  if (!logo) rText = removeLogo(rText);
  Object.entries(variables).forEach(([key, value]) => {
    rText = rText.replaceAll(key, value.toString());
  });

  return rText;
};

function calculateTextWidth(
  textContent: string,
  fontSize = 55,
  fontFamily = 'Arial'
): number {
  const tempText = new fabric.Text(textContent, {
    fontSize: fontSize,
    fontFamily: fontFamily,
  });
  return tempText.width ?? 0;
}
/*
 *
 * Remove the tspan tag
 * <tspan> tag is not supported in FabricJS
 * Source: https://github.com/fabricjs/fabric.js/issues/8439#issuecomment-1315438121
 *
 */
export function deleteTspan(svg: string) {
  const tspanTagMatch = svg.match(/<tspan.*>(.*)<\/tspan>/);
  const tspanAll = svg.match(/<tspan[^>]*>(.*?)<\/tspan>/g);
  let tspanString = null;
  let text: string | null = null;
  let x = null;
  let y = null;
  let groupString = null;
  let matrixString = null;
  let xMatrix = null;
  let yMatrix = null;
  let transformedSvg = svg;
  if (tspanTagMatch && tspanTagMatch.length > 1) {
    text = tspanTagMatch[1] as string;

    tspanString = tspanTagMatch[0];
    const coordMatch = tspanString.match(/x="(-?\d*\.?\d+)" y="(-?\d*\.?\d+)"/);
    if (coordMatch && coordMatch.length > 2) {
      x = parseFloat(coordMatch[1]);
      y = parseFloat(coordMatch[2]);
    }

    const groupTagMatch = svg.match(/<g .*transform.*>/);
    if (groupTagMatch) {
      groupString = groupTagMatch[0];

      // groupString is like <g transform="matrix(a b c d e f)" style=""  > where e = xMatrix et f = yMatrix
      const matrixMatch = groupString.match(
        /matrix\((-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+) (-?\d*\.?\d+)\)/
      );
      if (matrixMatch && matrixMatch.length > 6) {
        matrixString = matrixMatch[0];
        xMatrix = parseFloat(matrixMatch[5]);
        yMatrix = parseFloat(matrixMatch[6]);

        if (
          x !== null &&
          y !== null &&
          xMatrix !== null &&
          yMatrix !== null &&
          text !== null &&
          matrixString !== null
        ) {
          const newMatrixString = `matrix(${matrixMatch[1]} ${matrixMatch[2]} ${
            matrixMatch[3]
          } ${matrixMatch[4]} ${x + xMatrix} ${y + yMatrix})`;

          let allText = '';
          tspanAll?.forEach((v, index) => {
            if (index === tspanAll?.length - 1 || tspanAll?.length === 1)
              return;
            const fValue = v.match(/<tspan.*>(.*)<\/tspan>/);
            if (fValue)
              allText +=
                index === 0 ? fValue[1] : `{NEW_LINE_BREAK}${fValue[1]}`;
          });
          allText +=
            tspanAll && tspanAll?.length > 1 ? `{NEW_LINE_BREAK}${text}` : text;
          transformedSvg = svg
            .replace(matrixString, newMatrixString)
            .replace(tspanString, allText);
        }
      }
    }
  }
  return transformedSvg;
}
