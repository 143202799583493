import { keyframes } from 'styled-components';
import React from 'react';
import { css, styled } from 'twin.macro';
import { AdEditorIconProps } from './StoryIcon';

export const AdEditorFeedIcon: React.FC<AdEditorIconProps> = ({ isVideo }) => {
  return (
    <>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="96"
        height="117"
        viewBox="0 0 96 117"
      >
        <defs>
          <filter
            id="backdrop"
            x="0"
            y="0"
            width="96"
            height="117"
            filterUnits="userSpaceOnUse"
          >
            <feOffset />
            <feGaussianBlur stdDeviation="1.5" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g id="FeedIcon" transform="translate(4.5 4.5)">
          <g transform="matrix(1, 0, 0, 1, -4.5, -4.5)" filter="url(#backdrop)">
            <rect
              id="backdrop-2"
              data-name="backdrop"
              width="87"
              height="108"
              rx="5"
              transform="translate(4.5 4.5)"
              fill="#fff"
            />
          </g>
          <rect
            id="right-button"
            width="6"
            height="6"
            transform="translate(73.571 96)"
            fill="#f8f8f8"
          />
          <circle
            id="middle-button"
            cx="3.485"
            cy="3.485"
            r="3.485"
            transform="translate(40.015 95.515)"
            fill="#f8f8f8"
          />
          <rect
            id="left-button"
            width="6"
            height="6"
            transform="translate(10.5 94.757) rotate(45)"
            fill="#f8f8f8"
          />
          <rect
            id="feed-media"
            width="87"
            height="54"
            transform="translate(0 36)"
            fill="#d3d3d3"
          />
          {isVideo && (
            <path
              id="Video-play"
              d="M10.913,4.408a3,3,0,0,1,5.174,0l8.261,14.073A3,3,0,0,1,21.761,23H5.239a3,3,0,0,1-2.587-4.519Z"
              transform="translate(58 47) rotate(90)"
              fill="#fff"
            />
          )}
          <rect
            id="text-bottom"
            width="72"
            height="4"
            rx="2"
            transform="translate(8 24)"
            fill="#f0f0f0"
          />
          <rect
            id="text-top"
            width="58"
            height="4"
            rx="2"
            transform="translate(22 17)"
            fill="#f0f0f0"
          />
          <rect
            id="username"
            width="29"
            height="5"
            rx="2.5"
            transform="translate(22 5)"
            fill="#d9d5d5"
          />
          <circle
            id="user-picture"
            cx="8"
            cy="8"
            r="8"
            transform="translate(4 5)"
            fill="#d3d3d3"
          />
          <rect
            id="Rectangle_21"
            data-name="Rectangle 21"
            width="87"
            height="108"
            rx="5"
            fill="none"
          />
        </g>
      </SVG>
    </>
  );
};

const playBtnWiggle = (x: number, y: number, scale: number) => keyframes`
0%, 100% {
  transform: translate(${x}, ${y}) rotate(90deg);
}
35% {
  transform: translate(${x}, ${y}) scale(${scale}) rotate(90deg);
}
70% {
  transform: translate(${x}, ${y}) scale(${scale}) rotate(90deg);
}
`;

const SVG = styled.svg`
  ${css`
    #Video-play {
      animation: ${playBtnWiggle(58, 47, 1.4)} 1s ease-in-out infinite;
    }
    #text-top,
    #text-bottom,
    #Video-play {
      animation-play-state: paused;
    }
    :hover {
      cursor: pointer;

      #text-top,
      #text-bottom,
      #Video-play {
        animation-play-state: running;
      }
    }
  `}
`;

export default AdEditorFeedIcon;
