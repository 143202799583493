import 'twin.macro';
import ApiDropdown from 'components/dropdown/api-dropdown/ApiDropdown';
import { DropdownItem } from 'components/dropdown/Dropdown';
import React, { useEffect, useMemo, useState } from 'react';
import Input from 'components/form/input/Input';
import SaveButton from 'components/save-button/SaveButton';
import TextEditor from 'components/form/text-editor/TextEditor';

import { EditorState } from 'draft-js';
import { Controller, useForm } from 'react-hook-form';
import { CREATE_OR_UPDATE_TEMPLATE } from 'graphql/templates/mutations';
import {
  createStateFromDefaultValue,
  suggestions,
} from 'utils/templateHelpers';
import { Template } from 'types/templateTypes';
import { useParams, useLocation, Link } from 'react-router-dom';
import AutoSuggest from 'components/auto-suggest/AutoSuggest';
import { Body, Footnote } from 'components/typography/Typography';
import { Routes } from 'types/routeTypes';
import Label from 'components/form/Label';
import { useMutation } from 'hooks/sympl-mutation';
import { useQuery } from 'hooks/sympl-query';
import { GET_TEMPLATE } from 'graphql/templates/queries';
import { CaretLeft, EnvelopeSimple } from '@phosphor-icons/react';
import useUnsavedFields from 'hooks/unsaved';
import useNavigationContext from 'hooks/context/nav-context';
import { useNavigate } from 'react-router-dom';
import { getLanguageValue } from 'utils/languageHelpers';
import { fireEvent } from 'utils/eventHelper';

interface UpdateTemplateResponse {
  template: {
    id?: number;
  };
}

interface UpdateTemplatePayload {
  customerId?: number;
  input: Template;
}

interface TemplateState {
  language?: number;
  subject?: string;
  body?: string;
}

const TemplateEditor: React.FC = () => {
  const location = useLocation();
  const defaultTemplate = location?.state as TemplateState;
  const navigate = useNavigate();
  // const defaultTemplate = location?.state;
  const { template: templateId } = useParams<{ template?: string }>();
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  // TODO: rework this into the language context (get all languages from there and map them) - 02/10/20024 - 16h42
  const [language, setLanguage] = useState<number>(141); // english default
  const [languageName, setLanguageName] = useState<string>('English'); // english default
  const { isAdmin } = useNavigationContext();

  useEffect(() => {
    setLanguageName(getLanguageValue(language) || 'English');
  }, [language]);

  const formMethods = useForm();
  const { control, handleSubmit } = formMethods;

  const [shouldSaveChanges, resetUnsavedFlag] = useUnsavedFields(formMethods);

  const { loading, data } = useQuery<{ template: Template }>(GET_TEMPLATE, {
    fetchPolicy: 'cache-and-network',
    variables: { id: templateId },
    skip: !templateId,
  });

  const [createOrUpdateTemplate, { loading: updateTemplateLoading }] =
    useMutation<UpdateTemplateResponse, UpdateTemplatePayload>(
      CREATE_OR_UPDATE_TEMPLATE
    );

  const originalTemplate = useMemo(
    () =>
      data?.template ??
      ({
        name: '',
        body: defaultTemplate?.body ?? '',
        language_id: defaultTemplate?.language ?? 141,
        subject: defaultTemplate?.subject ?? '',
      } as Template),
    [data?.template, defaultTemplate]
  );

  useEffect(() => {
    setEditorState(createStateFromDefaultValue(originalTemplate.body));
  }, [originalTemplate]);

  const readOnly = useMemo(
    () =>
      data?.template && // TEMPLATE AVAILABLE
      (!!data?.template.slug || !data?.template.customer_id) && // HAS SLUG OR IS NOT FROM CUSTOMER
      !isAdmin, // NOT ADMIN
    [data?.template, isAdmin]
  );

  const onSubmit = (data: Template) => {
    const input = { ...data, id: templateId } as Template;
    createOrUpdateTemplate({
      variables: {
        input,
      },
    }).then((response) => {
      fireEvent('update_mail_template', {
        template: response.data?.template.id,
      });
      const templateId = response.data?.template.id;
      if (templateId)
        navigate(`${Routes.TEMPLATE_SETTINGS}/${templateId}`, {
          replace: true,
        });
    });
  };

  const languageChangeHandler = (
    item: DropdownItem | string,
    onChange: (arg: number | string) => void
  ) => {
    setLanguage(typeof item === 'string' ? Number(item) : Number(item.key));
    onChange(typeof item === 'string' ? item : item.key);
  };

  const [visible, setVisible] = useState(false);
  const editorWrapperRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleWindowClick = (e: MouseEvent) => {
      // Hide the autosuggest if the click was outside of the editor
      // Don't use onBlur of text editor as it will hide the autosuggest when the user clicks on it
      if (!editorWrapperRef.current?.contains(e.target as Node))
        setVisible(false);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === '#') setVisible(true);
      else setVisible(false);
    };

    document.addEventListener('click', handleWindowClick);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('click', handleWindowClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (loading) return <div>Loading</div>;

  return (
    <div tw="p-1">
      <div tw="flex justify-between">
        <Link
          tw="flex items-center justify-between text-gray-600"
          to={Routes.TEMPLATE_SETTINGS}
        >
          <div tw="flex flex-row items-center">
            <CaretLeft weight="bold" size={20} tw="mr-1" />
            <Body>Back to overview</Body>
          </div>
        </Link>
        <div tw="flex flex-row-reverse">
          {!readOnly && (
            <SaveButton
              type="submit"
              loading={updateTemplateLoading}
              shouldSave={shouldSaveChanges}
              onDone={resetUnsavedFlag}
              form="template-form"
            />
          )}
        </div>
      </div>
      <form
        id="template-form"
        tw="flex flex-col my-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div tw="flex flex-row gap-4">
          <section tw="flex-1">
            <Label htmlFor="name">Name</Label>
            <Controller
              id="name"
              name="name"
              defaultValue={originalTemplate.name}
              control={control}
              register
              rules={{
                required: true,
              }}
              render={({ value, onChange }) => (
                <Input
                  id="name"
                  name="name"
                  defaultValue={value}
                  disabled={readOnly}
                  onChange={(e) => onChange(e.target.value)}
                />
              )}
            />
          </section>
          <section>
            <Label htmlFor="language_id">Language</Label>
            <div>
              <Controller
                id="language_id"
                name="language_id"
                control={control}
                register
                defaultValue={originalTemplate.language_id}
                rules={{
                  required: true,
                }}
                render={({ onChange, value }) => (
                  <ApiDropdown
                    enableSearch
                    mode="key"
                    type="languages"
                    value={value}
                    disabled={readOnly}
                    onChange={(item) => {
                      item && languageChangeHandler(item, onChange);
                    }}
                  />
                )}
              />
            </div>
          </section>
        </div>
        <div tw="grow">
          <div>
            <Label htmlFor="subject">Subject</Label>
            <div>
              <Controller
                id="subject"
                name="subject"
                control={control}
                register
                defaultValue={originalTemplate.subject}
                rules={{
                  required: true,
                }}
                render={({ value, onChange }) => (
                  <Input
                    id="subject"
                    name="subject"
                    defaultValue={value}
                    disabled={readOnly}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
            </div>
          </div>
          <div>
            <Label htmlFor="body">Body</Label>
            <div>
              <Controller
                id="body"
                name="body"
                defaultValue={originalTemplate.body}
                control={control}
                register
                rules={{
                  required: true,
                }}
                render={({ value, onChange }) => (
                  <>
                    <TextEditor
                      identifier="template_editor"
                      editorWrapperRef={editorWrapperRef}
                      defaultValue={value}
                      externalState={editorState}
                      disabled={readOnly}
                      setExternalState={setEditorState}
                      autoSuggest={
                        <AutoSuggest
                          editorWrapperRef={editorWrapperRef}
                          visible={visible}
                          setVisible={setVisible}
                          suggestions={suggestions}
                          editorState={editorState}
                          setEditorState={setEditorState}
                        />
                      }
                      onChange={onChange}
                      aiEnabled={!readOnly}
                      initialContext={[
                        {
                          role: 'assistant',
                          content: `You always reply in ${languageName}`,
                        },
                        {
                          role: 'assistant',
                          content: `Every line should be wrapped inside a <p></p> tag.`,
                        },
                      ]}
                      aiSuggestedActions={[
                        {
                          icon: (
                            <EnvelopeSimple
                              weight="bold"
                              tw="text-indigo-500"
                            />
                          ),
                          label: 'Write a template about ...',
                          prompt: 'Write a template about ',
                        },
                      ]}
                    />
                    <Footnote mt={1}>Tip: Type # to insert variables.</Footnote>
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TemplateEditor;
